const tipsArr = (t) => {
  const sharedT = (key) => t(`change_password_form.${key}`);
  return [
    {
      id: 1,
      name: sharedT('capitalChar')
    },
    {
      id: 2,
      name: sharedT('smallChar')
    },
    {
      id: 3,
      name: sharedT('symbolChar')
    },
    {
      id: 4,
      name: sharedT('numberChar')
    },
    {
      id: 5,
      name: sharedT('eightChars')
    },
    {
      id: 6,
      name: sharedT('noName')
    }
  ];
};

export default tipsArr;
