/* eslint-disable eqeqeq */
import { Link as RouterLink } from 'react-router-dom';
import eyeImg from '../../../assets/imgs/icons/lefted-cart/eye.svg';
import phoneImg from '../../../assets/imgs/icons/lefted-cart/phone.svg';
import whatsappImg from '../../../assets/imgs/icons/lefted-cart/whatsapp.svg';
import CustomImage from '../../../common/custom-image/CustomImage';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';

const tableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t
) => {
  // const handleViewDelegateDetails = (record) => {
  //   const found = allFetchedTableData.find((obj) => obj.id === record.id);
  //   if (found) {
  //     setSelectedTableRow({ ...found });
  //     setDetailsModalOpened(true);
  //   }
  // };
  const sharedT = (key) => t(`leftedCartPage.${key}`);

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap lefted-cart-row-btns">
        <RouterLink
          to={merchentRouterLinks?.storeLeftedCartDetailsRoute(
            record?.cart?.length > 0 ? record.cart[0]?.store_id : '',
            record?.id ? record.id : ''
          )}
          className="row-btn"
        >
          <img src={eyeImg} alt="details" />
        </RouterLink>
        {/* <button
          className="row-btn"
          onClick={() => {
            setSelectedTableRow(record);
            setFormModalOpened(true);
          }}
        >
          <img src={shareImg} alt="Share" />
        </button> */}
        <button className="row-btn">
          <a
            className="whatsapp-link"
            href={`tel:+${record?.phone}`}
            rel="noreferrer"
          >
            <img src={phoneImg} alt="phone" />
          </a>
        </button>
        <button className="row-btn">
          <a
            className="whatsapp-link"
            href={`https://wa.me/${record?.phone}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={whatsappImg} alt="whatsapp" />
            {/* <span>{record?.phone}</span> */}
          </a>
        </button>
      </div>
    );
  };

  // const renderTotalPrice = (arr) => {
  //   return arr.reduce((acc, curr) => {
  //     let currPrice = curr?.totalPrice ? parseFloat(curr.totalPrice) : 0;
  //     return currPrice + acc;
  //   }, 0);
  // };

  return [
    {
      title: sharedT('clientName'),
      dataIndex: 'clientName',
      width: 242,
      render: (_, record) => {
        return (
          <div className="name-photo-wrap">
            <CustomImage src={record?.image} />
            <div className="name-phone">
              <p>{record?.name}</p>
              <p>{record?.phone}</p>
            </div>
          </div>
        );
      }
    },
    // {
    //   title: sharedT('createdAt'),
    //   dataIndex: 'createdAt',
    //   render: (_, record) => {
    //     return record?.created_at ? record.created_at : '---';
    //   }
    // },
    {
      title: sharedT('totalProudcts'),
      dataIndex: 'totalProudcts',
      render: (_, record) => {
        return record?.cart?.length
          ? record?.cart.reduce((acc, curr) => curr?.quantity + acc, 0)
          : '---';
      }
    },
    // {
    //   title: sharedT('cartCost'),
    //   dataIndex: 'cartCost',
    //   render: (_, record) => {
    //     return record?.cart?.length
    //       ? `${renderTotalPrice(record.cart)} ${t('currency.sar')}`
    //       : '---';
    //   }
    // },

    {
      title: sharedT('action'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default tableColumns;
