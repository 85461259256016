/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import AffiliatePageTable from './AffiliatePageTable';
import { useContext, useEffect } from 'react';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import getAllAgentsApi from '../../../apis/stores-apis/getAllAgentsApi';
import AffiliateContext from '../../../contexts/affiliate-context/AffiliateContext';
import queryString from 'query-string';
import UserContext from '../../../contexts/user-context/UserProvider';
import checkRes from '../../../utils/checkRes';
import NewAffiliateModal from './NewAffiliateModal';
import './MerchentAffiliatePage.scss';
import useSingleProduct from '../../../custom-hooks/useSingleProduct';
import productStatus from '../../../const-values/productStatus';

const MerchentAffiliatePage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { user } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const { fetchedData } = useSingleProduct();

  useEffect(() => {
    if (
      fetchedData?.status == productStatus?.underReview ||
      fetchedData?.status == productStatus?.rejected
    ) {
      navigate(merchentRouterLinks?.merchentDashboard);
    }
  }, [fetchedData]);

  const {
    fetchTableDataCount,
    tableFilter,
    setIsLoadingTableData,
    setAllFetchedTableData,
    setTablePagination,
    formModalOpened,
    setFormModalOpened
  } = useContext(AffiliateContext);
  const sharedTrans = (key) => t(`merchentDashboard.${key}`);

  const customApiRequest = useCustomApiRequest();
  const getPageDataRequest = () => {
    customApiRequest(
      getAllAgentsApi(
        {
          ...tableFilter,
          ...values,
          store_id: params?.store_id,
          product_id: params?.product_id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        setIsLoadingTableData(false);
        if (checkRes(res) && res.data?.data?.data?.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedTableData(data);
        } else {
          setAllFetchedTableData(null);
        }
        if (res.data.data?.pagination) {
          setTablePagination(res.data.data.pagination);
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );
  };

  //

  useEffect(() => {
    let isMounted = true;
    if (
      isMounted &&
      (fetchedData || fetchedData?.status != productStatus?.underReview)
    ) {
      setIsLoadingTableData(true);
      getPageDataRequest();
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchTableDataCount, tableFilter, search]);

  return (
    <div className="merchent-affiliate-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.merchentDashboard
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('affiliate'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-create-affiliate-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">{sharedTrans('affiliate')}</div>
          </div>

          <Button
            className="create-new-aff-btn"
            onClick={() => setFormModalOpened(true)}
          >
            <PlusOutlined />
            {t('affiliatePage.addNew')}
          </Button>
        </div>

        <AffiliatePageTable />
      </div>

      {formModalOpened && <NewAffiliateModal />}
    </div>
  );
};

export default MerchentAffiliatePage;
