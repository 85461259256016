/* eslint-disable eqeqeq */
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cashImg from '../../assets/imgs/icons/cash.png';
import madaImg from '../../assets/imgs/icons/mada.svg';
import masterCardImg from '../../assets/imgs/icons/master-card.svg';
import tabbyImg from '../../assets/imgs/icons/tabby.png';
import visaImg from '../../assets/imgs/icons/visa.svg';
import walletImg from '../../assets/imgs/icons/wallet.png';
import { paymentTypesValues } from '../../const-values/paymentTypes';
import CartContext from '../../contexts/cart-context/CartContext';
import UserContext from '../../contexts/user-context/UserProvider';
import './PaymentSection.scss';

const PaymentSection = () => {
  const { t } = useTranslation();
  const { paymentType, setPaymentType, cartProducts } = useContext(CartContext);

  const { user } = useContext(UserContext);
  const renderPaymentBtns = () => {
    if (cartProducts?.length > 0) {
      const foundOnlinePayment = cartProducts.find(
        (obj) => obj?.paymentTypeStore === paymentTypesValues?.onlinePayment
      );
      if (foundOnlinePayment)
        return (
          <div className="btns-wrap">
            {/* <button
              className={`payment-btn cash-btn ${
                paymentType == paymentTypesValues?.cashPayment ? 'selected' : ''
              }`}
              onClick={() => {
                setPaymentType(paymentTypesValues?.cashPayment);
              }}
            >
              <img src={cashImg} alt="cash" />
            </button> */}
            <button
              onClick={() => {
                setPaymentType(paymentTypesValues?.onlinePayment);
              }}
              className={`payment-btn online-btn ${
                paymentType == paymentTypesValues?.onlinePayment
                  ? 'selected'
                  : ''
              }`}
            >
              <ul className="images-list">
                <li>
                  <img src={madaImg} alt="mada" />
                </li>
                <li>
                  <img src={masterCardImg} alt="master-card" />
                </li>
                <li>
                  <img src={visaImg} alt="visa" />
                </li>
              </ul>
            </button>

            {/* <button
              className={`payment-btn tamara-btn ${
                paymentType == paymentTypesValues?.tamara ? 'selected' : ''
              }`}
              onClick={() => {
                setPaymentType(paymentTypesValues?.tamara);
              }}
              style={{
                backgroundImage: `url(${tamaraImg})`
              }}
            >
            </button> */}

            <button
              className={`payment-btn tabby-btn ${
                paymentType == paymentTypesValues?.tabby ? 'selected' : ''
              }`}
              onClick={() => {
                setPaymentType(paymentTypesValues?.tabby);
              }}
              style={{
                backgroundImage: `url(${tabbyImg})`
              }}
            >
              {/* <img src={tamaraImg} alt="tamara" /> */}
            </button>

            <button
              className={`payment-btn wallet-btn ${
                paymentType == paymentTypesValues?.walletPayment
                  ? 'selected'
                  : ''
              }`}
              onClick={() => {
                setPaymentType(paymentTypesValues?.walletPayment);
              }}
            >
              <img src={walletImg} alt="wallet" />
              {/* <p>{t('paymentOptions.walletPayment.label')}</p> */}
              <p>
                {user?.wallet} {t('currency.sar')}
              </p>
            </button>
          </div>
        );

      return (
        <div className="btns-wrap">
          <button
            className={`payment-btn cash-btn ${
              paymentType == paymentTypesValues?.cashPayment ? 'selected' : ''
            }`}
            onClick={() => {
              setPaymentType(paymentTypesValues?.cashPayment);
            }}
          >
            <img src={cashImg} alt="cash" />
          </button>

          <button
            className={`payment-btn wallet-btn ${
              paymentType == paymentTypesValues?.walletPayment ? 'selected' : ''
            }`}
            onClick={() => {
              setPaymentType(paymentTypesValues?.walletPayment);
            }}
          >
            <img src={walletImg} alt="wallet" />
            <p>
              {user?.wallet} {t('currency.sar')}
            </p>
          </button>
        </div>
      );
    }
  };

  return (
    <div className="cart-payment-section">
      <h3>{t('checkoutForm.pyament')}</h3>
      {renderPaymentBtns()}
    </div>
  );
};

export default PaymentSection;
