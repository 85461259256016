import React from 'react';

const DropdownIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        width="13"
        height="7"
        viewBox="0 0 13 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.7958 0.890307C11.6914 0.785764 11.5673 0.702833 11.4308 0.646251C11.2942 0.58967 11.1479 0.560547 11.0001 0.560547C10.8523 0.560547 10.7059 0.58967 10.5694 0.646251C10.4329 0.702833 10.3088 0.785764 10.2043 0.890307L6.76484 4.32906C6.69452 4.39936 6.59916 4.43885 6.49972 4.43885C6.40028 4.43885 6.30492 4.39936 6.23459 4.32906L2.79584 0.890307C2.5849 0.679261 2.29875 0.560657 2.00036 0.560586C1.70197 0.560516 1.41577 0.678985 1.20472 0.889931C0.993674 1.10088 0.87507 1.38702 0.875 1.68542C0.87493 1.98381 0.993399 2.27001 1.20435 2.48106L4.64384 5.92056C4.8876 6.16434 5.17699 6.35772 5.49549 6.48966C5.81399 6.62159 6.15535 6.6895 6.50009 6.6895C6.84484 6.6895 7.1862 6.62159 7.5047 6.48966C7.8232 6.35772 8.11259 6.16434 8.35634 5.92056L11.7958 2.48106C12.0068 2.27009 12.1252 1.98399 12.1252 1.68568C12.1252 1.38737 12.0068 1.10128 11.7958 0.890307Z"
          fill={color ? color : '#C4C4C4'}
        />
      </svg>
    </span>
  );
};

export default DropdownIcon;
