import axios from 'axios';

const getAllUserOrdersApi = async (paramsFilter, token, langIsoCode) => {
  try {
    const url = `/OrderUser/myOrders?status=${
      paramsFilter?.status ? paramsFilter.status : ''
    }&page=${paramsFilter?.page ? paramsFilter.page : ''}`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllUserOrdersApi;
