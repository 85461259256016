export const affiliatFeesTypes = {
  static: '1',
  percentage: '2'
};
export const firstOrEach = {
  first: '1',
  each: '2'
};
export const agentTypes = {
  active: '1',
  notActive: '2'
};
