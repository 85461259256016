/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import EyeOpenedIcon from '../../common/icons/EyeOpenedIcon';
import EyeClosedIcon from '../../common/icons/EyeClosedIcon';
import { Link as RouterLink } from 'react-router-dom';
import AntdCheckbox from '../../common/antd-form-components/AntdCheckbox';
import routerLinks from '../../components/app/routerLinks';
import useSignupEmailPassword from '../../custom-hooks/useSignupEmailPassowrd';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import FileInput from '../../common/file-input/FileInput';
import LockIcon from '../../common/icons/LockIcon';
import UserIcon from '../../common/icons/UserIcon';
import EmailIcon from '../../common/icons/EmailIcon';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import accountTypes from '../../const-values/accountTypes';
import CustomMap from '../../components/custom-map/CustomMap';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import TermsModal from './TermsModal';
import PinIcon from '../../common/icons/PinIcon';
import './SignupForm.scss';
import LoadingModal from '../../common/loading-modal/LoadingModal';

// import useFirebaseDeviceToken from '../../custom-hooks/useFirebaseDeviceToken';

const MerchentSignupForm = ({ countries }) => {
  const { i18n, t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  useContext(ForgetPasswordContext);
  const generalLabelStr = (v) => t(`signup_form.${v}.label`);
  const generalrequiredErrStr = (v) => t(`signup_form.${v}.errors.required`);
  const generalTypeErrorStr = (v) => t(`signup_form.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`signup_form.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    name: Yup.string().required(generalrequiredErrStr('name')),
    saloonName: Yup.string().required(generalrequiredErrStr('saloonName')),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(10, generalMinErrorStr('phone', 10)),
    email: Yup.string()
      .required(generalrequiredErrStr('email'))
      .email(generalTypeErrorStr('email')),
    password: Yup.string().required(generalrequiredErrStr('password')),
    password_confirmation: Yup.string()
      .required(generalrequiredErrStr('password_confirmation'))
      .oneOf(
        [Yup.ref('password')],
        generalTypeErrorStr('password_confirmation')
      ),
    terms: Yup.boolean()
      .required(t('signup_form.requiredTerms'))
      .oneOf([true], t('signup_form.requiredTerms'))
  });
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
      saloonName: '',
      area: '',
      country_id: '',
      city_id: '',
      saloonDescription_ar: '',
      saloonDescription_en: '',
      saloonFiles: [],
      remember: true
    }
  });

  const [termsModalOpened, setTermsModalOpened] = useState(false);
  const [selectedLocation, setSelecectedLocation] = React.useState({
    lat: '',
    lng: ''
  });
  const [selectedAddress, setSelectedAddress] = React.useState('');

  const { isLoadingSignup, signMeUpWithEmailPassword } =
    useSignupEmailPassword();
  const onSubmit = (data) => {
    const formatedIntlPhone = formatPhoneNumberIntl(data.phone); // '+966 50 123 4444'
    const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    const countryCode = phoneStrArr[0];
    let phone = '';
    if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    else if (phoneStrArr?.length > 2) {
      let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
      phone = array.join('');
    }

    signMeUpWithEmailPassword({
      ...data,
      phone,
      countryCode,
      lat: selectedLocation?.lat ? selectedLocation.lat : '23.8859',
      lng: selectedLocation?.lng ? selectedLocation.lng : '45.0792',
      accountType: accountTypes()?.merchent
    });
  };

  const handleCityOptions = (countryId) => {
    if (countryId && countries?.length > 0) {
      const foundCountry = countries.find((c) => c.id == countryId);
      if (foundCountry && foundCountry.cities?.length > 0) {
        return foundCountry.cities.map((city) => ({
          title: city?.name,
          value: String(city?.id)
        }));
      }
    }
    return [];
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="signup-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-header">
          <p className="bold-font main-title">{t('signup_form.newAcc')}</p>
          <div>
            <p className="sub-title">{t('signup_form.subTitle')}</p>
          </div>
        </div>

        <div className="form-body">
          <AntdTextField
            name="name"
            type="text"
            placeholder={generalLabelStr('name')}
            label={generalLabelStr('name')}
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<UserIcon />}
            control={control}
          />
          <AntdTextField
            name="saloonName"
            type="text"
            placeholder={generalLabelStr('saloonName')}
            label={generalLabelStr('saloonName')}
            errorMsg={errors?.saloonName?.message}
            validateStatus={errors?.saloonName ? 'error' : ''}
            prefix={<UserIcon />}
            control={control}
          />
          <AntdTextField
            name="email"
            type="text"
            placeholder={generalLabelStr('email')}
            label={generalLabelStr('email')}
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EmailIcon />}
            control={control}
          />

          <div
            className={`country-code-phone-wrap ${i18n.dir()}`}
            style={{
              marginBottom: 28
            }}
          >
            <p
              style={{
                paddingBottom: 8
              }}
            >
              {generalLabelStr('phone')}
            </p>
            <Controller
              name="phone"
              control={control}
              // render={({ field: { onChange, onBlur, value, name, ref } }) => {
              render={({ field }) => {
                return (
                  <PhoneInput
                    {...field}
                    placeholder={generalLabelStr('phone')}
                    // value={phoneValue}
                    // onChange={setPhoneValue}
                    defaultCountry="SA"
                    className={`custom-phone-input ${i18n.language}`}
                  />
                );
              }}
            />
            <p className="error-p">{errors?.phone?.message}</p>

            {/* <PhoneInput
              placeholder="Enter phone number"
              value={phoneValue}
              onChange={setPhoneValue}
              defaultCountry="SA"
              className={`custom-phone-input ${i18n.language}`}
            /> */}
          </div>

          <div className="login-password-field-wrap">
            <AntdTextField
              name="password"
              type={passwordVisible ? 'text' : 'password'}
              placeholder={generalLabelStr('password')}
              label={generalLabelStr('password')}
              errorMsg={errors?.password?.message}
              prefix={<LockIcon />}
              validateStatus={errors?.password ? 'error' : ''}
              control={control}
            />

            {watch('password') && (
              <div
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwordVisible ? <EyeOpenedIcon /> : <EyeClosedIcon />}
              </div>
            )}
          </div>
          <div className="login-password-field-wrap">
            <AntdTextField
              name="password_confirmation"
              type={passwordVisible ? 'text' : 'password'}
              placeholder={generalLabelStr('password_confirmation')}
              label={generalLabelStr('password_confirmation')}
              errorMsg={errors?.password_confirmation?.message}
              prefix={<LockIcon />}
              validateStatus={errors?.password_confirmation ? 'error' : ''}
              control={control}
            />

            {watch('password_confirmation') && (
              <div
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwordVisible ? <EyeOpenedIcon /> : <EyeClosedIcon />}
              </div>
            )}
          </div>

          <div className="select-label-wrap">
            <p className="label-p">{t('signup_form.country.label')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`country_id`}
                errorMsg={errors?.country_id && errors.country_id.message}
                validateStatus={errors?.country_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('signup_form.country.label')}
                prefix={<UserIcon />}
                options={
                  countries?.length > 0 &&
                  countries.map((obj) => ({
                    title: obj?.name,
                    value: String(obj?.id)
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="signup-form"
              />
            </div>
          </div>

          <div className="select-label-wrap">
            <p className="label-p">{t('signup_form.city.label')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`city_id`}
                errorMsg={errors?.city_id && errors.city_id.message}
                validateStatus={errors?.city_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('signup_form.city.label')}
                options={handleCityOptions(watch('country_id'))}
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="signup-form"
              />
            </div>
          </div>

          <AntdTextField
            name="area"
            type="text"
            placeholder={t('signup_form.area.label')}
            label={t('signup_form.area.label')}
            errorMsg={errors?.area?.message}
            validateStatus={errors?.area ? 'error' : ''}
            prefix={<PinIcon />}
            control={control}
          />
          <AntdTextField
            name="street"
            type="text"
            placeholder={t('signup_form.street.label')}
            label={t('signup_form.street.label')}
            errorMsg={errors?.street?.message}
            validateStatus={errors?.street ? 'error' : ''}
            prefix={<PinIcon />}
            control={control}
          />
          <AntdTextField
            name="buildingNumber"
            type="text"
            placeholder={t('signup_form.buildingNumber.label')}
            label={t('signup_form.buildingNumber.label')}
            errorMsg={errors?.buildingNumber?.message}
            validateStatus={errors?.buildingNumber ? 'error' : ''}
            prefix={<PinIcon />}
            control={control}
          />

          {/* <AntdTextField
            name="address"
            type="text"
            placeholder={t('signup_form.address.label')}
            label={t('signup_form.address.label')}
            errorMsg={errors?.address?.message}
            validateStatus={errors?.address ? 'error' : ''}
            prefix={<PinIcon />}
            control={control}
          /> */}
          <div className="text-area-label-wrap">
            <p className="label-p">{t('signup_form.description_ar.label')}</p>
            <div className="text-field-wrap">
              <AntdTextarea
                className="form-text-area white-bg"
                name="saloonDescription_ar"
                rows={4}
                placeholder={t('signup_form.description_ar.label')}
                errorMsg={errors?.saloonDescription_ar?.message}
                validateStatus={errors?.saloonDescription_ar ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-area-label-wrap">
            <p className="label-p">{t('signup_form.description_en.label')}</p>
            <div className="text-field-wrap">
              <AntdTextarea
                className="form-text-area white-bg"
                name="saloonDescription_en"
                rows={4}
                placeholder={t('signup_form.description_en.label')}
                errorMsg={errors?.saloonDescription_en?.message}
                validateStatus={errors?.saloonDescription_en ? 'error' : ''}
                control={control}
              />
            </div>
          </div>

          <CustomMap
            width="100%"
            height="400px"
            selectedLocation={selectedLocation}
            setSelecectedLocation={setSelecectedLocation}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            isDraggable={true}
            hasAutoComplete={true}
          />

          <div
            className="images-wrap"
            style={{
              marginTop: 22
            }}
          >
            <FileInput
              name="saloonFiles"
              label=""
              // accept="image/png, image/jpg, image/jpeg, image/gif"
              accept="image/*"
              multiple={true}
              setValue={setValue}
              watch={watch}
              register={register}
              unregister={unregister}
              // setUrls={setUrls}
              dropzoneText={t('signup_form.storeImg')}
              className="product-images-dropzone"
              dropzoneUrls={[]}
              canDelete={false}
            />
          </div>

          <AntdCheckbox
            name="remember"
            control={control}
            label={t('signup_form.remember_me')}
            errorMsg={errors?.remember?.message}
            validateStatus={errors?.remember ? 'error' : ''}
          />
          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
          <div>
            <div className="terms-checkbox-wrap">
              <AntdCheckbox
                name="terms"
                control={control}
                label={t('signup_form.accept')}
                style={{ marginBottom: 0 }}

                // errorMsg={errors?.terms?.message}
                // validateStatus={errors?.terms ? 'error' : ''}
              />
              <button
                className="terms-btn"
                onClick={(e) => {
                  setTermsModalOpened(true);
                  e.preventDefault();
                }}
                to={generalRouterLinks?.refundTermsRoute}
              >
                {t('signup_form.terms')}
              </button>
            </div>
            {errors?.terms?.message && (
              <p className="error-p">{errors.terms.message}</p>
            )}
          </div>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isLoadingSignup}
          >
            {isSubmitting
              ? t('signup_form.submit_btn.loading')
              : t('signup_form.submit_btn.label')}
          </Button>
        </div>

        <div className="already-have-account">
          <span>{t('signup_form.haveAccount')} </span>{' '}
          <RouterLink to={routerLinks?.signinPage}>
            {t('signup_form.signin')}
          </RouterLink>
        </div>
      </Form>
      {termsModalOpened && (
        <TermsModal
          modalOpened={termsModalOpened}
          setModalOpened={setTermsModalOpened}
        />
      )}

      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default MerchentSignupForm;
