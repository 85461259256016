/* eslint-disable eqeqeq */
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Popover } from 'antd';

const UserMessagesTableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t,
  props
) => {
  const sharedT = (key) => t(`messagesPage.${key}`);
  return [
    {
      title: sharedT('name'),
      dataIndex: 'name',
      width: '20%',
      render: (_, record) => {
        return record?.title || '----';
      }
    },
    {
      title: sharedT('description'),
      dataIndex: 'details',
      // width: 192,
      render: (_, record) => {
        return (
          <Popover title={sharedT('description')} content={record.description}>
            <Button
              style={{
                width: 38,
                height: 38
              }}
              size="large"
              type="dashed"
              shape="round"
              icon={<EyeOutlined />}
            />
          </Popover>
        );
      }
    },
    {
      title: sharedT('url'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {!!record.url && (
              <Button shape="circle" type="text">
                <a download target="_blank" href={record.url} rel="noreferrer">
                  <DownloadOutlined />
                </a>
              </Button>
            )}
          </div>
        );
      }
    },
    {
      title: sharedT('image'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {!!record.image && (
              <Button shape="circle" type="text">
                <a
                  download
                  target="_blank"
                  href={record.image}
                  rel="noreferrer"
                >
                  <DownloadOutlined />
                </a>
              </Button>
            )}
          </div>
        );
      }
    },
    {
      title: sharedT('video'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {!!record.video && (
              <Button shape="circle" type="text">
                <a
                  download
                  target="_blank"
                  href={record.video}
                  rel="noreferrer"
                >
                  <DownloadOutlined />
                </a>
              </Button>
            )}
          </div>
        );
      }
    }
  ];
};

export default UserMessagesTableColumns;
