export const hasOfferValues = {
  yes: 1,
  no: 2
};

export const hasDiscountValues = {
  yes: 1,
  no: 0
};

export const offerTypes = {
  static: '1',
  percentage: '2'
};
export const notificationTypes = {
  email: '1',
  notification: '2'
};
