/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getCountriesApi from '../apis/general/getCountriesApi';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useCountries = () => {
  const { i18n } = useTranslation();
  const [isLoadingCountries, setIsLoadingCountries] = useState(false);
  const [fetchCountriesCount, setFetchCountriesCount] = useState(0);
  const [allFetchedCountries, stAllFetchedCountries] = useState([]);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    setIsLoadingCountries(true);
    customApiRequest(
      getCountriesApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoadingCountries(false);
          if (checkRes(res) && res?.data?.data) {
            stAllFetchedCountries(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoadingCountries(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCountriesCount]);

  return {
    isLoadingCountries,
    setIsLoadingCountries,
    //
    fetchCountriesCount,
    setFetchCountriesCount,
    //
    allFetchedCountries,
    stAllFetchedCountries
  };
};

export default useCountries;
