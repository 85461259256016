import ArchHeading from '../../../common/arch-heading/ArchHeading';
import SlickSliderBtns from '../../../utils/SlickSliderBtns';
import SharedProductsSlider from './SharedProductsSlider';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BlogCard from '../../blogs-page/BlogCard';
import './BlogsSection.scss';
import generalRouterLinks from '../../../components/app/general-routes/gerneralRouterLinks';

const BlogsSlider = ({ slides, isLoading, setFetchCount }) => {
  // SlickSliderBtns('trendy-products-slider', false);
  const { t } = useTranslation();
  SlickSliderBtns('blogs-slider', isLoading);

  const renderMySlide = (item) => {
    return (
      <div className="slide-wrap" key={item.id}>
        <BlogCard card={item} className="slider-blog-card" />
      </div>
    );
  };

  return (
    <section className="blogs-section">
      <div className="custom-container">
        <div className="arch-all-wrapper">
          <ArchHeading title={t('homeSections.blogs')} />
          <Link to={generalRouterLinks?.blogsRoute}>
            {t('homeSections.seeAllBlogs')}
          </Link>
        </div>
        <SharedProductsSlider
          slides={slides}
          setFetchCount={setFetchCount}
          className="blogs-slider"
          renderMySlide={renderMySlide}
        />
      </div>
    </section>
  );
};

export default BlogsSlider;
