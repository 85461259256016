import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import EmailIcon from '../../../common/icons/EmailIcon';
// import fbImg from '../../../assets/imgs/icons/social-icons/facebook-icon.svg';
// import instagramImg from '../../../assets/imgs/icons/social-icons/instagram-icon.svg';
import snapchatImg from '../../../assets/imgs/icons/social-icons/snapchat-icon.svg';
import twitterImg from '../../../assets/imgs/icons/social-icons/twitter-icon.svg';
import tiktokImg from '../../../assets/imgs/icons/social-icons/tiktok.svg';
import youtubeImg from '../../../assets/imgs/icons/social-icons/youtube.svg';

import './SubscriptionSection.scss';
import GeneralSettingsContext from '../../../contexts/general-settings-context/GeneralSettingsContext';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import joinUsApi from '../../../apis/general/joinUsApi';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';

const SubscriptionSection = () => {
  const { fetchedGeneralSettings } = useContext(GeneralSettingsContext);
  const { t, i18n } = useTranslation();
  // const fcmDeviceToken = useFCMDeviceToken();

  const schema = Yup.object().shape({
    // email: Yup.string()
    //   .required('من فضلك ادخل البريد الاكترونى')
    //   .email('ادخل بريد الكترونى صحيح'),
    email: Yup.string()
      .required(t('subscriptionForm.email.errors.required'))
      .email(t('subscriptionForm.email.errors.email'))
  });
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      email: ''
    }
  });

  const arr = [
    // {
    //   id: 1,
    //   img: fbImg,
    //   url: fetchedGeneralSettings?.facebook
    // },
    // {
    //   id: 2,
    //   img: instagramImg,
    //   url: fetchedGeneralSettings?.instagram
    // },
    {
      id: 3,
      img: snapchatImg,
      url: fetchedGeneralSettings?.snap
    },
    {
      id: 4,
      img: twitterImg,
      url: fetchedGeneralSettings?.twitter
    },
    {
      id: 5,
      img: tiktokImg,
      url: fetchedGeneralSettings?.tiktok
    },
    {
      id: 6,
      img: youtubeImg,
      url: fetchedGeneralSettings?.youtube
    }
  ];
  const renderSocial = () => {
    return arr.map((item) => (
      <a key={item?.id} href={item?.url} target="_blank" rel="noreferrer">
        <img src={item?.img} alt="icon" />
      </a>
    ));
  };

  const [isMounted, setIsMounted] = useState(true);
  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('email', data.email);

    setIsSubmittingForm(true);
    // setIsLoadSingleProduct(true);
    customApiRequest(
      joinUsApi(formData, i18n.language),
      (res) => {
        if (isMounted) {
          setIsSubmittingForm(false);
          // setIsLoadSingleProduct(false);
          if (checkRes(res)) {
            successNotification({
              title: t('successTitle'),
              message:
                i18n?.language === 'ar'
                  ? 'تم رفع طلب الانضمام'
                  : 'Request to join has been submitted'
            });
            reset({
              email: ''
            });
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || t('errorMessage')
            });
          }
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        // setIsLoadSingleProduct(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || t('errorMessage')
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <>
      <div className="subscription-section">
        <div className="custom-container">
          <div className="form-wrapper">
            <Form
              className="subscription-form"
              form={form}
              layout="vertical"
              onFinish={handleSubmit(onSubmit)}
            >
              <div className="form-body">
                <div className="email-field-wrap">
                  <p className="touch-p">{t('subscriptionForm.touch')}</p>
                  <AntdTextField
                    name="email"
                    type="text"
                    placeholder={t('subscriptionForm.email.label')}
                    // label={t('subscriptionForm.email.label')}
                    errorMsg={errors?.email?.message}
                    validateStatus={errors?.email ? 'error' : ''}
                    prefix={<EmailIcon />}
                    control={control}
                  />
                </div>

                {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
                <Button
                  className="submit-btn"
                  htmlType="submit"
                  type="primary"
                  // icon={<LoginOutlined />}
                  loading={isSubmittingForm}
                >
                  {isSubmitting
                    ? t('subscriptionForm.submitBtn.loading')
                    : t('subscriptionForm.submitBtn.label')}
                </Button>
              </div>
            </Form>
          </div>
          <div className="social-links-wrapper">{renderSocial()}</div>
        </div>
      </div>

      {isSubmittingForm && <LoadingModal />}
    </>
  );
};

export default SubscriptionSection;
