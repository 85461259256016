import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoadingTableData: false,
  setIsLoadingTableData: (v) => {},
  formModalOpened: false,
  setFormModalOpened: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  fetchTableDataCount: 0,
  shareLinkModalOpened: false,
  setShareLinkModalOpened: (v) => {},
  statisticsModalOpened: false,
  setStatisticsModalOpened: (v) => {},
  setFetchTableDataCount: (v) => {},
  allFetchedTableData: [],
  setAllFetchedTableData: (v) => {},
  isTogglingAgent: false,
  setIsTogglingAgent: (v) => {},
  selectedTableRow: null,
  setSelectedTableRow: (v) => {},
  copied: false,
  setCopied: (v) => {},
  copyCount: 0,
  setCopyCount: (v) => {},
  //
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  //
  deleteAffiliateModalOpened: false,
  setDeleteAffiliateModalOpened: (v) => {},
  //
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  // filter
  tableFilter: {
    page: 1,
    store_id: '',
    product_id: ''
  },
  setTableFilter: (v) => {}
};

const AffiliateContext = createContext(INITIAL_VALUES);

export const AffiliateProvider = ({ children }) => {
  const [isLoadingTableData, setIsLoadingTableData] = useState(
    INITIAL_VALUES.isLoadingTableData
  );
  const [fetchTableDataCount, setFetchTableDataCount] = useState(
    INITIAL_VALUES.fetchTableDataCount
  );
  const [allFetchedTableData, setAllFetchedTableData] = useState(
    INITIAL_VALUES.allFetchedTableData
  );
  const [selectedTableRow, setSelectedTableRow] = useState(
    INITIAL_VALUES.selectedTableRow
  );
  const [isTogglingAgent, setIsTogglingAgent] = useState(
    INITIAL_VALUES.isTogglingAgent
  );
  const [copied, setCopied] = useState(INITIAL_VALUES?.copied);
  const [copyCount, setCopyCount] = useState(INITIAL_VALUES?.copyCount);
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES.tablePagination
  );
  const [formModalOpened, setFormModalOpened] = useState(
    INITIAL_VALUES.formModalOpened
  );
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  //
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  //
  const [deleteAffiliateModalOpened, setDeleteAffiliateModalOpened] = useState(
    INITIAL_VALUES.deleteAffiliateModalOpened
  );
  const [shareLinkModalOpened, setShareLinkModalOpened] = useState(
    INITIAL_VALUES.shareLinkModalOpened
  );
  const [statisticsModalOpened, setStatisticsModalOpened] = useState(
    INITIAL_VALUES.statisticsModalOpened
  );
  //
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES.tableFilter);

  return (
    <AffiliateContext.Provider
      value={{
        isLoadingTableData,
        setIsLoadingTableData,
        formModalOpened,
        setFormModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchTableDataCount,
        setFetchTableDataCount,
        allFetchedTableData,
        setAllFetchedTableData,
        selectedTableRow,
        setSelectedTableRow,
        isTogglingAgent,
        setIsTogglingAgent,
        copied,
        setCopied,
        copyCount,
        setCopyCount,
        //
        isSubmittingForm,
        setIsSubmittingForm,
        //
        deleteAffiliateModalOpened,
        setDeleteAffiliateModalOpened,
        shareLinkModalOpened,
        setShareLinkModalOpened,
        statisticsModalOpened,
        setStatisticsModalOpened,
        //
        tablePagination,
        setTablePagination,
        tableFilter,
        setTableFilter
      }}
    >
      {children}
    </AffiliateContext.Provider>
  );
};

export default AffiliateContext;
