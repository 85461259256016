const baseLabelTransString = (t, key) => t(`product_form.${key}.label`);

export const productSettingsOptionsValues = {
  inStore: '1',
  insideCity: '2',
  outSideCity: '3',
  anotherCountry: '4'
};

export const productSettingsOptionsApiTitles = {
  inStore: 'inStorePrice',
  insideCity: 'inHomePrice',
  outSideCity: 'OutSidePrice',
  anotherCountry: 'outCountryPrice'
};
const productSettingsOptions = (t) => {
  return [
    {
      id: '1',
      name: baseLabelTransString(t, 'inStore'),
      value: '1',
      apiTitle: 'inStorePrice'
    },
    {
      id: '2',
      name: baseLabelTransString(t, 'insideCity'),
      value: '2',
      apiTitle: 'inHomePrice'
    },
    {
      id: '3',
      name: baseLabelTransString(t, 'outsideCity'),
      value: '3',
      apiTitle: 'OutSidePrice'
    },
    {
      id: '4',
      name: baseLabelTransString(t, 'anotherCountry'),
      value: '4',
      apiTitle: 'outCountryPrice'
    }
  ];
};

export const isServiceInStore = {
  checked: 0,
  notChecked: 1
};

export default productSettingsOptions;
