/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Pagination } from 'antd';
import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import getAllStoreOrdersApi from '../../../apis/stores-apis/getAllStoreOrdersApi';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import { orderStatusArr } from '../../../const-values/orderStatus';
import StoreOrdersContext from '../../../contexts/store-orders-context/StoreOrdersContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import AddressMapModal from './AddressMapModal';
import ReportClientModal from './ReportClientModal';
import StoreOrderCard from './StoreOrderCard';
import StoreOrderReceiptModal from './StoreOrderReceiptModal';
import './StoreOrdersPage.scss';
import OrderReportRepliesModal from './order-report-replies-modal';
import OrderReportsModal from './order-reports-modal';

const StoreOrdersPage = () => {
  const { user } = useContext(UserContext);
  const params = useParams();
  const {
    isLoadingOrders,
    setIsLoadingOrders,
    ordersFilter,
    fetchOrdersCount,
    setFetchOrdersCount,
    allFetchedOrders,
    setAllFetchedOrders,
    setOrdersPagination,
    isRejecting,
    isAccepting,
    isReporting,
    reportClientModalOpened,
    receiptModalOpened,
    reportsModalOpened,
    reportRepliesModal,
    addressMapModalOpened,
    ordersPagination
  } = useContext(StoreOrdersContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);

  const customApiRequest = useCustomApiRequest();
  //
  // select request user or merchent
  const chooseRequest = () => {
    customApiRequest(
      getAllStoreOrdersApi(
        {
          ...ordersFilter,
          ...values,
          store_id: params?.store_id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        window.scrollTo(0, 0);
        setIsLoadingOrders(false);
        if (checkRes(res) && res.data?.data?.data?.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedOrders(data);
          if (res.data.data?.pagination) {
            setOrdersPagination(res.data.data.pagination);
          }
        } else {
          setAllFetchedOrders(null);
        }
      },
      (error) => {
        setIsLoadingOrders(false);
      }
    );
  };

  //
  // select filter links user or merchent
  const selectFilterLinks = () => {
    return (
      <div className="filter-wrap">
        {orderStatusArr(user, t, {
          storeId: params?.storeId
        })?.length > 0 &&
          orderStatusArr(user, t, {
            storeId: params?.store_id
          }).map((obj) => {
            return (
              <RouterLink
                key={`${obj?.id}_key`}
                className={`filter-link ${i18n?.dir()} ${
                  values?.status == obj?.status ? 'active-filter' : ''
                }`}
                to={obj?.url || '/'}
              >
                {obj?.name}
              </RouterLink>
            );
          })}
      </div>
    );
  };
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setIsLoadingOrders(true);
      chooseRequest();
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchOrdersCount, ordersFilter, search]);

  const renderPageContent = () => {
    if (isLoadingOrders && fetchOrdersCount === 0) {
      return (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            padding: '132px 0',
            margin: '32px auto'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (allFetchedOrders?.length === 0)
      return (
        <div className="custom-cotainer">
          <div
            style={{
              display: 'grid',
              padding: '52px 22px'
            }}
          >
            <Empty description={false}>You don't have any orders yet !!!</Empty>
          </div>
        </div>
      );
    else if (allFetchedOrders?.length > 0)
      return (
        <>
          <ul className="orders-ul">
            {allFetchedOrders.map((card) => (
              <StoreOrderCard key={card?.id} card={card} />
            ))}
          </ul>

          {ordersPagination?.total > 0 && (
            <Pagination
              locale={{
                jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                page: ''
              }}
              showSizeChanger={false}
              defaultCurrent={1}
              current={ordersPagination.current_page}
              pageSize={ordersPagination.per_page}
              total={ordersPagination.total}
              // itemRender={itemRender}
              onChange={(page, pageSize) => {
                setFetchOrdersCount((prev) => prev + 1);
                navigate(
                  `${merchentRouterLinks?.storeOrders(
                    params?.store_id
                  )}?status=${values?.status || ''}&page=${page}`
                );
              }}
              hideOnSinglePage={true}
            />
          )}
        </>
      );

    return null;
  };

  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  return (
    <div className="store-orders-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('clientsOrders'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{sharedTrans('clientsOrders')}</div>
        </div>
        <div className="page-body">
          {selectFilterLinks()}
          {renderPageContent()}
        </div>
      </div>

      {(isLoadingOrders || isRejecting || isAccepting || isReporting) && (
        <LoadingModal />
      )}
      {reportClientModalOpened && <ReportClientModal />}
      {receiptModalOpened && <StoreOrderReceiptModal />}
      {addressMapModalOpened && <AddressMapModal />}
      {reportsModalOpened && <OrderReportsModal />}
      {!!reportRepliesModal && reportRepliesModal?.opened && (
        <OrderReportRepliesModal />
      )}
    </div>
  );
};

export default StoreOrdersPage;
