import React from 'react';

const AffiliateIcon = ({ color }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.028 8.12404C11.8063 8.12404 11.6196 7.95488 11.5905 7.73321C11.4505 6.41488 10.7388 5.21904 9.64213 4.46071C9.4438 4.32071 9.39713 4.05238 9.5313 3.85404C9.6713 3.65571 9.94546 3.60904 10.138 3.74321C11.4446 4.64738 12.2846 6.07071 12.4538 7.63988C12.4771 7.87904 12.308 8.09488 12.063 8.12404C12.0571 8.12404 12.0396 8.12404 12.028 8.12404Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M2.03529 8.1534C2.01779 8.1534 2.00612 8.1534 1.98862 8.1534C1.74946 8.12424 1.57446 7.9084 1.59779 7.66924C1.75529 6.10007 2.58946 4.68257 3.87862 3.76674C4.07696 3.62674 4.35112 3.6734 4.49112 3.87174C4.63112 4.07007 4.58446 4.34424 4.38612 4.48424C3.30112 5.2484 2.60112 6.44424 2.46696 7.76257C2.44946 7.98424 2.25696 8.1534 2.03529 8.1534Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M7.03433 13.689C6.171 13.689 5.3485 13.4907 4.5785 13.1057C4.36266 12.9948 4.27516 12.7323 4.386 12.5165C4.49683 12.3007 4.75933 12.2132 4.97516 12.324C6.23516 12.9598 7.75183 12.9715 9.0235 12.359C9.23933 12.254 9.50183 12.3473 9.60683 12.5632C9.71183 12.779 9.6185 13.0415 9.40266 13.1465C8.656 13.5082 7.86266 13.689 7.03433 13.689Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M7.03573 5.42351C5.89823 5.42351 4.97656 4.50184 4.97656 3.36434C4.97656 2.22684 5.89823 1.30518 7.03573 1.30518C8.17323 1.30518 9.0949 2.22684 9.0949 3.36434C9.0949 4.50184 8.1674 5.42351 7.03573 5.42351ZM7.03573 2.18601C6.3824 2.18601 5.85156 2.71684 5.85156 3.37018C5.85156 4.02351 6.3824 4.55434 7.03573 4.55434C7.68906 4.55434 8.2199 4.02351 8.2199 3.37018C8.2199 2.71684 7.68323 2.18601 7.03573 2.18601Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M2.81698 12.5573C1.67948 12.5573 0.757812 11.6356 0.757812 10.4981C0.757812 9.36646 1.67948 8.43896 2.81698 8.43896C3.95448 8.43896 4.87615 9.36063 4.87615 10.4981C4.87615 11.6298 3.95448 12.5573 2.81698 12.5573ZM2.81698 9.31396C2.16365 9.31396 1.63281 9.8448 1.63281 10.4981C1.63281 11.1515 2.16365 11.6823 2.81698 11.6823C3.47031 11.6823 4.00115 11.1515 4.00115 10.4981C4.00115 9.8448 3.47031 9.31396 2.81698 9.31396Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M11.1822 12.5573C10.0447 12.5573 9.12305 11.6356 9.12305 10.4981C9.12305 9.36646 10.0447 8.43896 11.1822 8.43896C12.3197 8.43896 13.2414 9.36063 13.2414 10.4981C13.2355 11.6298 12.3139 12.5573 11.1822 12.5573ZM11.1822 9.31396C10.5289 9.31396 9.99805 9.8448 9.99805 10.4981C9.99805 11.1515 10.5289 11.6823 11.1822 11.6823C11.8355 11.6823 12.3664 11.1515 12.3664 10.4981C12.3605 9.8448 11.8355 9.31396 11.1822 9.31396Z"
        fill={color ? color : '#6D6D6D'}
      />
    </svg>
  );
};

export default AffiliateIcon;
