/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Pagination, Tabs } from 'antd';
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import getStoresApi from '../../../apis/stores-apis/getAllStoresApi';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import NewProductIcon from '../../../common/icons/store-card-icons/NewProductIcon';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import appPages from '../../../const-values/appPages';
import { storePermissionsValues } from '../../../const-values/permissions';
import storeStatus from '../../../const-values/storeStatus';
import EmployeesContext from '../../../contexts/employees-context/EmployeesContext';
import StoresContext from '../../../contexts/stores-context/StoresContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useSelectedPage from '../../../custom-hooks/useSelectedPage';
import checkPermission from '../../../utils/checkPermission';
import checkRes from '../../../utils/checkRes';
import NewEmployeeModal from '../merchent-employees-page/NewEmployeeModal';
import MerchentStoreDeactivateModal from '../merchent-store-details-page/MerchentStoreDeactivateModal';
import MerchentStoreDeleteModal from '../merchent-store-details-page/MerchentStoreDeleteModal';
import MerchentStoreReactivateModal from '../merchent-store-details-page/MerchentStoreReactivateModal';
import MerchentStoreRejectModal from '../merchent-store-details-page/MerchentStoreRejectModal';
import MerchentStoreTermsAndConditionsModal from '../merchent-store-details-page/MerchentStoreTermsAndConditionsModal';
import MerchentWalletModal from '../merchent-wallet-page/MerchentWalletModal';
import MerchentStoreCard from './MerchentStoreCard';
import './MerchentStoresPage.scss';

const MerchentStoresPage = () => {
  const { i18n, t } = useTranslation();
  const sharedT = (key) => t(`stores.tabFilter.${key}`);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { merchentStores } = appPages(i18n, t, user);
  const { formModalOpened: employeesModalOpened } =
    useContext(EmployeesContext);
  useSelectedPage({
    currentPage: merchentStores
  });
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    isLoadingTableData,
    setIsLoadingTableData,
    allFetchedTableData,
    setAllFetchedTableData,
    fetchTableDataCount,
    setFetchTableDataCount,
    selectedTableRow,
    setSelectedTableRow,
    termsAndConditionsModalOpened,
    setTermsAndConditionsModalOpened,
    deactivateStoreModalOpened,
    setDeactivateStoreModalOpened,
    reactivateStoreModalOpened,
    setReactivateStoreModalOpened,
    rejectModalOpened,
    setRejectModalOpened,
    deleteStoreModalOpened,
    setDeleteStoreModalOpened,
    withdrawModalOpened,
    setWithdrawModalOpened,
    tablePagination,
    setTablePagination,
    setTableFilter,
    tableFilter
  } = useContext(StoresContext);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      getStoresApi(
        {
          ...tableFilter,
          ...values // { page: ...}
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedTableData(data);
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          } else {
            setAllFetchedTableData([]);
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, i18n.language]);

  const handleChange = (status) => {
    // setIsLoadingTableData(true);
    navigate(`${merchentRouterLinks?.merchentStores}?page=1`);
    setTableFilter((prev) => ({
      ...prev,
      page: 1,
      status
    }));
    setFetchTableDataCount((prev) => prev + 1);
  };

  const renderStoreCards = (status) => {
    if (isLoadingTableData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedTableData?.length > 0) {
      return (
        <div className="stores-wrap">
          {allFetchedTableData.map((obj) => (
            <MerchentStoreCard key={obj?.id} card={obj} />
          ))}

          <Pagination
            locale={{
              jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
              // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
              page: ''
            }}
            showSizeChanger={false}
            defaultCurrent={1}
            current={tablePagination.current_page}
            pageSize={tablePagination.per_page}
            total={tablePagination.total}
            // itemRender={itemRender}
            onChange={(page, pageSize) => {
              setFetchTableDataCount((prev) => prev + 1);
              navigate(`${merchentRouterLinks?.merchentStores}?page=${page}`);
            }}
            hideOnSinglePage={true}
          />
        </div>
      );
    }
    return <Empty description={false}>{t('storesPage.empty')}</Empty>;
  };

  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  return (
    <div className="merchent-stores-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('stores'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-create-store-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">{t('storesPage.stores')}</div>
          </div>

          {checkPermission({
            user,
            supposedRouteRoles: [storePermissionsValues(t).addStores],
            authed: false
          }) && (
            <RouterLink
              className="new-store-btn"
              to={merchentRouterLinks?.merchentNewStore}
            >
              <NewProductIcon />
              {t('storesPage.newStore')}
            </RouterLink>
          )}
        </div>

        <Tabs
          onChange={(key) => {
            handleChange(key);
          }}
          defaultActiveKey={storeStatus?.active}
          items={[
            {
              key: storeStatus?.active,
              label: sharedT('active'),
              children: renderStoreCards(storeStatus?.active)
            },
            {
              key: storeStatus?.disabled,
              label: sharedT('disabled'),
              children: renderStoreCards(storeStatus?.disabled)
            },
            {
              key: storeStatus?.underReview,
              label: sharedT('underReview'),
              children: renderStoreCards(storeStatus?.underReview)
            },
            {
              key: storeStatus?.rejected,
              label: sharedT('rejected'),
              children: renderStoreCards(storeStatus?.rejected)
            },
            {
              key: storeStatus?.notCompleted,
              label: sharedT('notCompleted'),
              children: renderStoreCards(storeStatus?.notCompleted)
            }
          ]}
        />
      </div>

      {termsAndConditionsModalOpened && (
        <MerchentStoreTermsAndConditionsModal
          store={selectedTableRow}
          setSelectedTableRow={setSelectedTableRow}
          modalOpened={termsAndConditionsModalOpened}
          setModalOpened={setTermsAndConditionsModalOpened}
          setFetchCount={setFetchTableDataCount}
        />
      )}
      {deactivateStoreModalOpened && (
        <MerchentStoreDeactivateModal
          store={selectedTableRow}
          setSelectedTableRow={setSelectedTableRow}
          modalOpened={deactivateStoreModalOpened}
          setModalOpened={setDeactivateStoreModalOpened}
          setFetchCount={setFetchTableDataCount}
        />
      )}
      {reactivateStoreModalOpened && (
        <MerchentStoreReactivateModal
          store={selectedTableRow}
          setSelectedTableRow={setSelectedTableRow}
          modalOpened={reactivateStoreModalOpened}
          setModalOpened={setReactivateStoreModalOpened}
          setFetchCount={setFetchTableDataCount}
        />
      )}

      {deleteStoreModalOpened && (
        <MerchentStoreDeleteModal
          store={selectedTableRow}
          setSelectedTableRow={setSelectedTableRow}
          modalOpened={deleteStoreModalOpened}
          setModalOpened={setDeleteStoreModalOpened}
          setFetchCount={setFetchTableDataCount}
        />
      )}

      {rejectModalOpened && (
        <MerchentStoreRejectModal
          store={selectedTableRow}
          setSelectedTableRow={setSelectedTableRow}
          modalOpened={rejectModalOpened}
          setModalOpened={setRejectModalOpened}
          setFetchCount={setFetchTableDataCount}
        />
      )}

      {employeesModalOpened && <NewEmployeeModal />}

      {withdrawModalOpened && selectedTableRow && (
        <MerchentWalletModal
          setFetchCount={setFetchTableDataCount}
          selectedStore={selectedTableRow}
          modalOpened={withdrawModalOpened}
          setModalOpened={setWithdrawModalOpened}
          setSelected={setSelectedTableRow}
        />
      )}
    </div>
  );
};

export default MerchentStoresPage;
