import React from 'react';

const CubeIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.5007 30.1145C28.419 30.1145 27.3373 29.8441 26.354 29.3279L10.3502 20.6991C8.89982 19.9124 7.99023 18.3637 7.99023 16.6429C7.99023 14.922 8.89982 13.3733 10.3502 12.5866L26.354 3.95785C28.3207 2.90077 30.6807 2.90077 32.6227 3.95785L48.6265 12.5866C50.0769 13.3733 50.9865 14.922 50.9865 16.6429C50.9865 18.3637 50.0769 19.9124 48.6265 20.6991L32.6227 29.3279C31.664 29.8687 30.5823 30.1145 29.5007 30.1145ZM29.5007 6.8341C29.0336 6.8341 28.5419 6.95702 28.0994 7.20285L12.1202 15.807C11.7023 16.0283 11.6777 16.4954 11.6777 16.6429C11.6777 16.7904 11.7023 17.2574 12.1202 17.4541L28.124 26.0828C29.009 26.5499 30.0169 26.5499 30.9019 26.0828L46.9057 17.4541C47.299 17.2329 47.3482 16.7658 47.3482 16.6429C47.3482 16.4954 47.3236 16.0283 46.9057 15.8316L30.9019 7.20285C30.4594 6.95702 29.9677 6.8341 29.5007 6.8341Z"
          fill={color ? color : '#6D6D6D'}
        />
        <path
          d="M24.0418 55.8529C23.3535 55.8529 22.6406 55.6808 22.0014 55.3613L7.10393 47.9371C4.62102 46.6833 3.07227 44.1758 3.07227 41.3979L3.07227 27.3363C3.07227 25.7383 3.88352 24.2879 5.2356 23.4521C6.61227 22.6163 8.25935 22.5425 9.68518 23.2554L24.5827 30.6796C27.0656 31.9333 28.6144 34.4163 28.6144 37.2188L28.6144 51.2804C28.6144 52.8783 27.8031 54.3288 26.451 55.1646C25.7135 55.6317 24.8777 55.8529 24.0418 55.8529ZM7.64477 26.4513C7.4481 26.4513 7.27602 26.525 7.17768 26.5742C7.03018 26.6725 6.75977 26.8938 6.75977 27.3363L6.75977 41.3979C6.75977 42.7746 7.52185 44.0283 8.75102 44.6429L23.6239 52.0917C24.0173 52.2883 24.3369 52.1408 24.4844 52.0671C24.6319 51.9688 24.9023 51.7475 24.9023 51.305L24.9023 37.2433C24.9023 35.8667 24.1402 34.6129 22.911 33.9983L8.0381 26.5496C7.8906 26.4758 7.76768 26.4513 7.64477 26.4513Z"
          fill={color ? color : '#6D6D6D'}
        />
        <path
          d="M34.9573 55.8531C34.1214 55.8531 33.2856 55.6318 32.5481 55.1648C31.196 54.3289 30.3848 52.8785 30.3848 51.2806L30.3848 37.2189C30.3848 34.441 31.9335 31.9335 34.4164 30.6798L49.2893 23.231C50.7152 22.5181 52.3869 22.5918 53.7389 23.4277C55.091 24.2635 55.9023 25.7139 55.9023 27.3118V41.3735C55.9023 44.1514 54.3535 46.6589 51.8706 47.9127L36.9977 55.3614C36.3585 55.7056 35.6456 55.8531 34.9573 55.8531ZM51.3543 26.4514C51.2314 26.4514 51.1085 26.476 50.961 26.5498L36.0881 33.9985C34.8589 34.6131 34.0968 35.8423 34.0968 37.2435V51.3052C34.0968 51.7477 34.3673 51.9689 34.5148 52.0673C34.6623 52.1656 34.9819 52.2885 35.3752 52.0918L50.2481 44.6431C51.4773 44.0285 52.2394 42.7748 52.2394 41.3981V27.3364C52.2394 26.8939 51.9689 26.6727 51.8214 26.5743C51.7231 26.5252 51.551 26.4514 51.3543 26.4514Z"
          fill={color ? color : '#6D6D6D'}
        />
      </svg>
    </span>
  );
};

export default CubeIcon;
