import { useTranslation } from 'react-i18next';
import ArchHeading from '../../../common/arch-heading/ArchHeading';
// import SlickSliderBtns from '../../../utils/SlickSliderBtns';
import generalRouterLinks from '../../../components/app/general-routes/gerneralRouterLinks';
import SharedProductsSlider from './SharedProductsSlider';

const NewProductsSlider = ({ slides, isLoading, setFetchCount }) => {
  // SlickSliderBtns('trendy-products-slider', false);
  // SlickSliderBtns('new-products-slider', isLoading);
  const { t } = useTranslation();
  return (
    <section className="new-products-section">
      <div className="custom-container">
        <ArchHeading title={t('homeSections.newProducts')} />
        <SharedProductsSlider
          slides={slides}
          className="new-products-slider"
          setFetchCount={setFetchCount}
          url={generalRouterLinks.newProductsRoute}
        />
      </div>
    </section>
  );
};

export default NewProductsSlider;
