import { Breadcrumb } from 'antd';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './Breadcrumb.scss';

const CustomBreadcrumb = ({ arr }) => {
  return (
    <div className="breadcrumb-section">
      <div className="custom-container">
        <div className="breadcrumb-content">
          <Breadcrumb
            items={
              arr?.length > 0 &&
              arr.map((ele, index) => {
                return {
                  key: index,
                  title: ele?.isLink ? (
                    <RouterLink to={ele.to}>{ele.title}</RouterLink>
                  ) : (
                    ele.title
                  )
                };
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CustomBreadcrumb;
