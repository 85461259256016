import React from 'react';

const EditProductIcon = ({ color }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1436_15388)">
        <mask id="path-1-inside-1_1436_15388" fill="white">
          <path d="M11.382 1.0427L4.27003 8.1547C3.99839 8.42487 3.78305 8.74624 3.63646 9.1002C3.48987 9.45415 3.41495 9.83368 3.41603 10.2168V11.0002C3.41603 11.1549 3.47749 11.3033 3.58688 11.4127C3.69628 11.5221 3.84465 11.5835 3.99936 11.5835H4.78278C5.16589 11.5846 5.54541 11.5097 5.89937 11.3631C6.25333 11.2165 6.57469 11.0012 6.84486 10.7295L13.9569 3.61754C14.2978 3.2758 14.4892 2.81282 14.4892 2.33012C14.4892 1.84743 14.2978 1.38444 13.9569 1.0427C13.6102 0.71131 13.149 0.526367 12.6694 0.526367C12.1898 0.526367 11.7287 0.71131 11.382 1.0427V1.0427ZM13.132 2.7927L6.02003 9.9047C5.6911 10.2316 5.24653 10.4157 4.78278 10.4169H4.58269V10.2168C4.58391 9.75303 4.76794 9.30846 5.09486 8.97954L12.2069 1.86754C12.3315 1.74851 12.4971 1.68209 12.6694 1.68209C12.8418 1.68209 13.0074 1.74851 13.132 1.86754C13.2545 1.99034 13.3233 2.15669 13.3233 2.33012C13.3233 2.50355 13.2545 2.6699 13.132 2.7927V2.7927Z" />
        </mask>
        <path
          d="M11.382 1.0427L4.27003 8.1547C3.99839 8.42487 3.78305 8.74624 3.63646 9.1002C3.48987 9.45415 3.41495 9.83368 3.41603 10.2168V11.0002C3.41603 11.1549 3.47749 11.3033 3.58688 11.4127C3.69628 11.5221 3.84465 11.5835 3.99936 11.5835H4.78278C5.16589 11.5846 5.54541 11.5097 5.89937 11.3631C6.25333 11.2165 6.57469 11.0012 6.84486 10.7295L13.9569 3.61754C14.2978 3.2758 14.4892 2.81282 14.4892 2.33012C14.4892 1.84743 14.2978 1.38444 13.9569 1.0427C13.6102 0.71131 13.149 0.526367 12.6694 0.526367C12.1898 0.526367 11.7287 0.71131 11.382 1.0427V1.0427ZM13.132 2.7927L6.02003 9.9047C5.6911 10.2316 5.24653 10.4157 4.78278 10.4169H4.58269V10.2168C4.58391 9.75303 4.76794 9.30846 5.09486 8.97954L12.2069 1.86754C12.3315 1.74851 12.4971 1.68209 12.6694 1.68209C12.8418 1.68209 13.0074 1.74851 13.132 1.86754C13.2545 1.99034 13.3233 2.15669 13.3233 2.33012C13.3233 2.50355 13.2545 2.6699 13.132 2.7927V2.7927Z"
          fill="#6D6D6D"
        />
        <mask id="path-3-inside-2_1436_15388" fill="white">
          <path d="M13.9167 5.73775C13.762 5.73775 13.6136 5.79921 13.5042 5.9086C13.3948 6.018 13.3333 6.16637 13.3333 6.32108V9.25H11C10.5359 9.25 10.0908 9.43437 9.76256 9.76256C9.43437 10.0908 9.25 10.5359 9.25 11V13.3333H3.41667C2.95254 13.3333 2.50742 13.149 2.17923 12.8208C1.85104 12.4926 1.66667 12.0475 1.66667 11.5833V3.41667C1.66667 2.95254 1.85104 2.50742 2.17923 2.17923C2.50742 1.85104 2.95254 1.66667 3.41667 1.66667H8.69117C8.84588 1.66667 8.99425 1.60521 9.10365 1.49581C9.21304 1.38642 9.2745 1.23804 9.2745 1.08333C9.2745 0.928624 9.21304 0.780251 9.10365 0.670854C8.99425 0.561458 8.84588 0.5 8.69117 0.5H3.41667C2.6434 0.500926 1.90208 0.808515 1.3553 1.3553C0.808515 1.90208 0.500926 2.6434 0.5 3.41667L0.5 11.5833C0.500926 12.3566 0.808515 13.0979 1.3553 13.6447C1.90208 14.1915 2.6434 14.4991 3.41667 14.5H10.0334C10.4166 14.5011 10.7962 14.4262 11.1503 14.2796C11.5043 14.133 11.8258 13.9177 12.0961 13.646L13.6454 12.0955C13.9171 11.8253 14.1325 11.504 14.2792 11.15C14.4259 10.7961 14.5009 10.4166 14.5 10.0334V6.32108C14.5 6.16637 14.4385 6.018 14.3291 5.9086C14.2198 5.79921 14.0714 5.73775 13.9167 5.73775ZM11.2713 12.8212C11.0368 13.0551 10.7402 13.217 10.4167 13.2878V11C10.4167 10.8453 10.4781 10.6969 10.5875 10.5875C10.6969 10.4781 10.8453 10.4167 11 10.4167H13.2896C13.2174 10.7396 13.0557 11.0355 12.8229 11.2707L11.2713 12.8212Z" />
        </mask>
        <path
          d="M13.9167 5.73775C13.762 5.73775 13.6136 5.79921 13.5042 5.9086C13.3948 6.018 13.3333 6.16637 13.3333 6.32108V9.25H11C10.5359 9.25 10.0908 9.43437 9.76256 9.76256C9.43437 10.0908 9.25 10.5359 9.25 11V13.3333H3.41667C2.95254 13.3333 2.50742 13.149 2.17923 12.8208C1.85104 12.4926 1.66667 12.0475 1.66667 11.5833V3.41667C1.66667 2.95254 1.85104 2.50742 2.17923 2.17923C2.50742 1.85104 2.95254 1.66667 3.41667 1.66667H8.69117C8.84588 1.66667 8.99425 1.60521 9.10365 1.49581C9.21304 1.38642 9.2745 1.23804 9.2745 1.08333C9.2745 0.928624 9.21304 0.780251 9.10365 0.670854C8.99425 0.561458 8.84588 0.5 8.69117 0.5H3.41667C2.6434 0.500926 1.90208 0.808515 1.3553 1.3553C0.808515 1.90208 0.500926 2.6434 0.5 3.41667L0.5 11.5833C0.500926 12.3566 0.808515 13.0979 1.3553 13.6447C1.90208 14.1915 2.6434 14.4991 3.41667 14.5H10.0334C10.4166 14.5011 10.7962 14.4262 11.1503 14.2796C11.5043 14.133 11.8258 13.9177 12.0961 13.646L13.6454 12.0955C13.9171 11.8253 14.1325 11.504 14.2792 11.15C14.4259 10.7961 14.5009 10.4166 14.5 10.0334V6.32108C14.5 6.16637 14.4385 6.018 14.3291 5.9086C14.2198 5.79921 14.0714 5.73775 13.9167 5.73775ZM11.2713 12.8212C11.0368 13.0551 10.7402 13.217 10.4167 13.2878V11C10.4167 10.8453 10.4781 10.6969 10.5875 10.5875C10.6969 10.4781 10.8453 10.4167 11 10.4167H13.2896C13.2174 10.7396 13.0557 11.0355 12.8229 11.2707L11.2713 12.8212Z"
          fill="#6D6D6D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1436_15388">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EditProductIcon;
