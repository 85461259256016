import React from 'react';

const PageArrowRightIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.061 4.525L10.475 0.939005C10.1936 0.657743 9.81201 0.499784 9.41415 0.499878C9.01629 0.499972 8.63476 0.65811 8.3535 0.939505C8.07224 1.2209 7.91428 1.6025 7.91437 2.00036C7.91447 2.39822 8.07261 2.77974 8.354 3.061L10.793 5.5H2C1.60218 5.5 1.22064 5.65804 0.93934 5.93934C0.658035 6.22065 0.5 6.60218 0.5 7C0.5 7.39783 0.658035 7.77936 0.93934 8.06067C1.22064 8.34197 1.60218 8.5 2 8.5H10.793L8.354 10.939C8.07261 11.2203 7.91447 11.6018 7.91437 11.9997C7.91428 12.3975 8.07224 12.7791 8.3535 13.0605C8.63476 13.3419 9.01629 13.5 9.41415 13.5001C9.81201 13.5002 10.1936 13.3423 10.475 13.061L14.061 9.47501C14.7162 8.81797 15.0842 7.92792 15.0842 7C15.0842 6.07209 14.7162 5.18204 14.061 4.525Z"
        fill={color || '#9d4edd'}
      />
    </svg>
  );
};

export default PageArrowRightIcon;
