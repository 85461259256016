import React from 'react';

const NewAddressIcon = ({ color }) => {
  return (
    <svg
      width="66"
      height="70"
      viewBox="0 0 66 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8429 65.4715C27.9755 71.4708 37.7702 71.4953 43.9328 65.5267L55.5465 54.7944C68.1275 42.381 68.2632 22.119 55.8497 9.53817C43.4362 -3.04268 23.1744 -3.17858 10.5935 9.23493C10.4918 9.33528 10.3906 9.43646 10.2903 9.53817C-2.20624 22.0366 -2.2046 42.299 10.2938 54.7955C10.3197 54.8213 10.3456 54.8472 10.3716 54.873L21.8429 65.4715ZM14.4039 13.6519C24.6066 3.40415 41.185 3.36765 51.4328 13.5704C61.6805 23.7732 61.717 40.3516 51.5142 50.5993L39.9238 61.3112C35.965 65.0371 29.7828 65.0141 25.8517 61.2589L14.4039 50.6808C4.17866 40.4556 4.17853 23.8774 14.4036 13.652C14.4037 13.652 14.4039 13.6519 14.4039 13.6519ZM18.3721 32.1664C18.3721 30.5596 19.6746 29.2571 21.2814 29.2571H30.0091V20.5294C30.0091 18.9227 31.3116 17.6202 32.9183 17.6202C34.525 17.6202 35.8275 18.9227 35.8275 20.5294V29.2571H44.5553C46.162 29.2571 47.4645 30.5596 47.4645 32.1664C47.4645 33.7731 46.162 35.0756 44.5553 35.0756H35.8275V43.8033C35.8275 45.41 34.525 46.7126 32.9183 46.7126C31.3116 46.7126 30.0091 45.41 30.0091 43.8033V35.0756H21.2814C19.6746 35.0756 18.3721 33.7731 18.3721 32.1664Z"
        fill={color ? color : '#9D4EDD'}
      />
    </svg>
  );
};

export default NewAddressIcon;
