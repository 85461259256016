import './ArchHeading.scss';
import archImg from '../../assets/imgs/icons/arch.png';

const ArchHeading = ({ title }) => {
  return (
    <div className="arch-heading">
      <p className="arch-title">{title}</p>
      <img src={archImg} alt="title" />
    </div>
  );
};

export default ArchHeading;
