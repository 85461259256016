import { useTranslation } from 'react-i18next';
import tipsArr from '../../../const-values/tips';
import UserChangePasswordForm from './UserChangePasswordForm';
import './ChangePasswordPageContent.scss';

const ChangePasswordPageContent = () => {
  const { t } = useTranslation();
  return (
    <div className="change-pass-page-body">
      <UserChangePasswordForm />
      <div className="tips-section">
        <h2>{t('change_password_form.tips')}</h2>
        <ul>
          {tipsArr(t)?.length > 0 &&
            tipsArr(t).map((obj) => <li key={obj?.id}>{obj?.name}</li>)}
        </ul>
      </div>
    </div>
  );
};

export default ChangePasswordPageContent;
