import React from 'react';

const DeleteStoreIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill="#ED0006" fillOpacity="0.1" />
      <g clipPath="url(#clip0_1317_26783)">
        <path
          d="M17.1423 7.42878H15.3709C15.2382 6.78387 14.8873 6.20441 14.3773 5.78805C13.8673 5.37169 13.2293 5.1439 12.5709 5.14307L11.428 5.14307C10.7696 5.1439 10.1316 5.37169 9.62158 5.78805C9.11154 6.20441 8.76064 6.78387 8.62801 7.42878H6.85658C6.70503 7.42878 6.55969 7.48898 6.45252 7.59615C6.34536 7.70331 6.28516 7.84866 6.28516 8.00021C6.28516 8.15176 6.34536 8.29711 6.45252 8.40427C6.55969 8.51143 6.70503 8.57164 6.85658 8.57164H7.42801V16.0002C7.42892 16.7577 7.73023 17.4839 8.26585 18.0195C8.80147 18.5551 9.52767 18.8564 10.2852 18.8574H13.7137C14.4712 18.8564 15.1974 18.5551 15.733 18.0195C16.2687 17.4839 16.57 16.7577 16.5709 16.0002V8.57164H17.1423C17.2939 8.57164 17.4392 8.51143 17.5464 8.40427C17.6535 8.29711 17.7137 8.15176 17.7137 8.00021C17.7137 7.84866 17.6535 7.70331 17.5464 7.59615C17.4392 7.48898 17.2939 7.42878 17.1423 7.42878ZM11.428 6.28592H12.5709C12.9253 6.28636 13.2709 6.39642 13.5604 6.60103C13.8498 6.80564 14.0688 7.09478 14.1874 7.42878H9.81144C9.93006 7.09478 10.1491 6.80564 10.4385 6.60103C10.7279 6.39642 11.0736 6.28636 11.428 6.28592ZM15.428 16.0002C15.428 16.4549 15.2474 16.8909 14.9259 17.2124C14.6044 17.5339 14.1684 17.7145 13.7137 17.7145H10.2852C9.8305 17.7145 9.39446 17.5339 9.07297 17.2124C8.75148 16.8909 8.57087 16.4549 8.57087 16.0002V8.57164H15.428V16.0002Z"
          fill="#ED0006"
        />
        <path
          d="M10.8566 15.4284C11.0081 15.4284 11.1535 15.3681 11.2606 15.261C11.3678 15.1538 11.428 15.0085 11.428 14.8569V11.4284C11.428 11.2768 11.3678 11.1315 11.2606 11.0243C11.1535 10.9171 11.0081 10.8569 10.8566 10.8569C10.705 10.8569 10.5597 10.9171 10.4525 11.0243C10.3454 11.1315 10.2852 11.2768 10.2852 11.4284V14.8569C10.2852 15.0085 10.3454 15.1538 10.4525 15.261C10.5597 15.3681 10.705 15.4284 10.8566 15.4284Z"
          fill="#ED0006"
        />
        <path
          d="M13.1417 15.4284C13.2933 15.4284 13.4386 15.3681 13.5458 15.261C13.653 15.1538 13.7132 15.0085 13.7132 14.8569V11.4284C13.7132 11.2768 13.653 11.1315 13.5458 11.0243C13.4386 10.9171 13.2933 10.8569 13.1417 10.8569C12.9902 10.8569 12.8448 10.9171 12.7377 11.0243C12.6305 11.1315 12.5703 11.2768 12.5703 11.4284V14.8569C12.5703 15.0085 12.6305 15.1538 12.7377 15.261C12.8448 15.3681 12.9902 15.4284 13.1417 15.4284Z"
          fill="#ED0006"
        />
      </g>
      <defs>
        <clipPath id="clip0_1317_26783">
          <rect
            width="13.7143"
            height="13.7143"
            fill="white"
            transform="translate(5.14258 5.14307)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteStoreIcon;
