/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import appPages from '../../../const-values/appPages';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import UserContext from '../../../contexts/user-context/UserProvider';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import useSelectedPage from '../../../custom-hooks/useSelectedPage';
import { useParams } from 'react-router-dom';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import './NewProductPage.scss';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import NewProductForm from './NewProductForm';
import SingleProductContext from '../../../contexts/single-product-context/SingleProductContext';
import getSingleProductApi from '../../../apis/products-apis/getSingleProudctApi';

const NewProductPage = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { fetchedSingleProductDetails } = useContext(SingleProductContext);
  const navigate = useNavigate();
  const { merchentStores } = appPages(i18n, t, user);
  useSelectedPage({
    currentPage: merchentStores
  });

  const params = useParams();
  const {
    fetchSingleStoreDetailsCount,
    setFetchedSingleProductDetails,
    isLoading,
    setIsLoading,
    isSubmittingForm
  } = useContext(SingleProductContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (params?.product_id) {
      const fetchData = () => {
        setIsLoading(true);
        customApiRequest(
          getSingleProductApi(
            {
              product_id: params?.product_id
            },
            user?.token,
            i18n.language
          ),
          (res) => {
            if (isMounted) {
              setIsLoading(false);
              if (checkRes(res)) {
                const data = res.data.data;
                setFetchedSingleProductDetails(data);
              }
            }
          },
          (error) => {
            setIsLoading(false);
          }
        );
      };
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [fetchSingleStoreDetailsCount]);

  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  return (
    <div className="merchent-new-product-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.merchentDashboard
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('stores'),
            isLink: true,
            to: merchentRouterLinks?.merchentStores
          },
          {
            title: sharedTrans('newStore'),
            isLink: false
          }
        ]}
      />
      <div className="custom-container">
        <div className="title-create-store-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">
              {fetchedSingleProductDetails
                ? t('product_form.editProduct')
                : t('product_form.newProduct')}
            </div>
          </div>
        </div>

        <NewProductForm />

        {(isLoading || isSubmittingForm) && <LoadingModal />}
      </div>
    </div>
  );
};

export default NewProductPage;
