import React from 'react';

const SignoutIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2328_34698)">
        <path
          d="M12.524 15.0479C12.7892 15.0479 13.0436 15.1532 13.2311 15.3407C13.4186 15.5283 13.524 15.7826 13.524 16.0479V19.0479C13.524 19.8435 13.8401 20.6066 14.4027 21.1692C14.9653 21.7318 15.7284 22.0479 16.524 22.0479H19C19.7956 22.0479 20.5587 21.7318 21.1213 21.1692C21.6839 20.6066 22 19.8435 22 19.0479V5.04785C22 4.2522 21.6839 3.48914 21.1213 2.92653C20.5587 2.36392 19.7956 2.04785 19 2.04785H16.524C15.7284 2.04785 14.9653 2.36392 14.4027 2.92653C13.8401 3.48914 13.524 4.2522 13.524 5.04785V8.04785C13.524 8.31307 13.4186 8.56742 13.2311 8.75496C13.0436 8.94249 12.7892 9.04785 12.524 9.04785C12.2588 9.04785 12.0044 8.94249 11.8169 8.75496C11.6294 8.56742 11.524 8.31307 11.524 8.04785V5.04785C11.5256 3.72226 12.0529 2.45141 12.9902 1.51407C13.9276 0.576734 15.1984 0.0494394 16.524 0.0478516H19C20.3256 0.0494394 21.5964 0.576734 22.5338 1.51407C23.4711 2.45141 23.9984 3.72226 24 5.04785V19.0479C23.9984 20.3734 23.4711 21.6443 22.5338 22.5816C21.5964 23.519 20.3256 24.0463 19 24.0479H16.524C15.1984 24.0463 13.9276 23.519 12.9902 22.5816C12.0529 21.6443 11.5256 20.3734 11.524 19.0479V16.0479C11.524 15.7826 11.6294 15.5283 11.8169 15.3407C12.0044 15.1532 12.2588 15.0479 12.524 15.0479Z"
          fill={color || '#ED0006'}
        />
        <path
          d="M1.133 9.92742L5.719 5.34142C5.81124 5.24591 5.92159 5.16973 6.04359 5.11732C6.1656 5.06491 6.29682 5.03732 6.4296 5.03617C6.56238 5.03502 6.69406 5.06032 6.81695 5.1106C6.93985 5.16088 7.0515 5.23513 7.14539 5.32903C7.23929 5.42292 7.31354 5.53457 7.36382 5.65747C7.4141 5.78036 7.4394 5.91204 7.43825 6.04482C7.43709 6.1776 7.40951 6.30882 7.3571 6.43083C7.30469 6.55283 7.22851 6.66317 7.133 6.75542L2.87 11.0184L18 11.0484C18.2652 11.0484 18.5196 11.1538 18.7071 11.3413C18.8946 11.5289 19 11.7832 19 12.0484C19 12.3136 18.8946 12.568 18.7071 12.7555C18.5196 12.9431 18.2652 13.0484 18 13.0484L2.812 13.0184L7.135 17.3414C7.23051 17.4337 7.30669 17.544 7.3591 17.666C7.41151 17.788 7.4391 17.9192 7.44025 18.052C7.4414 18.1848 7.4161 18.3165 7.36582 18.4394C7.31554 18.5623 7.24129 18.6739 7.14739 18.7678C7.0535 18.8617 6.94185 18.936 6.81895 18.9862C6.69606 19.0365 6.56438 19.0618 6.4316 19.0607C6.29882 19.0595 6.1676 19.0319 6.04559 18.9795C5.92359 18.9271 5.81325 18.8509 5.721 18.7554L1.135 14.1694C0.572319 13.6071 0.256012 12.8443 0.255638 12.0488C0.255262 11.2533 0.570848 10.4903 1.133 9.92742Z"
          fill={color || '#ED0006'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2328_34698">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0.0478516)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignoutIcon;
