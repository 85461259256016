import React from 'react';

const IbanIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3700_4313)">
        <path
          d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520204 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61384 17.0484 4.32616 15.3611 2.63889C13.6738 0.951621 11.3862 0.00258081 9 0V0ZM9 16.5C7.51664 16.5 6.0666 16.0601 4.83323 15.236C3.59986 14.4119 2.63856 13.2406 2.07091 11.8701C1.50325 10.4997 1.35473 8.99168 1.64411 7.53682C1.9335 6.08197 2.64781 4.74559 3.6967 3.6967C4.7456 2.64781 6.08197 1.9335 7.53683 1.64411C8.99168 1.35472 10.4997 1.50325 11.8701 2.0709C13.2406 2.63856 14.4119 3.59985 15.236 4.83322C16.0601 6.06659 16.5 7.51664 16.5 9C16.4978 10.9885 15.7069 12.8948 14.3009 14.3009C12.8948 15.7069 10.9885 16.4978 9 16.5ZM9 5.25C8.46988 5.249 7.95652 5.43564 7.55082 5.77687C7.14513 6.11811 6.87329 6.59191 6.78346 7.11437C6.69363 7.63682 6.7916 8.17421 7.06003 8.63135C7.32845 9.08849 7.74999 9.43589 8.25 9.612V12C8.25 12.1989 8.32902 12.3897 8.46967 12.5303C8.61033 12.671 8.80109 12.75 9 12.75C9.19892 12.75 9.38968 12.671 9.53033 12.5303C9.67099 12.3897 9.75 12.1989 9.75 12V9.612C10.25 9.43589 10.6716 9.08849 10.94 8.63135C11.2084 8.17421 11.3064 7.63682 11.2165 7.11437C11.1267 6.59191 10.8549 6.11811 10.4492 5.77687C10.0435 5.43564 9.53012 5.249 9 5.25ZM9 8.25C8.85167 8.25 8.70666 8.20601 8.58333 8.1236C8.45999 8.04119 8.36386 7.92406 8.30709 7.78701C8.25033 7.64997 8.23548 7.49917 8.26442 7.35368C8.29335 7.2082 8.36479 7.07456 8.46967 6.96967C8.57456 6.86478 8.7082 6.79335 8.85369 6.76441C8.99917 6.73547 9.14997 6.75033 9.28702 6.80709C9.42406 6.86386 9.54119 6.95999 9.62361 7.08332C9.70602 7.20666 9.75 7.35166 9.75 7.5C9.75 7.69891 9.67099 7.88968 9.53033 8.03033C9.38968 8.17098 9.19892 8.25 9 8.25Z"
          fill={color ? color : '#C4C4C4'}
        />
      </g>
      <defs>
        <clipPath id="clip0_3700_4313">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IbanIcon;
