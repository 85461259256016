import axios from 'axios';

const generalSingleStoreApi = async (filterValues, token, langIsoCode) => {
  try {
    const url = `/Saloon/singleStore?store_id=${
      filterValues?.storeId || ''
    }&sort=${filterValues?.sort || ''}&page=${filterValues?.page || 1}`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default generalSingleStoreApi;
