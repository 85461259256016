import cashImg from '../assets/imgs/icons/cash.png';
import onlineImg from '../assets/imgs/icons/online.png';
import tabbyImg from '../assets/imgs/icons/tabby.png';
import tamaraImg from '../assets/imgs/icons/tamara.png';
import walletImg from '../assets/imgs/icons/wallet.png';

const baseLabelTransString = (t, key) => t(`paymentOptions.${key}.label`);

export const paymentTypesValues = {
  cashPayment: 1,
  onlinePayment: 2,
  walletPayment: 3,
  tamara: 4,
  tabby: 5
};
export const paymentStatusValues = {
  paid: 1,
  not_paid: 2
};
export const merchantPaymentTypes = (t) => {
  return [
    {
      name: baseLabelTransString(t, 'cashPayment'),
      value: '1',
      id: '1'
    },
    {
      name: baseLabelTransString(t, 'onlinePayment'),
      value: '2',
      id: '2'
    }
  ];
};

export const paymentTypesFilter = (t) => {
  return [
    {
      name: baseLabelTransString(t, 'cashPayment'),
      img: cashImg,
      value: '1',
      id: '1'
    },
    {
      name: baseLabelTransString(t, 'onlinePayment'),
      img: onlineImg,
      value: '2',
      id: '2'
    }
  ];
};

const paymentTypes = (t) => {
  return [
    ...paymentTypesFilter(t),
    {
      name: baseLabelTransString(t, 'walletPayment'),
      img: walletImg,
      value: '3',
      id: '3'
    },
    {
      name: baseLabelTransString(t, 'tamara'),
      img: tamaraImg,
      value: '4',
      id: '4'
    },
    {
      name: baseLabelTransString(t, 'tabby'),
      img: tabbyImg,
      value: '5',
      id: '5'
    }
  ];
};

export default paymentTypes;
