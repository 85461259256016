import { useContext, useEffect, useState } from 'react';
import SharedAntdTable from '../../../common/shared-antd-table/SharedAntdTable';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import UserContext from '../../../contexts/user-context/UserProvider';
import tableColumns from './tableColumns';
import StoreLeftedCartContext from '../../../contexts/store-lefted-cart-context/StoreLeftedCartContext';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LeftedCartTable = () => {
  const { i18n } = useTranslation();
  const params = useParams();
  const { user } = useContext(UserContext);
  const {
    allFetchedData,
    pagination,
    fetchCount,
    setFetchCount,
    setIsLoading,
    isLoading,
    setFormModalOpened
  } = useContext(StoreLeftedCartContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedData?.length >= 0) {
      const mappedData = mapDataSource(allFetchedData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedData]);

  // console.log('table data : ', tableDataSource);

  return (
    <SharedAntdTable
      scroll={1024}
      className="lefted-cart-table"
      hasPagination={true}
      tableDataSource={tableDataSource}
      tableColumns={tableColumns}
      allFetchedTableData={allFetchedData}
      setFormModalOpened={setFormModalOpened}
      // setDetailsModalOpened={setDetailsModalOpened}
      setFetchTableDataCount={setFetchCount}
      isLoadingTableData={isLoading}
      setIsLoadingTableData={setIsLoading}
      tablePagination={pagination}
      user={user}
      baseTablePageUrl={merchentRouterLinks?.storeLeftedCartRoute(
        params?.store_id
      )}
      emptyText={
        i18n.language === 'ar'
          ? 'لا توجد خدمات معلقة من العميل'
          : 'No lefted services from the client'
      }
    />
  );
};

export default LeftedCartTable;
