/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import errorNotification from '../../../utils/errorNotification';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import UserContext from '../../../contexts/user-context/UserProvider';
import changePasswordApi from '../../../apis/auth/changePasswordApi';
import { useTranslation } from 'react-i18next';
import LockIcon from '../../../common/icons/LockIcon';
import EyeOpenedIcon from '../../../common/icons/EyeOpenedIcon';
import EyeClosedIcon from '../../../common/icons/EyeClosedIcon';
import './ChangePasswordForm.scss';

const UserChangePasswordForm = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);

  const generalLabelStr = (v) => t(`change_password_form.${v}.label`);
  const generalrequiredErrStr = (v) =>
    t(`change_password_form.${v}.errors.required`);
  const generalTypeErrorStr = (v) =>
    t(`change_password_form.${v}.errors.type_error`);

  const schema = Yup.object().shape({
    oldPassword: Yup.string().required(generalrequiredErrStr('oldPassword')),
    newPassword: Yup.string().required(generalrequiredErrStr('newPassword')),
    newPasswordConfirmation: Yup.string()
      .required(generalrequiredErrStr('newPasswordConfirmation'))
      .oneOf(
        [Yup.ref('newPassword')],
        generalTypeErrorStr('newPasswordConfirmation')
      )
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      oldPassword: '',
      newPassword: ''
    }
  });

  React.useEffect(() => {
    return () => {
      reset({ email: '' });
    };
  }, []);
  const [passwrodVisible, setPasswordVisible] = useState(false);

  // handle cancel
  const handleCancel = () => {
    reset({
      oldPassword: '',
      newPassword: ''
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsLoading(true);
    customApiRequest(
      changePasswordApi(data, user?.token, i18n.language),
      (res) => {
        setIsLoading(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: 'تم تغيير كلمة المرور بنجاح'
          });

          reset({
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: ''
          });
        } else {
          errorNotification({
            title: 'حدث خطأ اثناء العملية',
            message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="change-password-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="login-password-field-wrap">
          <AntdTextField
            name="oldPassword"
            type={passwrodVisible ? 'text' : 'password'}
            placeholder={generalLabelStr('oldPassword')}
            label={generalLabelStr('oldPassword')}
            errorMsg={errors?.oldPassword?.message}
            prefix={<LockIcon />}
            validateStatus={errors?.oldPassword ? 'error' : ''}
            control={control}
          />

          {watch('oldPassword') && (
            <div
              className="eye-icon-btn"
              style={{
                left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                right: i18n.dir() === 'ltr' ? '14px' : 'auto'
              }}
              onClick={() => {
                setPasswordVisible((prevState) => !prevState);
              }}
            >
              {passwrodVisible ? <EyeOpenedIcon /> : <EyeClosedIcon />}
            </div>
          )}
        </div>
        <div className="login-password-field-wrap">
          <AntdTextField
            name="newPassword"
            type={passwrodVisible ? 'text' : 'password'}
            placeholder={generalLabelStr('newPassword')}
            label={generalLabelStr('newPassword')}
            errorMsg={errors?.newPassword?.message}
            prefix={<LockIcon />}
            validateStatus={errors?.newPassword ? 'error' : ''}
            control={control}
          />

          {watch('newPassword') && (
            <div
              className="eye-icon-btn"
              style={{
                left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                right: i18n.dir() === 'ltr' ? '14px' : 'auto'
              }}
              onClick={() => {
                setPasswordVisible((prevState) => !prevState);
              }}
            >
              {passwrodVisible ? <EyeOpenedIcon /> : <EyeClosedIcon />}
            </div>
          )}
        </div>
        <div className="login-password-field-wrap">
          <AntdTextField
            name="newPasswordConfirmation"
            type={passwrodVisible ? 'text' : 'password'}
            placeholder={generalLabelStr('newPasswordConfirmation')}
            label={generalLabelStr('newPasswordConfirmation')}
            errorMsg={errors?.newPasswordConfirmation?.message}
            prefix={<LockIcon />}
            validateStatus={errors?.newPasswordConfirmation ? 'error' : ''}
            control={control}
          />

          {watch('newPasswordConfirmation') && (
            <div
              className="eye-icon-btn"
              style={{
                left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                right: i18n.dir() === 'ltr' ? '14px' : 'auto'
              }}
              onClick={() => {
                setPasswordVisible((prevState) => !prevState);
              }}
            >
              {passwrodVisible ? <EyeOpenedIcon /> : <EyeClosedIcon />}
            </div>
          )}
        </div>
        <div className="save-cancel-btns">
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isLoading}
          >
            {t('change_password_form.submit_btn.label')}
          </Button>
          <button onClick={handleCancel} type="button" className="cancel-btn">
            {t('change_password_form.cancel')}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default UserChangePasswordForm;
