/* eslint-disable eqeqeq */
import { LoadingOutlined } from '@ant-design/icons';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import getTabbyCheckoutUrlApi from '../../apis/cart-apis/getTabbyCheckoutUrlApi';
import getTamaraCheckoutUrlApi from '../../apis/cart-apis/getTamaraCheckoutUrlApi';
import successImg from '../../assets/imgs/icons/success.png';
import routerLinks from '../../components/app/routerLinks';
import { paymentTypesValues } from '../../const-values/paymentTypes';
import CartContext from '../../contexts/cart-context/CartContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import errorNotification from '../../utils/errorNotification';
import './CartStepThree.scss';

const CartStepThree = () => {
  const { t, i18n } = useTranslation();
  // const { step, setStep, isLastStep, childrenArray } = useContext(
  //   CustomStepperWrapContext
  // );
  const { cartOrderRes, paymentType } = useContext(CartContext);
  const customApiRequest = useCustomApiRequest();
  const { user } = useContext(UserContext);

  let isMounted = true;
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const handleTammaraCheckout = async () => {
    customApiRequest(
      getTamaraCheckoutUrlApi(
        {
          id: cartOrderRes?.id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingPayment(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            if (data?.checkout_url) window.location.replace(data.checkout_url);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'Try again later'
            });
          }
        }
      },
      (error) => {
        setIsLoadingPayment(false);
      }
    );
  };

  const handleTabbyCheckout = async () => {
    customApiRequest(
      getTabbyCheckoutUrlApi(
        {
          id: cartOrderRes?.id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingPayment(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            if (data?.checkoutUrl) window.location.replace(data.checkoutUrl);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'Try again later'
            });
          }
        }
      },
      (error) => {
        setIsLoadingPayment(false);
      }
    );
  };

  const renderBtn = () => {
    if (paymentType === paymentTypesValues.onlinePayment) {
      return (
        <a
          // target="_blank"
          href={`${
            process.env.REACT_APP_BASE_URL
          }/Payment/preparePayment?order_id=${cartOrderRes?.id || ''}`}
          className="my-orders-link"
        >
          {t('userCartPage.pay')}
          {/* {t('userCartPage.myOrdersBtn')} */}
        </a>
      );
    } else if (paymentType === paymentTypesValues.tamara)
      return (
        <button
          // target="_blank"
          onClick={handleTammaraCheckout}
          className="my-orders-link"
          disabled={isLoadingPayment}
        >
          {isLoadingPayment && <LoadingOutlined />} {t('userCartPage.pay')}
        </button>
      );
    else if (paymentType === paymentTypesValues.tabby)
      return (
        <button
          // target="_blank"
          onClick={handleTabbyCheckout}
          className="my-orders-link"
          disabled={isLoadingPayment}
        >
          {isLoadingPayment && <LoadingOutlined />} {t('userCartPage.pay')}
        </button>
      );

    return (
      <Link className="my-orders-link" to={routerLinks?.myOrders}>
        {t('userCartPage.myOrdersBtn')}
      </Link>
    );
  };

  return (
    <div className="stepper-step cart-step-three">
      <div className="step-content">
        <img className="success-img" src={successImg} alt="success" />
        <h2 className="success-text bold-font">
          {t('userCartPage.successPayment')}
        </h2>
        <p className="thanks-text">{t('userCartPage.thanks')}</p>

        {renderBtn()}
      </div>
    </div>
  );
};

export default CartStepThree;
