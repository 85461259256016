import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Logo from '../../common/logo/Logo';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import ForgetPasswordFormEnterCode from '../signin-page/ForgetPasswordFormEnterCode';
import ForgetPasswordFormEnterEmail from '../signin-page/ForgetPasswordFormEnterEmail';
import ForgetPasswordFormResetPassword from '../signin-page/ForgetPasswordFormResetPassword';
import './ForgetPasswordPage.scss';
import lockImg from '../../assets/imgs/icons/success-lock.png';
import { Link as RouterLink } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';

const ForgetPasswordPage = () => {
  const { t } = useTranslation();
  const sharedT = (key) => t(`forgetPasswordPage.${key}`);
  const {
    forgetPasswordFormEnterEmailAppended,
    forgetPasswordFormEnterCodeAppended,
    forgetPasswordFormResetPasswordAppended,
    successLock
  } = useContext(ForgetPasswordContext);

  const renderExtraText = () => {
    if (forgetPasswordFormEnterEmailAppended) {
      return (
        <div className="extra-text">
          <h2 className="main-title">{sharedT('enterEmail.mainTitle')}</h2>
          <p className="sub-title">{sharedT('enterEmail.subTitle')}</p>
        </div>
      );
    } else if (forgetPasswordFormEnterCodeAppended) {
      return (
        <div className="extra-text">
          <h2 className="main-title">{sharedT('verification.mainTitle')}</h2>
          <p className="sub-title">{sharedT('verification.subTitle')}</p>
        </div>
      );
    } else if (forgetPasswordFormEnterCodeAppended) {
      return (
        <div className="extra-text">
          <h2 className="main-title">{sharedT('change.mainTitle')}</h2>
          <p className="sub-title">{sharedT('change.subTitle')}</p>
        </div>
      );
    }
  };

  const LockComp = () => {
    return (
      <div className="lock-img-wrap">
        <img src={lockImg} alt="success" />
        <p>{t('forgetPasswordPage.successLock')}</p>
        <RouterLink className="signin-link" to={routerLinks?.signinPage}>
          {t('forgetPasswordPage.login')}
        </RouterLink>
      </div>
    );
  };

  return (
    <div className="forget-password-page">
      <div className="custom-container">
        <div className="page-content-wrap">
          <Logo className="app-logo" />
          <div className="page-form-wrap">
            {renderExtraText()}

            {forgetPasswordFormEnterEmailAppended && (
              <ForgetPasswordFormEnterEmail />
            )}
            {forgetPasswordFormEnterCodeAppended && (
              <ForgetPasswordFormEnterCode />
            )}
            {forgetPasswordFormResetPasswordAppended && (
              <ForgetPasswordFormResetPassword />
            )}
            {successLock && <LockComp />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
