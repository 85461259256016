import { Modal } from 'antd';
import './VideoModal.scss';
const VideoModal = ({ modalOpened, setModalOpened, src }) => {
  return (
    <Modal
      className="shared-custom-modal video-modal"
      width="96%"
      style={{ maxWidth: '842px' }}
      title=""
      open={modalOpened}
      closeIcon={false}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <div
        style={{
          margin: 12
        }}
      >
        <video
          style={{
            borderRadius: 6
          }}
          controls
          width="100%"
          src={src}
        ></video>
      </div>
    </Modal>
  );
};

export default VideoModal;
