/* eslint-disable eqeqeq */
import { LoadingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getOrderReportsApi from '../../../../apis/stores-apis/getOrderReportsApi';
import StoreOrdersContext from '../../../../contexts/store-orders-context/StoreOrdersContext';
import UserContext from '../../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../../utils/checkRes';
import OrderReportsTable from './order-reports-table';
import './styles.scss';

const OrderReportsModal = () => {
  const {
    setSelectedOrder,
    reportsModalOpened,
    setReportsModalOpened,
    selectedOrder
  } = useContext(StoreOrdersContext);
  const [complaints, setComplaints] = useState([]);
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const [isLoadingReports, setIsLoadingReports] = useState();
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;

    if (selectedOrder?.id) {
      setIsLoadingReports(true);
      customApiRequest(
        getOrderReportsApi(
          {
            id: selectedOrder.id
          },
          user?.token,
          i18n.language
        ),
        (res) => {
          if (isMounted) {
            setIsLoadingReports(false);
            if (checkRes(res) && res?.data?.data) {
              setComplaints(res.data.data);
            }
          }
        },
        (error) => {
          if (isMounted) setIsLoadingReports(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language]);

  return (
    <Modal
      className="shared-custom-modal order-reports-modal"
      width="90%"
      style={{ maxWidth: '900px' }}
      title={t('ordersPage.reports')}
      open={reportsModalOpened}
      onOk={() => {
        setReportsModalOpened(false);
        setSelectedOrder(null);
      }}
      onCancel={() => {
        setReportsModalOpened(false);
        setSelectedOrder(null);
      }}
      footer={false}
    >
      <div className="modal-content">
        {isLoadingReports ? (
          <div
            className="custom-container"
            style={{
              margin: '92px 0',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <LoadingOutlined style={{ fontSize: 24 }} spin />
          </div>
        ) : (
          <OrderReportsTable
            complaints={complaints}
            isLoadingDate={isLoadingReports}
            setIsLoadingData={setIsLoadingReports}
          />
        )}
      </div>
    </Modal>
  );
};

export default OrderReportsModal;
