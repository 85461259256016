import React from 'react';

const ShieldIcon = ({ color }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2141_19267)">
        <path
          d="M10.6703 1.24896L7.07254 0.0303788C6.95475 -0.00947521 6.82741 -0.00947521 6.70961 0.0303788L3.11185 1.24896C2.53983 1.44198 2.04223 1.8132 1.68976 2.30988C1.3373 2.80656 1.14789 3.40344 1.14844 4.01571V7.00063C1.14844 11.4124 6.43167 13.849 6.65793 13.9505C6.73133 13.9836 6.81076 14.0007 6.89108 14.0007C6.9714 14.0007 7.05083 13.9836 7.12423 13.9505C7.35049 13.849 12.6337 11.4124 12.6337 7.00063V4.01571C12.6343 3.40344 12.4449 2.80656 12.0924 2.30988C11.7399 1.8132 11.2423 1.44198 10.6703 1.24896ZM11.4852 7.00063C11.4852 10.1827 7.85641 12.2699 6.89108 12.7692C5.92459 12.2716 2.29697 10.1909 2.29697 7.00063V4.01571C2.297 3.64837 2.41083 3.29036 2.62232 2.9924C2.83382 2.69443 3.13226 2.47162 3.47536 2.35555L6.89108 1.1988L10.3068 2.35555C10.6499 2.47162 10.9483 2.69443 11.1598 2.9924C11.3713 3.29036 11.4852 3.64837 11.4852 4.01571V7.00063Z"
          fill={color ? color : '#6D6D6D'}
        />
        <path
          d="M8.78641 4.84196L6.38139 7.29196L5.09275 5.92696C5.04103 5.86952 4.97852 5.82318 4.9089 5.79066C4.83928 5.75814 4.76396 5.7401 4.68737 5.73761C4.61078 5.73512 4.53448 5.74823 4.46295 5.77615C4.39143 5.80408 4.32613 5.84627 4.2709 5.90022C4.21567 5.95418 4.17163 6.01882 4.14138 6.09034C4.11113 6.16185 4.09527 6.23879 4.09474 6.31663C4.09421 6.39446 4.10902 6.47162 4.1383 6.54355C4.16759 6.61548 4.21074 6.68073 4.26523 6.73546L5.58948 8.13546C5.68824 8.24382 5.80761 8.3307 5.94037 8.39086C6.07313 8.45102 6.21652 8.4832 6.36187 8.48546H6.38082C6.52327 8.48594 6.66439 8.45767 6.796 8.40229C6.9276 8.34692 7.04707 8.26554 7.14746 8.16288L9.60072 5.67087C9.6543 5.61656 9.69682 5.55206 9.72586 5.48105C9.7549 5.41005 9.76989 5.33392 9.76997 5.25703C9.77005 5.18014 9.75522 5.10399 9.72633 5.03292C9.69744 4.96185 9.65505 4.89726 9.60158 4.84283C9.54811 4.7884 9.48461 4.74521 9.41471 4.71571C9.34481 4.68621 9.26987 4.67098 9.19417 4.6709C9.11848 4.67082 9.04351 4.68588 8.97354 4.71523C8.90358 4.74458 8.83999 4.78764 8.78641 4.84196Z"
          fill={color ? color : '#6D6D6D'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2141_19267">
          <rect width="13.7823" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShieldIcon;
