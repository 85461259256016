/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import loadUserWalletApi from '../../../apis/load-wallet-apis/loadUserWalletApi';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../utils/errorNotification';
import './UserWalletForm.scss';

const UserWalletForm = ({ setModalOpened }) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  // const [fetchCount, setFetchCount] = useState(0);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        amount: Yup.string()
          .required(t('walletPage.enterAmount'))
          .test('amount', t('walletPage.invalidAmount'), (v, context) => {
            let result = true;
            if (v && !v.match(/^(\d+)?(\.\d+)?$/)) result = false;
            return result;
          })
      })
    ),
    mode: 'all',
    defaultValues: {
      amount: ''
    }
  });

  const customApiRequest = useCustomApiRequest();

  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    const formData = new FormData();
    formData.append('amount', data.amount);
    customApiRequest(
      loadUserWalletApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        // setIsLoadSingleProduct(false);
        if (res?.status === 200 && res?.data?.data?.url)
          window.location.replace(res.data.data.url);
        // if (checkRes(res)) {
        //   successNotification({
        //     title: t('successTitle'),
        //     message: res?.data?.message || t('successMessage')
        //   });
        //   setFetchCount((prev) => prev + 1);
        //   setModalOpened(false);
        // } else {
        //   errorNotification({
        //     title: t('errorTitle'),
        //     message: res?.data?.message || t('errorMessage')
        //   });
        // }
      },
      (error) => {
        setIsSubmittingForm(false);
        // setIsLoadSingleProduct(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || t('errorMessage')
        });
      }
    );
  };

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     customApiRequest(
  //       myInfoApi(user?.token, i18n.language),
  //       (res) => {
  //         if (checkRes(res)) {
  //           const data = res.data.data;
  //           setCurrentUser({
  //             ...data,
  //             token: user?.token,
  //             accountType: user.accountType,
  //             activated: user.activated
  //           });
  //         }
  //       },
  //       (error) => {}
  //     );
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [fetchCount]);

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="user-wallet-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <AntdTextField
            className="amount-field"
            name="amount"
            type="text"
            placeholder={t('walletPage.amount')}
            // label={t('walletPage.amount')}
            errorMsg={errors?.amount?.message}
            validateStatus={errors?.amount ? 'error' : ''}
            prefix={t('currency.sar')}
            control={control}
          />
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // loading={isSubmittingForm}
        >
          {t('walletPage.continue')}
        </Button>
      </Form>

      {isSubmittingForm && <LoadingModal />}
    </>
  );
};

export default UserWalletForm;
