/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Button } from 'antd';
import { Modal } from 'antd';
import errorNotification from '../../../utils/errorNotification';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import UserContext from '../../../contexts/user-context/UserProvider';
import reactivateStoreApi from '../../../apis/stores-apis/reactivateStoreApi';
import './MerchentStoreReactivateModal.scss';

const MerchentStoreReactivateModal = (props) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    store,
    setSelectedTableRow,
    modalOpened,
    setModalOpened,
    setFetchCount
  } = props;
  const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);

  const customApiRequest = useCustomApiRequest();
  const handleReactivate = () => {
    setIsSubmittingForm(true);
    const formData = new FormData();
    formData.append('store_id', store?.id);

    customApiRequest(
      reactivateStoreApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        if (checkRes(res)) {
          setModalOpened(false);
          setFetchCount((prevState) => prevState + 1);
          successNotification({
            title: t('successTitle'),
            message: res?.data?.message || 'تم التعديل بنجاح'
          });
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };
  const handleCancel = () => {
    setModalOpened(false);
    setSelectedTableRow && setSelectedTableRow(null);
  };
  const sharedT = (key) => t(`reactivateModal.${key}`);
  return (
    <Modal
      transitionName=""
      className="store-reactivate-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      // title={<div className="modal-title"></div>}
      title={sharedT('modalTitle')}
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedTableRow && setSelectedTableRow(null);
      }}
      onCancel={() => {
        handleCancel();
      }}
      footer={false}
    >
      <div className="modal-content">
        <p>{sharedT('title')}</p>
        <div className="modal-btns">
          <Button
            className="submit-btn"
            onClick={handleReactivate}
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmittingForm}
          >
            {sharedT('yes')}
          </Button>
          <Button onClick={handleCancel} className="cancel-btn">
            {sharedT('no')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MerchentStoreReactivateModal;
