/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useLocation,
  useParams,
  Link as RouterLink
} from 'react-router-dom';
import getSingleStoreApi from '../../../apis/stores-apis/getSingleStoreApi';
import SingleStoreContext from '../../../contexts/single-store-context/SingleStoreContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
// import verifyIcon from '../../../assets/imgs/icons/store-card/verify.svg';
import dotsHorizontalImg from '../../../assets/imgs/icons/store-card/dots-horizontal.svg';
import { LoadingOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import CustomImage from '../../../common/custom-image/CustomImage';
import EditProductIcon from '../../../common/icons/product-card-icons/EditProductIcon';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import PhoneIcon from '../../../common/icons/PhoneIcon';
import PinIcon from '../../../common/icons/PinIcon';
import ProductCard from '../products-page/ProductCard';
import { Dropdown, Empty, Menu, Pagination } from 'antd';
import './MerchentStoreDetailsPage.scss';
import PrivacyTermsIcon from '../../../common/icons/store-card-icons/PrivacyTermsIcon';
import StopWorkingIcon from '../../../common/icons/store-card-icons/StopWorkingIcon';
import DeleteStoreIcon from '../../../common/icons/store-card-icons/DeleteStoreIcon';
import MerchentStoreTermsAndConditionsModal from './MerchentStoreTermsAndConditionsModal';
import MerchentStoreDeactivateModal from './MerchentStoreDeactivateModal';
import RenderStoreStatus from '../../../common/render-store-status/RenderStoreStatus';
import MerchentStoreDeleteModal from './MerchentStoreDeleteModal';
import productTypes from '../../../const-values/productTypes';
import MerchentStoreRejectModal from './MerchentStoreRejectModal';
import MerchentStoreReactivateModal from './MerchentStoreReactivateModal';
import storeStatus from '../../../const-values/storeStatus';
import EmptyCompoent from '../../../common/empty-compoent/EmptyComponent';
import emptyImg from '../../../assets/imgs/empty/empty.png';

const MerchentStoreDetailspage = () => {
  const { user } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const params = useParams();
  const navigate = useNavigate();
  const {
    fetchedSingleStoreDetails,
    isLoadingSingleStoreDetails,
    setIsLoadingSingleStoreDetails,
    fetchSingleStoreDetailsCount,
    setFetchSingleStoreDetailsCount,
    setFetchedSingleStoreDetails,
    setTermsAndConditionsModalOpened,
    termsAndConditionsModalOpened,
    deactivateStoreModalOpened,
    setDeactivateStoreModalOpened,
    reactivateModalOpened,
    setReactivateModalOpened,
    rejectModalOpened,
    setRejectModalOpened,
    deleteStoreModalOpened,
    setDeleteStoreModalOpened,
    tablePagination,
    setTablePagination,
    tableFilter
  } = useContext(SingleStoreContext);
  const sharedT = (key) => t(`storesPage.${key}`);

  const sharedDropDownList = () => [
    {
      key: 'terms_key',
      icon: <PrivacyTermsIcon />,
      onClick: () => {
        setTermsAndConditionsModalOpened(true);
      },
      label: (
        <span
          style={{
            padding: '0px 4px'
          }}
        >
          {sharedT('menu.terms')}
        </span>
      )
    },
    {
      key: 'stop_working_key',
      icon: <StopWorkingIcon />,
      onClick: () => {
        setDeactivateStoreModalOpened(true);
      },
      label: (
        <span
          style={{
            padding: '0px 4px'
          }}
        >
          {sharedT('menu.stop')}
        </span>
      )
    },
    {
      key: 'delete_store_key',
      icon: <DeleteStoreIcon />,
      onClick: () => {
        setDeleteStoreModalOpened(true);
      },
      label: (
        <span
          style={{
            padding: '0px 4px'
          }}
        >
          {sharedT('menu.delete')}
        </span>
      )
    }
  ];

  const renerStoreDropdownMenu = () => {
    if (
      fetchedSingleStoreDetails?.store?.status == storeStatus?.active ||
      fetchedSingleStoreDetails?.store?.status == storeStatus?.notCompleted
    ) {
      return (
        <Dropdown
          arrow
          trigger={['click']}
          // disabled={loadingSignout}
          menu={{
            items: sharedDropDownList()
          }}
        >
          <button className="more-actions-btn">
            <img src={dotsHorizontalImg} alt="more options" />
          </button>
        </Dropdown>
      );
    } else if (
      fetchedSingleStoreDetails?.store?.status == storeStatus?.disabled
    ) {
      return (
        <Dropdown
          arrow
          trigger={['click']}
          // disabled={loadingSignout}
          menu={{
            items: [
              ...sharedDropDownList(),
              {
                key: 'reactivate_key',
                style: {
                  backgroundColor: 'transparent',
                  margin: '12px auto',
                  justifyContent: 'center',
                  textAlign: 'center'
                },
                onClick: () => {
                  setReactivateModalOpened(true);
                },
                label: (
                  <span
                    style={{
                      padding: '4px 18px',
                      backgroundColor: 'rgba(37, 211, 102, 0.1)',
                      color: 'rgb(37, 211, 102)',
                      borderRadius: 4
                    }}
                  >
                    {sharedT('menu.reactivate')}
                  </span>
                )
              }
            ].filter((item) => item.key !== 'stop_working_key')
          }}
        >
          <button className="more-actions-btn">
            <img src={dotsHorizontalImg} alt="more options" />
          </button>
        </Dropdown>
      );
    }
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingSingleStoreDetails(true);
    customApiRequest(
      getSingleStoreApi(
        {
          store_id: params?.store_id,
          ...tableFilter,
          ...values // { page: ...}
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingSingleStoreDetails(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setFetchedSingleStoreDetails(data);
            if (
              res.data.data?.products?.data?.length > 0 &&
              res.data.data.products?.pagination
            ) {
              setTablePagination(res.data.data.products?.pagination);
            }
          } else {
            setFetchedSingleStoreDetails(null);
          }
        }
      },
      (error) => {
        setIsLoadingSingleStoreDetails(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchSingleStoreDetailsCount, i18n.language]);

  const renderProductsCards = () => {
    if (fetchedSingleStoreDetails?.products?.data?.length > 0) {
      return (
        <div className="products-wrap">
          {fetchedSingleStoreDetails?.products?.data.map((obj) => (
            <ProductCard
              type={productTypes?.merchentProduct}
              key={obj?.id}
              card={obj}
            />
          ))}

          {tablePagination && (
            <Pagination
              locale={{
                jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                page: ''
              }}
              showSizeChanger={false}
              defaultCurrent={1}
              current={tablePagination?.current_page}
              pageSize={tablePagination?.per_page}
              total={tablePagination?.total}
              // itemRender={itemRender}
              onChange={(page, pageSize) => {
                setFetchSingleStoreDetailsCount((prev) => prev + 1);
                navigate(
                  `${merchentRouterLinks?.storeProducts(
                    params?.store_id
                  )}?page=${page}`
                );
              }}
              hideOnSinglePage={true}
            />
          )}
        </div>
      );
    }
    return <Empty description={false}>لا توجد خدمات متاحة</Empty>;
  };

  if (isLoadingSingleStoreDetails) {
    return (
      <div
        className="custom-container"
        style={{
          paddingTop: 92,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    );
  } else if (
    fetchedSingleStoreDetails &&
    (fetchedSingleStoreDetails?.store?.status == storeStatus?.active ||
      fetchedSingleStoreDetails?.store?.status == storeStatus?.notCompleted)
  ) {
    return (
      <div className="merchent-stores-details-page shared-dashboard-page shared-custom-page">
        <div className="store-details-content">
          <div
            className="store-cover-photo"
            style={{
              backgroundImage: `url(${fetchedSingleStoreDetails?.store?.cover})`
            }}
          ></div>
        </div>
        <div className="custom-container">
          <div className="logo-tab-bar">
            <div className="logo-name-wrap">
              <div className="logo-wrap">
                <CustomImage src={fetchedSingleStoreDetails?.store?.logo} />
              </div>
              <div className="name-income-wrap">
                <div className="name-status">
                  <div className="name">
                    {fetchedSingleStoreDetails?.store?.name}
                  </div>
                  <RenderStoreStatus obj={fetchedSingleStoreDetails?.store} />
                </div>

                <div className="income-wrap">
                  <span
                    style={{
                      color: '#AAAAAA'
                    }}
                  >
                    {t('merchentStoreDetailsPage.totalIncome')}
                  </span>
                  <span>{fetchedSingleStoreDetails?.store?.wallet}</span>
                </div>
              </div>
            </div>
            {/*  */}

            <div className="action-btns">
              <RouterLink
                className="edit-link"
                to={merchentRouterLinks?.merchentEditStore(params?.store_id)}
              >
                <EditProductIcon />
                {t('merchentStoreDetailsPage.edit')}
              </RouterLink>

              {renerStoreDropdownMenu()}
            </div>
          </div>

          <div className="desc-contact-info-wrap">
            {fetchedSingleStoreDetails?.store?.description ? (
              <div className="desc-wrap">
                <div className="title-wrap">
                  {t('merchentStoreDetailsPage.details')}
                </div>
                <div className="desc-data">
                  {fetchedSingleStoreDetails.store.description}
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div className="contact-info-wrap">
              <div className="title-wrap">
                {t('merchentStoreDetailsPage.contactInfo')}
              </div>
              <div className="contact-data">
                {fetchedSingleStoreDetails?.store?.phone && (
                  <div className="icon-title-wrap">
                    <PhoneIcon />
                    {fetchedSingleStoreDetails?.store?.phone}
                  </div>
                )}
                {fetchedSingleStoreDetails?.store?.country && (
                  <div className="icon-title-wrap">
                    <PinIcon />
                    {fetchedSingleStoreDetails.store.country} /
                    {fetchedSingleStoreDetails.store?.city}
                  </div>
                )}
                {fetchedSingleStoreDetails?.store?.address && (
                  <div className="icon-title-wrap">
                    <PinIcon />
                    {fetchedSingleStoreDetails.store.address}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="custom-container">
          <div className="ps-title">
            {t('merchentStoreDetailsPage.storeProducts')}
          </div>
          {renderProductsCards()}
        </div>

        {termsAndConditionsModalOpened && (
          <MerchentStoreTermsAndConditionsModal
            store={fetchedSingleStoreDetails?.store}
            setSelectedTableRow={null}
            modalOpened={termsAndConditionsModalOpened}
            setModalOpened={setTermsAndConditionsModalOpened}
            setFetchCount={setFetchSingleStoreDetailsCount}
          />
        )}

        {deactivateStoreModalOpened && (
          <MerchentStoreDeactivateModal
            store={fetchedSingleStoreDetails?.store}
            setSelectedTableRow={null}
            modalOpened={deactivateStoreModalOpened}
            setModalOpened={setDeactivateStoreModalOpened}
            setFetchCount={setFetchSingleStoreDetailsCount}
          />
        )}

        {reactivateModalOpened && (
          <MerchentStoreReactivateModal
            store={fetchedSingleStoreDetails?.store}
            setSelectedTableRow={null}
            modalOpened={reactivateModalOpened}
            setModalOpened={setReactivateModalOpened}
            setFetchCount={setFetchSingleStoreDetailsCount}
          />
        )}

        {deleteStoreModalOpened && (
          <MerchentStoreDeleteModal
            store={fetchedSingleStoreDetails?.store}
            setSelectedTableRow={null}
            modalOpened={deleteStoreModalOpened}
            setModalOpened={setDeleteStoreModalOpened}
            setFetchCount={setFetchSingleStoreDetailsCount}
          />
        )}

        {rejectModalOpened && (
          <MerchentStoreRejectModal
            store={fetchedSingleStoreDetails?.store}
            setSelectedTableRow={null}
            modalOpened={rejectModalOpened}
            setModalOpened={setRejectModalOpened}
            setFetchCount={setFetchSingleStoreDetailsCount}
          />
        )}
      </div>
    );
  }
  return <EmptyCompoent img={emptyImg} />;
};

export default MerchentStoreDetailspage;
