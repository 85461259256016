/* eslint-disable react-hooks/exhaustive-deps */
import SharedSlider from '../../components/shared-slider/SharedSlider';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import SlickSliderBtns from '../../utils/SlickSliderBtns';
import './HomeSlider.scss';
import checkRes from '../../utils/checkRes';
import getHomeSlidesApi from '../../apis/general/getHomeSlidesApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import SharedAppContext from '../../contexts/shared-app-context/SharedAppContext';

const HomeSlider = () => {
  const { i18n, t } = useTranslation();
  const [sliderDir, setSliderDir] = useState(i18n.dir());
  useEffect(() => {
    setSliderDir(i18n.dir());
  }, [i18n.dir()]);

  const sliderSettings = {
    fade: true,
    dots: true,
    arrows: true,
    rtl: sliderDir === 'rtl' ? true : false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
  };

  // SlickSliderBtns('main-app-slick-slider', isLoading);
  SlickSliderBtns('main-app-slick-slider', false);
  const { isLoadingHomeSlider, fetchedHomeSlides } =
    useContext(SharedAppContext);

  const renderSingleSlide = (item) => {
    return (
      <div className="slide-wrap" key={item.id}>
        <img className="slide-img" src={item.image} alt="slide" />
        <div className="slide-text">
          <div className="custom-container">
            <div className={`content-wrap ${i18n.dir()}`}>
              {item?.mainTitle && (
                <div className="text-wrap">
                  {<p className="provide-p">{t('hero_section.main_title')}</p>}
                  {item?.mainTitle && (
                    <h2 className="slide-h2">{item.mainTitle}</h2>
                  )}
                  {item?.subTitle && (
                    <p className="sub-title">{item.subTitle}</p>
                  )}
                </div>
              )}
              {item?.to && (
                <a className="shop-link" href={item?.to}>
                  {t('hero_section.shop_btn')}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="home-hero-section">
      <SharedSlider
        className="main-app-slick-slider"
        slides={fetchedHomeSlides?.length > 0 ? fetchedHomeSlides : []}
        settings={sliderSettings}
        renderSingleSlide={renderSingleSlide}
        isLoading={isLoadingHomeSlider}
      />
    </div>
  );
};

export default HomeSlider;
