import ArchHeading from '../../common/arch-heading/ArchHeading';
import { useTranslation } from 'react-i18next';
import CustomImage from '../../common/custom-image/CustomImage';
import { LoadingOutlined } from '@ant-design/icons';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import './HomeCategorySection.scss';
import { useContext } from 'react';
import GeneralProductsContext from '../../contexts/general-products-context/GeneralProductsContext';
import { Link as RouterLink } from 'react-router-dom';
import CatsContext from '../../contexts/cats-context/CatsContext';
const HomeCategorySection = () => {
  const { t } = useTranslation();
  const { fetchedData: allFetchedCats, isLoading: isLoadingCats } =
    useContext(CatsContext);
  const { setTableFilter } = useContext(GeneralProductsContext);

  const renderFilterCats = () => {
    if (isLoadingCats) {
      return (
        <div
          style={{
            padding: '132px 0',
            display: 'grid',
            placeItems: 'center'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (allFetchedCats?.length > 0) {
      return allFetchedCats.map((cat) => (
        <RouterLink
          to={`${generalRouterLinks?.generalProductsRoute}?cat_id=${
            cat?.id || ''
          }`}
          key={cat?.id}
          className="cat-item"
          onClick={() => {
            setTableFilter((prev) => ({
              ...prev,
              cat_id: cat?.id
            }));

            // navigate(generalRouterLinks?.generalProductsRoute, {
            //   state: {
            //     cat_id: cat?.id
            //   }
            // });
          }}
        >
          <CustomImage className="cat-img" src={cat?.image} />
          <div className="cat-name">{cat?.name}</div>
        </RouterLink>
      ));
    }
    return null;
  };

  return (
    <section className="home-cats-section">
      <div className="custom-container">
        <ArchHeading title={t('categories_section.title')} />
        <div className="section-content">
          <div className="cats-list">{renderFilterCats()}</div>
        </div>
      </div>
    </section>
  );
};

export default HomeCategorySection;
