import React from 'react';

const VideoPlayIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.8744 55.9274L22.1243 55.9274C8.7756 55.9274 3.07227 50.2241 3.07227 36.8753L3.07227 22.1253C3.07227 8.77658 8.7756 3.07324 22.1243 3.07324L36.8744 3.07324C50.2231 3.07324 55.9264 8.77658 55.9264 22.1253L55.9264 36.8753C55.9264 50.2241 50.2231 55.9274 36.8744 55.9274ZM22.1243 6.76074C10.7914 6.76074 6.75977 10.7924 6.75977 22.1253L6.75977 36.8753C6.75977 48.2082 10.7914 52.2399 22.1243 52.2399L36.8744 52.2399C48.2073 52.2399 52.2389 48.2082 52.2389 36.8753L52.2389 22.1253C52.2389 10.7924 48.2073 6.76074 36.8744 6.76074L22.1243 6.76074Z"
          fill={color ? color : '#6D6D6D'}
        />
        <path
          d="M52.8053 19.3223L6.19531 19.3223C5.1874 19.3223 4.35156 18.4864 4.35156 17.4785C4.35156 16.4706 5.16281 15.6348 6.19531 15.6348L52.8053 15.6348C53.8132 15.6348 54.6491 16.4706 54.6491 17.4785C54.6491 18.4864 53.8378 19.3223 52.8053 19.3223Z"
          fill={color ? color : '#6D6D6D'}
        />
        <path
          d="M20.9453 18.9787C19.9374 18.9787 19.1016 18.1429 19.1016 17.135V5.1875C19.1016 4.17958 19.9374 3.34375 20.9453 3.34375C21.9532 3.34375 22.7891 4.17958 22.7891 5.1875V17.135C22.7891 18.1429 21.9532 18.9787 20.9453 18.9787Z"
          fill={color ? color : '#6D6D6D'}
        />
        <path
          d="M38.0547 17.8725C37.0468 17.8725 36.2109 17.0367 36.2109 16.0287V5.1875C36.2109 4.17958 37.0468 3.34375 38.0547 3.34375C39.0626 3.34375 39.8984 4.17958 39.8984 5.1875V16.0287C39.8984 17.0612 39.0872 17.8725 38.0547 17.8725Z"
          fill={color ? color : '#6D6D6D'}
        />
        <path
          d="M27.2629 44.5446C26.3779 44.5446 25.5421 44.3234 24.78 43.9054C23.1083 42.9221 22.125 40.9554 22.125 38.4725V32.5725C22.125 30.0896 23.1083 28.0984 24.8046 27.115C26.5008 26.1317 28.7133 26.2792 30.8521 27.5329L35.9654 30.4829C38.1042 31.7121 39.3579 33.5559 39.3579 35.5225C39.3579 37.4892 38.1042 39.3329 35.9408 40.5621L30.8275 43.5121C29.6475 44.2004 28.4183 44.5446 27.2629 44.5446ZM27.2875 30.1634C27.0417 30.1634 26.8204 30.2125 26.6483 30.3109C26.1321 30.6059 25.8125 31.4417 25.8125 32.5725V38.4725C25.8125 39.5788 26.1321 40.4146 26.6483 40.7342C27.1646 41.0292 28.0496 40.8817 29.0083 40.3163L34.1217 37.3663C35.0804 36.8009 35.6704 36.1125 35.6704 35.5225C35.6704 34.9325 35.105 34.2442 34.1217 33.6788L29.0083 30.7288C28.3692 30.36 27.7546 30.1634 27.2875 30.1634Z"
          fill={color ? color : '#6D6D6D'}
        />
      </svg>
    </span>
  );
};

export default VideoPlayIcon;
