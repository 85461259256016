import GridViewIcon from '../common/icons/GridViewIcon';
import RowViewIcon from '../common/icons/RowViewIcon';

export const viewAsOptions = {
  grid: 1,
  row: 2
};
export const viewAsArr = (t) => {
  const sharedT = (key) => t(`viewAs.${key}`);
  return [
    {
      id: 1,
      value: viewAsOptions?.grid,
      icon: <GridViewIcon />,
      name: sharedT('grid')
    },
    {
      id: 2,
      value: viewAsOptions?.row,
      icon: <RowViewIcon />,
      name: sharedT('row')
    }
    // {
    //   id: 3,
    //   value: 3,
    //   icon: 'Three',
    //   name: 'Three'
    // }
  ];
};
