/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import UserContext from '../../../contexts/user-context/UserProvider';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import formDefaultValues from './otherServiceFormDefaultValues';
import getValidationSchema from './otherServiceFormValidationSchema';
import createNewOtherServiceApi from '../../../apis/other-services-apis/createNewOtherServiceApi';
import UserIcon from '../../../common/icons/UserIcon';
import walletImg from '../../../assets/imgs/icons/wallet.png';
import onlineImg from '../../../assets/imgs/icons/online.png';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
// import useFirebaseDeviceToken from '../../custom-hooks/useFirebaseDeviceToken';
// import cameraImg from '../../../assets/imgs/icons/camera-other.svg';
// import imgOther from '../../../assets/imgs/icons/img-other.png';
import CameraOtherIcon from '../../../common/icons/CameraOtherIcon';
import ImagesOtherIcon from '../../../common/icons/ImagesOtherIcon';

const baseLabelTransString = (t, key) => t(`other_service_form.${key}.label`);

const OtherForm = ({ allFetchedServices }) => {
  const { i18n, t } = useTranslation();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { user } = useContext(UserContext);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(getValidationSchema(null, t)),
    mode: 'all',
    defaultValues: formDefaultValues
  });
  // console.log('watch :, ', watch());
  // console.log('errors : ', errors);

  const renderMsg = (type) => {
    if (type == 1 || type == 3) {
      return i18n?.language === 'ar'
        ? 'تم ارسال الطلب بنجاح'
        : 'Your order sent successfully';
    }
    return t('successMessage');
  };

  useEffect(() => {
    if (watch('service_id')) {
      setValue('servicePrice_id', '');
    }
  }, [watch('service_id')]);

  const handleServicePricesOptions = (serviceId) => {
    if (serviceId && allFetchedServices?.length > 0) {
      const foundService = allFetchedServices.find((c) => c.id == serviceId);
      if (foundService && foundService.servicePrice?.length > 0) {
        return foundService.servicePrice.map((p) => ({
          title: `${p?.name} = ${p?.price} ${t('currency.sar')}`,
          value: String(p?.id)
        }));
      }
    }
    return [];
  };

  const customApiRequest = useCustomApiRequest();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('service_id', data.service_id);
    if (data.servicePrice_id)
      formData.append('servicePrice_id', data.servicePrice_id);
    formData.append('paymentType', data.paymentType || '');
    formData.append('note', data.note || '');

    setIsSubmittingForm(true);
    customApiRequest(
      createNewOtherServiceApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        if (checkRes(res)) {
          if (data.paymentType == 2)
            window.location.replace(
              `${process.env.REACT_APP_BASE_URL}/PaymentOtherService/pay?id=${
                res?.data?.data?.id || ''
              }`
            );
          reset(formDefaultValues);
          successNotification({
            title: t('successTitle'),
            message: renderMsg(res?.data?.data?.paymentType)
          });
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || t('errorMessage')
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || t('errorMessage')
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="create-other-service-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="radios-wrap">
            <p className="radios-group-title">
              {baseLabelTransString(t, 'need_radio')}
            </p>
            <div className="labels-wrap need-label">
              {allFetchedServices?.length > 0 &&
                allFetchedServices.map((obj, index) => {
                  return obj?.name ? (
                    <label
                      key={obj?.id}
                      className={`radio-input-label ${
                        String(watch(`service_id`)) == String(obj.id)
                          ? 'selected'
                          : ''
                      }`}
                    >
                      <input
                        type="radio"
                        value={obj?.id}
                        {...register(`service_id`)}
                      />
                      {watch(`service_id`) == String(obj.id) ? (
                        // <RadioButtonFilled />
                        index === 0 ? (
                          <CameraOtherIcon color="#fff" />
                        ) : (
                          <ImagesOtherIcon color="#fff" />
                        )
                      ) : // <RadioButtonEmpty />
                      index === 0 ? (
                        <CameraOtherIcon />
                      ) : (
                        <ImagesOtherIcon />
                      )}
                      <span className="label-span">{obj.name}</span>
                    </label>
                  ) : null;
                })}
            </div>
            {errors?.service_id && (
              <p
                className="error-p"
                style={{
                  marginTop: 6
                }}
              >
                {errors?.service_id?.message}
              </p>
            )}
          </div>

          {allFetchedServices.find((obj) => obj?.id == watch('service_id'))
            ?.servicePrice?.length > 0 &&
          allFetchedServices.find((obj) => obj?.id == watch('service_id')) ? (
            <div className="select-label-wrap">
              <p className="label-p">
                {baseLabelTransString(t, 'video_duration')}
              </p>
              <div className="custom-select-wrap without-icon">
                <AntdSelectOption
                  name={`servicePrice_id`}
                  errorMsg={
                    errors?.servicePrice_id && errors.servicePrice_id.message
                  }
                  validateStatus={errors?.servicePrice_id ? 'error' : ''}
                  control={control}
                  setValue={setValue}
                  placeholder={baseLabelTransString(t, 'servicePrice_id')}
                  prefix={<UserIcon />}
                  options={handleServicePricesOptions(watch(`service_id`))}
                  showSearch={true}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  formClassName="create-other-service-form"
                />
              </div>
            </div>
          ) : null}

          <div
            className="text-area-label-wrap"
            style={{
              marginTop: 22
            }}
          >
            <p className="label-p">{baseLabelTransString(t, 'note')}</p>
            <div className="text-field-wrap">
              <AntdTextarea
                className="form-text-area white-bg"
                name="note"
                rows={4}
                placeholder={baseLabelTransString(t, 'note')}
                errorMsg={errors?.note?.message}
                validateStatus={errors?.note ? 'error' : ''}
                control={control}
              />
            </div>
          </div>

          <div className="radios-wrap payment-radios">
            <p className="radios-group-title">
              {baseLabelTransString(t, 'payment_method')}
            </p>
            <div className="labels-wrap">
              {[
                {
                  id: '1',
                  src: walletImg,
                  text: (
                    <div>
                      <p>
                        {i18n.language === 'ar'
                          ? 'الدفع نقدا فى مقر نعوم'
                          : 'Cash payment in naomy place'}
                      </p>
                    </div>
                  )
                },
                {
                  id: '2',
                  src: onlineImg,
                  text: (
                    <div>
                      <p>
                        {i18n.language === 'ar'
                          ? 'الدفع من خلال البطاقات الالكترونية'
                          : 'Payment via online cards'}
                      </p>
                    </div>
                  )
                },
                {
                  id: '3',
                  src: null,
                  text: (
                    <div>
                      <p>
                        {i18n?.language === 'ar'
                          ? 'حوالة مالية الى حساب نعوم'
                          : "Money transfer to naomy's account"}
                      </p>
                      <p>
                        {i18n?.language === 'ar'
                          ? 'مصرف الانماء'
                          : 'Enmaa Bank'}
                      </p>
                      <p>SA54454644454555</p>
                    </div>
                  )
                }
              ].map((obj, i) => (
                <label
                  key={i}
                  className={`radio-input-label ${
                    String(watch(`paymentType`)) === String(obj.id)
                      ? 'selected'
                      : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={obj.id}
                    {...register(`paymentType`)}
                  />
                  <div className="payment-img">
                    {obj?.src && <img src={obj?.src} alt="img" />}
                    {obj?.text && obj.text}
                  </div>
                </label>
              ))}
            </div>
            {errors?.paymentType && (
              <p
                className="error-p"
                style={{
                  marginTop: 6
                }}
              >
                {errors?.paymentType?.message}
              </p>
            )}
          </div>

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmittingForm}
          >
            {!isSubmittingForm
              ? baseLabelTransString(t, 'submitBtn')
              : t('product_form.submitBtn.submitting')}
          </Button>
        </div>

        {isSubmittingForm && <LoadingModal />}
      </Form>
    </>
  );
};

export default OtherForm;
