/* eslint-disable eqeqeq */
import verifyIcon from '../../assets/imgs/icons/store-card/verify.svg';
import pindingIcon from '../../assets/imgs/icons/store-card/pinding-icon.svg';
import refusedIcon from '../../assets/imgs/icons/store-card/refused-icon.svg';
import storeStatus from '../../const-values/storeStatus';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import StoresContext from '../../contexts/stores-context/StoresContext';

const RenderStoreStatus = ({ obj }) => {
  const { t } = useTranslation();
  const { setRejectModalOpened, setSelectedTableRow } =
    useContext(StoresContext);
  const sharedT = (key) => t(`storeStatus.${key}`);
  const renderStatus = () => {
    if (obj?.status == storeStatus?.active) {
      return (
        <div
          className="status"
          style={{
            color: '#25D366'
          }}
        >
          <img src={verifyIcon} alt="verify" />
          {sharedT('active')}
        </div>
      );
    } else if (obj?.status == storeStatus?.disabled) {
      return (
        <div
          className="status"
          style={{
            color: '#25D366'
          }}
        >
          <img src={verifyIcon} alt="verify" />
          {sharedT('disabled')}
        </div>
      );
    } else if (obj?.status == storeStatus?.underReview)
      return (
        <div
          className="status"
          style={{
            color: '#F9A000'
          }}
        >
          <img src={pindingIcon} alt="pinding" />
          {sharedT('underReview')}
        </div>
      );
    else if (obj?.status == storeStatus?.rejected)
      return (
        <div className="reject-reason-wrap">
          <div
            className="status"
            style={{
              color: '#ED0006'
            }}
          >
            <img src={refusedIcon} alt="rejected" />
            {sharedT('rejected')}
          </div>

          <button
            onClick={() => {
              setSelectedTableRow(obj);
              setRejectModalOpened(true);
            }}
          >
            {sharedT('rejectReason')}
          </button>
        </div>
      );
    else if (obj?.status == storeStatus?.notCompleted)
      return (
        <div className="reject-reason-wrap">
          <div
            className="status"
            style={{
              color: '#ED0006'
            }}
          >
            <img src={refusedIcon} alt="not completed" />
            {sharedT('notCompleted')}
          </div>

          <button
            onClick={() => {
              setSelectedTableRow(obj);
              setRejectModalOpened(true);
            }}
          >
            {sharedT('reason')}
          </button>
        </div>
      );
    return '';
  };

  return renderStatus();
};

export default RenderStoreStatus;
