import { useState, createContext } from 'react';
import { EditorState } from 'draft-js';
import productStatus from '../../const-values/productStatus';

const INITIAL_VALUES = {
  isLoadingTableData: false,
  setIsLoadingTableData: (v) => {},
  formModalOpened: false,
  setFormModalOpened: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  fetchTableDataCount: 0,
  setFetchTableDataCount: (v) => {},
  allFetchedTableData: null,
  setAllFetchedTableData: (v) => {},
  questionEditorStateAr: EditorState.createEmpty(),
  setQuestionEditorStateAr: (v) => {},
  rejectedModalOpened: false,
  setRejectedModalOpened: (v) => {},
  isTogglingHide: false,
  setIsTogglingHide: (v) => {},
  deleteModalOpened: false,
  setDeleteModalOpened: (v) => {},
  isDeletingProduct: false,
  setIsDeletingProduct: (v) => {},
  //
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  selectedTableRow: null,
  setSelectedTableRow: (v) => {},
  // filter
  tableFilter: {
    page: 1,
    status: productStatus?.active,
    store_id: ''
  },
  setTableFilter: (v) => {}
};

const ProductsContext = createContext(INITIAL_VALUES);

export const ProductsProvider = ({ children }) => {
  const [isLoadingTableData, setIsLoadingTableData] = useState(
    INITIAL_VALUES.isLoading
  );
  const [fetchTableDataCount, setFetchTableDataCount] = useState(
    INITIAL_VALUES.fetchTableDataCount
  );
  const [allFetchedTableData, setAllFetchedTableData] = useState(
    INITIAL_VALUES.allFetchedTableData
  );
  const [selectedTableRow, setSelectedTableRow] = useState(
    INITIAL_VALUES.selectedTableRow
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES.tablePagination
  );
  const [formModalOpened, setFormModalOpened] = useState(
    INITIAL_VALUES.modalOpened
  );
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  const [rejectedModalOpened, setRejectedModalOpened] = useState(
    INITIAL_VALUES.rejectedModalOpened
  );
  const [isTogglingHide, setIsTogglingHide] = useState(
    INITIAL_VALUES.isTogglingHide
  );
  const [deleteModalOpened, setDeleteModalOpened] = useState(
    INITIAL_VALUES.deleteModalOpened
  );
  const [isDeletingProduct, setIsDeletingProduct] = useState(
    INITIAL_VALUES.isDeletingProduct
  );
  //

  //
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES.tableFilter);

  return (
    <ProductsContext.Provider
      value={{
        isLoadingTableData,
        setIsLoadingTableData,
        formModalOpened,
        setFormModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchTableDataCount,
        setFetchTableDataCount,
        allFetchedTableData,
        setAllFetchedTableData,
        selectedTableRow,
        setSelectedTableRow,
        rejectedModalOpened,
        setRejectedModalOpened,
        isTogglingHide,
        setIsTogglingHide,
        deleteModalOpened,
        setDeleteModalOpened,
        isDeletingProduct,
        setIsDeletingProduct,
        //
        tablePagination,
        setTablePagination,
        tableFilter,
        setTableFilter
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export default ProductsContext;
