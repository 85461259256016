/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import getAdsApi from '../../apis/general/getAdsApi';
import CustomImage from '../../common/custom-image/CustomImage';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import './OffersSection.scss';

const OffersSection = () => {
  const { i18n } = useTranslation();
  const [ads, setAds] = useState([]);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    customApiRequest(
      getAdsApi(i18n.language),
      (res) => {
        if (isMounted) {
          if (checkRes(res) && res?.data?.data) {
            setAds(res.data.data);
          }
        }
      },
      (error) => {}
    );

    return () => (isMounted = false);
  }, []);
  if (ads?.length > 0) {
    return (
      <section className="home-offers-section">
        <div className="custom-container">
          <div className="offers-wrap">
            {ads.map((item) => {
              return (
                <RouterLink
                  key={item?.id}
                  className={`offer-link ${i18n.dir()}`}
                  to={`${
                    generalRouterLinks?.generalProductsRoute
                  }?catFilter_id=${item?.catFilter_id || ''}&discount=${
                    item?.discount || ''
                  }`}
                >
                  <div className="offer-data">
                    <div className="offer-text">
                      <div className="main-title">{item?.title}</div>
                      {item?.tags?.length > 0 && (
                        <ul className="offer-tags">
                          {item.tags.map((item) => (
                            <li key={item?.id}>{item?.name}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <CustomImage
                      className="offer-img"
                      src={item?.image}
                      alt=""
                    />

                    {item?.discount && (
                      <div className="sale-offer">
                        <div className="title-sale">
                          {i18n.language === 'en'
                            ? 'Sale Get Up To '
                            : 'احصل على عرض حتى '}
                        </div>
                        <div className="offer-value">
                          {item.discount}% {i18n.language === 'en' && 'OFF'}
                        </div>
                      </div>
                    )}
                  </div>
                </RouterLink>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
  return null;
};

export default OffersSection;
