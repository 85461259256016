/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import emptyImg from '../../../assets/imgs/empty/empty.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import EmptyCompoent from '../../../common/empty-compoent/EmptyComponent';
import SharedAntdTable from '../../../common/shared-antd-table/SharedAntdTable';
import routerLinks from '../../../components/app/routerLinks';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import useMessages from '../../../custom-hooks/useMessages';
import './UserMessagesPage.scss';
import UserMessagesTableColumns from './UserMessagesTableColumns';

const UserMessagesPage = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const sharedTrans = (key) => t(`userDashboard.${key}`);
  const { isLoadingData, allFetchedTableData } = useMessages();

  const renderPageContent = () => {
    if (isLoadingData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedTableData?.length === 0) {
      return <EmptyCompoent img={emptyImg} />;
    } else if (allFetchedTableData?.length > 0) {
      return (
        <div className="page-content-wrapper">
          <div className="custom-container">
            <div className="user-messages-table-wrapper">
              <SharedAntdTable
                scroll={1200}
                className={`user-messages-table ${i18n.dir()}`}
                hasPagination={true}
                tableDataSource={
                  allFetchedTableData && allFetchedTableData?.length > 0
                    ? allFetchedTableData.map((obj) => ({
                        ...obj,
                        key: obj.id
                      }))
                    : []
                }
                tableColumns={UserMessagesTableColumns}
                allFetchedTableData={allFetchedTableData}
                //
                //

                isLoadingTableData={isLoadingData}
              />
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="user-messages-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: userRouterLinks?.userDashboard
          },
          {
            title: t('messagesPage.title'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{t('messagesPage.title')}</div>
        </div>
        <div className="page-body">{renderPageContent()}</div>
      </div>
    </div>
  );
};

export default UserMessagesPage;
