/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import CustomStepperWrapContext from '../../contexts/custom-stepper-wrap-context/CustomStepperWrapContext';
import './CustomStepperWrap.scss';

const CustomStepperWrap = ({
  children,
  sideWrapper,
  className,
  defaultValues,
  ...props
}) => {
  const { setChildrenArray, currentChild } = useContext(
    CustomStepperWrapContext
  );

  useEffect(() => {
    setChildrenArray(React.Children.toArray(children));
  }, []);

  return (
    <div className={`custom-stepper-wrap ${className ? className : ''}`}>
      <div {...props} className="stepper-form">
        {currentChild}

        {/* <div style={{ direction: 'ltr', overflow: 'scroll' }}>
            <pre />
            <h1>Values</h1>
            <pre>{JSON.stringify(methods.watch(), null, 2)}</pre>
            <pre />
            <h1>Errors</h1>
            <pre>{JSON.stringify(methods.formState.errors, null, 2)}</pre>
            <pre />
            <h1>Touched</h1>
            <pre>{JSON.stringify(touched, null, 2)}</pre>
          </div> */}
      </div>
    </div>
  );
};

export default CustomStepperWrap;
