import './SuccessCard.scss';
import successImg from '../../assets/imgs/icons/success.png';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from 'antd';
import Logo from '../../common/logo/Logo';

const SuccessCard = ({
  mainTitle,
  subTitle,
  btnText,
  btnUrl,
  onClick,
  className
}) => {
  return (
    <div className={`success-card ${className || ''}`}>
      <Logo className="logo-wrapper" />
      <img src={successImg} alt="success" />
      {mainTitle && <h2>{mainTitle}</h2>}
      {subTitle && <p>{subTitle}</p>}

      {btnUrl ? (
        <RouterLink className="action-btn" to={btnUrl}>
          {btnText}
        </RouterLink>
      ) : (
        <Button className="action-btn" onClick={onClick} type="primary">
          {btnText}
        </Button>
      )}
    </div>
  );
};

export default SuccessCard;
