import { Modal } from 'antd';
import { useContext, useState } from 'react';
import CustomMap from '../../../components/custom-map/CustomMap';
import StoreOrdersContext from '../../../contexts/store-orders-context/StoreOrdersContext';

const AddressMapModal = () => {
  const [selectedAddress, setSelectedAddress] = useState('');
  const {
    selectedOrder,
    addressMapModalOpened,
    setAddressMapModalOpened,
    setSelectedOrder
  } = useContext(StoreOrdersContext);
  const [selectedLocation, setSelecectedLocation] = useState({
    lat: selectedOrder?.address?.lat
      ? parseFloat(selectedOrder.address.lat)
      : '',
    lng: selectedOrder?.address?.lng
      ? parseFloat(selectedOrder.address.lng)
      : ''
  });
  return (
    <Modal
      className="shared-custom-modal new-employee-modal"
      width="90%"
      style={{ maxWidth: '842px' }}
      title={selectedOrder?.store?.address}
      open={addressMapModalOpened}
      onOk={() => {
        setAddressMapModalOpened(false);
        setSelectedOrder(null);
      }}
      onCancel={() => {
        setAddressMapModalOpened(false);
        setSelectedOrder(null);
      }}
      footer={false}
    >
      <CustomMap
        width="100%"
        height="400px"
        selectedLocation={selectedLocation}
        setSelecectedLocation={setSelecectedLocation}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
      />
    </Modal>
  );
};

export default AddressMapModal;
