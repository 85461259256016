import { useContext } from 'react';
import accountTypes from '../../../const-values/accountTypes';
import { BlogsProvider } from '../../../contexts/blogs-context/BlogsContext';
import { GeneralProductsProvider } from '../../../contexts/general-products-context/GeneralProductsContext';
import { SaloonsProvider } from '../../../contexts/saloons-context/SaloonsContext';
import { SingleBlogProvider } from '../../../contexts/single-blog-context/SingleBlogContext';
import { SingleSaloonProvider } from '../../../contexts/single-saloon-context/SingleSaloonContext';
import { SingleStoreProvider } from '../../../contexts/single-store-context/SingleStoreContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import AboutPage from '../../../pages/about-page/AboutPage';
import AllSaloonsPage from '../../../pages/all-saloons-page/AllSaloonsPage';
import BlogsPage from '../../../pages/blogs-page/BlogsPage';
import SingleBlogPage from '../../../pages/blogs-page/SingleBlogPage';
import ContactUsPage from '../../../pages/contact-us-page/ContactUsPage';
import GeneralProductsPage from '../../../pages/general-products-page/GeneralProductsPage';
import GeneralSignleProductPage from '../../../pages/general-products-page/GeneralSingleProductPage';
import NewProductsPage from '../../../pages/new-products-page';
import PopularProductsPage from '../../../pages/popular-products-page';
import PrivacyPage from '../../../pages/privacy-page/PrivacyPage';
import RefundTermsPage from '../../../pages/refund-terms-page/RefundTermsPage';
import SingleSaloonPage from '../../../pages/single-saloon-page/SingleSaloonPage';
import SingleStorePage from '../../../pages/single-saloon-page/SingleStorePage';
import TermsPage from '../../../pages/terms-page/TermsPage';
import TodayOffersPage from '../../../pages/today-offers-page/TodayOffersPage';
import TopSaloonsPage from '../../../pages/top-saloons-page/TopSaloonsPage';
import generalRoute from '../../../utils/generalRoute';
import routerLinks from '../routerLinks';
import userRouterLinks from '../user-routes/uesRouterLinks';
import generalRouterLinks from './gerneralRouterLinks';

const GeneralRoutes = () => {
  const { user, loggedIn } = useContext(UserContext);
  return [
    generalRoute(
      generalRouterLinks?.generalProductsRoute,
      <GeneralProductsProvider>
        <GeneralProductsPage />
      </GeneralProductsProvider>,
      'general_products_route',
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    generalRoute(
      generalRouterLinks?.newProductsRoute,
      <NewProductsPage />,
      'new_products_route',
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    generalRoute(
      generalRouterLinks?.popularProductsRoute,
      <PopularProductsPage />,
      'popular_products_route',
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    generalRoute(
      generalRouterLinks?.generalSingleProductRoute(),
      <GeneralSignleProductPage />,
      'general_single_product_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.merchent]
    ),
    generalRoute(
      generalRouterLinks?.generalAffiliateProductRoute(),
      <GeneralSignleProductPage />,
      'general_affiliate_single_product_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user]
    ),
    generalRoute(
      generalRouterLinks?.contact_us,
      <ContactUsPage />,
      'contact_us_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.merchent, accountTypes()?.employee]
    ),
    generalRoute(
      generalRouterLinks?.blogsRoute,
      <BlogsProvider>
        <BlogsPage />
      </BlogsProvider>,
      'blogs_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.merchent, accountTypes()?.employee]
    ),
    generalRoute(
      generalRouterLinks?.singleBlogRoute(),
      <SingleBlogProvider>
        <SingleBlogPage />
      </SingleBlogProvider>,
      'single_blog_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.merchent, accountTypes()?.employee]
    ),

    //
    generalRoute(
      userRouterLinks?.singleStoreRoute(),
      <SingleStoreProvider>
        <SingleStorePage />
      </SingleStoreProvider>,
      'general_single_store_route',
      routerLinks?.signinPage,
      loggedIn,
      user
    ),

    //
    generalRoute(
      routerLinks?.todayOffersRoute,
      <TodayOffersPage />,
      1,
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    generalRoute(
      userRouterLinks?.topSaloonsRoute,
      <SaloonsProvider>
        <TopSaloonsPage />
      </SaloonsProvider>,
      'top_saloons_route',
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    generalRoute(
      userRouterLinks?.allSaloonsRoute,
      <SaloonsProvider>
        <AllSaloonsPage />
      </SaloonsProvider>,
      'all_saloons_route',
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    generalRoute(
      userRouterLinks?.singleSaloonRoute(),
      <SingleSaloonProvider>
        <SingleSaloonPage />
      </SingleSaloonProvider>,
      'single_saloon_route',
      routerLinks?.signinPage,
      loggedIn,
      user
    ),
    generalRoute(
      generalRouterLinks?.refundTermsRoute,
      <RefundTermsPage />,
      'refund_terms_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.merchent, accountTypes()?.employee]
    ),
    generalRoute(
      generalRouterLinks?.termsRoute,
      <TermsPage />,
      'terms_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.merchent, accountTypes()?.employee]
    ),
    generalRoute(
      generalRouterLinks?.privacyPolicyRoute,
      <PrivacyPage />,
      'privacy_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.merchent, accountTypes()?.employee]
    ),
    generalRoute(
      generalRouterLinks?.aboutRoute,
      <AboutPage />,
      'about_route',
      routerLinks?.signinPage,
      loggedIn,
      user,
      [accountTypes()?.user, accountTypes()?.merchent, accountTypes()?.employee]
    )
    //
  ];
};

export default GeneralRoutes;
