import { useContext, useEffect, useState } from 'react';
import SharedAntdTable from '../../../common/shared-antd-table/SharedAntdTable';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import EmployeesContext from '../../../contexts/employees-context/EmployeesContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import tableColumns from './tableColumns';

const EmployeesTable = () => {
  const { user } = useContext(UserContext);
  const {
    allFetchedTableData,
    tablePagination,
    fetchTableDataCount,
    setFetchTableDataCount,
    setIsLoadingTableData,
    isLoadingTableData,
    setSelectedTableRow,
    setFormModalOpened
  } = useContext(EmployeesContext);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedTableData?.length >= 0) {
      const mappedData = mapDataSource(allFetchedTableData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, allFetchedTableData]);
  return (
    <div className="employees-table">
      <SharedAntdTable
        className="employees-table"
        hasPagination={true}
        tableDataSource={tableDataSource}
        tableColumns={tableColumns}
        allFetchedTableData={allFetchedTableData}
        setFormModalOpened={setFormModalOpened}
        // setDetailsModalOpened={setDetailsModalOpened}
        setFetchTableDataCount={setFetchTableDataCount}
        isLoadingTableData={isLoadingTableData}
        setIsLoadingTableData={setIsLoadingTableData}
        setSelectedTableRow={setSelectedTableRow}
        tablePagination={tablePagination}
        user={user}
        baseTablePageUrl={merchentRouterLinks?.merchentEmployees}
      />
    </div>
  );
};

export default EmployeesTable;
