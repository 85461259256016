/* eslint-disable eqeqeq */
import { Descriptions, Empty, Modal } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import StoreOrdersContext from '../../../../contexts/store-orders-context/StoreOrdersContext';
import './styles.scss';

const OrderReportRepliesModal = () => {
  const { setSelectedOrder, reportRepliesModal, setReportRepliesModal } =
    useContext(StoreOrdersContext);
  const { t } = useTranslation();

  console.log({ reportRepliesModal });

  return (
    <Modal
      rootClassName="order-report-replies-modal-root"
      className="shared-custom-modal order-report-replies-modal"
      width="90%"
      style={{ maxWidth: '500px' }}
      title={t('ordersPage.report_replies')}
      open={reportRepliesModal?.opened}
      onOk={() => {
        setReportRepliesModal({ opened: false, selected: null });
      }}
      onCancel={() => {
        setReportRepliesModal({ opened: false, selected: null });
      }}
      footer={false}
    >
      <div className="modal-content">
        {!reportRepliesModal?.selected ||
        reportRepliesModal?.selected?.length === 0 ? (
          <Empty description={false}>لا يوجد بيانات متاحة</Empty>
        ) : (
          reportRepliesModal.selected.map((obj) => (
            <Descriptions key={obj.id} column={1} title="" bordered>
              <Descriptions.Item label={t('ordersPage.date_time')}>
                <p>{obj.date_time ?? ''}</p>
              </Descriptions.Item>
              <Descriptions.Item label={t('ordersPage.message')}>
                <p>{obj.message ?? ''}</p>
              </Descriptions.Item>
            </Descriptions>
          ))
        )}
      </div>
    </Modal>
  );
};

export default OrderReportRepliesModal;
