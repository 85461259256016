export const transactionsStatusValues = {
  underReview: 1,
  success: 2,
  rejected: 3
};

export const transactionsStatusArr = (t) => {
  return [
    {
      id: 1,
      color: 'orange',
      name: t('walletPage.underReview'),
      value: transactionsStatusValues.underReview
    },
    {
      id: 2,
      color: 'green',
      name: t('walletPage.success'),
      value: transactionsStatusValues.success
    },
    {
      id: 3,
      color: 'red',
      name: t('walletPage.rejected'),
      value: transactionsStatusValues.rejected
    }
  ];
};
