/* eslint-disable react-hooks/exhaustive-deps */
import './Messages.styles.scss';

import { CloseOutlined, SendOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import ChatContext from '../../contexts/chat-context/ChatContext';
import UserContext from '../../contexts/user-context/UserProvider';
// import firebase from '../../firebase';

const Messages = () => {
  const { user } = useContext(UserContext);
  const inputRef = useRef(null);
  const chatBodyRef = useRef(null);
  const [fieldValue, setFieldValue] = useState('');

  const {
    myChatGroups,
    selectedGroupId,
    groupMessages,
    setMyChatGroups,
    setGroupMessages
  } = useContext(ChatContext);

  // get my chat groups
  // const firestoreDb = firebase.firestore();

  // get the selected  group messages

  ////////////////////////////////////
  ////////////////////////////////////
  ////////////////////////////////////

  useEffect(() => {
    inputRef.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // scroll to bottom every time the chat messages array chagnes
  useEffect(() => {
    if (groupMessages.length) {
      // console.log('Chat Messages', chatMessages);
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
      if (groupMessages.length > 0) {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupMessages]);

  //////////////////////////////////////

  const pastePlainText = (e) => {
    e.preventDefault();

    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  };

  // const disableNewlines = evt => {
  // 	const keyCode = evt.keyCode || evt.which;

  // 	if (keyCode === 13) {
  // 		evt.returnValue = false;
  // 		// if (evt.preventDefault) evt.preventDefault();
  // 	}
  // };

  const handleFieldChange = (e) => {
    const fieldValue = e.target.innerText
      .replace(/&nbsp;/g, '')
      .replace(/&amp;/g, '&')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<');

    setFieldValue(fieldValue);
  };

  const renderChatMessages = () => {
    return (
      <>
        {groupMessages.length > 0 &&
          groupMessages.map((msg) => {
            const {
              docId,
              message,
              sender: { image, name },
              timeStamp
            } = msg;
            if (name === user.user_name) {
              return (
                <div key={docId} className="chatSelf">
                  <Avatar src={image ? image : null} />
                  <div className="msg__date">
                    <div className="msg">{message}</div>
                    <RouterLink to={`/profile/${name}`}></RouterLink>
                    <span className="msg-date">
                      {timeStamp && dayjs(timeStamp.toDate()).fromNow()}
                    </span>
                  </div>
                </div>
              );
            }
            return (
              <div key={docId} className="chatOther">
                <div className="msg__date">
                  <div className="msg">
                    <div className="othreUsername">
                      <strong>{name}</strong>
                    </div>{' '}
                    {message}
                  </div>
                  <span className="msg-date">
                    {timeStamp && dayjs(timeStamp.toDate()).fromNow()}
                  </span>
                </div>
                <RouterLink to={`/profile/${name}`}>
                  <Avatar src={image ? image : null} />
                </RouterLink>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div className="chat-wrapper">
      <div
        // className={`chatHeader ${pickBkgClass(themeName, 'hard')}`}
        className={`chatHeader`}
      >
        <span>الرســـائل الواردة</span>

        <CloseOutlined color="primary" className="cancelBtn" />
      </div>

      <div ref={chatBodyRef} className="chatBody">
        {renderChatMessages()}
      </div>

      <form className="chatForm">
        <div
          ref={inputRef}
          contentEditable={true}
          placeholder="اكتب رســالتك..."
          onInput={handleFieldChange}
          // onKeyPress={disableNewlines
          onPaste={pastePlainText}
        />
        <button className="submit-btn" type="submit">
          <SendOutlined />
        </button>
      </form>
    </div>
  );
};

export default Messages;
