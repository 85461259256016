import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import lngs from '../../languages';

// import 'flag-icon-css/css/flag-icons.min .css';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import 'flag-icons/css/flag-icons.min.css';
import useSignout from '../../custom-hooks/useSignout';
import sleep from '../../utils/sleep';
import DropdownIcon from '../icons/DropdownIcon';
import LoadingModal from '../loading-modal/LoadingModal';
import './LanguageButton.scss';

const LanguageButton = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);
  const [loadingModalVisible, setLoadingModalVisible] = useState(false);
  const renderLanguage = () => {
    const code = i18n.language;

    return (
      <>
        <Dropdown
          arrow
          trigger={['click']}
          // disabled={loadingSignout}
          menu={{
            items: [
              ...lngs.map(({ country_code, code, name }, index) => ({
                key: index,
                onClick: async () => {
                  if (i18n.language !== code) {
                    setLoadingModalVisible(true);
                    i18n.changeLanguage(code);
                    await sleep(600);
                    setLoadingModalVisible(false);
                  }
                },
                label: (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px'
                    }}
                  >
                    <span
                      key={country_code}
                      className={`fi fi-${country_code}`}
                    ></span>
                    {name}
                  </div>
                )
              }))
            ]
          }}
        >
          <Button className="profile-menu-btn" type="text">
            {/* {lngs.filter((lng) => lng.code === code)[0]?.name} */}
            <span
              className={`fi fi-${
                lngs.filter((lng) => lng.code === code)[0]?.country_code
              }`}
            ></span>
            {loadingModalVisible ? <LoadingOutlined /> : <DropdownIcon />}
          </Button>
        </Dropdown>
        {loadingModalVisible && <LoadingModal />}
      </>
    );
  };

  return <div className="main-lang-btn">{renderLanguage()}</div>;
};

export default LanguageButton;
