import './EmptyCompoent.scss';

const EmptyCompoent = ({ img, p }) => {
  return (
    <div className="custom-container">
      <div className="empty-component-wrap">
        <img src={img} alt="empty" />
        <p>{p}</p>
      </div>
    </div>
  );
};

export default EmptyCompoent;
