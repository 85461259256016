/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import debounce from 'lodash.debounce';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getGeneralStoresApi from '../../apis/general/getGeneralStoresApi';
import SearchIcon from '../../common/icons/SearchIcon';
import SearchMapIcon from '../../common/icons/SearchMapIcon';
import SharedAppContext from '../../contexts/shared-app-context/SharedAppContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import userRouterLinks from '../app/user-routes/uesRouterLinks';
import './MainAppSearch.scss';
import MapModal from './MapModal';

const MainAppBarSearch = ({ location }) => {
  const { i18n, t } = useTranslation();
  const {
    isLoadingStores,
    allFetchedStores,
    fetchStoresCount,
    setIsLoadingStores,
    setAllFetchedStores,
    setFetchStoresCount
  } = useContext(SharedAppContext);
  const [mapModalOpened, setMapModalOpened] = useState(false);
  const [searchStoresResultArr, setSearchStoreResultArr] = useState([]);
  // handle search
  const [searchText, setSearchText] = useState('');
  const [query, setQuery] = useState('');
  const { user } = useContext(UserContext);

  const changeHandler = (event) => {
    setQuery(event.target.value);
  };

  const [isMounted, setIsMounted] = useState(true);
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 800), []);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    if (query) {
      setFetchStoresCount((prev) => prev + 1);
      setIsLoadingStores(true);
      customApiRequest(
        getGeneralStoresApi(
          {
            searchText: query
          },
          user?.token,
          i18n.language
        ),
        (res) => {
          if (isMounted) {
            setIsLoadingStores(false);

            if (checkRes(res) && res?.data?.data?.length > 0) {
              setAllFetchedStores(res.data.data);
            } else {
              setAllFetchedStores([]);
            }
          }
        },
        (error) => {
          setIsLoadingStores(false);
        }
      );
    }

    return () => setIsMounted(false);
  }, [query]);

  useEffect(() => {
    if (allFetchedStores?.length > 0) {
      setSearchStoreResultArr(
        allFetchedStores.filter((obj) =>
          obj?.name?.toLowerCase()?.includes(searchText.toLowerCase())
        )
      );
    } else setSearchStoreResultArr(allFetchedStores);
  }, [allFetchedStores]);

  // console.log('all fetched stores : ', allFetchedStores);
  // console.log('search results arr  : ', searchStoresResultArr);
  const navigate = useNavigate();
  const renderServicesMenu = () => {
    if (isLoadingStores) {
      return [
        {
          key: 'item-1',
          label: t('main_app_search.searching')
        }
      ];
    } else if (searchStoresResultArr?.length > 0) {
      return searchStoresResultArr.map((store) => ({
        key: store?.id,
        onClick: () => {
          setSearchText(store?.name);
          navigate(userRouterLinks?.singleStoreRoute(store?.id));
        },
        label: store?.name
      }));
    } else if (allFetchedStores?.length === 0) {
      return [
        {
          key: 'item-1',
          label: `${t('main_app_search.notFound')} !!!`
        }
      ];
    }
    return [
      {
        key: 'item-1',
        label: `${t('main_app_search.notFound')} !!!`
      }
    ];
  };

  // handle submit
  // const navigate = useNavigate();
  // const handleFormSubmit = (e) => {
  //   e.preventDefault();
  //   if (selectedStore) {
  //     navigate(userRouterLinks?.singleStoreRoute(null, selectedStore?.id));
  //   }
  // };

  return (
    <>
      <Dropdown
        className="main-search-dropdown"
        // arrow
        trigger={['click']}
        // disabled={loadingSignout}
        overlayClassName="root-main-search-dropdown-list"
        menu={{
          className: 'main-search-dropdown-list',
          items: renderServicesMenu()
        }}
      >
        <form
          className={`main-app-search-form ${i18n.dir()}`}
          // onSubmit={handleFormSubmit}
        >
          <label>
            <div className="icon-wrap">
              {isLoadingStores && fetchStoresCount > 0 ? (
                <LoadingOutlined />
              ) : (
                <SearchIcon color="#999" />
              )}
            </div>
            <input
              type="text"
              name="main_app_search"
              placeholder={t('main_app_search.placeholder')}
              onChange={(e) => {
                setSearchText(e.target.value);
                debouncedChangeHandler(e);
              }}
              value={searchText || ''}
            />

            <div className="btn-map-wrapper">
              {location?.loaded &&
                location?.coordinates?.lat &&
                location?.coordinates?.lng && (
                  <button
                    type="button"
                    className="map-btn"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setMapModalOpened(true);
                    }}
                  >
                    <SearchMapIcon />
                  </button>
                )}
              <Button
                htmlType="button"
                className={`search-btn ${i18n.dir()}`}
                type="primary"
              >
                {t('main_app_search.search')}
              </Button>
            </div>
          </label>
        </form>
      </Dropdown>

      <MapModal
        modalVisible={mapModalOpened}
        setModalVisible={setMapModalOpened}
        stores={allFetchedStores}
      />
    </>
  );
};

export default MainAppBarSearch;
