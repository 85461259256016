/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, Pagination, Tabs } from 'antd';
import React, { useContext, useEffect } from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useLocation,
  useParams
} from 'react-router-dom';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import NewProductIcon from '../../../common/icons/store-card-icons/NewProductIcon';
import productStatus from '../../../const-values/productStatus';
import ProductCard from './ProductCard';
import { LoadingOutlined } from '@ant-design/icons';
import useSelectedPage from '../../../custom-hooks/useSelectedPage';
import queryString from 'query-string';
import appPages from '../../../const-values/appPages';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import getAllProductsApi from '../../../apis/products-apis/getAllProductsApi';
import ProductsContext from '../../../contexts/products-context/ProductsContext';
import checkRes from '../../../utils/checkRes';
import './ProductsPage.scss';
import RejectedProductModal from './RejectedProductModal';
import productTypes from '../../../const-values/productTypes';
// 1=>active , 2=> hide ,3=> in review ,4=>pending

const ProductsPage = () => {
  const params = useParams();
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { merchentStores } = appPages(i18n, t, user);
  useSelectedPage({
    currentPage: merchentStores
  });
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    isLoadingTableData,
    setIsLoadingTableData,
    allFetchedTableData,
    setAllFetchedTableData,
    fetchTableDataCount,
    setFetchTableDataCount,
    rejectedModalOpened,
    tablePagination,
    setTablePagination,
    tableFilter,
    setTableFilter
  } = useContext(ProductsContext);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      getAllProductsApi(
        {
          ...tableFilter,
          store_id: params?.store_id,
          ...values
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedTableData(data);
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, i18n.language]);

  const handleChange = (status) => {
    navigate(`${merchentRouterLinks?.storeProducts(params?.store_id)}?page=1`);
    setTableFilter((prev) => ({
      ...prev,
      store_id: params?.store_id,
      page: 1,
      status
    }));
    setFetchTableDataCount((prev) => prev + 1);
  };

  const renderProductsCards = (status) => {
    if (isLoadingTableData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedTableData?.length > 0) {
      return (
        <div className="products-wrap">
          {allFetchedTableData.map((obj) => (
            <ProductCard
              type={productTypes?.merchentProduct}
              key={obj?.id}
              card={obj}
              setFetchParentCount={setFetchTableDataCount}
            />
          ))}

          <Pagination
            locale={{
              jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
              // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
              page: ''
            }}
            showSizeChanger={false}
            defaultCurrent={1}
            current={tablePagination.current_page}
            pageSize={tablePagination.per_page}
            total={tablePagination.total}
            // itemRender={itemRender}
            onChange={(page, pageSize) => {
              setFetchTableDataCount((prev) => prev + 1);
              navigate(
                `${merchentRouterLinks?.storeProducts(
                  params?.store_id
                )}?page=${page}`
              );
            }}
            hideOnSinglePage={true}
          />
        </div>
      );
    }
    return <Empty description={false}>{t('productsPage.empty')}</Empty>;
  };
  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  return (
    <div className="store-products-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('stores'),
            isLink: true,
            to: merchentRouterLinks?.merchentStores
          },
          {
            title: sharedTrans('products'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-create-product-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">{sharedTrans('products')}</div>
          </div>
          <RouterLink
            className="new-product-btn"
            to={merchentRouterLinks?.storeNewProduct(params?.store_id)}
          >
            <NewProductIcon />
            {sharedTrans('newProduct')}
          </RouterLink>
        </div>

        <Tabs
          onChange={(key) => {
            handleChange(key);
          }}
          defaultActiveKey={productStatus?.active}
          items={[
            {
              key: productStatus?.active,
              label: t('productStatus.active'),
              children: renderProductsCards(productStatus?.active)
            },
            {
              key: productStatus?.hidden,
              label: t('productStatus.hidden'),
              children: renderProductsCards(productStatus?.hidden)
            },
            {
              key: productStatus?.underReview,
              label: t('productStatus.underReview'),
              children: renderProductsCards(productStatus?.underReview)
            },
            {
              key: productStatus?.rejected,
              label: t('productStatus.rejected'),
              children: renderProductsCards(productStatus?.rejected)
            }
          ]}
        />
      </div>

      {rejectedModalOpened && <RejectedProductModal />}
    </div>
  );
};

export default ProductsPage;
