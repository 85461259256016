/* eslint-disable eqeqeq */
import SettingsIcon from '../../common/icons/user-icons/SettingsIcon';
import ChangePasswordIcon from '../../common/icons/user-icons/ChangePasswordIcon';
// import ChatCenterIcon from '../../common/icons/user-icons/ChatCenterIcon';
import DashboardIcon from '../../common/icons/user-icons/DashboardIcon';
import MyAddressIcon from '../../common/icons/user-icons/MyAddressIcon';
import MyOrdersIcon from '../../common/icons/user-icons/MyOrdersIcon';
import userRouterLinks from '../app/user-routes/uesRouterLinks';
import accountTypes from '../../const-values/accountTypes';
import merchentRouterLinks from '../app/merchent-routes/merchentRouterLinks';
import StoresIcon from '../../common/icons/StoresIcon';
import CubeIcon from '../../common/icons/CubeIcon';
import PlusIcon from '../../common/icons/PlusIcon';
import UserSettingsIcon from '../../common/icons/UserSettingsIcon';
import WalletIcon from '../../common/icons/WalletIcon';
import VideoPlayIcon from '../../common/icons/VideoPlayIcon';
import UsersIcon from '../../common/icons/UsersIcon';

const mainAppProfileMenuLinks = (i18n, t, user) => {
  const userLabelTransString = (key) => t(`userDashboard.${key}`);
  const merchentLabelTransString = (key) => t(`merchentDashboard.${key}`);

  if (user?.accountType == accountTypes()?.user)
    return [
      {
        id: 1,
        name: userLabelTransString('dashboard'),
        link: userRouterLinks?.userDashboard,
        icon: <DashboardIcon />
      },
      {
        id: 2,
        name: userLabelTransString('settings'),
        link: userRouterLinks?.accountSettings,
        icon: <SettingsIcon />
      },
      {
        id: 3,
        name: userLabelTransString('changePass'),
        link: userRouterLinks?.changePassword,
        icon: <ChangePasswordIcon />
      },
      {
        id: 4,
        name: userLabelTransString('address'),
        link: userRouterLinks?.myAddresses,
        icon: <MyAddressIcon />
      },
      {
        id: 5,
        name: userLabelTransString('myOrders'),
        link: userRouterLinks?.myOrders,
        icon: <MyOrdersIcon />
      }
      // {
      //   id: 6,
      //   name: userLabelTransString('chatCenter'),
      //   link: userRouterLinks?.userChatCenterRoute,
      //   icon: <ChatCenterIcon />
      // }
    ];
  else if (user?.accountType == accountTypes()?.merchent) {
    return [
      {
        id: 1,
        name: merchentLabelTransString('dashboard'),
        link: merchentRouterLinks?.merchentDashboard,
        icon: <DashboardIcon />
      },
      {
        id: 2,
        name: merchentLabelTransString('stores'),
        link: merchentRouterLinks?.merchentStores,
        icon: <StoresIcon />
      },
      {
        id: 3,
        name: merchentLabelTransString('employees'),
        link: merchentRouterLinks?.merchentEmployees,
        icon: <UsersIcon />
      },
      {
        id: 4,
        name: merchentLabelTransString('other'),
        link: merchentRouterLinks?.merchentOther,
        icon: <CubeIcon />
      },
      {
        id: 5,
        name: merchentLabelTransString('newStore'),
        link: merchentRouterLinks?.merchentNewStore,
        icon: <PlusIcon />
      },
      {
        id: 6,
        name: merchentLabelTransString('settings'),
        link: merchentRouterLinks?.merchentSettings,
        icon: <UserSettingsIcon />
      },
      {
        id: 7,
        name: merchentLabelTransString('wallet'),
        link: merchentRouterLinks?.merchentWallet,
        icon: <WalletIcon />
      },
      {
        id: 8,
        name: merchentLabelTransString('videoTutorial'),
        link: merchentRouterLinks?.merchentTutorial,
        icon: <VideoPlayIcon />
      }
    ];
  } else if (user?.accountType == accountTypes()?.employee) {
    return [
      {
        id: 1,
        name: merchentLabelTransString('dashboard'),
        link: merchentRouterLinks?.merchentDashboard,
        icon: <DashboardIcon />
      },
      // {
      //   id: 5,
      //   name: merchentLabelTransString('newStore'),
      //   link: merchentRouterLinks?.merchentNewStore,
      //   icon: <PlusIcon />
      // },
      {
        id: 6,
        name: merchentLabelTransString('settings'),
        link: merchentRouterLinks?.merchentSettings,
        icon: <UserSettingsIcon />
      },
      {
        id: 8,
        name: merchentLabelTransString('videoTutorial'),
        link: merchentRouterLinks?.merchentTutorial,
        icon: <VideoPlayIcon />
      }
    ];
  }
  return null;
};

export default mainAppProfileMenuLinks;
