import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import ForgetPasswordModal from './ForgetPasswordModal';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import EyeOpenedIcon from '../../common/icons/EyeOpenedIcon';
import EyeClosedIcon from '../../common/icons/EyeClosedIcon';
import { Link as RouterLink } from 'react-router-dom';
import useSigninEmailPassword from '../../custom-hooks/useSigninEmailPassword';
import routerLinks from '../../components/app/routerLinks';
import AntdCheckbox from '../../common/antd-form-components/AntdCheckbox';
import LockIcon from '../../common/icons/LockIcon';
import UserIcon from '../../common/icons/UserIcon';
import './SigninForm.scss';
import useFCMDeviceToken from '../../custom-hooks/useFCMDeviceToken';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const SigninForm = () => {
  const { t, i18n } = useTranslation();
  const [passwrodVisible, setPasswordVisible] = React.useState(false);
  const fcmDeviceToken = useFCMDeviceToken();
  const { forgetPasswordModalOpened } = useContext(ForgetPasswordContext);
  const schema = Yup.object().shape({
    // loginKey: Yup.string()
    //   .required('من فضلك ادخل البريد الاكترونى')
    //   .email('ادخل بريد الكترونى صحيح'),
    loginKey: Yup.string().required(t('signin_form.login_key.errors.required')),
    password: Yup.string().required(t('signin_form.password.errors.required'))
  });
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      loginKey: '',
      password: '',
      remember: true
    }
  });

  const { signMeInWithEmailPassword, isLoadingSignin } =
    useSigninEmailPassword();
  const onSubmit = (data) => {
    signMeInWithEmailPassword({
      // email: data.loginKey,
      ...data,
      fire_base: fcmDeviceToken
    });
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="signin-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-header">
          <p className="bold-font main-title">{t('signin_form.title')}</p>
          <p className="sub-title">{t('signin_form.sub_title')}</p>
        </div>

        <div className="form-body">
          <AntdTextField
            name="loginKey"
            type="text"
            placeholder={t('signin_form.login_key.label')}
            label={t('signin_form.login_key.label')}
            errorMsg={errors?.loginKey?.message}
            validateStatus={errors?.loginKey ? 'error' : ''}
            prefix={<UserIcon />}
            control={control}
          />

          <div className="login-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="password"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder={t('signin_form.password.label')}
              label={t('signin_form.password.label')}
              errorMsg={errors?.password?.message}
              prefix={<LockIcon />}
              validateStatus={errors?.password ? 'error' : ''}
              control={control}
            />

            {watch('password') && (
              <div
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOpenedIcon /> : <EyeClosedIcon />}
              </div>
            )}
          </div>
          <RouterLink
            className="forget-p"
            // onClick={() => setForgetPasswordModalOpened(true)}
            to={routerLinks?.forgetPasswordRoute}
          >
            {t('signin_form.forget_pass')}
          </RouterLink>
          <AntdCheckbox
            name="remember"
            control={control}
            label={t('signup_form.remember_me')}
            errorMsg={errors?.remember?.message}
            validateStatus={errors?.remember ? 'error' : ''}
          />
          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isLoadingSignin}
          >
            {isLoadingSignin
              ? t('signin_form.submit_btn.loading')
              : t('signin_form.submit_btn.label')}
          </Button>
        </div>
        <div className="dont-have-account-text">
          <span>{t('signin_form.dont')}</span>{' '}
          <RouterLink to={routerLinks?.userSignupRoute}>
            {t('signin_form.new_account')}
          </RouterLink>
        </div>
      </Form>

      {forgetPasswordModalOpened && <ForgetPasswordModal />}
      {isLoadingSignin && <LoadingModal />}
    </>
  );
};

export default SigninForm;
