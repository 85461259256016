import { Modal, Rate } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rateOrderApi from '../../../apis/cart-apis/rateOrderApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import UserOrdersContext from '../../../contexts/user-orders-context/UserOrdersContext';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import './RateOrderModal.scss';

const RateOrderModal = () => {
  const { t, i18n } = useTranslation();
  const {
    selectedOrder,
    rateModalOpened,
    setRateModalOpened,
    setFetchOrdersCount,
    setIsRating
  } = useContext(UserOrdersContext);
  const { user } = useContext(UserContext);
  const [rateV, setRateV] = useState(0);
  const customApiRequest = useCustomApiRequest();
  const handleRate = (v) => {
    setRateV(v);
  };

  const handleSubmitRate = () => {
    setIsRating(true);
    const formData = new FormData();
    formData.append('order_id', selectedOrder?.id);
    formData.append('rate', rateV);
    customApiRequest(
      rateOrderApi(formData, user?.token, i18n.language),
      (res) => {
        setIsRating(false);
        if (checkRes(res)) {
          setFetchOrdersCount((prev) => prev + 1);
          setRateV(0);
          setRateModalOpened(false);
        }
      },
      (error) => {
        setIsRating(false);
        errorNotification({
          title: 'Error!',
          message: error?.response?.data?.message || 'Try again later'
        });
      }
    );
  };

  const sharedT = (key) => t(`orderRateModal.${key}`);
  return (
    <Modal
      className="shared-custom-modal rate-order-modal"
      width="96%"
      style={{ maxWidth: '842px' }}
      title=""
      open={rateModalOpened}
      onOk={() => {
        setRateModalOpened(false);
      }}
      onCancel={() => {
        setRateModalOpened(false);
      }}
      footer={false}
    >
      <div className="rate-title bold-font">{sharedT('rateService')}</div>
      <div className="rate-text">{sharedT('rateText')}</div>

      <div className="rate-comp-wrapper">
        <Rate
          // value={selectedOrder?.rate || 0}
          disabled={selectedOrder?.rate}
          className="rate-comp"
          onChange={(v) => {
            handleRate(v);
          }}
        />
      </div>

      {rateV > 0 && (
        <button
          onClick={() => {
            if (rateV > 0) {
              handleSubmitRate();
            }
          }}
          type="button"
          className="submit-rate-btn"
        >
          {sharedT('submitBtn')}
        </button>
      )}
    </Modal>
  );
};

export default RateOrderModal;
