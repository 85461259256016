const notificationTypes = () => {
  return {
    newOrder: 1,
    canceledByUser: 2,
    storeAcceptedOrder: 3,
    storeRejectedOrder: 4,
    orderCompleted: 5,
    cartType: 55
  };
};

export default notificationTypes;
