/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Pagination } from 'antd';
import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import getAllBlogsApi from '../../apis/blogs-apis/getAllBlogsApi';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import BlogsContext from '../../contexts/blogs-context/BlogsContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import BlogCard from './BlogCard';
import './BlogsPage.scss';
import BlogsSideCats from './BlogsSideCats';

const BlogsPage = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    isLoadingBlogs,
    setIsLoadingBlogs,
    setAllFetchedBlogs,
    fetchBlogsCount,
    setFetchBlogsCount,
    allFetchedBlogs,
    blogsPagination,
    setBlogsPagination,
    blogsFilter
  } = useContext(BlogsContext);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    setIsLoadingBlogs(true);
    customApiRequest(
      getAllBlogsApi(
        {
          ...blogsFilter,
          ...values
        },
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingBlogs(false);
          if (checkRes(res) && res.data?.data?.data) {
            const data = res.data.data.data;
            setAllFetchedBlogs(data);
            if (res.data.data?.pagination) {
              setBlogsPagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingBlogs(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchBlogsCount, search]);

  const renderBlogs = () => {
    if (isLoadingBlogs) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedBlogs?.length === 0)
      return <Empty description={false}>No Blogs Found!!!</Empty>;
    else if (allFetchedBlogs?.length > 0) {
      return (
        <div className="blogs-list">
          {allFetchedBlogs.map((blog) => (
            <BlogCard key={blog?.id} card={blog} />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="blogs-page">
      <div className="breadcrumb-title">
        <CustomBreadcrumb
          arr={[
            {
              title: t('main_app_bar_links.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('main_app_bar_links.blogs'),
              isLink: false
            }
          ]}
        />
        <div className="custom-container">
          <h2 className="page-title">{t('blogsPage.title')}</h2>
        </div>
      </div>

      <div className="page-body">
        <div className="custom-container">
          <div className="main-page-wrap">
            <div className="blogs-pagination-wrap">
              {renderBlogs()}
              {blogsPagination?.total > 0 && (
                <Pagination
                  locale={{
                    jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                    // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                    page: ''
                  }}
                  showSizeChanger={false}
                  defaultCurrent={1}
                  current={blogsPagination.current_page}
                  pageSize={blogsPagination.per_page}
                  total={blogsPagination.total}
                  // itemRender={itemRender}
                  onChange={(page, pageSize) => {
                    setFetchBlogsCount((prev) => prev + 1);
                    navigate(`${generalRouterLinks?.blogsRoute}?page=${page}`);
                  }}
                  hideOnSinglePage={true}
                />
              )}
            </div>
            <BlogsSideCats />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsPage;
