/* eslint-disable eqeqeq */
import accountTypes from '../const-values/accountTypes';
import {
  employeesPermissionsValues,
  storePermissionsValues
} from '../const-values/permissions';

const {
  editStores,
  deleteStores,
  addStores,
  reviewOrders,
  addEmployees,
  reviewStatistics
} = storePermissionsValues();
const { addProducts, editProducts, deleteProducts } =
  employeesPermissionsValues();
export const checkAuthedEmployeeRoute = ({
  rolesArr,
  supposedRouteRoles,
  authed
}) => {
  // check if authed by default like view stores route
  if (authed) {
    return accountTypes()?.employee;
  } else if (rolesArr?.length > 0) {
    // check stores permission
    if (
      rolesArr.includes(editStores) ||
      rolesArr.includes(deleteStores) ||
      rolesArr.includes(addStores) ||
      rolesArr.includes(reviewOrders) ||
      rolesArr.includes(addEmployees) ||
      rolesArr.includes(reviewStatistics) ||
      rolesArr.includes(addProducts) ||
      rolesArr.includes(editProducts) ||
      rolesArr.includes(deleteProducts)
    ) {
      // else if (rolesArr.includes(supposedRouteRole))
      //   return accountTypes()?.employee;
      for (let i of supposedRouteRoles) {
        const found = rolesArr.find((v) => v == i);
        if (found) {
          return accountTypes()?.employee;
          // break;
        }
      }
    }
    return null;
  }
};

const checkPermission = ({ user, supposedRouteRoles, authed }) => {
  if (authed) return true;
  else if (user?.accountType == accountTypes()?.merchent) return true;
  return (
    user?.accountType == accountTypes()?.employee &&
    checkAuthedEmployeeRoute({
      rolesArr:
        user?.storeRoles?.length >= 0 && user?.employeeRolesArray?.length >= 0
          ? [...user?.storeRoles, ...user?.employeeRolesArray]
          : [],
      supposedRouteRoles,
      authed: false
    }) == accountTypes()?.employee
  );
};

export default checkPermission;
