import React from 'react';

const UserSettingsIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      width={width || '59'}
      height={height || '59'}
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5007 31.3441C21.7077 31.3441 15.3652 25.0016 15.3652 17.2087C15.3652 9.41574 21.7077 3.07324 29.5007 3.07324C37.2936 3.07324 43.6361 9.41574 43.6361 17.2087C43.6361 25.0016 37.2936 31.3441 29.5007 31.3441ZM29.5007 6.76074C23.7482 6.76074 19.0527 11.4562 19.0527 17.2087C19.0527 22.9612 23.7482 27.6566 29.5007 27.6566C35.2532 27.6566 39.9486 22.9612 39.9486 17.2087C39.9486 11.4562 35.2532 6.76074 29.5007 6.76074Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M38.8911 55.9268C37.9569 55.9268 37.0719 55.5826 36.4327 54.9434C35.6707 54.1813 35.3265 53.0751 35.4986 51.9197L35.9657 48.601C36.0886 47.7406 36.6048 46.7326 37.2194 46.0935L45.9219 37.391C49.4127 33.9002 52.4857 35.8914 53.9853 37.391C55.2636 38.6694 55.9273 40.0459 55.9273 41.4226C55.9273 42.8238 55.2882 44.1268 53.9853 45.4297L45.2827 54.1323C44.6681 54.7468 43.6357 55.2631 42.7753 55.386L39.4564 55.853C39.2598 55.9021 39.0877 55.9268 38.8911 55.9268ZM49.929 39.1363C49.4865 39.1363 49.0931 39.4314 48.5277 39.9968L39.8253 48.6993C39.7515 48.773 39.6285 49.0188 39.6285 49.1172L39.1861 52.1901L42.259 51.7476C42.3573 51.723 42.6031 51.6002 42.6769 51.5264L51.3794 42.8238C51.7728 42.4305 52.2398 41.8651 52.2398 41.4226C52.2398 41.0538 51.9449 40.5377 51.3794 39.9968C50.7894 39.4068 50.3469 39.1363 49.929 39.1363Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M51.4278 47.249C51.2557 47.249 51.0836 47.2245 50.9361 47.1753C47.6911 46.2657 45.1099 43.6845 44.2003 40.4395C43.9299 39.4562 44.4954 38.4483 45.4787 38.1779C46.462 37.9074 47.4699 38.4728 47.7403 39.4561C48.3057 41.4719 49.9037 43.0699 51.9195 43.6353C52.9028 43.9057 53.4683 44.9382 53.1979 45.8969C52.9766 46.7082 52.2391 47.249 51.4278 47.249Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M8.38281 55.9271C7.3749 55.9271 6.53906 55.0913 6.53906 54.0833C6.53906 43.5863 16.8395 35.0312 29.5 35.0312C32.1795 35.0312 34.8345 35.4246 37.3174 36.1621C38.3008 36.4571 38.8416 37.4896 38.5466 38.4484C38.2516 39.4317 37.2191 39.9725 36.2604 39.6775C34.097 39.0384 31.8354 38.6941 29.5 38.6941C18.88 38.6941 10.2266 45.5775 10.2266 54.0587C10.2266 55.0912 9.39073 55.9271 8.38281 55.9271Z"
        fill={color ? color : '#6D6D6D'}
      />
    </svg>
  );
};

export default UserSettingsIcon;
