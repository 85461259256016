import React from 'react';

const EmailIcon = ({ color }) => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1554 0.666992H2.8544C2.32581 0.666992 1.81886 0.877706 1.44509 1.25278C1.07131 1.62785 0.861328 2.13656 0.861328 2.66699V9.33366C0.861328 9.86409 1.07131 10.3728 1.44509 10.7479C1.81886 11.1229 2.32581 11.3337 2.8544 11.3337H12.1554C12.684 11.3337 13.191 11.1229 13.5647 10.7479C13.9385 10.3728 14.1485 9.86409 14.1485 9.33366V2.66699C14.1485 2.13656 13.9385 1.62785 13.5647 1.25278C13.191 0.877706 12.684 0.666992 12.1554 0.666992ZM2.8544 2.00033H12.1554C12.3316 2.00033 12.5006 2.07056 12.6252 2.19559C12.7498 2.32061 12.8198 2.49018 12.8198 2.66699L7.50492 5.92032L2.19005 2.66699C2.19005 2.49018 2.26004 2.32061 2.38463 2.19559C2.50922 2.07056 2.67821 2.00033 2.8544 2.00033ZM12.8198 9.33366C12.8198 9.51047 12.7498 9.68004 12.6252 9.80506C12.5006 9.93009 12.3316 10.0003 12.1554 10.0003H2.8544C2.67821 10.0003 2.50922 9.93009 2.38463 9.80506C2.26004 9.68004 2.19005 9.51047 2.19005 9.33366V4.18699L7.15945 7.23366C7.26044 7.29217 7.37501 7.32297 7.49163 7.32297C7.60825 7.32297 7.72281 7.29217 7.82381 7.23366L12.8198 4.18699V9.33366Z"
        fill={color ? color : '#C4C4C4'}
      />
    </svg>
  );
};

export default EmailIcon;
