/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { CheckOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import removeProductFromCartApi from '../../apis/cart-apis/removeProductFromCartApi';
import CustomImage from '../../common/custom-image/CustomImage';
import ShieldIcon from '../../common/icons/ShieldIcon';
import DeleteStoreIcon from '../../common/icons/store-card-icons/DeleteStoreIcon';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import { hasOfferValues } from '../../const-values/leftedCartOptions';
import productSettingsOptions from '../../const-values/productSettingsOptions';
import refundOptions from '../../const-values/refundOptions';
import CartContext from '../../contexts/cart-context/CartContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import {
  getProductPriceAfterDiscount,
  getProductPriceAfterDiscountAndOffer
} from '../../utils/calcServicePrices';
import checkRes from '../../utils/checkRes';
import renderTime from '../../utils/renderTime';

const CartCard = ({ card }) => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  const {
    setIsRemovingProduct,
    setFetchCartCount,
    cartProducts,
    setCartProducts
  } = useContext(CartContext);

  const calcCardPrice = () => {
    let cardPrice = 0;
    const foundObj =
      cartProducts?.length > 0 && cartProducts.find((i) => i.id == card.id);

    if (foundObj?.quantity) {
      const cardNetPrice =
        getProductPriceAfterDiscountAndOffer({
          pPrice: card?.product?.price,
          hasDiscount: card?.product?.hasCode,
          discountType: card?.product?.codeType,
          discountValue: card?.product?.codeValue,
          hasOffer: card?.hasOffer,
          offerType: card?.offerType,
          offerValue: card?.offerValue,
          offerEndDate: card?.offerEndDate,
          codeEndDate: card?.product?.codeEndDate
        }) * parseFloat(foundObj.quantity);

      if (parseFloat(card?.optionPrice)) {
        cardPrice = cardNetPrice + parseFloat(card.optionPrice);
      } else {
        cardPrice = cardNetPrice;
      }

      return cardPrice;
    }
    return 0;
  };

  const renderStrikedPrice = (price) => {
    return (
      <span className="old-price striked">
        {price}
        <span className="line"></span>
      </span>
    );
  };

  const renderProductPriceAfterDiscount = ({
    strikedDiscountPrice = false
  }) => {
    return (
      <div className="old-new-price-wrapper">
        <span>{t('cartCard.pricePerService')}</span>
        {renderStrikedPrice(card?.product?.price)}
        <span className={`new-price ${strikedDiscountPrice ? 'striked' : ''}`}>
          {getProductPriceAfterDiscount({
            pPrice: card?.product?.price,
            hasDiscount: card?.product?.hasCode,
            discountType: card?.product?.codeType,
            discountValue: card?.product?.codeValue,
            codeEndDate: card?.product?.codeEndDate
          })}{' '}
          {strikedDiscountPrice && <span className="line"></span>}
          {!strikedDiscountPrice && t('currency.sar')}
        </span>
      </div>
    );
  };

  const renderPricePerService = () => {
    // check if product has lefted cart offer

    if (
      card?.hasOffer == hasOfferValues?.yes &&
      card.offerEndDate &&
      (dayjs(card.offerEndDate).isAfter() ||
        dayjs(card.offerEndDate).date() === dayjs().date())
    ) {
      // check if has discount code also => then product has discount and lefted cart offer
      if (
        card?.product?.hasCode &&
        card?.product?.codeValue &&
        card?.product?.codeEndDate &&
        (dayjs(card?.product?.codeEndDate).isAfter() ||
          dayjs(card?.product?.codeEndDate).date() === dayjs().date())
      ) {
        return (
          <div className="offer-with-discount-wrapper">
            {renderProductPriceAfterDiscount({
              strikedDiscountPrice: true
            })}
            {getProductPriceAfterDiscountAndOffer({
              pPrice: card?.product?.price,
              hasDiscount: card?.product?.hasCode,
              discountType: card?.product?.codeType,
              discountValue: card?.product?.codeValue,
              hasOffer: card?.hasOffer,
              offerType: card?.offerType,
              offerValue: card?.offerValue,
              offerEndDate: card?.offerEndDate,
              codeEndDate: card?.product?.codeEndDate
            })}{' '}
            {t('currency.sar')}
          </div>
        );
      }
      // so here product has lefted cart offer and no product discount
      return (
        <div className="old-new-price-wrapper">
          <span>{t('cartCard.pricePerService')}</span>
          {renderStrikedPrice(card?.product?.price)}
          <span className={`new-price`}>
            {getProductPriceAfterDiscountAndOffer({
              pPrice: card?.product?.price,
              hasDiscount: card?.product?.hasCode,
              discountType: card?.product?.codeType,
              discountValue: card?.product?.codeValue,
              hasOffer: card?.hasOffer,
              offerType: card?.offerType,
              offerValue: card?.offerValue,
              offerEndDate: card?.offerEndDate,
              codeEndDate: card?.product?.codeEndDate
            })}{' '}
            {t('currency.sar')}
          </span>
        </div>
      );
    }

    // check if has discount code but no lefted cart offer
    else if (
      card?.product?.hasCode &&
      card?.product?.codeValue &&
      card?.product?.codeEndDate &&
      (dayjs(card?.product?.codeEndDate).isAfter() ||
        dayjs(card?.product?.codeEndDate).date() === dayjs().date())
    ) {
      return renderProductPriceAfterDiscount({
        strikedDiscountPrice: false
      });
    }

    return (
      <p>
        <span>{t('cartCard.pricePerService')}</span>
        <span className="no-discount-price">
          {card?.product?.price} {t('currency.sar')}
        </span>
      </p>
    );
  };

  const handleDeleteProduct = () => {
    setIsRemovingProduct(true);
    const formData = new FormData();
    formData.append('cart_id', card?.id);
    customApiRequest(
      removeProductFromCartApi(formData, user?.token, i18n.language),
      (res) => {
        setIsRemovingProduct(false);
        if (checkRes(res)) {
          setFetchCartCount((prev) => prev + 1);
        }
      },
      (error) => {
        setIsRemovingProduct(false);
      }
    );
  };

  const handleCounterClick = async (type, e) => {
    e.preventDefault();
    if (cartProducts?.length >= 0) {
      if (type === 'up') {
        setCartProducts((prev) =>
          prev.map((item) =>
            item?.id === card?.id
              ? {
                  ...item,
                  quantity: item?.quantity + 1
                }
              : item
          )
        );
      } else {
        if (card?.quantity >= 2) {
          setCartProducts((prev) => {
            return prev.map((item) =>
              item?.id === card?.id
                ? {
                    ...item,
                    quantity: item?.quantity - 1
                  }
                : item
            );
            //  else {
            //   return prev.filter((item) => item?.id !== card?.id);
            // }
          });
        }
      }
    }
  };

  const renderProductSettingsOptions = () => {
    if (productSettingsOptions(t)?.length > 0) {
      const foundOption = productSettingsOptions(t).find(
        (item) => item.value == card?.optionType
      );
      if (foundOption)
        return (
          <p className="option-p">
            <CheckOutlined /> {foundOption.name}{' '}
            {card?.product[foundOption.apiTitle] &&
            Number(card?.product[foundOption.apiTitle]) ? (
              <>
                {card?.optionPrice} {t('currency.sar')}
              </>
            ) : null}
          </p>
        );
      return '';
    }
  };

  const renderRefundOption = () => {
    if (refundOptions(t)?.length > 0) {
      const foundRefundOption = refundOptions(t).find(
        (item) => item.value == card?.product?.refundOption
      );
      if (foundRefundOption)
        return (
          <p className="refund-title">
            <ShieldIcon color="#FB2E86" />
            {foundRefundOption.title}
          </p>
        );
      return '';
    }
  };

  return (
    <RouterLink
      className="cart-card"
      to={generalRouterLinks?.generalSingleProductRoute(card?.product?.id)}
    >
      <CustomImage
        className="p-logo"
        src={
          card?.product?.productImages?.length > 0
            ? card.product.productImages[0]?.image
            : card?.product?.logo
        }
      />
      <div className="main-content">
        <div className="name-store-wrap">
          <div className="p-name">{card?.product?.name}</div>
          {card?.product?.store && (
            <div className="store-name">({card.product.store})</div>
          )}
        </div>

        <div className="date-time-wrap">
          {/* <div className="date-wrap">{card?.date}</div> */}
          {/* <div className="time-wrap">({card?.time})</div> */}
          {card.date && (
            <div className="date-wrap">
              {dayjs(card.date).format('DD-MM-YYYY')}
            </div>
          )}
          <div className="time-wrap">({renderTime(card?.time, i18n)})</div>
          {/* {card.time && (
            <div className="time-wrap">{dayjs(card.time).format('HH:MM')}</div>
          )} */}
        </div>

        {renderProductSettingsOptions()}

        {renderRefundOption()}

        {renderPricePerService()}
      </div>
      <div className="card-actions-price-wrap">
        <button
          className="remove-btn"
          onClick={(e) => {
            e.preventDefault();
            handleDeleteProduct();
            // setCartProducts(
            //   (prev) =>
            //     prev?.length > 0 && prev.filter((item) => item?.id !== card?.id)
            // );
          }}
        >
          <DeleteStoreIcon />
        </button>
        <div className="price-btns-wrap">
          <div className="p-total-price">
            {calcCardPrice()} {t('currency.sar')}
          </div>
          <div className="counter-btns-wrap">
            <button
              className="count-btn up"
              onClick={(e) => handleCounterClick('up', e)}
            >
              +
            </button>
            <span className="count-value">{card?.quantity}</span>
            <button
              className="count-btn down"
              onClick={(e) => handleCounterClick('down', e)}
            >
              -
            </button>
          </div>
        </div>
      </div>
    </RouterLink>
  );
};

export default CartCard;
