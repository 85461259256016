import CustomImage from '../../../common/custom-image/CustomImage';
import whatsappImg from '../../../assets/imgs/icons/lefted-cart/whatsapp.svg';
import phoneImg from '../../../assets/imgs/icons/lefted-cart/phone.svg';

const UserCard = ({ user }) => {
  return (
    <div className="user-card">
      <CustomImage className="user-img" src={user?.image} />
      <p className="username">{user?.name}</p>
      <p className="phone-p">{user.phone}</p>
      <div className="phones-btns">
        <button className="row-btn">
          <a
            className="whatsapp-link"
            href={`https://wa.me/${user?.phone}`}
            target="_blank"
            rel="noreferrer"
          >
            <img src={whatsappImg} alt="whatsapp" />
          </a>
        </button>
        <button className="row-btn">
          <a
            className="whatsapp-link"
            href={`tel:+${user?.phone}`}
            rel="noreferrer"
          >
            <img src={phoneImg} alt="phone" />
          </a>
        </button>
      </div>
    </div>
  );
};

export default UserCard;
