import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import filledWalletImg from '../../../assets/imgs/icons/wallet-full.png';
import './MerchentWalletModal.scss';
import MerchentWalletForm from './MerchentWalletForm';

const MerchentWalletModal = ({
  modalOpened,
  setModalOpened,
  selectedStore,
  setFetchCount,
  setSelected
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="user-wallet-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '842px' }}
      title=""
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelected(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelected(null);
      }}
      footer={false}
    >
      <img className="wallet-img" src={filledWalletImg} alt="wallet img" />
      <h2>{t('walletPage.withdrawWallet')}</h2>
      <MerchentWalletForm
        setModalOpened={setModalOpened}
        selectedStore={selectedStore}
        setFetchCount={setFetchCount}
      />
    </Modal>
  );
};

export default MerchentWalletModal;
