/* eslint-disable eqeqeq */
import * as Yup from 'yup';
import { affiliatFeesTypes } from '../../../const-values/affiliateOptions';

const baseRequiredErrTransString = (t, key) =>
  t(`affiliateForm.${key}.errors.required`);
const sharedNumberOnlyErr = (t, key) =>
  t(`affiliateForm.${key}.errors.numbersOnly`);
const sharedSchema = (t) => {
  return {
    name: Yup.string().required(baseRequiredErrTransString(t, 'name')),
    email: Yup.string()
      .required(baseRequiredErrTransString(t, 'email'))
      .email(t(`affiliateForm.email.errors.type_error`)),
    feesType: Yup.string().required(baseRequiredErrTransString(t, 'feesType')),
    feesValue: Yup.string()
      .test(
        'feesValue',
        baseRequiredErrTransString(t, 'feesValue'),
        (v, context) => {
          let result = true;
          if (!v && context?.parent?.feesType == affiliatFeesTypes?.static)
            result = false;
          return result;
        }
      )
      .test('feesValue', sharedNumberOnlyErr(t, 'feesValue'), (v, context) => {
        let result = true;
        if (
          context?.parent?.feesType == affiliatFeesTypes?.static &&
          v &&
          !v.match(/^(\d+)?(\.\d+)?$/)
        )
          result = false;
        return result;
      }),
    feesPercentage: Yup.string()
      .test(
        'feesPercentage',
        baseRequiredErrTransString(t, 'feesPercentage'),
        (v, context) => {
          let result = true;
          if (!v && context?.parent?.feesType == affiliatFeesTypes?.percentage)
            result = false;
          return result;
        }
      )
      .test(
        'feesPercentage',
        sharedNumberOnlyErr(t, 'feesPercentage'),
        (v, context) => {
          let result = true;
          if (
            context?.parent?.feesType == affiliatFeesTypes?.percentage &&
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/)
          )
            result = false;
          return result;
        }
      ),
    firstOrEach: Yup.string().required(
      baseRequiredErrTransString(t, 'firstOrEach')
    )
  };
};

const createValidationSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchema(t)

    // codeAttach: Yup.mixed().test(
    //   'codeAttach',
    //   baseRequiredErrTransString(t, 'codeAttach'),
    //   (v, context) => {
    //     let result = true;
    //     if (context?.parent?.hasCode && !v) result = false;
    //     return result;
    //   }
    // )
  });
};

const editValidationSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};

const getValidationSchema = (fetchedData, t) => {
  if (!fetchedData) return createValidationSchema(t);
  return editValidationSchema(t);
};

export default getValidationSchema;
