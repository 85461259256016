import React from 'react';

const PercentageIcon = ({ color }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.75 3.75009L4.25 14.2501"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.375 6.75C6.41053 6.75 7.25 5.91053 7.25 4.875C7.25 3.83947 6.41053 3 5.375 3C4.33947 3 3.5 3.83947 3.5 4.875C3.5 5.91053 4.33947 6.75 5.375 6.75Z"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.625 14.9999C14.6605 14.9999 15.5 14.1604 15.5 13.1249C15.5 12.0894 14.6605 11.2499 13.625 11.2499C12.5895 11.2499 11.75 12.0894 11.75 13.1249C11.75 14.1604 12.5895 14.9999 13.625 14.9999Z"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PercentageIcon;
