import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSettingsContext from '../contexts/general-settings-context/GeneralSettingsContext';

export default function useToggleOverflow(state) {
  const { windowGutterWidth } = useContext(GeneralSettingsContext);
  const { i18n } = useTranslation();
  const toggleOverflow = () => {
    document.documentElement.classList.toggle('hidden', state);
    if (state) {
      document.documentElement.style.overflowY = 'hidden';
      if (i18n.dir() === 'ltr')
        document.documentElement.style.paddingInlineEnd = `${windowGutterWidth}px`;
      else
        document.documentElement.style.paddingInlineStart = `${windowGutterWidth}px`;
    } else {
      document.documentElement.style.overflowY = 'initial';
      document.documentElement.style.paddingInlineStart = 'initial';
      document.documentElement.style.paddingInlineEnd = 'initial';
    }
  };

  useEffect(() => toggleOverflow(), [state]);

  return { toggleOverflow };
}
