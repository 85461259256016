import { Modal } from 'antd';
import { useContext, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import CustomMap from '../../../components/custom-map/CustomMap';
import UserOrdersContext from '../../../contexts/user-orders-context/UserOrdersContext';
import './AddressMapModal.scss';

const AddressMapModal = () => {
  const [selectedAddress, setSelectedAddress] = useState('');
  const {
    selectedOrder,
    addressMapModalOpened,
    setAddressMapModalOpened,
    setSelectedOrder
  } = useContext(UserOrdersContext);
  const [selectedLocation, setSelecectedLocation] = useState({
    lat: selectedOrder?.store?.lat ? parseFloat(selectedOrder.store.lat) : '',
    lng: selectedOrder?.store?.lng ? parseFloat(selectedOrder.store.lng) : ''
  });
  return (
    <Modal
      className="shared-custom-modal address-map-modal"
      width="90%"
      style={{ maxWidth: '842px' }}
      title={selectedOrder?.store?.address}
      open={addressMapModalOpened}
      onOk={() => {
        setAddressMapModalOpened(false);
        setSelectedOrder(null);
      }}
      onCancel={() => {
        setAddressMapModalOpened(false);
        setSelectedOrder(null);
      }}
      footer={false}
    >
      <CustomMap
        width="100%"
        height="500px"
        selectedLocation={selectedLocation}
        setSelecectedLocation={setSelecectedLocation}
        selectedAddress={selectedAddress}
        setSelectedAddress={setSelectedAddress}
        isDraggable={false}
      />
    </Modal>
  );
};

export default AddressMapModal;
