import React from 'react';

const ShareProductIcon = ({ color }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50618 13.7707C7.13868 13.7707 6.77118 13.6482 6.48535 13.4032L5.56367 12.6099C5.47034 12.5282 5.23701 12.4465 5.11451 12.4465H4.10534C3.242 12.4465 2.54201 11.7466 2.54201 10.8832V9.88567C2.54201 9.76317 2.46034 9.53566 2.37868 9.44233L1.59117 8.51485C1.11284 7.94902 1.11284 7.05653 1.59117 6.4907L2.37868 5.56322C2.46034 5.46989 2.54201 5.24238 2.54201 5.11988V4.12233C2.54201 3.259 3.242 2.55904 4.10534 2.55904H5.11451C5.23701 2.55904 5.47034 2.47152 5.56367 2.39569L6.48535 1.60236C7.05701 1.11236 7.94951 1.11236 8.52117 1.60236L9.44283 2.39569C9.53617 2.47735 9.76951 2.55904 9.89201 2.55904H10.8837C11.747 2.55904 12.447 3.259 12.447 4.12233V5.11404C12.447 5.23654 12.5345 5.46989 12.6162 5.56322L13.4037 6.48486C13.8937 7.05653 13.8937 7.94902 13.4037 8.52069L12.6162 9.44233C12.5345 9.53566 12.4528 9.76901 12.4528 9.89151V10.8832C12.4528 11.7466 11.7528 12.4465 10.8895 12.4465H9.89783C9.77533 12.4465 9.54201 12.534 9.44867 12.6099L8.52701 13.4032C8.23535 13.6482 7.86785 13.7707 7.50618 13.7707ZM4.10534 3.4282C3.72617 3.4282 3.41701 3.73733 3.41701 4.11649V5.11404C3.41701 5.44654 3.25951 5.87236 3.04951 6.1232L2.262 7.05068C2.05783 7.28984 2.05783 7.70403 2.262 7.94319L3.04951 8.87067C3.26534 9.12734 3.42285 9.55316 3.42285 9.87983V10.8774C3.42285 11.2565 3.73201 11.5657 4.11118 11.5657H5.12035C5.45868 11.5657 5.88452 11.7232 6.14118 11.9449L7.06284 12.7382C7.30201 12.9423 7.72201 12.9423 7.96118 12.7382L8.88284 11.9449C9.1395 11.729 9.56534 11.5657 9.90367 11.5657H10.8953C11.2745 11.5657 11.5837 11.2565 11.5837 10.8774V9.88567C11.5837 9.54734 11.7412 9.1215 11.9628 8.86484L12.7503 7.94319C12.9545 7.70403 12.9545 7.284 12.7503 7.04484L11.9628 6.1232C11.747 5.86653 11.5837 5.4407 11.5837 5.10236V4.11065C11.5837 3.73149 11.2745 3.42236 10.8953 3.42236H9.90367C9.57117 3.42236 9.1395 3.26485 8.88284 3.04318L7.96118 2.24985C7.72201 2.04569 7.30201 2.04569 7.06284 2.24985L6.14118 3.04318C5.88452 3.25901 5.45868 3.42236 5.12035 3.42236H4.10534V3.4282Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M8.66734 10.3992H7.62901C7.38401 10.3992 7.0165 10.3234 6.80066 10.1076L5.99568 9.48927L6.53234 8.79506L7.37818 9.44839C7.43651 9.48923 7.54151 9.51839 7.62901 9.51839H8.66734C8.80734 9.51839 8.9765 9.39594 9.00566 9.27344L9.67067 7.24922C9.68817 7.19672 9.68817 7.16176 9.68233 7.15009C9.67067 7.13259 9.62983 7.11506 9.5715 7.11506H8.48067C8.27067 7.11506 8.07817 7.02761 7.93817 6.87011C7.804 6.71261 7.73983 6.50261 7.769 6.28677L7.90901 5.40593C7.92068 5.35927 7.88567 5.30089 7.85067 5.28922C7.81567 5.28339 7.76318 5.29508 7.74568 5.31258L6.63151 6.96923L5.90234 6.47925L7.02233 4.81676C7.25567 4.46676 7.74567 4.3151 8.14234 4.46094C8.58567 4.60677 8.8715 5.10844 8.77233 5.56344L8.66734 6.22838H9.5715C9.91566 6.22838 10.219 6.37425 10.3998 6.63092C10.5748 6.88175 10.6157 7.20843 10.5048 7.5176L9.8515 9.50095C9.729 10.0026 9.20984 10.3992 8.66734 10.3992Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M5.69164 10.1016H5.41748C4.55998 10.1016 4.43164 9.50075 4.43164 9.14492V6.47328C4.43164 6.11744 4.55998 5.5166 5.41748 5.5166H5.69164C6.54914 5.5166 6.67748 6.11744 6.67748 6.47328V9.14492C6.67748 9.50075 6.54914 10.1016 5.69164 10.1016ZM5.31832 9.22075C5.34165 9.22659 5.37665 9.22659 5.42331 9.22659H5.69748C5.74415 9.22659 5.77914 9.22659 5.80248 9.22075C5.80248 9.20325 5.80832 9.17992 5.80832 9.14492V6.47328C5.80832 6.43828 5.80831 6.41494 5.80248 6.39744C5.77914 6.39161 5.74415 6.3916 5.69748 6.3916H5.42331C5.37665 6.3916 5.34165 6.39161 5.31832 6.39744C5.31832 6.41494 5.31248 6.43828 5.31248 6.47328V9.14492C5.31248 9.17992 5.31249 9.20325 5.31832 9.22075Z"
        fill={color ? color : '#6D6D6D'}
      />
    </svg>
  );
};

export default ShareProductIcon;
