import React from 'react';

const PhoneIcon = ({ color }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={color ? color : '#C4C4C4'}
        d="M9.90267 13.0763H9.92128C10.2723 13.0763 10.6041 12.9406 10.8554 12.694L12.6583 10.9244C12.7201 10.8639 12.7692 10.792 12.8026 10.7128C12.8361 10.6337 12.8533 10.5488 12.8533 10.4631C12.8533 10.3775 12.8361 10.2926 12.8026 10.2134C12.7692 10.1343 12.7201 10.0624 12.6583 10.0018L9.99907 7.39191C9.93738 7.33126 9.86411 7.28315 9.78346 7.25033C9.70281 7.2175 9.61635 7.2006 9.52904 7.2006C9.44172 7.2006 9.35527 7.2175 9.27462 7.25033C9.19396 7.28315 9.1207 7.33126 9.05901 7.39191L7.99929 8.43197C7.50798 8.28842 6.5912 7.96218 6.01014 7.39191C5.42909 6.82164 5.09668 5.92187 4.95042 5.43968L6.01014 4.39963C6.07194 4.33908 6.12096 4.26718 6.15441 4.18802C6.18785 4.10887 6.20507 4.02401 6.20507 3.93832C6.20507 3.85263 6.18785 3.76778 6.15441 3.68862C6.12096 3.60947 6.07194 3.53756 6.01014 3.47702L3.35086 0.867088C3.22377 0.74935 3.05561 0.683763 2.88083 0.683763C2.70606 0.683763 2.5379 0.74935 2.41081 0.867088L0.608479 2.63662C0.355847 2.88456 0.213575 3.22516 0.218894 3.57293C0.234185 4.50207 0.484822 7.72924 3.07629 10.2726C5.66776 12.816 8.95596 13.0613 9.90267 13.0763ZM2.8815 2.251L4.60072 3.93832L3.74111 4.78198C3.66289 4.85854 3.60542 4.9531 3.57395 5.05703C3.54248 5.16097 3.53801 5.27098 3.56095 5.37705C3.5769 5.45208 3.96715 7.2314 5.07075 8.31452C6.17436 9.39764 7.98732 9.78065 8.06377 9.79631C8.17184 9.81894 8.28397 9.81461 8.38989 9.78372C8.49581 9.75282 8.59215 9.69636 8.67009 9.61948L9.5297 8.77583L11.2489 10.4631L9.9153 11.7714C9.0856 11.7577 6.24682 11.5391 4.01635 9.34936C1.77856 7.1531 1.56183 4.35722 1.54853 3.55923L2.8815 2.251ZM12.185 5.89903H13.5146C13.5146 2.5518 10.9398 0.0273438 7.52461 0.0273438V1.33231C10.2251 1.33231 12.185 3.25256 12.185 5.89903Z"
      />
    </svg>
  );
};

export default PhoneIcon;
