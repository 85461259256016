import axios from 'axios';

const deleteSingleAddressApi = async (values, token, langIsoCode) => {
  try {
    const res = await axios.post(
      `/Address/delete?address_id=${values?.addressId || ''}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          lang: langIsoCode
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default deleteSingleAddressApi;
