import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Pagination, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';

const SharedAntdTable = ({
  scroll,
  className,
  hasPagination,
  tableDataSource,
  tableColumns,
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  isLoadingTableData,
  setIsLoadingTableData,
  setSelectedTableRow,
  user,
  //
  tablePagination,
  baseTablePageUrl,
  showHeader = true,
  ...props
}) => {
  const navigate = useNavigate();
  const customApiRequest = useCustomApiRequest();
  const { i18n, t } = useTranslation();

  return (
    <>
      <Table
        bordered={props?.bordered || false}
        scroll={{
          x: scroll || 1500
        }}
        rowSelection={props?.rowSelection || null}
        showHeader={showHeader}
        locale={{
          emptyText: (
            <Empty description={false}>
              {props?.emptyText || t('employeesTable.empty')}
            </Empty>
          )
        }}
        pagination={false}
        className={`antd-custom-table ${className}`}
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedTableData,
          setFormModalOpened,
          setDetailsModalOpened,
          setFetchTableDataCount,
          setSelectedTableRow,
          setIsLoadingTableData,
          user?.token,
          customApiRequest,
          i18n,
          t,
          { ...props }
        )}
        // loading={isLoadingTableData}
        loading={{
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
          spinning: isLoadingTableData
        }}
        // scroll={{ x: 400 }}
        footer={false}
      />

      {hasPagination && (
        <Pagination
          locale={{
            jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
            // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
            page: ''
          }}
          showSizeChanger={false}
          defaultCurrent={1}
          current={tablePagination?.current_page}
          pageSize={tablePagination?.per_page}
          total={tablePagination?.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            // setFetchTableDataCount((prev) => prev + 1);
            navigate(`${baseTablePageUrl}?page=${page}`);
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default SharedAntdTable;
