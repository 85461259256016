import { Modal } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralSettingsContext from '../../contexts/general-settings-context/GeneralSettingsContext';
import './TermsModal.scss';
const TermsModal = ({ modalOpened, setModalOpened }) => {
  const { t } = useTranslation();
  const { fetchedGeneralSettings } = useContext(GeneralSettingsContext);

  return (
    <Modal
      className="shared-custom-modal signup-terms-modal"
      width="96%"
      style={{ maxWidth: '642px' }}
      title={t('signup_form.terms')}
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <p>{fetchedGeneralSettings?.terms || ''}</p>
    </Modal>
  );
};

export default TermsModal;
