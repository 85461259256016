import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedData: null,
  setAllFetchedData: (v) => {},
  selectedTableRow: null,
  setSelectedTableRow: (v) => {},
  formModalOpened: false,
  setFormModalOpened: (v) => {},
  singleLefetedCart: null,
  setSingleLeftedCart: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  //
  //
  pagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setPagination: (v) => {},
  // filter
  filter: {
    page: 1
  },
  setFilter: (v) => {}
};

const StoreLeftedCartContext = createContext(INITIAL_VALUES);

export const StoreLeftedCartProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [isRating, setIsRating] = useState(INITIAL_VALUES.isRating);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedData, setAllFetchedData] = useState(
    INITIAL_VALUES.allFetchedData
  );
  const [singleLefetedCart, setSingleLeftedCart] = useState(
    INITIAL_VALUES?.singleLefetedCart
  );
  const [selectedTableRow, setSelectedTableRow] = useState(
    INITIAL_VALUES.selectedTableRow
  );
  const [formModalOpened, setFormModalOpened] = useState(
    INITIAL_VALUES.formModalOpened
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES?.isSubmittingForm
  );
  //
  const [filter, setFilter] = useState(INITIAL_VALUES?.filter);
  const [pagination, setPagination] = useState(INITIAL_VALUES?.pagination);

  return (
    <StoreLeftedCartContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isRating,
        setIsRating,
        fetchCount,
        setFetchCount,
        allFetchedData,
        setAllFetchedData,
        selectedTableRow,
        setSelectedTableRow,
        singleLefetedCart,
        setSingleLeftedCart,
        formModalOpened,
        setFormModalOpened,
        isSubmittingForm,
        setIsSubmittingForm,
        filter,
        setFilter,
        pagination,
        setPagination
      }}
    >
      {children}
    </StoreLeftedCartContext.Provider>
  );
};

export default StoreLeftedCartContext;
