/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Button } from 'antd';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import AffiliateContext from '../../../contexts/affiliate-context/AffiliateContext';
import AgentStatsContentWrapper from './AgentStatsContentWrapper';
import './AffiliateStatisticsModal.scss';

const AffiliateStatisticsModal = () => {
  const { t } = useTranslation();
  const {
    statisticsModalOpened,
    setStatisticsModalOpened,
    setSelectedTableRow,
    selectedTableRow
  } = useContext(AffiliateContext);

  const sharedT = (key, options) =>
    t(`affiliateAgentStatisticsModal.${key}`, options);

  const handleCancel = () => {
    setStatisticsModalOpened(false);
    setSelectedTableRow && setSelectedTableRow(null);
  };

  return (
    <Modal
      transitionName=""
      className="affiliate-statistics-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '894px' }}
      title={<div className="modal-title">{sharedT('modalTitle')}</div>}
      open={statisticsModalOpened}
      onOk={() => {
        setStatisticsModalOpened(false);
        setSelectedTableRow && setSelectedTableRow(null);
      }}
      onCancel={() => {
        handleCancel();
      }}
      footer={false}
    >
      <div className="modal-content">
        <AgentStatsContentWrapper obj={selectedTableRow} />
        <div className="modal-btns">
          <Button onClick={handleCancel} className="cancel-btn">
            {sharedT('close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AffiliateStatisticsModal;
