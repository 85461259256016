import axios from 'axios';

const editSaloonApi = async (token, values, langIsoCode) => {
  try {
    const res = await axios.post('/Auth_private/edit_saloon', values, {
      headers: {
        lang: langIsoCode || 'ar',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw new Error(error);
  }
};

export default editSaloonApi;
