/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../../components/app/routerLinks';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import slugify from 'slugify';
import UserEditIcon from '../../../common/icons/UserEditIcon';
import { useContext, useEffect } from 'react';
import './MerchentSettingsPage.scss';
import SettingsIcon from '../../../common/icons/SettingsIcon';
import ChangePasswordIcon from '../../../common/icons/user-icons/ChangePasswordIcon';
import UserContext from '../../../contexts/user-context/UserProvider';
import accountTypes from '../../../const-values/accountTypes';

const MerchentSettingsPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();
  const sharedTrans = (key) => t(`userDashboard.${key}`);

  useEffect(() => {
    if (slugify(pathname) === slugify(merchentRouterLinks?.merchentSettings))
      navigate(merchentRouterLinks?.merchentSettingsNestedAccount);
  }, [pathname]);
  const navsArr = (t) => {
    return [
      {
        id: 'settings_key_account',
        name: t('main_app_bar_links.merchentSettings'),
        icon: (
          <UserEditIcon
            color={
              slugify(pathname) ===
              slugify(merchentRouterLinks?.merchentSettingsAccount)
                ? '#fff'
                : 'gray'
            }
          />
        ),
        url: merchentRouterLinks?.merchentSettingsAccount
      },
      user?.accountType == accountTypes()?.merchent
        ? {
            id: 'settings_key_saloon_name',
            name: t('main_app_bar_links.saloonSettings'),
            icon: (
              <SettingsIcon
                color={
                  slugify(pathname) ===
                  slugify(merchentRouterLinks?.saloonSettings)
                    ? '#fff'
                    : 'gray'
                }
              />
            ),
            url: merchentRouterLinks?.saloonSettings
          }
        : null,
      {
        id: 'settings_key_change_pass',
        name: t('main_app_bar_links.changePassword'),
        icon: (
          <ChangePasswordIcon
            color={
              slugify(pathname) === slugify(merchentRouterLinks?.changePassword)
                ? '#fff'
                : 'gray'
            }
          />
        ),
        url: merchentRouterLinks?.changePassword
      }
    ];
  };

  const SettingsNavigationRoute = ({ name, icon, url, ...props }) => {
    return (
      <RouterLink
        className={`settings-nav-route ${
          slugify(pathname) === slugify(url) ? 'selected-nav' : ''
        }`}
        to={url || '/'}
      >
        {icon ? icon : 'gray'}
        {name}
      </RouterLink>
    );
  };

  return (
    <div className="merchent-settings-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('settings'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{t('user_settings_form.title')}</div>
        </div>

        <div className="page-main-wrapper">
          <div className="page-navigation">
            {navsArr(t)?.length > 0 &&
              navsArr(t)
                .filter((obj) => obj)
                .map((item) => {
                  return <SettingsNavigationRoute key={item?.id} {...item} />;
                })}
          </div>

          <div className="page-content">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchentSettingsPage;
