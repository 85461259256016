/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import NewAffiliateForm from './NewAffiliateForm';
import AffiliateContext from '../../../contexts/affiliate-context/AffiliateContext';

const NewAffiliateModal = () => {
  const {
    formModalOpened,
    setFormModalOpened,
    selectedTableRow,
    setSelectedTableRow
  } = useContext(AffiliateContext);
  const { t } = useTranslation();
  const sharedTrans = (key) => t(`affiliatePage.${key}`);
  return (
    <Modal
      transitionName=""
      className="new-affiliate-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={
        <div className="modal-title">
          {selectedTableRow
            ? sharedTrans('editAgent')
            : sharedTrans('newAgent')}
        </div>
      }
      open={formModalOpened}
      onOk={() => {
        setFormModalOpened(false);
        setSelectedTableRow(null);
      }}
      onCancel={() => {
        setFormModalOpened(false);
        setSelectedTableRow(null);
      }}
      footer={false}
    >
      <NewAffiliateForm />
    </Modal>
  );
};

export default NewAffiliateModal;
