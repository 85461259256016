/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import CustomStepperWrap from '../../common/custom-stepper-wrap/CustomStepperWrap';
import CartStepOne from './CartStepOne';
import CartStepThree from './CartStepThree';
import CartStepTwo from './CartStepTwo';
// import PosStepThree from './PosStepThree';

const CartStepperWrap = () => {
  return (
    <CustomStepperWrap
      className="cart-stepper-form-wrap"
      // defaultValues={posDefaultValues}
      onSubmit={async (values) => {
        try {
          // console.log('values: ', values);
        } catch (error) {
          // console.log(error);
        }
      }}
    >
      <CartStepOne stepTitle="step one" />

      <CartStepTwo stepTitle="step two" />

      <CartStepThree stepTitle="step three" />
    </CustomStepperWrap>
  );
};

export default CartStepperWrap;
