import React from 'react';

const TodayOffersPage = () => {
  return (
    <div>
      <div>today offers page</div>
    </div>
  );
};

export default TodayOffersPage;
