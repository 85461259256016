import * as Yup from 'yup';

// service_id: '',
// servicePrice_id: '',
// paymentType: ''
const baseRequiredErrTransString = (t, key) =>
  t(`other_service_form.${key}.errors.required`);
const sharedSchema = (t) => {
  return {
    service_id: Yup.string().required(
      baseRequiredErrTransString(t, 'need_radio')
    ),
    // servicePrice_id: Yup.string()
    // .test('servicePrice_id', baseRequiredErrTransString(t, 'video_duration'), (v, context) => {
    //   let result = true;
    //   if ()
    //   return result;
    // }),
    paymentType: Yup.string().required(
      baseRequiredErrTransString(t, 'payment_method')
    )
  };
};

const createValidationSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchema(t)

    // codeAttach: Yup.mixed().test(
    //   'codeAttach',
    //   baseRequiredErrTransString(t, 'codeAttach'),
    //   (v, context) => {
    //     let result = true;
    //     if (context?.parent?.hasCode && !v) result = false;
    //     return result;
    //   }
    // )
  });
};

const editValidationSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};

const getValidationSchema = (fetchedData, t) => {
  if (!fetchedData) return createValidationSchema(t);
  return editValidationSchema(t);
};

export default getValidationSchema;
