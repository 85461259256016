/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import EmailIcon from '../../../common/icons/EmailIcon';
import UserIcon from '../../../common/icons/UserIcon';
import './CreateStoreStepTwo.scss';
import DocTitleScrollTop from '../../../utils/DocTitleScrollTop';

const CreateStoreStepTwo = ({ countries }) => {
  const { t } = useTranslation();
  const sharedLabelTrans = (key) => t(`newStorePage.newStoreForm.${key}.label`);
  DocTitleScrollTop();
  const {
    watch,
    formState: { errors },
    setValue,
    control
  } = useFormContext();

  // console.log('watch : ', watch());
  // console.log('errors : ', errors);

  const handleCityOptions = (countryId) => {
    if (countryId && countries?.length > 0) {
      const foundCountry = countries.find((c) => c.id == countryId);
      if (foundCountry && foundCountry.cities?.length > 0) {
        return foundCountry.cities.map((city) => ({
          title: city?.name,
          value: String(city?.id)
        }));
      }
    }
    return [];
  };

  // React.useEffect(() => {
  //   if (watch('bankCountry_id')) {
  //     setValue('bankCity_id', '');
  //   }
  // }, [watch('bankCountry_id')]);

  // console.log('watch : ', watch());
  // console.log('errors : ', errors);

  return (
    <div className="stepper-step create-store-step-two">
      <AntdTextField
        name="bankName"
        type="text"
        placeholder={sharedLabelTrans('bankName')}
        label={sharedLabelTrans('bankName')}
        errorMsg={errors?.bankName?.message}
        validateStatus={errors?.bankName ? 'error' : ''}
        prefix={<UserIcon />}
        control={control}
      />

      <div className="select-label-wrap">
        <p className="label-p">{sharedLabelTrans('bankCountry')}</p>
        <div className="custom-select-wrap without-icon">
          <AntdSelectOption
            name={`bankCountry_id`}
            errorMsg={errors?.bankCountry_id && errors.bankCountry_id.message}
            validateStatus={errors?.bankCountry_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder={sharedLabelTrans('bankCountry')}
            prefix={<UserIcon />}
            options={
              countries?.length > 0 &&
              countries.map((obj) => ({
                title: obj?.name,
                value: String(obj?.id)
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="new-store-stepper-form"
          />
        </div>
      </div>

      <div className="select-label-wrap">
        <p className="label-p">{sharedLabelTrans('bankCity')}</p>
        <div className="custom-select-wrap without-icon">
          <AntdSelectOption
            name={`bankCity_id`}
            errorMsg={errors?.bankCity_id && errors.bankCity_id.message}
            validateStatus={errors?.bankCity_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder={sharedLabelTrans('bankCity')}
            options={handleCityOptions(watch('bankCountry_id'))}
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="new-store-stepper-form"
          />
        </div>
      </div>

      <AntdTextField
        name="shortNum"
        type="text"
        placeholder={sharedLabelTrans('shortNumber')}
        label={sharedLabelTrans('shortNumber')}
        errorMsg={errors?.shortNum?.message}
        validateStatus={errors?.shortNum ? 'error' : ''}
        prefix={<EmailIcon />}
        control={control}
      />

      <AntdTextField
        name="longNum"
        type="text"
        placeholder={sharedLabelTrans('longNumber')}
        label={sharedLabelTrans('longNumber')}
        errorMsg={errors?.longNum?.message}
        validateStatus={errors?.longNum ? 'error' : ''}
        prefix={<EmailIcon />}
        control={control}
      />
    </div>
  );
};

export default CreateStoreStepTwo;
