/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Badge, Dropdown } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  NavLink,
  Link as RouterLink,
  useLocation,
  useNavigate
} from 'react-router-dom';
import dropdownIcon from '../../assets/imgs/icons/appbar-dropdown-icon.svg';
import CartIcon from '../../common/icons/CartIcon';
import EmailIcon from '../../common/icons/EmailIcon';
import HeartIcon from '../../common/icons/HeartIcon';
import PhoneIcon from '../../common/icons/PhoneIcon';
import PinIcon from '../../common/icons/PinIcon';
import SigninRegisterIcon from '../../common/icons/SigninRegisterIcon';
import LanguageButton from '../../common/language-button/LanguageButton';
import Logo from '../../common/logo/Logo';
import accountTypes from '../../const-values/accountTypes';
import CartContext from '../../contexts/cart-context/CartContext';
import SharedAppContext from '../../contexts/shared-app-context/SharedAppContext';
import UserContext from '../../contexts/user-context/UserProvider';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';
import routerLinks from '../app/routerLinks';
import userRouterLinks from '../app/user-routes/uesRouterLinks';
import AppNotificationsDropdownMenu from './AppNotificationsDropdownMenu';
import './MainAppBarLg.scss';
import MainAppBarSearch from './MainAppBarSearch';
import MainAppProfileMenu from './MainAppProfileMenu';
import mainAppBarLinks from './mainAppBarLinks';

const MainAppBarLg = ({ className, exceeds0 }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const { user } = useContext(UserContext);
  const { cartItemsCount } = useContext(CartContext);
  const { allFetchedFilterCats, location, appCity } =
    useContext(SharedAppContext);

  const renderMenuSubMenu = (menu) => {
    if (menu?.length > 0) {
      return menu.map((filterCat) => {
        if (filterCat?.cats?.length > 0) {
          return (
            <Dropdown
              trigger={['click']}
              placement="bottom"
              arrow
              key={filterCat?.id}
              menu={{
                items: [
                  ...filterCat.cats.map((cat) => ({
                    className: 'nav-link-li',
                    key: cat?.id,
                    label: cat?.name,
                    onClick: () => {
                      navigate(
                        `${generalRouterLinks?.generalProductsRoute}?cat_id=${
                          cat?.id || ''
                        }&catFilter_id=${filterCat?.id || ''}`
                      );
                    }
                  }))
                ]
              }}
            >
              <div className="main-nav-link-div">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4
                  }}
                >
                  {filterCat?.name}
                  {filterCat?.cats?.length > 0 && (
                    <img src={dropdownIcon} alt="drop" />
                  )}
                </span>
                <div className="active-img-wrap"></div>
              </div>
            </Dropdown>
          );
        }
        return (
          <div
            className="main-nav-link-div"
            key={filterCat?.id}
            onClick={() => {
              navigate(
                `${generalRouterLinks?.generalProductsRoute}?catFilter_id=${
                  filterCat?.id || ''
                }`
              );
            }}
          >
            <span>{filterCat?.name}</span>
            <div className="active-img-wrap"></div>
          </div>
        );
      });
    }
  };

  const renderNavSubNav = (menu) => {
    if (menu?.length > 0) {
      return menu.map((nav) => {
        if (nav && nav?.sub && nav?.sub?.length > 0) {
          return (
            <Dropdown
              trigger={['click']}
              placement="bottom"
              arrow
              key={nav?.id}
              menu={{
                items: [
                  ...nav.sub.map((s) => ({
                    className: 'nav-link-li',
                    key: s?.id,
                    label: <Link to={s.link}>{s?.name}</Link>
                  }))
                ]
              }}
            >
              <div className="main-nav-link-div">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4
                  }}
                >
                  {nav?.name}
                  {nav && nav?.sub && nav?.sub?.length > 0 && (
                    <img src={dropdownIcon} alt="drop" />
                  )}
                </span>
                <div className="active-img-wrap"></div>
              </div>
            </Dropdown>
          );
        }

        return (
          <div
            className="main-nav-link-div"
            key={nav?.id}
            onClick={() => {
              navigate(nav.link);
            }}
          >
            <span>{nav?.name}</span>
            <div className="active-img-wrap"></div>
          </div>
        );
        return null;
      });
    }
  };

  const renderNavLinks = () => {
    return (
      <ul>
        {renderNavSubNav(mainAppBarLinks(i18n, t, user))}
        {/*  */}
        {(user?.type == accountTypes()?.user || !user) &&
          renderMenuSubMenu(allFetchedFilterCats)}

        <li key="blogs_key">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'active-link main-nav-link' : 'main-nav-link'
            }
            to={generalRouterLinks?.blogsRoute}
          >
            <div className="main-nav-link-div">
              <span>{t('main_app_bar_links.blogs')}</span>
              <div className="active-img-wrap"></div>
            </div>
          </NavLink>
        </li>
      </ul>
    );
  };

  const SigninSignupDropDown = () => {
    return (
      <Dropdown
        trigger={['click']}
        placement="bottom"
        arrow
        menu={{
          items: [
            {
              key: 1,
              className: 'nav-link-li',
              label: (
                <RouterLink
                  className="auth-dropdown-link"
                  to={routerLinks?.signinPage}
                >
                  {t('main_app_bar_links.signin')}
                </RouterLink>
              )
            },
            {
              key: 2,
              className: 'nav-link-li',
              label: (
                <RouterLink
                  className="auth-dropdown-link"
                  to={routerLinks?.userSignupRoute}
                >
                  {t('main_app_bar_links.signup')}
                </RouterLink>
              )
            },
            {
              key: 3,
              className: 'nav-link-li',
              label: (
                <RouterLink
                  className="auth-dropdown-link"
                  to={routerLinks?.merchentSignupRoute}
                >
                  {t('main_app_bar_links.merchentSignup')}
                </RouterLink>
              )
            }
          ]
        }}
      >
        <div className="sigin-signup-dropdown">
          <SigninRegisterIcon />
          {t('main_app_bar_links.signin_signup')}
        </div>
      </Dropdown>
    );
  };

  const renderBottomAppBar = () => {
    // check if no user or there is a user of type client or employee
    if (!user || user?.accountType == accountTypes()?.user) {
      // check if there is a user => active or not
      if (!user?.activated) {
        return (
          <div className="main-search-lang-wrap">
            <MainAppBarSearch location={location} />
            <SigninSignupDropDown />
          </div>
        );
      } else {
        return (
          <div className="main-search-lang-wrap">
            <MainAppBarSearch location={location} />
            <div
              style={{
                display: 'flex',
                gap: 22,
                alignItems: 'center'
              }}
            >
              <AppNotificationsDropdownMenu parentClassName="main-search-lang-wrap" />

              <RouterLink
                style={{ display: 'flex', alignItems: 'center' }}
                to={userRouterLinks?.myFavRoute}
              >
                <HeartIcon />
              </RouterLink>

              <RouterLink
                style={{ display: 'flex', alignItems: 'center' }}
                to={userRouterLinks?.userCartRoute}
              >
                <Badge count={cartItemsCount}>
                  <CartIcon />
                </Badge>
              </RouterLink>
              <MainAppProfileMenu />
            </div>
          </div>
        );
      }
    } else {
      // means there is a user of type merchent
      // then check if the user is active or not
      if (!user.activated) {
        return (
          <div className="merchent-signin-wrapper">
            <SigninSignupDropDown />
          </div>
        );
      } else {
        return (
          <div className="merchent-notifications-profile-wrapper">
            <div
              style={{
                display: 'flex',
                gap: 22,
                alignItems: 'center'
              }}
            >
              <AppNotificationsDropdownMenu parentClassName="merchent-notifications-profile-wrapper" />
              <MainAppProfileMenu />
            </div>
          </div>
        );
      }
    }
  };

  return (
    <div
      className={`${className} ${
        user?.accountType == accountTypes()?.user || !user ? 'user-nav' : ''
      }`}
    >
      <div className="fixed-top-appbar">
        <div className="custom-container">
          <div className="section-content">
            <div className="email-phone-wrapper">
              {user?.email && (
                <a className="email-link" href={`mailto:${user.email}`}>
                  <EmailIcon color="#fff" />
                  <span>{user.email}</span>
                </a>
              )}
              {user?.phone && (
                <a href={`tel:+${user.phone}`}>
                  <PhoneIcon color="#fff" />
                  <span>{user.phone}</span>
                </a>
              )}
            </div>
            <div className="other-content">
              {appCity && (
                <div className="deliver-wrap">
                  <PinIcon color="#fff" />
                  <p>
                    {t('top_appbar.deliver')} <span>{appCity}</span>
                  </p>
                </div>
              )}
              <div className="language-wrap">
                <LanguageButton />
              </div>
              <div className="dliver-wrap">{t('currency.sar')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="custom-container" style={{ display: 'grid' }}>
        <div
          className={`nav-content-wrapper ${
            pathname !== '/' ? 'not-home' : ''
          }`}
        >
          <Logo
            colored={exceeds0 || pathname !== '/'}
            className="main-app-bar-logo"
          />

          <div className="search-links-wrap">
            {renderBottomAppBar()}
            <div className="nav-lang-wrapper bottom-navs">
              {mainAppBarLinks?.length > 0 && renderNavLinks()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainAppBarLg;
