/* eslint-disable eqeqeq */
import { EyeFilled } from '@ant-design/icons';
import { Rate } from 'antd';
import { memo, useContext, useState } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import ImageGallery from 'react-image-gallery';
import { useParams } from 'react-router-dom';
import addProductToFavApi from '../../apis/products-apis/addProductToFavApi';
import CustomImage from '../../common/custom-image/CustomImage';
import HeartIcon from '../../common/icons/HeartIcon';
import HeartIconSolid from '../../common/icons/HeartIconSolid';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import CustomShareBtn from '../../components/custom-share-btn/CustomShareBtn';
import discountCodeTypes from '../../const-values/discountCodeTypes';
import guaranteeOptions from '../../const-values/guaranteeOptions';
import ingredientsOptions from '../../const-values/ingredietnsOptions';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import { checkUrlExtenstion, urlTypes } from '../../utils/checkUrlExtenstion';
import GeneralSingleProductForm from './GeneralSingleProductForm';
// import { isImgLink } from '../../utils/checkFiles';

const GeneralProductData = ({
  isLoadingSingleProduct,
  setIsLoadingSingleProduct,
  fetchSingleProductCount,
  fetchedSingleProduct,
  setFetchSingleProductCount
}) => {
  const params = useParams();
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const [isLoadingFav, setIsLoadingFav] = useState(false);

  const renderGalleryImages = () => {
    if (fetchedSingleProduct?.productImages?.length > 0) {
      if (
        fetchedSingleProduct?.video &&
        checkUrlExtenstion(fetchedSingleProduct?.video) === urlTypes?.videoUrl
      ) {
        let mapArr = [
          ...fetchedSingleProduct.productImages,
          {
            id: 'video_key',
            image: fetchedSingleProduct.video
          }
        ];
        return mapArr.map((img) => {
          return {
            id: img?.id || 'img_id',
            original: img?.image,
            thumbnail: img?.image
          };
        });
      } else {
        return fetchedSingleProduct?.productImages.map((item) => {
          return {
            original: item?.image,
            thumbnail: item?.image
          };
        });
      }
    } else if (
      (!fetchedSingleProduct?.productImages ||
        fetchedSingleProduct?.productImages?.length === 0) &&
      fetchedSingleProduct?.video
    ) {
      return [
        {
          original: 'iam video',
          thumbnail: fetchedSingleProduct.video
        }
      ];
    } else return [];
  };

  const customApiRequest = useCustomApiRequest();
  const handleFav = () => {
    setIsLoadingFav(true);
    const formData = new FormData();
    formData.append('product_id', params?.productId);
    customApiRequest(
      addProductToFavApi(formData, user?.token, i18n.language),

      (res) => {
        setIsLoadingFav(false);
        if (checkRes(res)) {
          setFetchSingleProductCount((prev) => prev + 1);
        }
      },
      (error) => {
        setIsLoadingFav(false);
      }
    );
  };

  const renderDiscontBadge = () => {
    if (fetchedSingleProduct?.hasCode) {
      if (fetchedSingleProduct?.codeType == discountCodeTypes?.fixedValue) {
        return (
          <div className="discount-badge">
            {fetchedSingleProduct?.codeValue}
            {t('currency.sar')} {t('off')}
          </div>
        );
      } else if (
        fetchedSingleProduct?.codeType == discountCodeTypes?.percentageValue
      ) {
        return (
          <div className="discount-badge">
            {fetchedSingleProduct?.codeValue}% {t('off')}
          </div>
        );
      }
    }
  };

  const renderViewsCountBadge = () => {
    return (
      <div className="views-count-badge">
        <EyeFilled />
        <span>{fetchedSingleProduct?.viewCount}</span>
      </div>
    );
  };

  const renderPrice = () => {
    // check if has code
    if (fetchedSingleProduct?.hasCode && fetchedSingleProduct?.codeValue) {
      return (
        <div className="old-new-price-wrapper">
          <span className="old-price striked">
            {fetchedSingleProduct?.price}
            <span className="line"></span>
          </span>
          <span className="new-price">
            {fetchedSingleProduct?.priceOffer}
            {/* {getProductPriceAfterDiscount({
              pPrice: fetchedSingleProduct?.price,
              hasDiscount: fetchedSingleProduct?.hasCode,
              discountType: fetchedSingleProduct?.codeType,
              discountValue: fetchedSingleProduct?.codeValue,
              codeEndDate: fetchedSingleProduct?.codeEndDate
            })} */}
            <span className="currency-span">{t('currency.sar')}</span>
          </span>
        </div>
      );
    }
    return (
      <span className="no-discount-price">
        {fetchedSingleProduct?.price}
        <span className="currency-span">{t('currency.sar')}</span>
      </span>
    );
  };

  const renderActionBtns = () => {
    return (
      <div className="action-btns-wrap">
        <button onClick={handleFav} className="action-btn-itself">
          {fetchedSingleProduct?.is_fav ? (
            <HeartIconSolid />
          ) : (
            <HeartIcon color="#C4C4C4" />
          )}
        </button>
        <CustomShareBtn obj={fetchedSingleProduct} />
      </div>
    );
  };

  const GalleryItem = memo(({ item }) => {
    // if (isImgLink(item?.original))
    if (checkUrlExtenstion(item?.original) === urlTypes?.imageUrl)
      return (
        <img className="image-gallery-image" src={item?.original} alt="alt" />
      );
    else if (checkUrlExtenstion(item?.original) === urlTypes?.videoUrl)
      return (
        <video controls autoPlay muted className="video-gallery">
          <source src={item?.original} />
        </video>
      );
  });
  const GalleryThumbnail = memo(({ item }) => {
    // if (isImgLink(item?.thumbnail))
    if (
      checkUrlExtenstion(item?.original) === urlTypes?.imageUrl &&
      item?.id !== 'video_key'
    )
      return (
        <img
          className="image-gallery-thumbnail-image"
          src={item?.thumbnail}
          alt="alt"
        />
      );
    else if (checkUrlExtenstion(item?.original))
      return (
        <CustomImage
          src={fetchedSingleProduct?.logo}
          className="video-thumbnail"
        />
      );
  });

  const renderIngredientsGuarantee = () => {
    const foundIng = ingredientsOptions(t).find(
      (obj) => obj.value == fetchedSingleProduct?.ingredients_type
    );
    const foundGuarantee = guaranteeOptions(t).find(
      (obj) => obj.value == fetchedSingleProduct?.guarantee
    );
    return (
      <div className="ingredients-guarantee-wrapper">
        {!!foundIng && (
          <p className="ing-span">
            <span>{t('ingredients')} :</span>
            <span> {foundIng?.title}</span>
          </p>
        )}
        {!!foundGuarantee && (
          <p className="ing-span">
            <span>{t('guarantee')} :</span>
            <span> {foundGuarantee?.title}</span>
          </p>
        )}
      </div>
    );
  };

  return (
    <section className="product-details-section">
      <div className="gallery-details-wrap">
        <div className="gallery-wrap">
          {renderDiscontBadge()}
          {fetchedSingleProduct?.hasCode == 1 &&
            fetchedSingleProduct?.codeEndDate && (
              <div className="discount-countdown-badge">
                <Countdown
                  date={new Date(fetchedSingleProduct.codeEndDate).getTime()}
                />
              </div>
            )}
          {renderViewsCountBadge()}
          {fetchedSingleProduct?.productImages?.length > 0 ? (
            <ImageGallery
              showFullscreenButton={false}
              showPlayButton={false}
              autoPlay={false}
              renderItem={(item) => {
                return <GalleryItem item={item} />;
              }}
              renderThumbInner={(item) => {
                return <GalleryThumbnail item={item} />;
              }}
              items={renderGalleryImages()}
            />
          ) : (
            <div className="img-badge-wrap">
              {renderDiscontBadge()}
              {fetchedSingleProduct?.hasCode == 1 &&
                fetchedSingleProduct?.codeEndDate && (
                  <div className="discount-countdown-badge">
                    <Countdown
                      date={new Date(
                        fetchedSingleProduct.codeEndDate
                      ).getTime()}
                    />
                  </div>
                )}
              {renderViewsCountBadge()}

              <CustomImage
                className="product-details-fallback-img"
                src={fetchedSingleProduct?.logo}
              />
            </div>
          )}
        </div>

        <div className="details-parent-wrap">
          <div className="name-actions-wrap">
            <div className="filter-name-wrap">
              <p className="cat-catFilter">
                {fetchedSingleProduct?.cat} - {fetchedSingleProduct?.catFilter}
              </p>
              <p className="p-title">{fetchedSingleProduct?.name}</p>
            </div>

            {renderActionBtns()}
          </div>

          <div className="store-name-location-wrap">
            {fetchedSingleProduct?.store && (
              <p className="store-name">
                {fetchedSingleProduct?.store
                  ? `${fetchedSingleProduct.store} - `
                  : ''}
              </p>
            )}
            {fetchedSingleProduct?.city && (
              <p className="store-city"> {fetchedSingleProduct.city}</p>
            )}
          </div>

          {renderIngredientsGuarantee()}

          <div className="stars-wrap">
            <div className="stars-itself">
              <Rate
                disabled
                allowHalf
                defaultValue={fetchedSingleProduct.rate}
              />
            </div>
            <div className="count-wrap">
              <span className="count-value">{fetchedSingleProduct.rate}</span>
              <span className="total-count">
                {' '}
                ( {fetchedSingleProduct.rateCount} {t('singleProductPage.rate')}{' '}
                )
              </span>
            </div>
          </div>

          <div className="price-instock-wrap">
            <div className="p-price">{renderPrice()}</div>
            <div className="stock-label">
              {fetchedSingleProduct?.inStock > 0 ? (
                <span className="in-stock">
                  {t('singleProductPage.inStock')}
                </span>
              ) : (
                <span className="out-of-stock">
                  {t('singleProductPage.outOfStock')}
                </span>
              )}
            </div>
          </div>

          {/* {fetchedSingleProduct?.hasCode == 1 &&
            fetchedSingleProduct?.codeEndDate && (
              <div className="discount-countdown">
                <Countdown
                  date={new Date(fetchedSingleProduct.codeEndDate).getTime()}
                />
              </div>
            )} */}

          <GeneralSingleProductForm
            fetchedSingleProduct={fetchedSingleProduct}
            isLoadingSingleProduct={isLoadingSingleProduct}
            setIsLoadingSingleProduct={setIsLoadingSingleProduct}
            setFetchSingleProductCount={setFetchSingleProductCount}
          />
        </div>
      </div>

      {isLoadingFav && <LoadingModal />}
    </section>
  );
};

export default GeneralProductData;
