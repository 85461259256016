import React from 'react';

const MyWalletIcon = ({ color }) => {
  return (
    <svg
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.958 29.2543L17.208 29.2543C16.2001 29.2543 15.3643 28.4184 15.3643 27.4105C15.3643 26.4026 16.2001 25.5668 17.208 25.5668L31.958 25.5668C32.9659 25.5668 33.8018 26.4026 33.8018 27.4105C33.8018 28.4184 32.9659 29.2543 31.958 29.2543Z"
        fill={color || '#6D6D6D'}
      />
      <path
        d="M4.91699 29.2544C3.90908 29.2544 3.07324 28.4186 3.07324 27.4107L3.07324 16.0532C3.07324 10.0303 7.96533 5.13818 13.9882 5.13818L27.8041 5.13818C34.122 5.13818 38.7191 9.34193 38.7191 15.119C38.7191 16.1269 37.8832 16.9628 36.8753 16.9628C35.8674 16.9628 35.0316 16.1269 35.0316 15.119C35.0316 10.7923 31.2703 8.82568 27.8041 8.82568L13.9882 8.82568C10.0057 8.82568 6.76074 12.0707 6.76074 16.0532L6.76074 27.4107C6.76074 28.4432 5.92491 29.2544 4.91699 29.2544Z"
        fill={color || '#6D6D6D'}
      />
      <path
        d="M39.3337 53.8375L14.7503 53.8375C8.30949 53.8375 3.07324 48.6013 3.07324 42.1604L3.07324 24.9521C3.07324 18.5113 8.30949 13.275 14.7503 13.275L39.3337 13.275C45.7745 13.275 51.0107 18.5113 51.0107 24.9521V28.5166C51.0107 29.5245 50.1749 30.3603 49.167 30.3603L46.5119 30.3603C45.6515 30.3603 44.865 30.6799 44.2995 31.2699L44.2749 31.2945C43.4637 32.1057 43.144 33.2366 43.439 34.3428C43.8078 35.7195 45.2583 36.7275 46.8808 36.7275H49.167C50.1749 36.7275 51.0107 37.5633 51.0107 38.5712V42.1358C51.0107 48.6012 45.7745 53.8375 39.3337 53.8375ZM14.7503 16.9625C10.3499 16.9625 6.76074 20.5517 6.76074 24.9521L6.76074 42.1604C6.76074 46.5609 10.3499 50.15 14.7503 50.15L39.3337 50.15C43.7341 50.15 47.3232 46.5609 47.3232 42.1604V40.4396H46.8808C43.562 40.4396 40.6857 38.35 39.8744 35.3262C39.2353 32.9416 39.9237 30.4095 41.6691 28.6887C42.9474 27.3858 44.6682 26.6728 46.5119 26.6728H47.3232V24.9521C47.3232 20.5517 43.7341 16.9625 39.3337 16.9625L14.7503 16.9625Z"
        fill={color || '#6D6D6D'}
      />
      <path
        d="M51.5506 40.4396H46.8061C43.094 40.4396 39.9474 37.6863 39.6524 34.1463C39.4557 32.1059 40.1932 30.1145 41.6682 28.6641C42.8974 27.3858 44.6427 26.6729 46.4865 26.6729H51.5262C53.9353 26.6729 55.9019 28.615 55.9019 31.0241V36.0883C55.9265 38.4975 53.9598 40.4396 51.5506 40.4396ZM46.5111 30.3604C45.6507 30.3604 44.8641 30.6799 44.2987 31.2699C43.5857 31.9583 43.2415 32.8925 43.3399 33.8267C43.4628 35.4492 45.0361 36.7521 46.8061 36.7521H51.5506C51.9194 36.7521 52.239 36.4571 52.239 36.0883V31.0241C52.239 30.6554 51.9194 30.3604 51.5506 30.3604H46.5111Z"
        fill={color || '#6D6D6D'}
      />
    </svg>
  );
};

export default MyWalletIcon;
