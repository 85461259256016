import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import useCountries from '../../custom-hooks/useCountries';
import './styles.scss';

const { Option } = Select;

const CitiesSelectionFilter = ({ defaultValue, onChange }) => {
  const { t } = useTranslation();
  const handleChange = (v) => {
    if (onChange && typeof onChange === 'function') onChange(v);
  };

  const { allFetchedCountries } = useCountries();

  if (
    allFetchedCountries &&
    allFetchedCountries?.length > 0 &&
    allFetchedCountries[0]?.cities &&
    allFetchedCountries[0]?.cities?.length > 0
  ) {
    const cities = allFetchedCountries[0].cities;

    return (
      <Select
        className="cities-selection-filter"
        defaultValue={defaultValue || ''}
        style={{ width: 162 }}
        size="large"
        onChange={handleChange}
        suffixIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
          >
            <path
              d="M1 0.5L5 4.5L9 0.5"
              stroke="#6D6D6D"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      >
        <Option value="">{t('select_city')}</Option>
        {cities?.length > 0 &&
          cities.map((op, index) => (
            <Option key={index} value={op.id}>
              {op.name}
            </Option>
          ))}
      </Select>
    );
  }
  return null;
};

export default CitiesSelectionFilter;
