import React from 'react';

const PageArrowLeftIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.375 5.81264H5.48286L8.22673 3.06877C8.5433 2.75235 8.72121 2.32313 8.72131 1.87554C8.72142 1.42795 8.54371 0.998649 8.22729 0.68208C7.91087 0.365511 7.48166 0.187606 7.03407 0.1875C6.58648 0.187395 6.15718 0.365098 5.84061 0.681518L1.80636 4.71577C1.06922 5.45493 0.655273 6.45624 0.655273 7.50014C0.655273 8.54405 1.06922 9.54535 1.80636 10.2845L5.84061 14.3188C6.15718 14.6352 6.58648 14.8129 7.03407 14.8128C7.48166 14.8127 7.91087 14.6348 8.22729 14.3182C8.54371 14.0016 8.72142 13.5723 8.72131 13.1247C8.72121 12.6772 8.5433 12.2479 8.22673 11.9315L5.48286 9.18764H15.375C15.8225 9.18764 16.2518 9.00985 16.5682 8.69339C16.8847 8.37692 17.0625 7.9477 17.0625 7.50014C17.0625 7.05259 16.8847 6.62337 16.5682 6.3069C16.2518 5.99043 15.8225 5.81264 15.375 5.81264Z"
        fill={color || '#9d4edd'}
      />
    </svg>
  );
};

export default PageArrowLeftIcon;
