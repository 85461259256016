/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import ProductStatisticsContext from '../../../contexts/product-statistics-context/ProductStatisticsContext';
import useProductStatistics from '../../../custom-hooks/useProductStatistics';
import useSingleProduct from '../../../custom-hooks/useSingleProduct';
import productStatus from '../../../const-values/productStatus';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import routerLinks from '../../../components/app/routerLinks';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import MerchentProductStatisticsChart from './MerchentProductStatisticsChart';
import { LoadingOutlined } from '@ant-design/icons';
import './MerchentProductStatisticsPage.scss';

const MerchentProductStatisticsPage = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const {
    isLoadingData,
    fetchedData,
    setIsLoadingData,
    setFetchedData,
    fetchDataCount,
    setTableFilter,
    tableFilter
  } = useContext(ProductStatisticsContext);
  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  const { fetchedData: fetchSingleProductData } = useSingleProduct();
  useProductStatistics({
    fetchCount: fetchDataCount,
    setFetchedData,
    setIsLoading: setIsLoadingData,
    dataFitler: tableFilter
  });
  useEffect(() => {
    if (
      fetchSingleProductData?.status == productStatus?.underReview ||
      fetchSingleProductData?.status == productStatus?.rejected
    ) {
      navigate(merchentRouterLinks?.merchentDashboard);
    }
  }, [fetchSingleProductData]);

  const renderProductsChart = () => {
    if (isLoadingData) {
      return (
        <div
          className="custom-container products-wrapper"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (fetchedData) {
      return <MerchentProductStatisticsChart />;
    }
    return null;
  };

  return (
    <div className="merchent-product-statistics-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('stores'),
            isLink: true,
            to: merchentRouterLinks?.merchentStores
          },
          {
            title: sharedTrans('statistics'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-create-store-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">{sharedTrans('statistics')}</div>
          </div>
        </div>
        <div className="chart-wrapper">{renderProductsChart()}</div>
      </div>
    </div>
  );
};

export default MerchentProductStatisticsPage;
