import React from 'react';
import MerchentSignupForm from './MerchentSignupForm';
import useCountries from '../../custom-hooks/useCountries';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../common/logo/Logo';
import routerLinks from '../../components/app/routerLinks';
import './SignupPage.scss';
import { useTranslation } from 'react-i18next';

const MerchentSignupPage = () => {
  const { allFetchedCountries } = useCountries();
  const { t } = useTranslation();

  return (
    <div className="shared-custom-page signup-page">
      <div className="custom-container">
        <section className="signup-form-section">
          <Logo className="logo" />

          <div className="btns-types-wrap">
            <RouterLink to={routerLinks?.userSignupRoute}>
              {t('signupPage.user')}
            </RouterLink>
            <RouterLink
              className="selected"
              to={routerLinks?.merchentSignupRoute}
            >
              {t('signupPage.merchent')}
            </RouterLink>
            <RouterLink to={routerLinks?.employeeSignupRoute}>
              {t('signupPage.employee')}
            </RouterLink>
          </div>
          <MerchentSignupForm countries={allFetchedCountries} />
        </section>
      </div>
    </div>
  );
};

export default MerchentSignupPage;
