/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable eqeqeq */
import { Dropdown } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import addUserIcon from '../../../assets/imgs/icons/store-card/add-user-icon.svg';
import dotsVerticalIcon from '../../../assets/imgs/icons/store-card/dots-vertical.svg';
import editIcon from '../../../assets/imgs/icons/store-card/edit-icon.svg';
import withdrawIcon from '../../../assets/imgs/icons/store-card/withdraw.svg';
import CustomImage from '../../../common/custom-image/CustomImage';
import DeleteStoreIcon from '../../../common/icons/store-card-icons/DeleteStoreIcon';
import LeftedCartIcon from '../../../common/icons/store-card-icons/LeftedCartIcon';
import NewProductIcon from '../../../common/icons/store-card-icons/NewProductIcon';
import OrdersIcon from '../../../common/icons/store-card-icons/OrdersIcon';
import PrivacyTermsIcon from '../../../common/icons/store-card-icons/PrivacyTermsIcon';
import ProductsIcon from '../../../common/icons/store-card-icons/ProductsIcon';
import StatisticsIcon from '../../../common/icons/store-card-icons/StatisctsIcon';
import StopWorkingIcon from '../../../common/icons/store-card-icons/StopWorkingIcon';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import './MerchentStoreCard.scss';
// import storeStatus from '../../../storeStatus';
import RenderStoreStatus from '../../../common/render-store-status/RenderStoreStatus';
import accountTypes from '../../../const-values/accountTypes';
import {
  employeesPermissionsValues,
  storePermissionsValues
} from '../../../const-values/permissions';
import storeStatus from '../../../const-values/storeStatus';
import EmployeesContext from '../../../contexts/employees-context/EmployeesContext';
import StoresContext from '../../../contexts/stores-context/StoresContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import checkPermission from '../../../utils/checkPermission';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import checkRes from '../../../utils/checkRes';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import storeWithdrawApi from '../../../apis/stores-apis/storeWithdarwApi';
import LoadingModal from '../../../common/loading-modal/LoadingModal';

const MerchentStoreCard = ({ card }) => {
  const { i18n, t } = useTranslation();
  const sharedT = (key) => t(`merchentStoreCard.${key}`);
  const { user } = useContext(UserContext);

  const {
    setTermsAndConditionsModalOpened,
    setDeactivateStoreModalOpened,
    setReactivateStoreModalOpened,
    setDeleteStoreModalOpened,
    setSelectedTableRow,
    // setWithdrawModalOpened,
    setFetchTableDataCount,
    selectedTableRow
  } = useContext(StoresContext);
  const { setFormModalOpened: setEmployeesModalOpened } =
    useContext(EmployeesContext);
  const [isSubmittingWithdraw, setIsSubmittingWithdraw] = useState(false);
  // if (card?.status !== productStatus?.active)
  const sharedStoresPageTrans = (key) => t(`storesPage.${key}`);
  const { editStores, deleteStores, addEmployees } = storePermissionsValues();

  const sharedDropDownList = () => [
    user?.accountType == accountTypes()?.merchent
      ? {
          key: 'terms_key',
          icon: <PrivacyTermsIcon />,
          onClick: () => {
            setSelectedTableRow(card);
            setTermsAndConditionsModalOpened(true);
          },
          label: (
            <span
              style={{
                padding: '0px 4px'
              }}
            >
              {sharedStoresPageTrans('menu.terms')}
            </span>
          )
        }
      : null,
    user?.accountType == accountTypes()?.merchent
      ? {
          key: 'stop_working_key',
          icon: <StopWorkingIcon />,
          onClick: () => {
            setSelectedTableRow(card);
            setDeactivateStoreModalOpened(true);
          },
          label: (
            <span
              style={{
                padding: '0px 4px'
              }}
            >
              {sharedStoresPageTrans('menu.stop')}
            </span>
          )
        }
      : null,
    {
      key: 'delete_store_key',
      icon: <DeleteStoreIcon />,
      onClick: () => {
        setSelectedTableRow(card);
        setDeleteStoreModalOpened(true);
      },
      label: (
        <span
          style={{
            padding: '0px 4px'
          }}
        >
          {sharedStoresPageTrans('menu.delete')}
        </span>
      )
    }
  ];

  const customApiRequest = useCustomApiRequest();
  const handleWithdrawAction = (storeCard) => {
    const formData = new FormData();
    formData.append('store_id', card?.id || '');
    formData.append('price', card.wallet);
    setIsSubmittingWithdraw(true);
    // setIsLoadSingleProduct(true);
    customApiRequest(
      storeWithdrawApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingWithdraw(false);
        // setIsLoadSingleProduct(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: res?.data?.message || t('successMessage')
          });
          setFetchTableDataCount((prev) => prev + 1);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || t('errorMessage')
          });
        }
      },
      (error) => {
        setIsSubmittingWithdraw(false);
        // setIsLoadSingleProduct(false);
        errorNotification({
          title: t('errorTitle'),
          message: t('errorMessage')
        });
      }
    );
  };

  const renderStoreCardDropdownMenu = () => {
    if (card?.status == storeStatus?.active) {
      return (
        <Dropdown
          arrow
          trigger={['click']}
          // disabled={loadingSignout}
          menu={{
            items: sharedDropDownList()
          }}
        >
          <button>
            <img src={dotsVerticalIcon} alt="more options" />
          </button>
        </Dropdown>
      );
    } else if (card?.status == storeStatus?.disabled) {
      return (
        <Dropdown
          arrow
          trigger={['click']}
          // disabled={loadingSignout}
          menu={{
            items: [
              ...sharedDropDownList(),
              user?.accountType == accountTypes()?.merchent
                ? {
                    key: 'reactivate_key',
                    style: {
                      backgroundColor: 'transparent',
                      margin: '12px auto',
                      justifyContent: 'center',
                      textAlign: 'center'
                    },
                    onClick: () => {
                      setSelectedTableRow(card);
                      setReactivateStoreModalOpened(true);
                    },
                    label: (
                      <span
                        style={{
                          padding: '4px 18px',
                          backgroundColor: 'rgba(37, 211, 102, 0.1)',
                          color: 'rgb(37, 211, 102)',
                          borderRadius: 4
                        }}
                      >
                        {sharedStoresPageTrans('menu.reactivate')}
                      </span>
                    )
                  }
                : null
            ].filter((item) => item?.key !== 'stop_working_key')
          }}
        >
          <button>
            <img src={dotsVerticalIcon} alt="more options" />
          </button>
        </Dropdown>
      );
    }
  };

  const SharedCardContent = ({ active }) => {
    return (
      <div className={`store-card ${!active ? 'disabled' : ''}`}>
        <div className="store-card-content">
          {active && user?.accountType !== accountTypes()?.employee ? (
            <RouterLink
              className="store-logo"
              to={merchentRouterLinks?.merchentStoreDetails(card?.id)}
            >
              <CustomImage className="store-img" src={card?.logo} />
            </RouterLink>
          ) : (
            <div className="store-logo">
              <CustomImage className="store-img" src={card?.logo} />
            </div>
          )}

          <div className="name-actions-wrap">
            <div className="name-wrap">
              <div className="name-status-income">
                <div className="name-status">
                  <div className="name">{card?.name}</div>
                  <RenderStoreStatus obj={card} />
                </div>
                {user?.accountType == accountTypes()?.merchent &&
                  card?.status == storeStatus?.active && (
                    <div className="total-withdraw">
                      <div className="total-wrap">
                        <span>
                          {i18n?.language === 'ar' && 'العائد الكلى :'}
                          {i18n?.language === 'en' && 'Total income :'}
                        </span>{' '}
                        <span>
                          {card?.totalIncome} {t('currency.sar')}
                        </span>
                      </div>
                      <div className="total-wrap">
                        <span>
                          {i18n?.language === 'ar' && 'المحفظه :'}
                          {i18n?.language === 'en' && 'Store wallet :'}
                        </span>{' '}
                        <span>
                          {card?.wallet} {t('currency.sar')}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          setSelectedTableRow(card);
                          // setWithdrawModalOpened(true);
                          handleWithdrawAction(card);
                        }}
                        className="withdraw"
                      >
                        <img src={withdrawIcon} alt="withdraw" />
                        <span>
                          {i18n?.language === 'ar' && 'سحب'}
                          {i18n?.language === 'en' && 'Withdraw'}
                        </span>
                      </div>
                    </div>
                  )}
              </div>

              {active ? (
                <div className="more-btns-wrap">
                  {checkPermission({
                    user,
                    supposedRouteRoles: [addEmployees],
                    authed: false
                  }) &&
                    card?.status !== storeStatus?.notCompleted && (
                      <button
                        onClick={() => {
                          setSelectedTableRow(card);
                          setEmployeesModalOpened(true);
                        }}
                      >
                        <img src={addUserIcon} alt="add user" />
                      </button>
                    )}

                  {checkPermission({
                    user,
                    supposedRouteRoles: [editStores],
                    authed: false
                  }) && (
                    <RouterLink
                      className="edit-link"
                      to={merchentRouterLinks?.merchentEditStore(card?.id)}
                    >
                      <img src={editIcon} alt="edit store" />
                    </RouterLink>
                  )}

                  {(checkPermission({
                    user,
                    supposedRouteRoles: [deleteStores],
                    authed: false
                  }) ||
                    user?.accountType == accountTypes()?.merchent) &&
                    renderStoreCardDropdownMenu()}
                </div>
              ) : null}
            </div>

            {active && card?.status !== storeStatus?.notCompleted ? (
              <div className="actions-wrap">
                {checkPermission({
                  user,
                  supposedRouteRoles: [
                    employeesPermissionsValues(t)?.addProducts
                  ],
                  authed: false
                }) && (
                  <RouterLink
                    className="action-btn"
                    to={merchentRouterLinks?.storeNewProduct(card?.id)}
                  >
                    <NewProductIcon />
                    {sharedT('newProduct')}
                  </RouterLink>
                )}
                {checkPermission({
                  user,
                  supposedRouteRoles: [
                    employeesPermissionsValues(t)?.editProducts
                  ],
                  authed: false
                }) && (
                  <RouterLink
                    className="action-btn"
                    to={merchentRouterLinks?.storeProducts(card?.id)}
                  >
                    <ProductsIcon />
                    {sharedT('products')}
                  </RouterLink>
                )}
                {checkPermission({
                  user,
                  supposedRouteRoles: [storePermissionsValues(t)?.reviewOrders],
                  authed: false
                }) && (
                  <RouterLink
                    className="action-btn"
                    to={merchentRouterLinks?.storeOrders(card?.id)}
                  >
                    <OrdersIcon />
                    {sharedT('orders')}
                  </RouterLink>
                )}
                {checkPermission({
                  user,
                  supposedRouteRoles: [
                    storePermissionsValues(t)?.reviewStatistics
                  ],
                  authed: false
                }) && (
                  <RouterLink
                    className="action-btn"
                    to={merchentRouterLinks?.storeStatistics(card?.id)}
                  >
                    <StatisticsIcon />
                    {sharedT('statistics')}
                  </RouterLink>
                )}
                {user?.accountType == accountTypes()?.merchent && (
                  <RouterLink
                    className="action-btn"
                    to={merchentRouterLinks?.storeLeftedCartRoute(card?.id)}
                  >
                    <LeftedCartIcon />
                    {sharedT('leftedCart')}
                  </RouterLink>
                )}
              </div>
            ) : (
              <div className="actions-wrap">
                {checkPermission({
                  user,
                  supposedRouteRoles: [
                    employeesPermissionsValues(t)?.addProducts
                  ],
                  authed: false
                }) && (
                  <div className="action-btn">
                    <NewProductIcon />
                    {sharedT('newProduct')}
                  </div>
                )}
                {checkPermission({
                  user,
                  supposedRouteRoles: [
                    employeesPermissionsValues(t)?.editProducts
                  ],
                  authed: false
                }) && (
                  <div className="action-btn">
                    <ProductsIcon />
                    {sharedT('products')}
                  </div>
                )}
                {checkPermission({
                  user,
                  supposedRouteRoles: [storePermissionsValues(t)?.reviewOrders],
                  authed: false
                }) && (
                  <div className="action-btn">
                    <OrdersIcon />
                    {sharedT('orders')}
                  </div>
                )}
                {checkPermission({
                  user,
                  supposedRouteRoles: [
                    storePermissionsValues(t)?.reviewStatistics
                  ],
                  authed: false
                }) && (
                  <div className="action-btn">
                    <StatisticsIcon />
                    {sharedT('statistics')}
                  </div>
                )}
                {user?.accountType == accountTypes()?.merchent && (
                  <div className="action-btn">
                    <LeftedCartIcon />
                    {sharedT('leftedCart')}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <SharedCardContent
        active={
          card?.status == storeStatus?.active ||
          card?.status == storeStatus?.disabled ||
          card?.status == storeStatus?.notCompleted
            ? true
            : false
        }
      />

      {isSubmittingWithdraw && selectedTableRow?.id === card?.id && (
        <LoadingModal />
      )}
    </>
  );
};

export default MerchentStoreCard;
