/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import SortBy from '../../components/sort-by/SortBy';
import ViewAs from '../../components/view-as/ViewAs';
import discountOfferFilterArr from '../../const-values/discountOffersFilterArr';
import filterTypes from '../../const-values/filterTypes';
import paymentTypes from '../../const-values/paymentTypes';
import productSettingsOptions from '../../const-values/productSettingsOptions';
import rateArr from '../../const-values/rateArr';
import { viewAsArr } from '../../const-values/viewAsOptions';
import GeneralProductsContext from '../../contexts/general-products-context/GeneralProductsContext';
import './GeneralProductsSelectedFilter.scss';

const GeneralProductsSelectedFilter = ({
  allFetchedCats,
  allFetchedCountries,
  allFetchedFilterCats,
  allFetchedPriceFilter
}) => {
  const { t } = useTranslation();
  const {
    setSelectedViewAsObj,
    allFetchedTableData,
    tablePagination,
    tableFilter
  } = useContext(GeneralProductsContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const values = queryString.parse(search);

  const [selectedCat, setSelectedCat] = useState(null);
  useEffect(() => {
    if (allFetchedCats?.length > 0 && values?.cat_id) {
      const foundObj = allFetchedCats.find((obj) => obj?.id == values.cat_id);
      if (foundObj) setSelectedCat(foundObj);
    } else setSelectedCat(null);
  }, [values, allFetchedCats]);

  const handleChangeSort = (v) => {
    navigate(
      `${generalRouterLinks?.generalProductsRoute}?store_id=${
        values?.store_id || ''
      }&country_id=${values?.country_id || ''}&city_id=${
        values?.city_id || ''
      }&cat_id=${values?.cat_id || ''}&catFilter_id=${
        values?.catFilter_id || ''
      }&discount=${values?.discount || ''}&delivery=${
        values?.delivery || ''
      }&rate=${values?.rate || ''}&fromPrice=${
        values?.fromPrice || ''
      }&toPrice=${values?.toPrice || ''}&paymentType=${
        values?.paymentType || ''
      }&sort=${v || ''}&page=${values?.page || 1}`
    );
  };

  const handleClickCheckBoxListFilter = (name, v) => {
    let filteredCountryIdStr = '';
    let filteredCityIdStr = '';
    if (values?.country_id?.split(',')?.length >= 0) {
      filteredCountryIdStr = (valuesString) =>
        valuesString
          ?.split(',')
          .filter((item) => item != v)
          .join(',');
    }
    if (values?.city_id?.split(',')?.length > 0) {
      filteredCityIdStr = (valuesString) =>
        valuesString
          ?.split(',')
          .filter((item) => item != v)
          .join(',');
    }

    if (name === filterTypes?.country_id) {
      navigate(
        `${generalRouterLinks?.generalProductsRoute}?store_id=${
          values?.store_id || ''
        }&country_id=${filteredCountryIdStr(values?.country_id) || ''}&cat_id=${
          values?.cat_id || ''
        }&catFilter_id=${values?.catFilter_id || ''}&discount=${
          values?.discount || ''
        }&delivery=${values?.delivery || ''}&rate=${
          values?.rate || ''
        }&fromPrice=${values?.fromPrice || ''}&toPrice=${
          values?.toPrice || ''
        }&paymentType=${values?.paymentType || ''}&sort=${
          values?.sort || ''
        }&page=1`
      );
    } else if (name === filterTypes?.city_id) {
      navigate(
        `${generalRouterLinks?.generalProductsRoute}?store_id=${
          values?.store_id || ''
        }&country_id=${values?.country_id || ''}&city_id=${
          filteredCityIdStr(values?.city_id) || ''
        }&cat_id=${values?.cat_id || ''}&catFilter_id=${
          values?.catFilter_id || ''
        }&discount=${values?.discount || ''}&delivery=${
          values?.delivery || ''
        }&rate=${values?.rate || ''}&fromPrice=${
          values?.fromPrice || ''
        }&toPrice=${values?.toPrice || ''}&paymentType=${
          values?.paymentType || ''
        }&sort=${values?.sort || ''}&page=1`
      );
    } else if (name === filterTypes?.catFilter_id) {
      navigate(
        `${generalRouterLinks?.generalProductsRoute}?store_id=${
          values?.store_id || ''
        }&country_id=${values?.country_id || ''}&city_id=${
          values?.city_id || ''
        }&cat_id=${values?.cat_id || ''}&catFilter_id=${
          filteredCountryIdStr(values?.catFilter_id) || ''
        }&discount=${values?.discount || ''}&delivery=${
          values?.delivery || ''
        }&rate=${values?.rate || ''}&fromPrice=${
          values?.fromPrice || ''
        }&toPrice=${values?.toPrice || ''}&paymentType=${
          values?.paymentType || ''
        }&sort=${values?.sort || ''}&page=1`
      );
    } else if (name === filterTypes?.delivery) {
      navigate(
        `${generalRouterLinks?.generalProductsRoute}?store_id=${
          values?.store_id || ''
        }&country_id=${values?.country_id || ''}&city_id=${
          values?.city_id || ''
        }&cat_id=${values?.cat_id || ''}&catFilter_id=${
          values?.catFilter_id || ''
        }&discount=${values?.discount || ''}&delivery=${
          filteredCountryIdStr(values?.delivery) || ''
        }&rate=${values?.rate || ''}&fromPrice=${
          values?.fromPrice || ''
        }&toPrice=${values?.toPrice || ''}&paymentType=${
          values?.paymentType || ''
        }&sort=${values?.sort || ''}&page=1`
      );
    }
  };
  const handleClickRouteFilterList = (name, v) => {
    const handleEachKey = (key) => {
      if (name === filterTypes[key]) {
        if (values[key]) return '';
        else return v;
      } else return values[key] || '';
    };
    navigate(
      `${generalRouterLinks?.generalProductsRoute}?store_id=${
        values?.store_id || ''
      }&country_id=${values?.country_id || ''}&city_id=${
        values?.city_id || ''
      }&cat_id=${handleEachKey('cat_id')}&catFilter_id=${
        values?.catFilter_id || ''
      }&discount=${handleEachKey('discount')}&delivery=${
        values?.delivery || ''
      }&rate=${handleEachKey('rate')}&fromPrice=${handleEachKey(
        'fromPrice'
      )}&toPrice=${handleEachKey('toPrice')}&paymentType=${handleEachKey(
        'paymentType'
      )}&sort=${values?.sort || ''}&page=1`
    );
  };
  const handleClickFromToPriceRouteFilterList = (
    fromPriceName,
    toPriceName,
    v
  ) => {
    const handleEachKey = (name, key) => {
      if (name === filterTypes[key]) {
        if (values[key]) return '';
        else return v;
      } else return values[key] || '';
    };
    navigate(
      `${generalRouterLinks?.generalProductsRoute}?store_id=${
        values?.store_id || ''
      }&country_id=${values?.country_id || ''}&city_id=${
        values?.city_id || ''
      }&cat_id=${values?.cat_id || ''}&catFilter_id=${
        values?.catFilter_id || ''
      }&discount=${values?.discount || ''}&delivery=${
        values?.delivery || ''
      }&rate=${values?.rate || ''}&fromPrice=${handleEachKey(
        fromPriceName,
        'fromPrice'
      )}&toPrice=${handleEachKey(toPriceName, 'toPrice')}&paymentType=${
        values?.paymentType || ''
      }&sort=${values?.sort || ''}&page=1`
    );
  };

  const renderSelectedArr = (name, label, valuesArr, toBeSearchedArr) => {
    if (valuesArr?.length > 0) {
      return (
        <div className="single-filter-list">
          {label && <div className="single-filter-list-label">{label} : </div>}
          <div className="values-list-wrapper">
            {valuesArr.map((v) => {
              const foundObj =
                toBeSearchedArr?.length > 0 &&
                toBeSearchedArr.find((obj) => obj?.id == v);
              if (foundObj) {
                return (
                  <span
                    key={v}
                    onClick={() => {
                      handleClickCheckBoxListFilter(name, v);
                    }}
                  >
                    {foundObj?.name}
                    <CloseOutlined
                      style={{
                        padding: '0 2px'
                      }}
                    />
                  </span>
                );
              }
              return null;
            })}
          </div>
        </div>
      );
    }
  };

  const renderSelectedRouteFilter = (name, label, value, toBeSearchedArr) => {
    if (value) {
      const foundObj =
        toBeSearchedArr?.length > 0 &&
        toBeSearchedArr.find((obj) => obj.id == value);
      return (
        <div className="single-filter-list">
          {label && <div className="single-filter-list-label">{label} : </div>}
          <div className="values-list-wrapper">
            {foundObj?.name ? (
              <span
                onClick={() => {
                  handleClickRouteFilterList(name, foundObj?.id);
                }}
              >
                {foundObj.name}
                <CloseOutlined
                  style={{
                    padding: '0 2px'
                  }}
                />
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      );
    }
  };
  const renderSelectedRoutePriceFilter = (
    fromPriceName,
    toPriceName,
    label,
    fromValue,
    toValue,
    toBeSearchedArr
  ) => {
    const renderFromToListFilter = () => {
      const foundObj = toBeSearchedArr.find(
        (obj) => obj?.fromPrice == fromValue && obj?.toPrice == toValue
      );

      if (foundObj) {
        return (
          <div
            className="from-to-wrap"
            onClick={() => {
              handleClickFromToPriceRouteFilterList(
                fromPriceName,
                toPriceName,
                foundObj?.id
              );
            }}
          >
            <span>
              {foundObj?.fromPrice || '0'} ${t('currency.sar')}
            </span>
            <span> - </span>
            <span>
              {foundObj?.toPrice == 0
                ? '+'
                : `${foundObj.toPrice}  ${t('currency.sar')}`}
            </span>
            <CloseOutlined
              style={{
                padding: '0 2px'
              }}
            />
          </div>
        );
      }
      return '';
    };

    if (fromValue || toValue) {
      return (
        <div className="single-filter-list">
          {label && <div className="single-filter-list-label">{label} : </div>}
          {toBeSearchedArr?.length > 0 && renderFromToListFilter()}
        </div>
      );
    }
  };
  const renderFilteredBy = () => {
    return (
      <div className="filters-lists-wrapper">
        {tableFilter?.country_id?.value?.length > 0 &&
          renderSelectedArr(
            filterTypes?.country_id,
            tableFilter?.country_id?.label(t),
            tableFilter?.country_id?.value,
            allFetchedCountries
          )}

        {tableFilter?.cat_id?.value &&
          renderSelectedRouteFilter(
            filterTypes?.cat_id,
            tableFilter.cat_id?.label(t),
            tableFilter.cat_id?.value,
            allFetchedCats
          )}

        {tableFilter?.catFilter_id?.value?.length > 0 &&
          renderSelectedArr(
            filterTypes?.catFilter_id,
            tableFilter?.catFilter_id?.label(t),
            tableFilter?.catFilter_id?.value,
            allFetchedFilterCats
          )}

        {tableFilter?.discount?.value &&
          renderSelectedRouteFilter(
            filterTypes?.discount,
            tableFilter.discount?.label(t),
            tableFilter.discount?.value,
            discountOfferFilterArr(t)
          )}

        {tableFilter?.delivery?.value?.length > 0 &&
          renderSelectedArr(
            filterTypes?.delivery,
            tableFilter?.delivery?.label(t),
            tableFilter?.delivery?.value,
            productSettingsOptions(t)
          )}

        {tableFilter?.rate?.value &&
          renderSelectedRouteFilter(
            filterTypes?.rate,
            tableFilter.rate?.label(t),
            tableFilter.rate?.value,
            rateArr
          )}

        {(tableFilter?.fromPrice?.value || tableFilter?.toPrice?.value) &&
          renderSelectedRoutePriceFilter(
            filterTypes?.fromPrice,
            filterTypes?.toPrice,
            tableFilter.fromPrice?.label(t),
            tableFilter.fromPrice?.value,
            tableFilter.toPrice?.value,
            allFetchedPriceFilter
          )}

        {tableFilter?.paymentType?.value &&
          renderSelectedRouteFilter(
            filterTypes?.paymentType,
            tableFilter.paymentType?.label(t),
            tableFilter.paymentType?.value,
            paymentTypes(t)
          )}
      </div>
    );
  };

  return (
    <div className="selected-filter-wrapper">
      <div className="filtered-by-selected-wrapper">
        <div className="selected-cat-filteredby-wrapper">
          {selectedCat?.name && (
            <div className="results-cat-name-wrapper">
              {tablePagination?.total > 0 && (
                <span>
                  {tablePagination.total} {t('totalResult.results')}
                </span>
              )}
              <h3 className="selected-cat-name bold-font">
                {selectedCat.name}
              </h3>
            </div>
          )}
        </div>

        {(tableFilter?.country_id?.value?.length > 0 ||
          tableFilter?.catFilter_id?.value?.length > 0 ||
          tableFilter?.delivery?.value?.length > 0 ||
          tableFilter?.cat_id?.value ||
          tableFilter?.discount?.value ||
          tableFilter?.rate?.value ||
          tableFilter?.fromPrice?.value ||
          tableFilter?.toPrice?.value ||
          tableFilter?.paymentType?.value) && (
          <div className="filtered-by-wrapper">
            <div className="filtered-title bold-font">
              {t('singleProductPage.filteredBy')}
            </div>
            {renderFilteredBy()}
          </div>
        )}
      </div>
      {allFetchedTableData?.length > 0 && (
        <div className="view-as-sort-wrapper">
          <ViewAs
            defaultValue={viewAsArr(t)[0]}
            cb={(obj) => setSelectedViewAsObj(obj)}
          />

          <div className="sort-filter-wrapper">
            <div className="title">{t('sort.sortBy')}</div>
            <SortBy
              defaultValue={parseInt(values?.sort) || ''}
              handleChangeSort={handleChangeSort}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneralProductsSelectedFilter;
