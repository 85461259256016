/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dropdown, Form, Menu } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import errorNotification from '../../../utils/errorNotification';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import './NewEmployeeForm.scss';
import EmployeesContext from '../../../contexts/employees-context/EmployeesContext';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import createNewEmployeeApi from '../../../apis/employess-apis/addEmployeeApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import successNotification from '../../../utils/successNotification';
import { useTranslation } from 'react-i18next';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdCheckboxsGroup from '../../../common/antd-form-components/AntdCheckboxsGroup';
import useStores from '../../../custom-hooks/useStores';
import './NewEmployeeForm.scss';
import { LoadingOutlined } from '@ant-design/icons';
import SearchIcon from '../../../common/icons/SearchIcon';
import debounce from 'lodash.debounce';
import checkRes from '../../../utils/checkRes';
import searchByEmailApi from '../../../apis/employess-apis/searchByEmailApi';
import {
  employeesPermissionsArr,
  storePermissionsArr
} from '../../../const-values/permissions';
import StoresContext from '../../../contexts/stores-context/StoresContext';

const NewEmployeeForm = () => {
  const { user } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const { isLoadingData, allFetchedTableData } = useStores();
  const [isSearching, setIsSearching] = useState(false);
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState('');

  const changeHandler = (event) => {
    setSelectedEmployee(null);
    setQuery(event.target.value);
  };
  // const debouncedCallback = debounce(callback, waitTime);
  const debouncedChangeHandler = useCallback(debounce(changeHandler, 800), []);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    if (query) {
      const formData = new FormData();
      formData.append('email', query);
      setIsSearching(true);
      customApiRequest(
        searchByEmailApi(formData, user?.token, i18n.language),
        (res) => {
          setIsSearching(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedUsers(res.data.data);
          }
        },
        (error) => {
          setIsSearching(false);
        }
      );
    }
  }, [query]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const renderUsersMenu = () => {
    if (query) {
      if (isSearching) {
        return [
          {
            key: 'searching',
            label: generalLabelStr('searching')
          }
        ];
      }
      if (fetchedUsers?.length > 0) {
        return fetchedUsers.map((user) => ({
          key: user?.id,
          className: 'user-menu-item',
          onClick: () => setSelectedEmployee(user),
          label: `${user?.email} ${user?.name}`
        }));
      }
      if (fetchedUsers?.length === 0) {
        return [
          {
            key: 'empty',
            label: generalLabelStr('noUsers')
          }
        ];
      }
      return [];
    }
    return [
      {
        key: 'email',
        label: generalLabelStr('email')
      }
    ];
  };

  const checkBoxesArr = (arr) => {
    let items = arr.map((item) => {
      return {
        label: item?.name,
        value: item?.id
      };
    });
    return [
      // {
      //   label: 'all',
      //   value: 'all'
      // },
      ...items
    ];
  };
  const {
    setIsLoadingTableData,
    setFormModalOpened,
    setFetchTableDataCount,
    selectedTableRow,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(EmployeesContext);

  const { selectedTableRow: selectedStoreCard } = useContext(StoresContext);

  const schema = Yup.object().shape({
    // buildingNum: Yup.string()
    //   .required('ادخل رقم المبنى')
    //   .matches(/^[0-9]+$/, 'رقم المبنى ارقام فقط'),
    // taxNumber: Yup.string().required('ادخل الرقم الضريبى'),
    // store_id: Yup.string().required('اختار نوع العميل')
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      employeeRoles: [],
      storeRoles: [],
      store_id: ''
    }
  });

  // handle initial values
  useEffect(() => {
    if (
      (selectedTableRow || selectedStoreCard) &&
      !isLoadingData &&
      allFetchedTableData?.length > 0
    ) {
      if (selectedTableRow?.employeeStore?.id && !selectedStoreCard) {
        setValue('store_id', String(selectedTableRow.employeeStore.id));
      }
      if (!selectedTableRow && selectedStoreCard) {
        setValue('store_id', String(selectedStoreCard.id));
      }
      if (selectedTableRow?.employeeRolesArray?.length > 0) {
        setValue(
          'employeeRoles',
          selectedTableRow.employeeRolesArray.map((item) => parseInt(item))
        );
      }
      if (selectedTableRow?.storeRoles?.length > 0) {
        setValue(
          'storeRoles',
          selectedTableRow.storeRoles.map((item) => parseInt(item))
        );
      }
    }
  }, [selectedTableRow, isLoadingData, allFetchedTableData]);

  const onSubmit = async (data) => {
    // console.log('store conversion data to be submitted: ', data);
    const mappedData = {};
    if (data.store_id) mappedData.store_id = data.store_id;
    if (data.employeeRoles?.length > 0) {
      mappedData.employeeRoles = data.employeeRoles.join(',');
    }
    if (data.storeRoles?.length > 0) {
      mappedData.storeRoles = data.storeRoles.join(',');
    }

    setIsSubmittingForm(true);
    setIsLoadingTableData(true);
    const formData = new FormData();
    formData.append('store_id', mappedData.store_id);
    formData.append(
      'employee_id',
      selectedEmployee?.id || selectedTableRow?.id
    );
    formData.append('employeeRoles', mappedData.employeeRoles);
    formData.append('storeRoles', mappedData.storeRoles);

    if (selectedTableRow) {
      customApiRequest(
        createNewEmployeeApi(formData, user?.token, i18n.language, true),
        (res) => {
          if (res?.status === 200 && res?.data?.data) {
            setIsSubmittingForm(false);
            setIsLoadingTableData(false);
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: t('successTitle'),
              message: 'تم تعديل بيانات الموظف بنجاح'
            });
          } else {
            setIsSubmittingForm(false);
            setIsLoadingTableData(false);
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoadingTableData(false);
          errorNotification({
            title: t('errorTitle'),
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      customApiRequest(
        createNewEmployeeApi(formData, user?.token, i18n.language, false),
        (res) => {
          if (checkRes(res)) {
            setIsSubmittingForm(false);
            setIsLoadingTableData(false);
            setFormModalOpened(false);
            setFetchTableDataCount((prevState) => prevState + 1);
            successNotification({
              title: t('successTitle'),
              message: 'تم اضافة الموظف بنجاح'
            });
          } else {
            setIsSubmittingForm(false);
            setIsLoadingTableData(false);
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoadingTableData(false);
          errorNotification({
            title: t('errorTitle'),
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  const generalLabelStr = (key) => t(`employeeForm.${key}`);
  const getSearchInputValue = () => {
    if (selectedEmployee?.name) return selectedEmployee.name;
    else if (!selectedEmployee && searchValue) return searchValue;
    else if (selectedTableRow?.email) return selectedTableRow.email;
    return '';
  };
  return (
    <Form
      className="new-employee-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      fields={[
        {
          name: 'storeRoles',
          value: watch('storeRoles')
        },
        {
          name: 'employeeRoles',
          value: watch('employeeRoles')
        }
      ]}
    >
      <div className="form-body">
        <div className="form-text">
          <div className="form-title">{generalLabelStr('addEmployee')}</div>
          <div className="form-desc">{generalLabelStr('desc')}</div>
        </div>

        {/* <AntdTextField
          name="name"
          type="text"
          placeholder={generalLabelStr('name')}
          label={generalLabelStr('name')}
          errorMsg={errors?.name?.message}
          validateStatus={errors?.name ? 'error' : ''}
          prefix={<UserIcon />}
          control={control}
        /> */}

        <Dropdown
          arrow
          overlayClassName="search-emp-dropdown"
          trigger={['click']}
          // disabled={loadingSignout}
          disabled={selectedTableRow}
          menu={{
            items: renderUsersMenu()
          }}
          onOpenChange={(visible) => {
            if ((!visible && !query) || !query) {
              setFetchedUsers([]);
            }
          }}
        >
          <div className="search-email-form">
            <p className="label-p">{generalLabelStr('email')}</p>
            <label>
              <div className="icon-wrap">
                {isSearching ? (
                  <LoadingOutlined />
                ) : (
                  <SearchIcon color="#999" />
                )}
              </div>
              <input
                disabled={selectedTableRow}
                type="text"
                name="email"
                placeholder={generalLabelStr('email')}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  debouncedChangeHandler(e);
                }}
                value={getSearchInputValue()}
              />
            </label>
          </div>
        </Dropdown>

        <div className="select-label-wrap">
          <p className="label-p">{generalLabelStr('store')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="store_id"
              errorMsg={errors?.store_id?.message}
              validateStatus={errors?.store_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={generalLabelStr('store')}
              options={
                allFetchedTableData?.length > 0
                  ? allFetchedTableData.map((item) => ({
                      title: item?.name,
                      value: item?.id
                    }))
                  : []
              }
              formClassName="new-employee-form"
            />
          </div>
        </div>

        <div className="roles-wrap">
          <p className="roles-title">{generalLabelStr('permissions')}</p>
          <div className="roles-checkboxs">
            <AntdCheckboxsGroup
              className="employees-roles"
              name="employeeRoles"
              label={generalLabelStr('employeesPerm')}
              // onChange={() => setValue('model_id', '')}
              control={control}
              validateStatus={errors?.employeeRoles ? 'error' : ''}
              errorMsg={errors?.employeeRoles?.message}
              options={
                employeesPermissionsArr(t)?.length > 0
                  ? checkBoxesArr(employeesPermissionsArr(t))
                  : []
              }
              defaultValue={
                selectedTableRow?.employeeRolesArray?.length > 0
                  ? selectedTableRow.employeeRolesArray
                  : []
              }
            />

            <AntdCheckboxsGroup
              className="store-roles"
              name="storeRoles"
              label={generalLabelStr('storePerm')}
              // onChange={() => setValue('model_id', '')}
              control={control}
              validateStatus={errors?.storeRoles ? 'error' : ''}
              errorMsg={errors?.storeRoles?.message}
              options={
                storePermissionsArr(t)?.length > 0
                  ? checkBoxesArr(storePermissionsArr(t))
                  : []
              }
              // defaultValue={
              //   selectedProduct?.type ? String(selectedProduct.type) : ''
              // }
            />
          </div>
        </div>

        <Button
          disabled={
            isSubmittingForm || (!selectedEmployee && !selectedTableRow)
          }
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedTableRow ? generalLabelStr('save') : generalLabelStr('send')}
        </Button>
      </div>
    </Form>
  );
};

export default NewEmployeeForm;
