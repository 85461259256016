/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import goldLogo from '../../assets/imgs/gold-logo.png';
import appStoreImg from '../../assets/imgs/icons/app-store.png';
import googlePlayImg from '../../assets/imgs/icons/google-play.png';
import madaImg from '../../assets/imgs/icons/mada.svg';
import masterCardImg from '../../assets/imgs/icons/master-card.svg';
import tabbyImg from '../../assets/imgs/icons/tabby.png';
import visaImg from '../../assets/imgs/icons/visa.svg';
import ClockIcon from '../../common/icons/ClockIcon';
import EmailIcon from '../../common/icons/EmailIcon';
import PhoneIcon from '../../common/icons/PhoneIcon';
import PinIcon from '../../common/icons/PinIcon';
import accountTypes from '../../const-values/accountTypes';
import menWomenFixedCatId from '../../const-values/menWomenFixedCatId';
import FilterCatsContext from '../../contexts/filter-cats/FilterCatsContext';
import GeneralSettingsContext from '../../contexts/general-settings-context/GeneralSettingsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';
import './MainAppFooter.scss';

const MainAppFooter = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { fetchedData: allFetchedFilterCats } = useContext(FilterCatsContext);
  const { fetchedGeneralSettings } = useContext(GeneralSettingsContext);

  const renderFilterList = (name, foundObj) => {
    return (
      <div className="list-wrap">
        <div className="list-title">{foundObj?.name || name}</div>

        <div className="list-itself">
          {foundObj?.cats?.length > 0 &&
            foundObj?.cats.map((cat) => (
              <RouterLink
                key={cat?.id}
                to={`${generalRouterLinks?.generalProductsRoute}?cat_id=${
                  cat?.id || ''
                }&catFilter_id=${foundObj?.id || ''}`}
              >
                {cat?.name}
              </RouterLink>
            ))}
        </div>
      </div>
    );
  };

  const renderMenCats = () => {
    let foundMenCat;
    if (allFetchedFilterCats?.length > 0) {
      foundMenCat = allFetchedFilterCats?.find(
        (item) => item.id == menWomenFixedCatId()?.men
      );
    }

    if (foundMenCat?.cats?.length > 0) {
      return renderFilterList(t('footer.men'), foundMenCat);
    }

    return null;
  };
  const renderWomenCats = () => {
    let foundWomen;
    if (allFetchedFilterCats?.length > 0) {
      foundWomen = allFetchedFilterCats?.find(
        (item) => item.id == menWomenFixedCatId()?.women
      );
    }

    if (foundWomen?.cats?.length > 0) {
      return renderFilterList(t('footer.women'), foundWomen);
    }

    return null;
  };
  const renderKidsGirlsCats = () => {
    let foundCat;
    if (allFetchedFilterCats?.length > 0) {
      foundCat = allFetchedFilterCats?.find(
        (item) => item.id == menWomenFixedCatId()?.kidsGirls
      );
    }

    if (foundCat?.cats?.length > 0) {
      return renderFilterList(t('footer.kids'), foundCat);
    }

    return null;
  };

  // const renderThirdCats = () => {
  //   if (allFetchedFilterCats?.length > 0) {
  //     let foundThirdCat;
  //     const arrWithoutMenWomen = allFetchedFilterCats.filter(
  //       (obj) =>
  //         obj?.id != menWomenFixedCatId()?.men &&
  //         obj?.id != menWomenFixedCatId()?.women
  //     );
  //     if (arrWithoutMenWomen?.length > 0) {
  //       foundThirdCat = arrWithoutMenWomen[0];
  //     }

  //     if (foundThirdCat?.cats?.length > 0) {
  //       return renderFilterList(foundThirdCat?.name, foundThirdCat);
  //     }
  //     return null;
  //   }
  // };

  return (
    <footer className="main-app-footer">
      {(user?.accountType == accountTypes()?.user || !user) && (
        <div className="custom-container">
          <div className="main-content">
            <div className="cats-wrap">
              {renderWomenCats()}
              {renderMenCats()}
              {renderKidsGirlsCats()}
            </div>
            <div className="list-wrap useful-links-list">
              <div className="list-title">{t('footer.usefulLinks')}</div>
              <div className="list-itself">
                <RouterLink to={generalRouterLinks?.contact_us}>
                  {t('footer.contactUs')}
                </RouterLink>
                <RouterLink to={generalRouterLinks?.blogsRoute}>
                  {t('footer.blogs')}
                </RouterLink>
                <RouterLink to={generalRouterLinks?.aboutRoute}>
                  {t('footer.about')}
                </RouterLink>
                <RouterLink to={generalRouterLinks?.termsRoute}>
                  {t('footer.terms')}
                </RouterLink>
                <RouterLink to={generalRouterLinks?.privacyPolicyRoute}>
                  {t('footer.privacy')}
                </RouterLink>
                <RouterLink to={generalRouterLinks?.refundTermsRoute}>
                  {t('footer.refundTerms')}
                </RouterLink>
              </div>
            </div>
            <div className="contact-us-list">
              <div className="list-title">{t('footer.contactUs')}</div>
              {fetchedGeneralSettings?.address && (
                <div className="list-itself contact-list-item">
                  <p>
                    <PinIcon color="#6D6D6D" />
                    <span>{fetchedGeneralSettings.address}</span>
                  </p>
                </div>
              )}
              {fetchedGeneralSettings?.phone && (
                <div className="list-itself contact-list-item">
                  <p>
                    <PhoneIcon color="#6D6D6D" />
                    <span>{fetchedGeneralSettings?.phone}</span>
                  </p>
                </div>
              )}
              {fetchedGeneralSettings?.work_time ? (
                <div className="list-itself contact-list-item">
                  <p>
                    <ClockIcon />
                    <span>
                      {t('footer.allWeek')} {fetchedGeneralSettings?.work_time}
                    </span>
                  </p>
                </div>
              ) : null}
              {fetchedGeneralSettings?.email && (
                <div className="list-itself contact-list-item">
                  <p>
                    <EmailIcon color="#6D6D6D" />
                    <span>{fetchedGeneralSettings.email}</span>
                  </p>
                </div>
              )}
              {fetchedGeneralSettings?.commercial_register ? (
                <div className="list-itself contact-list-item">
                  <p>
                    {t('footer.sgel')} :{' '}
                    {fetchedGeneralSettings?.commercial_register}
                  </p>
                </div>
              ) : null}

              <div className="stores-links">
                {/* <div className="list-title">{t('footer.download')}</div> */}
                <div className="links">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.Naomy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googlePlayImg} alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/sa/app/naomy/id6477305686?l=ar"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appStoreImg} alt="" />
                  </a>
                </div>
              </div>
              <a className="m3roof-link" href="https://maroof.sa/189386">
                <img src={goldLogo} alt="gold-logo" />
                <p>
                  <span>{t('footer.m3roof')} </span>
                  <span>189386</span>
                </p>
              </a>
            </div>
          </div>
        </div>
      )}

      {(user?.accountType == accountTypes()?.merchent ||
        user?.accountType == accountTypes()?.employee) && (
        <div className="custom-container">
          <div className="merchant-footer-wrapper">
            <div className="merchant-useful-links">
              <div className="custom-container">
                <div className="list-itself">
                  <RouterLink to={generalRouterLinks?.aboutRoute}>
                    {t('footer.about')}
                  </RouterLink>
                  <RouterLink to={generalRouterLinks?.termsRoute}>
                    {t('footer.terms')}
                  </RouterLink>
                  <RouterLink to={generalRouterLinks?.privacyPolicyRoute}>
                    {t('footer.privacy')}
                  </RouterLink>
                  <RouterLink to={generalRouterLinks?.refundTermsRoute}>
                    {t('footer.refundTerms')}
                  </RouterLink>
                </div>
              </div>
            </div>

            <div className="contact-us-list side-wrapper">
              {fetchedGeneralSettings?.address && (
                <div className="contact-list-item">
                  <p>
                    <PinIcon color="#6D6D6D" />
                    <span>{fetchedGeneralSettings.address}</span>
                  </p>
                </div>
              )}
              {fetchedGeneralSettings?.phone && (
                <div className="contact-list-item">
                  <p>
                    <PhoneIcon color="#6D6D6D" />
                    <span>{fetchedGeneralSettings?.phone}</span>
                  </p>
                </div>
              )}
              {fetchedGeneralSettings?.work_time ? (
                <div className="contact-list-item">
                  <p>
                    <ClockIcon />
                    <span>
                      {t('footer.allWeek')} {fetchedGeneralSettings?.work_time}
                    </span>
                  </p>
                </div>
              ) : null}
              {fetchedGeneralSettings?.email && (
                <div className="contact-list-item">
                  <p>
                    <EmailIcon color="#6D6D6D" />
                    <span>{fetchedGeneralSettings.email}</span>
                  </p>
                </div>
              )}
              {fetchedGeneralSettings?.commercial_register ? (
                <div className="contact-list-item">
                  <p>
                    {t('footer.sgel')} :{' '}
                    {fetchedGeneralSettings?.commercial_register}
                  </p>
                </div>
              ) : null}
            </div>

            <div className="side-wrapper">
              <div className="stores-links">
                {/* <div className="list-title">{t('footer.download')}</div> */}
                <div className="links">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.Naomy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={googlePlayImg} alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/sa/app/naomy/id6477305686?l=ar"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appStoreImg} alt="" />
                  </a>
                </div>
              </div>
              <a className="m3roof-link" href="https://maroof.sa/189386">
                <img src={goldLogo} alt="gold-logo" />
                <p>
                  <span>{t('footer.m3roof')} </span>
                  <span>189386</span>
                </p>
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="footer-bottom-content">
        <div className="custom-container">
          {/* <Logo className="footer-logo" /> */}
          <div className="content">
            <p>{t('copyRights')}</p>
            <ul className="footer-payment-methods">
              <li>
                <img src={madaImg} alt="mada" />
              </li>
              <li>
                <img src={masterCardImg} alt="master-card" />
              </li>
              <li>
                <img src={visaImg} alt="visa" />
              </li>
              <li>
                <img src={tabbyImg} alt="tabby" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MainAppFooter;
