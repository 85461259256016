const baseLabelTransString = (t, key) => t(`product_form.${key}.label`);

const discountCodeTypes = {
  fixedValue: '1',
  percentageValue: '2'
};

export default discountCodeTypes;

export const discountTypesOptions = (t) => {
  return [
    {
      title: baseLabelTransString(t, 'fixedValue'),
      value: '1'
    },
    {
      title: baseLabelTransString(t, 'percentageValue'),
      value: '2'
    }
  ];
};
