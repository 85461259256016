/* eslint-disable eqeqeq */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './UserDashboardCard.scss';

const UserDashboardCard = ({
  id,
  title,
  to,
  icon,
  closeAccountModalOpened,
  setCloseAccountModalOpened
}) => {
  const renderInsideLink = () => {
    return (
      <div className="inside-card-wrap">
        <div className="icon-wrap">{icon}</div>
        <div className="card-title">{title}</div>
      </div>
    );
  };

  return (
    <div className="user-dashboard-card">
      {to ? (
        <RouterLink className="link-card shared-card" to={to}>
          {renderInsideLink()}
        </RouterLink>
      ) : (
        <div
          onClick={() => {
            if (id == 'close_account_id') setCloseAccountModalOpened(true);
          }}
          className="not-link-card shared-card"
        >
          {renderInsideLink()}
        </div>
      )}
    </div>
  );
};

export default UserDashboardCard;
