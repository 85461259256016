import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoadingData: false,
  setIsLoadingData: (v) => {},
  fetchDataCount: 0,
  setFetchDataCount: (v) => {},
  fetchedSaloonData: null,
  setFetchedSaloonData: (v) => {},
  fetchedSaloonStores: [],
  setFetchedSaloonStores: (v) => {},
  //
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  selectedTableRow: null,
  setSelectedTableRow: (v) => {},
  // filter
  tableFilter: {
    page: 1
  },
  setTableFilter: (v) => {}
};

const SingleSaloonContext = createContext(INITIAL_VALUES);

export const SingleSaloonProvider = ({ children }) => {
  const [isLoadingData, setIsLoadingData] = useState(INITIAL_VALUES.isLoading);
  const [fetchDataCount, setFetchDataCount] = useState(
    INITIAL_VALUES.fetchDataCount
  );
  const [fetchedSaloonData, setFetchedSaloonData] = useState(
    INITIAL_VALUES.fetchedSaloonData
  );
  const [fetchedSaloonStores, setFetchedSaloonStores] = useState(
    INITIAL_VALUES.fetchedSaloonData
  );

  const [selectedTableRow, setSelectedTableRow] = useState(
    INITIAL_VALUES.selectedTableRow
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES.tablePagination
  );

  //

  //
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES.tableFilter);

  return (
    <SingleSaloonContext.Provider
      value={{
        isLoadingData,
        setIsLoadingData,
        fetchDataCount,
        setFetchDataCount,
        fetchedSaloonData,
        setFetchedSaloonData,
        fetchedSaloonStores,
        setFetchedSaloonStores,
        selectedTableRow,
        setSelectedTableRow,
        //
        tablePagination,
        setTablePagination,
        tableFilter,
        setTableFilter
      }}
    >
      {children}
    </SingleSaloonContext.Provider>
  );
};

export default SingleSaloonContext;
