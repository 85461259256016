/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import CartContext from '../../contexts/cart-context/CartContext';
import CustomStepperWrapContext from '../../contexts/custom-stepper-wrap-context/CustomStepperWrapContext';
import errorNotification from '../../utils/errorNotification';
import CartCard from './CartCard';
import DiscountCodeForm from './DiscountCodeForm';
import OrderSummery from './OrderSummery';

const CartStepOne = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { cartProducts } = useContext(CartContext);
  // const { step, setStep, isLastStep, childrenArray } = useContext(
  //   CustomStepperWrapContext
  // );
  const { setStep } = useContext(CustomStepperWrapContext);

  const handleClickNextStep = () => {
    let servicesWithInvalidDate = [];
    const handleFoundObjWithSameDateOrBefore = (obj) => {
      // check if before today
      // service date is before today then expired
      // service day is today but service time is before time now then expired
      if (
        obj?.time &&
        obj?.date &&
        // card date is before today
        (dayjs(obj.date, 'YYYY-MM-DD').isBefore(dayjs().startOf('day')) ||
          // card date is same date of today but the service time is before now time
          (dayjs(obj.date, 'YYYY-MM-DD').isSame(dayjs().startOf('day')) &&
            dayjs(obj.time, 'hh:mm a') < dayjs().startOf('minute')))
      ) {
        servicesWithInvalidDate.push(obj);
      }
    };

    if (cartProducts?.length > 0) {
      for (let obj of cartProducts) {
        handleFoundObjWithSameDateOrBefore(obj);
      }
    }

    //  check for every item in servicesWithinvalidDate arr
    // if this item in cartProducts array
    // then remove this obj from servicesWithInvalidDate arr
    if (servicesWithInvalidDate?.length >= 0) {
      if (cartProducts?.length === 0) servicesWithInvalidDate = [];
      else {
        for (let InvalidDateObj of servicesWithInvalidDate) {
          const foundInCartProducts = cartProducts.find(
            (item) => item?.id === InvalidDateObj?.id
          );
          if (!foundInCartProducts) {
            servicesWithInvalidDate.filter(
              (obj) => obj?.id !== InvalidDateObj?.id
            );
          }
        }
      }
    }

    if (servicesWithInvalidDate?.length > 0) {
      const errorMessage = () => {
        return (
          <div className="err-msgs-wrapper">
            {servicesWithInvalidDate.map((serv) => {
              return (
                <div key={serv?.id} className="msg-wrapper">
                  {t('userCartPage.serviceDate')} {serv?.product?.name}{' '}
                  {t('userCartPage.expired')}
                </div>
              );
            })}
          </div>
        );
      };
      errorNotification({
        title: t('errorTitle'),
        message: errorMessage()
      });
    } else {
      setStep((prev) => prev + 1);
    }
  };

  return (
    <div className="stepper-step cart-step-one">
      <div className="items-summery-wrap">
        <div className="cart-cards-wrap">
          {cartProducts.map((obj) => {
            return <CartCard card={obj} key={obj?.id} />;
          })}
        </div>
        <div
          style={{
            display: 'grid',
            alignContent: 'start',
            gap: 18
          }}
        >
          <OrderSummery />
          <DiscountCodeForm />

          <div className="btns-wrapper">
            <button
              className="checkout-btn"
              onClick={() => {
                handleClickNextStep();
              }}
            >
              {t('checkoutForm.checkoutBtn')}
            </button>
            <button
              onClick={() => {
                navigate(-1);
              }}
              className="back-btn"
              to={userRouterLinks?.userCartRoute}
            >
              {t('checkoutForm.backShoppingLink')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartStepOne;
