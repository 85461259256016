/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import getAllAddressesApi from '../apis/my-addresses-apis/getAllAddressesApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useAddresses = () => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);
  const [fetchAddressesCount, setFetchAddressesCount] = useState(0);
  const [allFetchedAddresses, setAllFetchedAddresses] = useState(null);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;

    setIsLoadingAddresses(true);
    customApiRequest(
      getAllAddressesApi(null, user?.token, i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoadingAddresses(false);
          if (checkRes(res) && res?.data?.data) {
            setAllFetchedAddresses(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoadingAddresses(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchAddressesCount]);

  return {
    isLoadingAddresses,
    setIsLoadingAddresses,
    //
    fetchAddressesCount,
    setFetchAddressesCount,
    //
    allFetchedAddresses,
    setAllFetchedAddresses
  };
};

export default useAddresses;
