const newProductFormDefaultValues = {
  name: '',
  cat_id: '',
  catFilter_id: '',
  price: '',
  inStock: '', // 1 => yes, 2 => no
  refundOption: '', // 1 => 4
  hasCode: '', // if have code send 1
  codeAttach: null,
  codeType: '', // 1 => fixed type , 2 => percentage type
  fixedValue: '',
  percentageValue: '',
  discountStartDate: '',
  discountEndDate: '',
  // codeValue: '',
  //
  isSelectedServiceSetting: false,
  inStore: {
    checked: false,
    price: 0
  },
  insideCity: {
    checked: false,
    price: ''
  },
  outsideCity: {
    checked: false,
    price: ''
  },
  anotherCountry: {
    checked: false,
    price: ''
  },
  description_ar: '',
  description_en: '',
  productImages: null,
  logo: null,
  video: null
};

export default newProductFormDefaultValues;
