import React from 'react';

const CopyIcon = ({ color }) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFF0F6" />
      <g clipPath="url(#clip0_1609_45462)">
        <path
          d="M25.25 34C26.907 33.998 28.4956 33.3389 29.6672 32.1672C30.8389 30.9956 31.498 29.407 31.5 27.75V16.8038C31.5019 16.1467 31.3735 15.4958 31.122 14.8888C30.8705 14.2818 30.501 13.7307 30.035 13.2675L27.2325 10.465C26.7693 9.99899 26.2183 9.62953 25.6112 9.37805C25.0042 9.12657 24.3533 8.99808 23.6962 9.00002H17.75C16.093 9.00201 14.5044 9.66112 13.3328 10.8328C12.1611 12.0045 11.502 13.593 11.5 15.25V27.75C11.502 29.407 12.1611 30.9956 13.3328 32.1672C14.5044 33.3389 16.093 33.998 17.75 34H25.25ZM14 27.75V15.25C14 14.2555 14.3951 13.3016 15.0983 12.5984C15.8016 11.8951 16.7554 11.5 17.75 11.5C17.75 11.5 23.8988 11.5175 24 11.53V14C24 14.6631 24.2634 15.2989 24.7322 15.7678C25.2011 16.2366 25.837 16.5 26.5 16.5H28.97C28.9825 16.6013 29 27.75 29 27.75C29 28.7446 28.6049 29.6984 27.9017 30.4017C27.1984 31.1049 26.2446 31.5 25.25 31.5H17.75C16.7554 31.5 15.8016 31.1049 15.0983 30.4017C14.3951 29.6984 14 28.7446 14 27.75ZM36.5 19V32.75C36.498 34.407 35.8389 35.9956 34.6672 37.1672C33.4956 38.3389 31.907 38.998 30.25 39H19C18.6685 39 18.3505 38.8683 18.1161 38.6339C17.8817 38.3995 17.75 38.0815 17.75 37.75C17.75 37.4185 17.8817 37.1006 18.1161 36.8661C18.3505 36.6317 18.6685 36.5 19 36.5H30.25C31.2446 36.5 32.1984 36.1049 32.9017 35.4017C33.6049 34.6984 34 33.7446 34 32.75V19C34 18.6685 34.1317 18.3506 34.3661 18.1161C34.6005 17.8817 34.9185 17.75 35.25 17.75C35.5815 17.75 35.8995 17.8817 36.1339 18.1161C36.3683 18.3506 36.5 18.6685 36.5 19Z"
          fill="#FB2E86"
        />
      </g>
      <defs>
        <clipPath id="clip0_1609_45462">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(9 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;
