import React from 'react';

const EyeClosedIcon = ({ color, ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="25.68"
      height="22.034"
      viewBox="0 0 25.68 22.034"
    >
      <path
        id="Path_2516"
        d="M25.505,44.4a24.35,24.35,0,0,0-5.547-5.282L23.578,35.5A.917.917,0,1,0,22.3,34.184l-.023.023-3.954,3.95a11.86,11.86,0,0,0-5.486-1.467C5.87,36.689.4,44.089.176,44.4a.917.917,0,0,0,0,1.079,24.351,24.351,0,0,0,5.547,5.282L2.1,54.384A.917.917,0,1,0,3.378,55.7L3.4,55.68l3.954-3.95A11.86,11.86,0,0,0,12.84,53.2c6.97,0,12.436-7.4,12.665-7.715A.917.917,0,0,0,25.505,44.4ZM7.056,49.434a22.837,22.837,0,0,1-4.97-4.49c1.35-1.635,5.728-6.42,10.755-6.42a9.708,9.708,0,0,1,4.117,1.009l-1.592,1.592a4.578,4.578,0,0,0-6.347,6.347Zm8.261-5.669a2.706,2.706,0,0,1,.275,1.179,2.751,2.751,0,0,1-2.751,2.751,2.706,2.706,0,0,1-1.179-.275Zm-4.953,2.357a2.706,2.706,0,0,1-.275-1.179,2.751,2.751,0,0,1,2.751-2.751,2.706,2.706,0,0,1,1.179.275Zm2.476,5.242a9.708,9.708,0,0,1-4.117-1.009l1.592-1.592a4.578,4.578,0,0,0,6.347-6.347l1.963-1.963a22.838,22.838,0,0,1,4.97,4.49C22.245,46.579,17.866,51.363,12.84,51.363Z"
        transform="translate(0 -33.926)"
        fill={color ? color : '#9a9a9a'}
      />
    </svg>
  );
};

export default EyeClosedIcon;
