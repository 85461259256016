/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
  hasDiscountValues,
  offerTypes
} from '../../../const-values/leftedCartOptions';

const lefetedCartValidationSchema = ({ t, selectedTableRow }) => {
  const sharedRequiredErr = (key) => t(`leftedCartForm.${key}.errors.required`);
  const sharedNumberOnlyErr = (key) =>
    t(`leftedCartForm.${key}.errors.numbersOnly`);
  return yupResolver(
    Yup.object().shape({
      offerType: Yup.string().required(sharedRequiredErr('discountType')),
      staticValue: Yup.string()
        .test('staticValue', sharedRequiredErr('staticPrice'), (v, context) => {
          let result = true;
          if (!v && context?.parent?.offerType == offerTypes?.static)
            result = false;
          return result;
        })
        .test(
          'staticValue',
          sharedNumberOnlyErr('staticPrice'),
          (v, context) => {
            let result = true;
            if (
              context?.parent?.offerType == offerTypes?.static &&
              v &&
              !v.match(/^(\d+)?(\.\d+)?$/)
            )
              result = false;
            return result;
          }
        )
        .test('staticValue', t('notValidValue'), (v, context) => {
          let result = true;
          if (selectedTableRow?.product?.price) {
            const hasDiscountCode =
              selectedTableRow?.product?.hasCode == hasDiscountValues?.yes;
            const productPrice = parseFloat(selectedTableRow.product.price);
            const productPriceAfterDiscount = selectedTableRow?.product
              ?.priceOffer
              ? parseFloat(selectedTableRow.product.priceOffer)
              : productPrice;

            if (
              v &&
              v.match(/^(\d+)?(\.\d+)?$/) &&
              (parseFloat(v) >= productPrice ||
                (hasDiscountCode && parseFloat(v) >= productPriceAfterDiscount))
            )
              result = false;
            else result = true;
          }

          return result;
        }),

      percentageValue: Yup.string()
        .test(
          'percentageValue',
          sharedRequiredErr('percentagePrice'),
          (v, context) => {
            let result = true;
            if (!v && context?.parent?.offerType == offerTypes?.percentage)
              result = false;
            return result;
          }
        )
        .test(
          'percentageValue',
          sharedNumberOnlyErr('percentagePrice'),
          (v, context) => {
            let result = true;
            if (
              context?.parent?.offerType == offerTypes?.percentage &&
              v &&
              !v.match(/^(\d+)?(\.\d+)?$/)
            )
              result = false;
            return result;
          }
        )
        .test('percentageValue', t('notValidValue'), (v, context) => {
          let result = true;
          if (selectedTableRow?.product?.price) {
            const hasDiscountCode =
              selectedTableRow?.product?.hasCode == hasDiscountValues?.yes;
            const isPercentageDiscountCodeType =
              selectedTableRow?.product?.codeType == offerTypes?.percentage;
            const codeValue = selectedTableRow?.product?.codeValue;

            if (
              v &&
              v.match(/^(\d+)?(\.\d+)?$/) &&
              (parseFloat(v) >= 100 ||
                (hasDiscountCode &&
                  isPercentageDiscountCodeType &&
                  parseFloat(v) + parseFloat(codeValue) >= 100))
            )
              result = false;
            else result = true;
          }

          return result;
        }),
      notificationType: Yup.string().required(
        sharedRequiredErr('notifications')
      ),
      offerEndDate: Yup.date()
        .typeError(sharedRequiredErr('offerEndDate'))
        .required(sharedRequiredErr('offerEndDate'))
    })
  );
};

export default lefetedCartValidationSchema;
