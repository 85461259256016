import React from 'react';

const MobileIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1074_4180)">
          <path
            d="M11.25 0H6.75C5.7558 0.00119089 4.80267 0.396661 4.09966 1.09966C3.39666 1.80267 3.00119 2.7558 3 3.75V14.25C3.00119 15.2442 3.39666 16.1973 4.09966 16.9003C4.80267 17.6033 5.7558 17.9988 6.75 18H11.25C12.2442 17.9988 13.1973 17.6033 13.9003 16.9003C14.6033 16.1973 14.9988 15.2442 15 14.25V3.75C14.9988 2.7558 14.6033 1.80267 13.9003 1.09966C13.1973 0.396661 12.2442 0.00119089 11.25 0V0ZM6.75 1.5H11.25C11.8467 1.5 12.419 1.73705 12.841 2.15901C13.2629 2.58097 13.5 3.15326 13.5 3.75V12H4.5V3.75C4.5 3.15326 4.73705 2.58097 5.15901 2.15901C5.58097 1.73705 6.15326 1.5 6.75 1.5ZM11.25 16.5H6.75C6.15326 16.5 5.58097 16.2629 5.15901 15.841C4.73705 15.419 4.5 14.8467 4.5 14.25V13.5H13.5V14.25C13.5 14.8467 13.2629 15.419 12.841 15.841C12.419 16.2629 11.8467 16.5 11.25 16.5Z"
            fill={color ? color : '#C4C4C4'}
          />
          <path
            d="M9 15.75C9.41422 15.75 9.75001 15.4142 9.75001 15C9.75001 14.5858 9.41422 14.25 9 14.25C8.58579 14.25 8.25 14.5858 8.25 15C8.25 15.4142 8.58579 15.75 9 15.75Z"
            fill={color ? color : '#C4C4C4'}
          />
        </g>
        <defs>
          <clipPath id="clip0_1074_4180">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default MobileIcon;
