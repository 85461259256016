/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../contexts/user-context/UserProvider';
import './ReportClientForm.scss';
import StoreOrdersContext from '../../../contexts/store-orders-context/StoreOrdersContext';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import reportClientApi from '../../../apis/stores-apis/reportClientApi';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../utils/errorNotification';

const ReportClientForm = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    setReportClientModalOpened,
    selectedOrder,
    setIsReporting,
    setFetchOrdersCount
  } = useContext(StoreOrdersContext);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        complaint: Yup.string().required(
          t('ordersPage.complaint.errors.required')
        )
      })
    ),
    mode: 'all',
    defaultValues: {
      complaint: ''
    }
  });

  const customApiRequest = useCustomApiRequest();

  const onSubmit = (data) => {
    const formData = new FormData();
    if (selectedOrder) {
      formData.append('cart_id', selectedOrder?.id);
      formData.append('complaint', data?.complaint);
    }
    setIsReporting(true);
    // setIsLoadSingleProduct(true);
    customApiRequest(
      reportClientApi(formData, user?.token, i18n.language),
      (res) => {
        setIsReporting(false);
        // setIsLoadSingleProduct(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: 'تم تقديم الشكوى بنجاح'
          });
          setFetchOrdersCount((prev) => prev + 1);
          setReportClientModalOpened(false);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsReporting(false);
        // setIsLoadSingleProduct(false);
        errorNotification({
          title: t('errorTitle'),
          message: 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="report-client-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-area-label-wrap">
            <p className="label-p">{t('ordersPage.complaint.label')}</p>
            <div className="text-field-wrap">
              <AntdTextarea
                className="form-text-area white-bg"
                name="complaint"
                rows={4}
                placeholder={t('ordersPage.complaint.label')}
                errorMsg={errors?.complaint?.message}
                validateStatus={errors?.complaint ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // loading={isSubmittingForm}
        >
          {t('ordersPage.submitReport')}
        </Button>
      </Form>
    </>
  );
};

export default ReportClientForm;
