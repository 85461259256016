export const sortValues = {
  asc: 1,
  desc: 2
};
export const sortArr = (t) => {
  const sharedT = (key) => t(`sort.${key}`);
  return [
    {
      id: 1,
      value: sortValues?.asc,
      name: sharedT('asc')
    },
    {
      id: 2,
      value: sortValues?.desc,
      name: sharedT('desc')
    }
  ];
};
