/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Pagination } from 'antd';
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import allSaloonsApi from '../../apis/general/allSaloonsApi';
import ArchHeading from '../../common/arch-heading/ArchHeading';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../components/app/routerLinks';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import CitiesSelectionFilter from '../../components/cities-selection-filter';
import SaloonsContext from '../../contexts/saloons-context/SaloonsContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import './AllSaloonsPage.scss';
import SaloonCard from './SaloonCard';

const AllSaloonsPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    isLoadingTableData,
    setIsLoadingTableData,
    allFetchedTableData,
    setAllFetchedTableData,
    tableFilter,
    setTablePagination,
    setFetchTableDataCount,
    fetchTableDataCount,
    tablePagination
  } = useContext(SaloonsContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      allSaloonsApi(
        {
          ...tableFilter,
          ...values
        },
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedTableData(data);
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, i18n.language, search]);

  const renderSaloonsList = () => {
    if (isLoadingTableData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center',
            minHeight: 332
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 24, width: 24, height: 24 }}
            spin
          />
        </div>
      );
    } else if (allFetchedTableData?.length === 0 || !allFetchedTableData) {
      return <Empty description={false}>No Saloons Found!!!</Empty>;
    } else if (allFetchedTableData?.length > 0) {
      return (
        <div className="list-pagination-wrap">
          <div className="saloons-wrap">
            {allFetchedTableData.map((obj) => (
              <SaloonCard key={obj?.id} obj={obj} />
            ))}
          </div>
          {tablePagination?.total > 0 && (
            <Pagination
              locale={{
                jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                page: ''
              }}
              showSizeChanger={false}
              className="saloons-pagination"
              defaultCurrent={1}
              current={tablePagination.current_page}
              pageSize={tablePagination.per_page}
              total={tablePagination.total}
              // itemRender={itemRender}
              onChange={(page, pageSize) => {
                setFetchTableDataCount((prev) => prev + 1);
                navigate(`${userRouterLinks?.allSaloonsRoute}?page=${page}`);
              }}
              hideOnSinglePage={true}
            />
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="all-saloons-page">
      <div className="breadcrumb-title">
        <CustomBreadcrumb
          arr={[
            {
              title: t('main_app_bar_links.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('main_app_bar_links.all_saloons'),
              isLink: false
            }
          ]}
        />
      </div>

      <div className="custom-container">
        <div className="arch-header-filter-wrapper">
          <ArchHeading title={t('main_app_bar_links.all_saloons')} />

          <CitiesSelectionFilter
            onChange={(v) => {
              navigate(
                `${routerLinks?.allSaloonsRoute}?city_id=${v || ''}&page=${
                  values?.page || 1
                }`
              );
            }}
          />
        </div>

        {renderSaloonsList()}
      </div>
    </div>
  );
};

export default AllSaloonsPage;
