/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomSharedBtn from '../custom-shared-button/CustomSharedBtn';
import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';

import './ReactHookFormStepper.scss';

const ReactHookFormStepper = ({
  children,
  sideWrapper,
  className,
  defaultValues,
  fetchedDefaultValues,
  isSubmittingForm,
  step,
  setStep,
  ...props
}) => {
  const childrenArray = React.Children.toArray(children);
  const { i18n, t } = useTranslation();
  // const [step, setStep] = useState(0);
  let currentChild;
  let currentStep;
  if (step < 2) {
    currentChild = childrenArray[step];
    currentStep = children[step]?.props?.step;
  }
  // const currentChild = childrenArray[step];
  // const currentStep = children[step]?.props?.step;
  const isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  const methods = useForm({
    resolver: currentChild?.props.validationSchema
      ? yupResolver(currentChild?.props.validationSchema)
      : null,
    mode: 'all',
    defaultValues
    // shouldUnregister: false
  });

  // handle initial values
  React.useEffect(() => {
    if (fetchedDefaultValues) {
      methods.reset({
        ...fetchedDefaultValues
      });
    }
  }, [fetchedDefaultValues]);

  // if errors object is empty then its a valid step
  const validStep = Object.keys(methods.formState.errors).length === 0;
  const onSubmit = async (data) => {
    // console.log('data :', data);
    if (isLastStep()) {
      await props.onSubmit(data);
    } else {
      setStep((currState) => currState + 1);
    }
  };

  const [form] = Form.useForm();

  return (
    <div className={`stepper-form-wrapper ${className ? className : ''}`}>
      {sideWrapper && (
        <div className="side-stepper-wrapper">
          {sideWrapper(childrenArray, currentStep, validStep)}
        </div>
      )}
      <FormProvider {...methods}>
        <Form
          {...props}
          form={form}
          className="stepper-form custom-shared-form"
          autoComplete="off"
          layout="vertical"
          onFinish={methods.handleSubmit(onSubmit)}
          // fields={[
          //   {
          //     name: 'roles',
          //     value: methods.watch('roles')
          //   }
          // ]}
        >
          <div className="form-body">{currentChild}</div>

          <div className={`stepper-btns ${i18n.dir()}`}>
            {step > 0 && step < 2 && (
              <CustomSharedBtn
                isButton
                type="button"
                onClick={() => setStep((currState) => currState - 1)}
              >
                <span>{t('prevStep')}</span>
              </CustomSharedBtn>
            )}
            {step < childrenArray.length && (
              <CustomSharedBtn isButton={true} type="submit">
                {/* {methods.formState.isSubmitting ? ( */}
                {isSubmittingForm ? (
                  <span className="img-msg">
                    <LoadingOutlined />
                    Saving...
                  </span>
                ) : (
                  <span>{`${isLastStep() ? t('finish') : t('nextStep')}`}</span>
                )}
              </CustomSharedBtn>
            )}
          </div>

          {/* <div style={{ direction: 'ltr', overflow: 'scroll' }}>
            <pre />
            <h1>Values</h1>
            <pre>{JSON.stringify(methods.watch(), null, 2)}</pre>
            <pre />
            <h1>Errors</h1>
						<pre>{JSON.stringify(errors, null, 2)}</pre>
						<pre />
						<h1>Touched</h1>
						<pre>{JSON.stringify(touched, null, 2)}</pre>
          </div> */}
        </Form>
      </FormProvider>
    </div>
  );
};

export default ReactHookFormStepper;
