/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import UserContext from '../../../contexts/user-context/UserProvider';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import UserIcon from '../../../common/icons/UserIcon';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import CustomMap from '../../../components/custom-map/CustomMap';
import useCountries from '../../../custom-hooks/useCountries';
import './NewAddressForm.scss';
import createNewAddressApi from '../../../apis/my-addresses-apis/createNewAddressApi';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import TextIcon from '../../../common/icons/TextIcon';
import { LoadingOutlined } from '@ant-design/icons';

const NewAddressForm = ({ fetchedAddress, isLoadingAddress }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { i18n, t } = useTranslation();
  const generalLabelStr = (v) => t(`newAddressForm.${v}.label`);
  const generalrequiredErrStr = (v) => t(`newAddressForm.${v}.errors.required`);
  // const generalTypeErrorStr = (v) => t(`newAddressForm.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`newAddressForm.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    address_ar: Yup.string().required(generalrequiredErrStr('address_ar')),
    address_en: Yup.string().required(generalrequiredErrStr('address_en')),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(10, generalMinErrorStr('phone', 10))
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    // setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      address_ar: '',
      address_en: '',
      phone: '',
      country_id: '',
      city_id: '',
      area_ar: '',
      area_en: '',
      streetName_ar: '',
      streetName_en: '',
      buildingNumber: ''
    }
  });

  const { user } = useContext(UserContext);

  const [selectedLocation, setSelecectedLocation] = React.useState({
    lat: '',
    lng: ''
  });
  const [selectedAddress, setSelectedAddress] = React.useState('');
  const { allFetchedCountries } = useCountries();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const [cancelCount, setCancelCount] = useState(0);
  // handle Initial values
  useEffect(() => {
    if (fetchedAddress && params?.addressId) {
      if (fetchedAddress?.name) {
        setValue('name', fetchedAddress.name);
      }
      if (fetchedAddress?.phone && fetchedAddress?.countryCode) {
        setValue(
          'phone',
          `${fetchedAddress.countryCode}${fetchedAddress.phone}`
        );
      } else {
        setValue('phone', fetchedAddress?.phone || '');
      }
      if (fetchedAddress?.country_id) {
        setValue('country_id', String(fetchedAddress.country_id));
      }
      if (fetchedAddress?.city_id) {
        setValue('city_id', String(fetchedAddress.city_id));
      }
      if (fetchedAddress?.area_ar) {
        setValue('area_ar', fetchedAddress.area_ar);
      }
      if (fetchedAddress?.area_en) {
        setValue('area_en', fetchedAddress.area_en);
      }
      if (fetchedAddress?.streetName_ar) {
        setValue('streetName_ar', fetchedAddress.streetName_ar);
      }
      if (fetchedAddress?.streetName_en) {
        setValue('streetName_en', fetchedAddress.streetName_en);
      }
      if (fetchedAddress?.address_ar) {
        setValue('address_ar', fetchedAddress.address_ar);
      }
      if (fetchedAddress?.address_en) {
        setValue('address_en', fetchedAddress.address_en);
      }
      if (fetchedAddress?.buildingNumber) {
        setValue('buildingNumber', fetchedAddress.buildingNumber);
      }
      setSelecectedLocation({
        lat: fetchedAddress.lat ? Number(fetchedAddress.lat) : '',
        lng: fetchedAddress.lng ? Number(fetchedAddress.lng) : ''
      });
    } else {
      reset({
        name: '',
        phone: '',
        country_id: '',
        city_id: '',
        area_ar: '',
        area_en: '',
        streetName_ar: '',
        streetName_en: '',
        address_ar: '',
        address_en: ''
      });
    }
  }, [fetchedAddress, cancelCount]);

  // handle cancel
  const handleCancel = () => {
    setCancelCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (watch('country_id') && !fetchedAddress) {
      const foundCountry = allFetchedCountries.find(
        (c) => c.id == watch('country_id')
      );

      if (foundCountry && foundCountry?.cities?.length >= 0) {
        const foundCity = foundCountry.cities.find(
          (city) => city.id == watch('city_id')
        );
        if (!foundCity) {
          setValue('city_id', '');
        }
      }
    }
  }, [watch('country_id'), watch('city_id')]);

  const handleCityOptions = (countryId) => {
    if (countryId && allFetchedCountries?.length > 0) {
      const foundCountry = allFetchedCountries.find((c) => c.id == countryId);
      if (foundCountry && foundCountry.cities?.length > 0) {
        return foundCountry.cities.map((city) => ({
          title: city?.name,
          value: String(city?.id)
        }));
      }
    }
    return [];
  };

  const onSubmit = (data) => {
    // if (!watch('image') && !systemImgUrl) {
    //   setError('image', {
    //     type: 'required',
    //     message: 'اختار صورة البروفايل'
    //   });
    // }

    const formData = new FormData();
    if (data.name) formData.append('name', data.name);
    const formatedIntlPhone = formatPhoneNumberIntl(data.phone); // '+966 50 123 4444'
    const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    const countryCode = phoneStrArr[0];
    let phone = '';
    if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    else if (phoneStrArr?.length > 2) {
      let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
      phone = array.join('');
    }
    if (data.phone) formData.append('phone', phone);
    if (data.phone) formData.append('countryCode', countryCode);
    if (data.country_id) formData.append('country_id', data.country_id);
    if (data.city_id) formData.append('city_id', data.city_id);
    if (data.area_ar) formData.append('area_ar', data.area_ar);
    if (data.area_en) formData.append('area_en', data.area_en);
    if (data.streetName_ar)
      formData.append('streetName_ar', data.streetName_ar);
    if (data.streetName_en)
      formData.append('streetName_en', data.streetName_en);
    if (data.address_ar) formData.append('address_ar', data.address_ar);
    if (data.address_en) formData.append('address_en', data.address_en);
    formData.append('buildingNumber', data.buildingNumber);
    if (selectedLocation?.lat) formData.append('lat', selectedLocation.lat);
    if (selectedLocation?.lng) formData.append('lng', selectedLocation.lng);

    setIsSubmittingForm(true);
    if (!fetchedAddress) {
      customApiRequest(
        createNewAddressApi(formData, user?.token, i18n.language),
        (res) => {
          setIsSubmittingForm(false);
          if (checkRes(res)) {
            successNotification({
              title: t('successTitle'),
              message: res?.data?.message || t('successMessage')
            });

            navigate(userRouterLinks?.myAddresses);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          errorNotification({
            title: t('errorTitle'),
            message: 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      formData.append('address_id', params?.addressId);
      customApiRequest(
        createNewAddressApi(formData, user?.token, i18n.language, true),
        (res) => {
          setIsSubmittingForm(false);
          if (checkRes(res)) {
            navigate(userRouterLinks?.myAddresses);
            successNotification({
              title: t('successTitle'),
              message: 'تم تعديل البيانات بنجاح'
            });
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          errorNotification({
            title: t('errorTitle'),
            message: 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();

  if (isLoadingAddress) {
    return (
      <div
        className="custom-container"
        style={{
          marginTop: 92,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    );
  }

  return (
    <Form
      className="custom-shared-form new-address-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-fields-wrap">
          <div className="addresses-wrapper">
            <AntdTextField
              name="address_ar"
              type="text"
              placeholder={generalLabelStr('address_ar')}
              label={generalLabelStr('address_ar')}
              errorMsg={errors?.address_ar?.message}
              validateStatus={errors?.address_ar ? 'error' : ''}
              prefix={<UserIcon />}
              control={control}
            />
            <AntdTextField
              name="address_en"
              type="text"
              placeholder={generalLabelStr('address_en')}
              label={generalLabelStr('address_en')}
              errorMsg={errors?.address_en?.message}
              validateStatus={errors?.address_en ? 'error' : ''}
              prefix={<UserIcon />}
              control={control}
            />
          </div>
          <div
            className={`country-code-phone-wrap ${i18n.dir()}`}
            style={{
              marginBottom: 28
            }}
          >
            <p
              style={{
                paddingBottom: 8
              }}
            >
              {generalLabelStr('phone')}
            </p>
            <Controller
              name="phone"
              control={control}
              // render={({ field: { onChange, onBlur, value, name, ref } }) => {
              render={({ field }) => {
                return (
                  <PhoneInput
                    {...field}
                    placeholder={generalLabelStr('phone')}
                    // value={phoneValue}
                    // onChange={setPhoneValue}
                    defaultCountry="SA"
                    className={`custom-phone-input ${i18n.language}`}
                  />
                );
              }}
            />
            <p className="error-p">{errors?.phone?.message}</p>
          </div>

          <div className="select-label-wrap">
            <p className="label-p">{generalLabelStr('country')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`country_id`}
                errorMsg={errors?.country_id && errors.country_id.message}
                validateStatus={errors?.country_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={generalLabelStr('country')}
                prefix={<UserIcon />}
                options={
                  allFetchedCountries?.length > 0 &&
                  allFetchedCountries.map((obj) => ({
                    title: obj?.name,
                    value: String(obj?.id)
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="new-address-form"
              />
            </div>
          </div>

          <div className="select-label-wrap">
            <p className="label-p">{generalLabelStr('city')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`city_id`}
                errorMsg={errors?.city_id && errors.city_id.message}
                validateStatus={errors?.city_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={generalLabelStr('city')}
                options={handleCityOptions(watch('country_id'))}
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="new-address-form"
              />
            </div>
          </div>

          <div className="area-wrapper">
            <AntdTextField
              name="area_ar"
              type="text"
              placeholder={generalLabelStr('area_ar')}
              label={generalLabelStr('area_ar')}
              errorMsg={errors?.area_ar?.message}
              validateStatus={errors?.area_ar ? 'error' : ''}
              prefix={<TextIcon />}
              control={control}
            />
            <AntdTextField
              name="area_en"
              type="text"
              placeholder={generalLabelStr('area_en')}
              label={generalLabelStr('area_en')}
              errorMsg={errors?.area_en?.message}
              validateStatus={errors?.area_en ? 'error' : ''}
              prefix={<TextIcon />}
              control={control}
            />
          </div>
          <div className="streetNamesWrapper">
            <AntdTextField
              name="streetName_ar"
              type="text"
              placeholder={generalLabelStr('streetName_ar')}
              label={generalLabelStr('streetName_ar')}
              errorMsg={errors?.streetName_ar?.message}
              validateStatus={errors?.streetName_ar ? 'error' : ''}
              prefix={<TextIcon />}
              control={control}
            />
            <AntdTextField
              name="streetName_en"
              type="text"
              placeholder={generalLabelStr('streetName_en')}
              label={generalLabelStr('streetName_en')}
              errorMsg={errors?.streetName_en?.message}
              validateStatus={errors?.streetName_en ? 'error' : ''}
              prefix={<TextIcon />}
              control={control}
            />
          </div>
          <AntdTextField
            name="buildingNumber"
            type="text"
            placeholder={generalLabelStr('buildingNumber')}
            label={generalLabelStr('buildingNumber')}
            errorMsg={errors?.buildingNumber?.message}
            validateStatus={errors?.buildingNumber ? 'error' : ''}
            prefix={<TextIcon />}
            control={control}
          />
        </div>
        <CustomMap
          width="100%"
          height="400px"
          selectedLocation={selectedLocation}
          setSelecectedLocation={setSelecectedLocation}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          isDraggable
          hasAutoComplete
        />
      </div>
      <div className="save-cancel-btns">
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('newAddressForm.submit_btn.label')}
        </Button>
        <button onClick={handleCancel} type="button" className="cancel-btn">
          {t('user_settings_form.cancelBtn')}
        </button>
      </div>
      {isSubmittingForm && <LoadingModal />}
    </Form>
  );
};

export default NewAddressForm;
