import axios from 'axios';

const getAllStoreOrdersApi = async (paramsFilter, token, langIsoCode) => {
  try {
    const url = `/SaloonOrder/getSaloonOrders?store_id=${
      paramsFilter?.store_id
    }&status=${paramsFilter?.status ? paramsFilter.status : ''}&page=${
      paramsFilter?.page ? paramsFilter.page : ''
    }`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllStoreOrdersApi;
