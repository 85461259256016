/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import { LoadingOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import routerLinks from '../../../components/app/routerLinks';
import { Empty } from 'antd';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import UserContext from '../../../contexts/user-context/UserProvider';
import checkRes from '../../../utils/checkRes';
import './StoreLeftedCartPage.scss';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import StoreLeftedCartContext from '../../../contexts/store-lefted-cart-context/StoreLeftedCartContext';
import getSingleLeftedCartItemApi from '../../../apis/stores-apis/getSinlgeLeftedCartItemApi';
import UserCard from './userCard';
import SharedAntdTable from '../../../common/shared-antd-table/SharedAntdTable';
import leftedCartProductsTableColumns from './leftedCartProductsTableColumns';
import LeftedCartOfferFormModal from './LeftedCartOfferFormModal';
import './LeftedCartSinglePage.scss';

const LeftedCartSinglePage = () => {
  const { user } = useContext(UserContext);
  const params = useParams();
  const {
    isLoading,
    setIsLoading,
    filter,
    fetchCount,
    setFetchCount,
    formModalOpened,
    setSelectedTableRow,
    setFormModalOpened,
    singleLefetedCart,
    setSingleLeftedCart
  } = useContext(StoreLeftedCartContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);

  const customApiRequest = useCustomApiRequest();
  //
  // select request user or merchent

  //

  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    customApiRequest(
      getSingleLeftedCartItemApi(
        {
          ...filter,
          ...values,
          store_id: params?.store_id,
          user_id: params?.cart_id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setSingleLeftedCart(data);
          } else {
            setSingleLeftedCart(null);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount, filter, search]);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (singleLefetedCart?.cart?.length >= 0) {
      const mappedData = mapDataSource(singleLefetedCart?.cart);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, singleLefetedCart]);

  const renderProductsTable = () => {
    return (
      <SharedAntdTable
        scroll={900}
        className="lefted-cart-products-table"
        tableDataSource={tableDataSource}
        tableColumns={leftedCartProductsTableColumns}
        allFetchedTableData={singleLefetedCart?.cart}
        setFormModalOpened={setFormModalOpened}
        // setDetailsModalOpened={setDetailsModalOpened}
        setFetchTableDataCount={setFetchCount}
        isLoadingTableData={isLoading}
        setIsLoadingTableData={setIsLoading}
        setSelectedTableRow={setSelectedTableRow}
        user={user}
        emptyText={
          i18n.language === 'ar'
            ? 'لا توجد خدمات معلقة من العميل'
            : 'No lefted services from the client'
        }
      />
    );
  };

  const renderPageContent = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            padding: '132px 0',
            margin: '32px auto'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (!singleLefetedCart || singleLefetedCart?.cart?.length === 0)
      return (
        <div className="custom-cotainer">
          <div
            style={{
              display: 'grid',
              padding: '52px 22px'
            }}
          >
            <Empty description={false}>
              {i18n.language === 'ar'
                ? 'لا توجد خدمات معلقة من العميل'
                : 'No lefted services from the client'}
            </Empty>
          </div>
        </div>
      );
    else if (singleLefetedCart && singleLefetedCart?.cart?.length > 0) {
      return (
        <div className="lefted-cart-single-page">
          <div className="table-title-wrap">
            <div className="user-card-main-content">
              <UserCard user={singleLefetedCart} />

              <div className="main-content">{renderProductsTable()}</div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="store-lefted-cart-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: t('merchentDashboard.home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: t('merchentDashboard.dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: t('merchentDashboard.leftedCartReport'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-lefted-cart-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">
              {t('merchentDashboard.leftedCartReport')}
            </div>
          </div>
        </div>

        <div className="page-body">{renderPageContent()}</div>
      </div>
      {formModalOpened && <LeftedCartOfferFormModal />}
    </div>
  );
};

export default LeftedCartSinglePage;
