import { Modal } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import EmployeesContext from '../../../contexts/employees-context/EmployeesContext';
import StoresContext from '../../../contexts/stores-context/StoresContext';
import NewEmployeeForm from './NewEmployeeForm';

const NewEmployeeModal = ({ selectedStore }) => {
  const {
    formModalOpened,
    setFormModalOpened,
    selectedTableRow,
    setSelectedTableRow
  } = useContext(EmployeesContext);
  const { setSelectedTableRow: setSelectedStoreCard } =
    useContext(StoresContext);
  const { t } = useTranslation();
  const sharedT = (key) => t(`employees.${key}`);
  return (
    <Modal
      className="shared-custom-modal new-employee-modal"
      width="90%"
      style={{ maxWidth: '842px' }}
      title={
        selectedTableRow ? sharedT('editEmployee') : sharedT('newEmployee')
      }
      open={formModalOpened}
      onOk={() => {
        setFormModalOpened(false);
        setSelectedTableRow(null);
        setSelectedStoreCard(null);
      }}
      onCancel={() => {
        setFormModalOpened(false);
        setSelectedTableRow(null);
        setSelectedStoreCard(null);
      }}
      footer={false}
    >
      <NewEmployeeForm />
    </Modal>
  );
};

export default NewEmployeeModal;
