import { Link as RouterLink, useParams } from 'react-router-dom';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import CustomImage from '../../../common/custom-image/CustomImage';
import { Rate } from 'antd';
import { useTranslation } from 'react-i18next';
import './StatisticsProductCard.scss';

const StatisticsProductCard = ({ card }) => {
  const params = useParams();
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`store-statistics-product-card ${i18n.dir()}`}
      // to={merchentRouterLinks?.storeEditProduct(params?.store_id, card?.id)}
    >
      <CustomImage className="product-img" src={card?.logo} />

      <div className="product-data">
        <p className="bold-font">{card?.name}</p>
        <p className="cat-catFilter">
          {card?.cat} {card?.catFilter ? `- ${card?.catFilter}` : ''}
        </p>

        <div className="stars-wrap">
          <div className="stars-itself">
            <Rate disabled allowHalf defaultValue={card?.rate} />
          </div>
          <div className="count-wrap">({card?.rate})</div>
        </div>

        <p className="bold-font price-p">
          {card?.price} {t('currency.sar')}
        </p>

        <p className="sale-count-p">
          {card?.saleCount} {t('storeStatistics.saleCount')}
        </p>
      </div>
    </div>
  );
};

export default StatisticsProductCard;
