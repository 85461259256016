import { useState } from 'react';
import ShareIcon from '../../common/icons/ShareIcon';
import CustomShareModal from './CustomShareModal';

const CustomShareBtn = ({
  obj,
  className,
  icon,
  children,
  disabled = false
}) => {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <div className={`custom-share-btn1`}>
        <button
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            setModalOpened(true);
          }}
          type="button"
          className={`action-btn-itself ${className || ''}`}
        >
          {icon ? icon : <ShareIcon color="#C4C4C4" />}
          {children}
        </button>
      </div>
      <CustomShareModal
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
        obj={obj}
      />
    </>
  );
};

export default CustomShareBtn;
