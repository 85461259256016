const affiliateFormDefaultValues = {
  name: '',
  email: '',
  country_id: '',
  city_id: '',
  otherInfo: '',
  feesType: '',
  feesValue: '',
  feesPercentage: '',
  firstOrEach: ''
};

export default affiliateFormDefaultValues;
