/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import UserContext from '../../../contexts/user-context/UserProvider';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import { useParams } from 'react-router-dom';
import UserIcon from '../../../common/icons/UserIcon';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import affiliateFormDefaultValues from './newAffiliateFormDefaultValues';
import getValidationSchema from './newAffiliateFormValidationSchema';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import EmailIcon from '../../../common/icons/EmailIcon';
import useCountries from '../../../custom-hooks/useCountries';
import {
  affiliatFeesTypes,
  firstOrEach
} from '../../../const-values/affiliateOptions';
import './NewAffiliateForm.scss';
import AffiliateContext from '../../../contexts/affiliate-context/AffiliateContext';
import createNewAgentApi from '../../../apis/stores-apis/createNewAgentApi';
// import useFirebaseDeviceToken from '../../custom-hooks/useFirebaseDeviceToken';

const baseLabelTransString = (t, key) => t(`affiliateForm.${key}.label`);

const NewAffiliateForm = () => {
  const { i18n, t } = useTranslation();
  const {
    setIsSubmittingForm,
    isSubmittingForm,
    setFetchTableDataCount,
    setFormModalOpened,
    selectedTableRow
  } = useContext(AffiliateContext);
  const { user } = useContext(UserContext);
  const params = useParams();
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(getValidationSchema(null, t)),
    mode: 'all',
    defaultValues: affiliateFormDefaultValues
  });

  useEffect(() => {
    if (watch('service_id')) {
      setValue('servicePrice_id', '');
    }
  }, [watch('service_id')]);

  const customApiRequest = useCustomApiRequest();

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name', selectedTableRow?.name || '');
      setValue('email', selectedTableRow?.email || '');
      setValue('country_id', String(selectedTableRow?.country_id || ''));
      setValue('city_id', String(selectedTableRow?.city_id || ''));
      setValue('otherInfo', selectedTableRow?.otherInfo || '');
      setValue('feesType', selectedTableRow?.discountType || '');
      if (selectedTableRow?.discountType == affiliatFeesTypes?.static)
        setValue('feesValue', selectedTableRow?.discountValue || '');
      else if (selectedTableRow?.discountType == affiliatFeesTypes?.percentage)
        setValue('feesPercentage', selectedTableRow?.discountValue || '');

      setValue('firstOrEach', String(selectedTableRow?.firstOrEach || ''));
    }
  }, [selectedTableRow]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('store_id', params?.store_id);
    formData.append('product_id', params?.product_id);
    if (data.name) formData.append('name', data.name);
    if (data.email) formData.append('email', data.email);
    if (data.country_id) formData.append('country_id', data.country_id);
    if (data.city_id) formData.append('city_id', data.city_id);
    if (data.otherInfo) formData.append('otherInfo', data.otherInfo);
    if (data.feesType) formData.append('discountType', data.feesType);
    if (data.feesType == affiliatFeesTypes?.static)
      formData.append('discountValue', data.feesValue);
    else if (data.feesType == affiliatFeesTypes?.percentage)
      formData.append('discountValue', data.feesPercentage);
    if (data.firstOrEach) formData.append('firstOrEach', data.firstOrEach);

    setIsSubmittingForm(true);
    if (!selectedTableRow) {
      customApiRequest(
        createNewAgentApi(formData, user?.token, i18n.language),
        (res) => {
          setIsSubmittingForm(false);
          if (checkRes(res)) {
            reset(affiliateFormDefaultValues);
            successNotification({
              title: t('successTitle'),
              message: res?.data?.message || 'تمت الاضافة بنجاح'
            });
            setFetchTableDataCount((prev) => prev + 1);
            setFormModalOpened(false);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          errorNotification({
            title: t('errorTitle'),
            message: 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      formData.append('agent_id', selectedTableRow.id);
      customApiRequest(
        createNewAgentApi(formData, user?.token, i18n.language, true),
        (res) => {
          setIsSubmittingForm(false);
          if (checkRes(res)) {
            reset(affiliateFormDefaultValues);
            successNotification({
              title: t('successTitle'),
              message: res?.data?.message || 'تم التعديل بنجاح'
            });
            setFetchTableDataCount((prev) => prev + 1);
            setFormModalOpened(false);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          errorNotification({
            title: t('errorTitle'),
            message: 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const { allFetchedCountries } = useCountries();
  const handleCityOptions = (countryId) => {
    if (countryId && allFetchedCountries?.length > 0) {
      const foundCountry = allFetchedCountries.find((c) => c.id == countryId);
      if (foundCountry && foundCountry.cities?.length > 0) {
        return foundCountry.cities.map((city) => ({
          title: city?.name,
          value: String(city?.id)
        }));
      }
    }
    return [];
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="new-affiliate-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="email-name-wrap">
            <AntdTextField
              name="email"
              type="text"
              placeholder={baseLabelTransString(t, 'email')}
              label={baseLabelTransString(t, 'email')}
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              prefix={<EmailIcon />}
              control={control}
            />

            <AntdTextField
              name="name"
              type="text"
              placeholder={baseLabelTransString(t, 'name')}
              label={baseLabelTransString(t, 'name')}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              prefix={<UserIcon />}
              control={control}
            />
          </div>

          <div className="select-label-wrap">
            <p className="label-p">{baseLabelTransString(t, 'country')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`country_id`}
                errorMsg={errors?.country_id && errors.country_id.message}
                validateStatus={errors?.country_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={baseLabelTransString(t, 'country')}
                prefix={<UserIcon />}
                options={
                  allFetchedCountries?.length > 0 &&
                  allFetchedCountries.map((obj) => ({
                    title: obj?.name,
                    value: String(obj?.id)
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="new-affiliate-form"
              />
            </div>
          </div>

          <div className="select-label-wrap">
            <p className="label-p">{baseLabelTransString(t, 'city')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`city_id`}
                errorMsg={errors?.city_id && errors.city_id.message}
                validateStatus={errors?.city_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={baseLabelTransString(t, 'city')}
                options={handleCityOptions(watch('country_id'))}
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="new-affiliate-form"
              />
            </div>
          </div>

          <AntdTextField
            name="otherInfo"
            type="text"
            placeholder={baseLabelTransString(t, 'additionalInfo')}
            label={baseLabelTransString(t, 'additionalInfo')}
            errorMsg={errors?.otherInfo?.message}
            validateStatus={errors?.otherInfo ? 'error' : ''}
            prefix={<UserIcon />}
            control={control}
          />

          <div className="affiliate-info-section">
            <h2>{t('affiliateForm.affiliateInfo.main')}</h2>
            <p>{t('affiliateForm.affiliateInfo.sub')}</p>
          </div>

          <div className="radios-wrap">
            <p className="radios-group-title">
              {baseLabelTransString(t, 'feesType')}
            </p>
            <div className="labels-wrap">
              <label
                className={`radio-input-label ${
                  String(watch(`feesType`)) == String(affiliatFeesTypes?.static)
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  type="radio"
                  value={affiliatFeesTypes?.static}
                  {...register(`feesType`)}
                />
                <span className={`label-span`}>
                  {baseLabelTransString(t, 'fixed')}
                </span>
              </label>
              <label
                className={`radio-input-label ${
                  String(watch(`feesType`)) ==
                  String(affiliatFeesTypes?.percentage)
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  type="radio"
                  value={affiliatFeesTypes?.percentage}
                  {...register(`feesType`)}
                />
                <span className={`label-span`}>
                  {baseLabelTransString(t, 'percentage')}
                </span>
              </label>
            </div>
            {errors?.feesType && (
              <p
                className="error-p"
                style={{
                  marginTop: 6
                }}
              >
                {errors?.feesType?.message}
              </p>
            )}
          </div>

          <div className="selected-radio-text-field-wrap">
            {watch('feesType') == affiliatFeesTypes?.static && (
              <AntdTextField
                name="feesValue"
                type="text"
                placeholder={baseLabelTransString(t, 'feesValue')}
                label={baseLabelTransString(t, 'feesValue')}
                errorMsg={errors?.feesValue?.message}
                validateStatus={errors?.feesValue ? 'error' : ''}
                prefix={<UserIcon />}
                control={control}
              />
            )}
            {watch('feesType') == affiliatFeesTypes?.percentage && (
              <AntdTextField
                name="feesPercentage"
                type="text"
                placeholder={baseLabelTransString(t, 'feesPercentage')}
                label={baseLabelTransString(t, 'feesPercentage')}
                errorMsg={errors?.feesPercentage?.message}
                validateStatus={errors?.feesPercentage ? 'error' : ''}
                prefix={<UserIcon />}
                control={control}
              />
            )}
          </div>

          <div className="radios-wrap">
            <p className="radios-group-title">
              {baseLabelTransString(t, 'firstOrEach')}
            </p>
            <div className="labels-wrap">
              <label
                className={`radio-input-label ${
                  String(watch(`firstOrEach`)) == String(firstOrEach?.first)
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  disabled={selectedTableRow}
                  type="radio"
                  value={firstOrEach?.first}
                  {...register(`firstOrEach`)}
                />
                <span className={`label-span`}>
                  {baseLabelTransString(t, 'first')}
                </span>
              </label>
              <label
                className={`radio-input-label ${
                  String(watch(`firstOrEach`)) == String(firstOrEach?.each)
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  disabled={selectedTableRow}
                  type="radio"
                  value={firstOrEach?.each}
                  {...register(`firstOrEach`)}
                />
                <span className={`label-span`}>
                  {baseLabelTransString(t, 'each')}
                </span>
              </label>
            </div>
            {errors?.firstOrEach && (
              <p
                className="error-p"
                style={{
                  marginTop: 6
                }}
              >
                {errors?.firstOrEach?.message}
              </p>
            )}
          </div>
          <div className="form-actions-btns">
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isSubmittingForm}
            >
              {!isSubmittingForm
                ? selectedTableRow
                  ? t(`affiliateForm.submitBtn.editLabel`)
                  : baseLabelTransString(t, 'submitBtn')
                : t(`affiliateForm.submitBtn.loading`)}
            </Button>

            <Button className="cancel-btn" htmlType="button" type="text">
              {t('leftedCartForm.cancelBtn')}
            </Button>
          </div>
        </div>

        {isSubmittingForm && <LoadingModal />}
      </Form>
    </>
  );
};

export default NewAffiliateForm;
