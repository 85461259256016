/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import SingleStoreContext from '../../contexts/single-store-context/SingleStoreContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import { LoadingOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import CustomImage from '../../common/custom-image/CustomImage';
import PinIcon from '../../common/icons/PinIcon';
import { Pagination } from 'antd';
import emptyImg from '../../assets/imgs/empty/empty.png';
import RenderStoreStatus from '../../common/render-store-status/RenderStoreStatus';
import productTypes from '../../const-values/productTypes';
import SingleStoreDeactivateModal from './SingleStoreDeactivateModal';
import ProductCard from '../merchent-pages/products-page/ProductCard';
import generalSingleStoreApi from '../../apis/general/generalSingleStoreApi';
import storeTermsImg from '../../assets/imgs/icons/store-terms.svg';
import './SingleStorePage.scss';
import StoreViewTermsModal from './StoreViewTermsModal';
import SortBy from '../../components/sort-by/SortBy';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import storeStatus from '../../const-values/storeStatus';
import EmptyCompoent from '../../common/empty-compoent/EmptyComponent';

const SingleStorePage = () => {
  const { user } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const params = useParams();
  const navigate = useNavigate();
  const {
    fetchedSingleStoreDetails,
    isLoadingSingleStoreDetails,
    setIsLoadingSingleStoreDetails,
    fetchSingleStoreDetailsCount,
    setFetchSingleStoreDetailsCount,
    setFetchedSingleStoreDetails,
    deactivateStoreModalOpened,
    setDeactivateStoreModalOpened,
    tablePagination,
    setTablePagination,
    tableFilter,
    setViewTermsModalOpened,
    viewTermsModalOpened
  } = useContext(SingleStoreContext);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingSingleStoreDetails(true);
    customApiRequest(
      generalSingleStoreApi(
        {
          ...tableFilter,
          ...values, // { page: ...}
          storeId: params?.storeId
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingSingleStoreDetails(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setFetchedSingleStoreDetails(data);
            if (
              res.data.data?.products?.data?.length > 0 &&
              res.data.data.products?.pagination
            ) {
              setTablePagination(res.data.data.products?.pagination);
            }
          } else {
            setFetchedSingleStoreDetails(null);
          }
        }
      },
      (error) => {
        setIsLoadingSingleStoreDetails(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchSingleStoreDetailsCount, search, params, i18n.language]);

  const handleChangeSort = (v) => {
    navigate(
      `${userRouterLinks?.singleStoreRoute(params?.storeId)}?sort=${v || ''}`
    );
  };

  const renderProductsCards = () => {
    if (fetchedSingleStoreDetails?.products?.data?.length > 0) {
      return (
        <>
          <div className="products-wrap">
            {fetchedSingleStoreDetails?.products?.data.map((obj) => (
              <ProductCard
                type={productTypes?.generalProduct}
                key={obj?.id}
                card={obj}
                setFetchParentCount={setFetchSingleStoreDetailsCount}
              />
            ))}
          </div>
          <div className="pagination-total-wrapper">
            {tablePagination && (
              <Pagination
                locale={{
                  jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                  // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                  page: ''
                }}
                showSizeChanger={false}
                defaultCurrent={1}
                current={tablePagination?.current_page}
                pageSize={tablePagination?.per_page}
                total={tablePagination?.total}
                // itemRender={itemRender}
                onChange={(page, pageSize) => {
                  // setFetchSingleStoreDetailsCount((prev) => prev + 1);
                  navigate(
                    `${userRouterLinks?.singleStoreRoute(
                      params?.storeId
                    )}?sort=${values?.sort || ''}&page=${page || 1}`
                  );
                }}
                hideOnSinglePage={true}
              />
            )}

            {tablePagination?.total > 0 ? (
              <div className="total-wrapper">
                <span>{t('totalResult.about')}</span>
                <span>{tablePagination.total}</span>
                <span>{t('totalResult.results')}</span>
              </div>
            ) : null}
          </div>
        </>
      );
    }
    return (
      <>
        <div className="shared-empty-wrapper">
          <img className="empty-img" src={emptyImg} alt="empty" />
          <p>لا توجد خدمات متاحة</p>
        </div>
      </>
    );
  };

  if (isLoadingSingleStoreDetails) {
    return (
      <div
        className="custom-container"
        style={{
          paddingTop: 92,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    );
  } else if (
    fetchedSingleStoreDetails &&
    (fetchedSingleStoreDetails?.storeData?.status == storeStatus?.active ||
      fetchedSingleStoreDetails?.storeData?.status == storeStatus?.notCompleted)
  ) {
    return (
      <div className="single-stores-details-page shared-dashboard-page shared-custom-page">
        <div className="store-details-content">
          <div
            className="store-cover-photo"
            style={{
              backgroundImage: `url(${fetchedSingleStoreDetails?.storeData?.cover})`
            }}
          ></div>
        </div>
        <div className="custom-container">
          <div className="logo-tab-bar">
            <div className="logo-name-wrap">
              <div className="logo-wrap">
                <CustomImage src={fetchedSingleStoreDetails?.storeData?.logo} />
              </div>
              <div className="name-income-wrap">
                <div className="name-status">
                  <div className="name">
                    {fetchedSingleStoreDetails?.storeData?.name}
                  </div>
                  <RenderStoreStatus
                    obj={fetchedSingleStoreDetails?.storeData}
                  />
                </div>
              </div>
            </div>

            <button
              className="terms-btn"
              onClick={() => setViewTermsModalOpened(true)}
            >
              {t('singleStoreDetailsPage.terms')}
              <img src={storeTermsImg} alt="terms" />
            </button>
          </div>

          <div className="desc-contact-info-wrap">
            {fetchedSingleStoreDetails?.storeData?.description ? (
              <div className="desc-wrap">
                <div className="title-wrap">
                  {t('singleStoreDetailsPage.details')}
                </div>
                <div className="desc-data">
                  {fetchedSingleStoreDetails.storeData.description}
                </div>
              </div>
            ) : (
              <div></div>
            )}

            <div className="contact-info-wrap">
              <div className="title-wrap">
                {t('singleStoreDetailsPage.contactInfo')}
              </div>
              <div className="contact-data">
                {fetchedSingleStoreDetails?.storeData?.country && (
                  <div className="icon-title-wrap">
                    <PinIcon />
                    <span>{t('singleStoreDetailsPage.countryName')} :</span>
                    {fetchedSingleStoreDetails.storeData?.country}
                  </div>
                )}
                {fetchedSingleStoreDetails?.storeData?.city && (
                  <div className="icon-title-wrap">
                    <PinIcon />
                    <span> {t('singleStoreDetailsPage.cityName')} : </span>
                    {fetchedSingleStoreDetails.storeData?.city}
                  </div>
                )}
                {fetchedSingleStoreDetails?.storeData?.area && (
                  <div className="icon-title-wrap">
                    <PinIcon />
                    <span>{t('singleStoreDetailsPage.areaName')} :</span>
                    {fetchedSingleStoreDetails.storeData?.area}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="custom-container">
          <div className="title-filter-wrapper">
            <div className="ps-title">
              {t('singleStoreDetailsPage.storeProducts')}
            </div>
            {fetchedSingleStoreDetails?.products?.data?.length > 0 && (
              <div className="filter-wrapper">
                <div className="title">{t('sort.sortBy')}</div>
                <SortBy
                  defaultValue={parseInt(values?.sort) || ''}
                  handleChangeSort={handleChangeSort}
                />
              </div>
            )}
          </div>
          {renderProductsCards()}
        </div>

        {viewTermsModalOpened && (
          <StoreViewTermsModal
            store={fetchedSingleStoreDetails?.storeData}
            modalOpened={viewTermsModalOpened}
            setModalOpened={setViewTermsModalOpened}
          />
        )}

        {deactivateStoreModalOpened && (
          <SingleStoreDeactivateModal
            store={fetchedSingleStoreDetails?.storeData}
            setSelectedTableRow={null}
            modalOpened={deactivateStoreModalOpened}
            setModalOpened={setDeactivateStoreModalOpened}
            setFetchCount={setFetchSingleStoreDetailsCount}
          />
        )}
      </div>
    );
  }
  return <EmptyCompoent img={emptyImg} />;
};

export default SingleStorePage;
