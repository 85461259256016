import React from 'react';

const UserEditIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.9477 12.75C9.78501 12.75 7.21094 10.17 7.21094 7C7.21094 3.83 9.78501 1.25 12.9477 1.25C16.1104 1.25 18.6845 3.83 18.6845 7C18.6845 10.17 16.1104 12.75 12.9477 12.75ZM12.9477 2.75C10.6131 2.75 8.70749 4.66 8.70749 7C8.70749 9.34 10.6131 11.25 12.9477 11.25C15.2823 11.25 17.188 9.34 17.188 7C17.188 4.66 15.2823 2.75 12.9477 2.75Z"
          fill={color || '#c4c4c4'}
        />
        <path
          d="M16.7578 22.7504C16.3786 22.7504 16.0195 22.6103 15.7601 22.3503C15.4508 22.0403 15.3111 21.5904 15.3809 21.1204L15.5705 19.7704C15.6204 19.4204 15.8299 19.0104 16.0793 18.7504L19.6112 15.2104C21.0279 13.7904 22.2751 14.6004 22.8837 15.2104C23.4025 15.7304 23.6718 16.2903 23.6718 16.8503C23.6718 17.4203 23.4125 17.9503 22.8837 18.4803L19.3518 22.0204C19.1023 22.2704 18.6833 22.4804 18.3342 22.5304L16.9872 22.7203C16.9074 22.7403 16.8376 22.7504 16.7578 22.7504ZM21.2374 15.9203C21.0578 15.9203 20.8982 16.0404 20.6687 16.2704L17.1369 19.8104C17.107 19.8404 17.0571 19.9403 17.0571 19.9803L16.8775 21.2303L18.1246 21.0504C18.1645 21.0404 18.2643 20.9904 18.2942 20.9604L21.8261 17.4203C21.9857 17.2603 22.1753 17.0303 22.1753 16.8503C22.1753 16.7003 22.0556 16.4904 21.8261 16.2704C21.5867 16.0304 21.407 15.9203 21.2374 15.9203Z"
          fill={color || '#c4c4c4'}
        />
        <path
          d="M21.8474 19.2203C21.7775 19.2203 21.7077 19.2104 21.6478 19.1904C20.3308 18.8204 19.2833 17.7704 18.9141 16.4504C18.8044 16.0504 19.0339 15.6404 19.4329 15.5304C19.832 15.4204 20.2411 15.6503 20.3508 16.0503C20.5803 16.8703 21.2288 17.5204 22.0469 17.7504C22.446 17.8604 22.6755 18.2803 22.5657 18.6703C22.4759 19.0003 22.1766 19.2203 21.8474 19.2203Z"
          fill={color || '#c4c4c4'}
        />
        <path
          d="M4.37718 22.75C3.96812 22.75 3.62891 22.41 3.62891 22C3.62891 17.73 7.8093 14.25 12.9475 14.25C14.035 14.25 15.1125 14.41 16.1202 14.71C16.5192 14.83 16.7387 15.25 16.619 15.64C16.4993 16.04 16.0803 16.26 15.6912 16.14C14.8132 15.88 13.8953 15.74 12.9475 15.74C8.6374 15.74 5.12546 18.54 5.12546 21.99C5.12546 22.41 4.78624 22.75 4.37718 22.75Z"
          fill={color || '#c4c4c4'}
        />
      </svg>
    </span>
  );
};

export default UserEditIcon;
