import { Link, useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import { LoadingOutlined } from '@ant-design/icons';
import './MerchentOtherPage.scss';
import useOtherServices from '../../../custom-hooks/useOtherServices';
import OtherForm from './OtherForm';
import { useTranslation } from 'react-i18next';
import { CloudDownloadOutlined, LinkOutlined } from '@ant-design/icons';

const MerchentOtherPage = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { isLoadingServices, allFetchedServices } = useOtherServices();

  const renderOtherForm = () => {
    if (isLoadingServices) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedServices?.length > 0) {
      return (
        <div className="other-form-files-wrapper">
          <OtherForm allFetchedServices={allFetchedServices} />
        </div>
      );
    }
    return null;
  };

  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  return (
    <div className="merchent-other-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('other'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-create-store-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">
              {i18n.language === 'ar'
                ? 'اطلب صورة او فيديو'
                : 'Ask for video or image'}
            </div>
          </div>

          <Link
            className="other-orders-link"
            to={routerLinks?.merchentOtherOrders}
          >
            {i18n.language === 'en' ? 'View orders' : 'عرض الطلبات'}
            <LinkOutlined />
          </Link>
        </div>
        {renderOtherForm()}
      </div>
    </div>
  );
};

export default MerchentOtherPage;
