/* eslint-disable eqeqeq */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { viewAsArr } from '../../const-values/viewAsOptions';
import './ViewAs.scss';

const ViewAs = ({ defaultValue, defaultNext, cb }) => {
  const { t } = useTranslation();
  const [selectedObj, setSelectedObj] = useState(
    defaultValue || (viewAsArr(t)?.length > 0 && viewAsArr(t)[0])
  );
  const [nextObj, setNextObj] = useState(
    defaultNext || (viewAsArr(t)?.length > 1 && viewAsArr(t)[1])
  );
  const handleClick = (obj) => {
    // first find the object index in array
    // then select the next object in the array
    // if no then go to the first object again
    const foundIndexInViewAsArr =
      viewAsArr(t)?.length > 0 &&
      viewAsArr(t).findIndex((i) => i.id == obj?.id);

    if (viewAsArr(t)?.length > 1) {
      if (foundIndexInViewAsArr < viewAsArr(t).length - 1) {
        // then select the next index in array
        setSelectedObj(viewAsArr(t)[foundIndexInViewAsArr + 1]);
        if (cb && typeof cb === 'function')
          cb(viewAsArr(t)[foundIndexInViewAsArr + 1]);
        ///
        ///
        if (foundIndexInViewAsArr < viewAsArr(t).length - 2)
          setNextObj(viewAsArr(t)[foundIndexInViewAsArr + 2]);
        else setNextObj(viewAsArr(t)[0]);
        ///
        ///
      } else {
        // then select the first item in the array
        setSelectedObj(viewAsArr(t)[0]);
        if (cb && typeof cb === 'function') cb(viewAsArr(t)[0]);
        setNextObj(viewAsArr(t)[1]);
      }
    }
  };

  return (
    <div className="custom-view-as-wrapper">
      <span>{t('viewAs.viewAs')} : </span>
      <button
        className="view-as-btn"
        onClick={() => {
          handleClick(selectedObj);
        }}
      >
        {/* {selectedObj?.icon} */}
        {nextObj?.icon}
      </button>
    </div>
  );
};

export default ViewAs;
