/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getGeneralSettingsApi from '../apis/general/generalSettingsApi';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useGeneralSettings = ({
  isLoading,
  setIsLoading,
  fetchCount,
  setFetchCount,
  fetchedSettings,
  setFetchedSettings
}) => {
  const { i18n } = useTranslation();
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    customApiRequest(
      getGeneralSettingsApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedSettings(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount]);

  return {
    isLoading,
    setIsLoading,
    //
    fetchCount,
    setFetchCount,
    //
    fetchedSettings,
    setFetchedSettings
  };
};

export default useGeneralSettings;
