import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoadingSingleStoreDetails: false,
  setIsLoadingSingleStoreDetails: (v) => {},
  fetchSingleStoreDetailsCount: 0,
  setFetchSingleStoreDetailsCount: (v) => {},
  fetchedSingleStoreDetails: null,
  setFetchedSingleStoreDetails: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  //
  termsAndConditionsModalOpened: false,
  setTermsAndConditionsModalOpened: (v) => {},
  deactivateStoreModalOpened: false,
  setDeactivateStoreModalOpened: (v) => {},
  reactivateModalOpened: false,
  setReactivateModalOpened: (v) => {},
  deleteStoreModalOpened: false,
  setDeleteStoreModalOpened: (v) => {},
  viewTermsModalOpened: false,
  setViewTermsModalOpened: (v) => {},
  //
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  // filter
  tableFilter: {
    store_id: '',
    page: 1
  },
  setTableFilter: (v) => {}
};

const SingleStoreContext = createContext(INITIAL_VALUES);

export const SingleStoreProvider = ({ children }) => {
  const [isLoadingSingleStoreDetails, setIsLoadingSingleStoreDetails] =
    useState(INITIAL_VALUES.isLoading);
  const [fetchSingleStoreDetailsCount, setFetchSingleStoreDetailsCount] =
    useState(INITIAL_VALUES.fetchSingleStoreDetailsCount);
  const [fetchedSingleStoreDetails, setFetchedSingleStoreDetails] = useState(
    INITIAL_VALUES.fetchedSingleStoreDetails
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  //
  const [termsAndConditionsModalOpened, setTermsAndConditionsModalOpened] =
    useState(INITIAL_VALUES.termsAndConditionsModalOpened);
  const [deactivateStoreModalOpened, setDeactivateStoreModalOpened] = useState(
    INITIAL_VALUES.deactivateStoreModalOpened
  );
  const [reactivateModalOpened, setReactivateModalOpened] = useState(
    INITIAL_VALUES.reactivateModalOpened
  );
  const [deleteStoreModalOpened, setDeleteStoreModalOpened] = useState(
    INITIAL_VALUES.deleteStoreModalOpened
  );
  //
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );
  const [viewTermsModalOpened, setViewTermsModalOpened] = useState(
    INITIAL_VALUES?.viewTermsModalOpened
  );

  return (
    <SingleStoreContext.Provider
      value={{
        isLoadingSingleStoreDetails,
        setIsLoadingSingleStoreDetails,
        fetchSingleStoreDetailsCount,
        setFetchSingleStoreDetailsCount,
        fetchedSingleStoreDetails,
        setFetchedSingleStoreDetails,
        isSubmittingForm,
        setIsSubmittingForm,
        termsAndConditionsModalOpened,
        setTermsAndConditionsModalOpened,
        deactivateStoreModalOpened,
        setDeactivateStoreModalOpened,
        reactivateModalOpened,
        setReactivateModalOpened,
        deleteStoreModalOpened,
        setDeleteStoreModalOpened,
        viewTermsModalOpened,
        setViewTermsModalOpened,
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </SingleStoreContext.Provider>
  );
};

export default SingleStoreContext;
