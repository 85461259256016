import React from 'react';

const DeleteProductIcon = ({ color }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1436_15424)">
        <mask id="path-1-inside-1_1436_15424" fill="white">
          <path d="M12.2493 2.83333H10.441C10.3056 2.17499 9.94741 1.58345 9.42675 1.15842C8.90608 0.733386 8.2548 0.500848 7.58268 0.5L6.41602 0.5C5.7439 0.500848 5.09262 0.733386 4.57195 1.15842C4.05129 1.58345 3.69307 2.17499 3.55768 2.83333H1.74935C1.59464 2.83333 1.44627 2.89479 1.33687 3.00419C1.22747 3.11358 1.16602 3.26196 1.16602 3.41667C1.16602 3.57138 1.22747 3.71975 1.33687 3.82915C1.44627 3.93854 1.59464 4 1.74935 4H2.33268V11.5833C2.33361 12.3566 2.6412 13.0979 3.18798 13.6447C3.73476 14.1915 4.47609 14.4991 5.24935 14.5H8.74935C9.52261 14.4991 10.2639 14.1915 10.8107 13.6447C11.3575 13.0979 11.6651 12.3566 11.666 11.5833V4H12.2493C12.4041 4 12.5524 3.93854 12.6618 3.82915C12.7712 3.71975 12.8327 3.57138 12.8327 3.41667C12.8327 3.26196 12.7712 3.11358 12.6618 3.00419C12.5524 2.89479 12.4041 2.83333 12.2493 2.83333ZM6.41602 1.66667H7.58268C7.94451 1.66711 8.29734 1.77947 8.59279 1.98834C8.88824 2.19721 9.11184 2.49237 9.23293 2.83333H4.76577C4.88686 2.49237 5.11046 2.19721 5.40591 1.98834C5.70136 1.77947 6.05419 1.66711 6.41602 1.66667ZM10.4993 11.5833C10.4993 12.0475 10.315 12.4926 9.98679 12.8208C9.6586 13.149 9.21348 13.3333 8.74935 13.3333H5.24935C4.78522 13.3333 4.3401 13.149 4.01191 12.8208C3.68372 12.4926 3.49935 12.0475 3.49935 11.5833V4H10.4993V11.5833Z" />
        </mask>
        <path
          d="M12.2493 2.83333H10.441C10.3056 2.17499 9.94741 1.58345 9.42675 1.15842C8.90608 0.733386 8.2548 0.500848 7.58268 0.5L6.41602 0.5C5.7439 0.500848 5.09262 0.733386 4.57195 1.15842C4.05129 1.58345 3.69307 2.17499 3.55768 2.83333H1.74935C1.59464 2.83333 1.44627 2.89479 1.33687 3.00419C1.22747 3.11358 1.16602 3.26196 1.16602 3.41667C1.16602 3.57138 1.22747 3.71975 1.33687 3.82915C1.44627 3.93854 1.59464 4 1.74935 4H2.33268V11.5833C2.33361 12.3566 2.6412 13.0979 3.18798 13.6447C3.73476 14.1915 4.47609 14.4991 5.24935 14.5H8.74935C9.52261 14.4991 10.2639 14.1915 10.8107 13.6447C11.3575 13.0979 11.6651 12.3566 11.666 11.5833V4H12.2493C12.4041 4 12.5524 3.93854 12.6618 3.82915C12.7712 3.71975 12.8327 3.57138 12.8327 3.41667C12.8327 3.26196 12.7712 3.11358 12.6618 3.00419C12.5524 2.89479 12.4041 2.83333 12.2493 2.83333ZM6.41602 1.66667H7.58268C7.94451 1.66711 8.29734 1.77947 8.59279 1.98834C8.88824 2.19721 9.11184 2.49237 9.23293 2.83333H4.76577C4.88686 2.49237 5.11046 2.19721 5.40591 1.98834C5.70136 1.77947 6.05419 1.66711 6.41602 1.66667ZM10.4993 11.5833C10.4993 12.0475 10.315 12.4926 9.98679 12.8208C9.6586 13.149 9.21348 13.3333 8.74935 13.3333H5.24935C4.78522 13.3333 4.3401 13.149 4.01191 12.8208C3.68372 12.4926 3.49935 12.0475 3.49935 11.5833V4H10.4993V11.5833Z"
          fill="#ED0006"
        />
        <path
          d="M5.83333 10.9997C5.98804 10.9997 6.13641 10.9382 6.24581 10.8288C6.35521 10.7194 6.41666 10.571 6.41666 10.4163V9.833V7.49967V6.91634C6.41666 6.76163 6.35521 6.61326 6.24581 6.50386C6.13641 6.39447 5.98804 6.33301 5.83333 6.33301C5.67862 6.33301 5.53025 6.39447 5.42085 6.50386C5.31146 6.61326 5.25 6.76163 5.25 6.91634L5.25 7.49967L5.25 9.833V10.4163C5.25 10.571 5.31146 10.7194 5.42085 10.8288C5.53025 10.9382 5.67862 10.9997 5.83333 10.9997Z"
          fill="#ED0006"
          strokeWidth="21.3333"
        />
        <path
          d="M8.16732 10.9997C8.32203 10.9997 8.47041 10.9382 8.5798 10.8288C8.6892 10.7194 8.75066 10.571 8.75066 10.4163V6.91634C8.75066 6.76163 8.6892 6.61326 8.5798 6.50386C8.47041 6.39447 8.32203 6.33301 8.16732 6.33301C8.01261 6.33301 7.86424 6.39447 7.75484 6.50386C7.64544 6.61326 7.58398 6.76163 7.58398 6.91634V10.4163C7.58398 10.571 7.64544 10.7194 7.75484 10.8288C7.86424 10.9382 8.01261 10.9997 8.16732 10.9997Z"
          fill="#ED0006"
        />
      </g>
      <defs>
        <clipPath id="clip0_1436_15424">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteProductIcon;
