/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../../components/app/routerLinks';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import UserChangePasswordForm from './UserChangePasswordForm';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import './UserChangePasswordPage.scss';
import ChangePasswordPageContent from './ChangePasswordPageContent';

const UserChangePasswordPage = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const sharedTrans = (key) => t(`userDashboard.${key}`);
  return (
    <div className="user-change-password-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: userRouterLinks?.userDashboard
          },
          {
            title: sharedTrans('changePass'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">
            {t('change_password_form.title')}
          </div>
        </div>
        <ChangePasswordPageContent />
      </div>
    </div>
  );
};

export default UserChangePasswordPage;
