import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import EyeOpenedIcon from '../../common/icons/EyeOpenedIcon';
import EyeClosedIcon from '../../common/icons/EyeClosedIcon';
import { Link as RouterLink } from 'react-router-dom';
import './SignupForm.scss';
import AntdCheckbox from '../../common/antd-form-components/AntdCheckbox';
import routerLinks from '../../components/app/routerLinks';
import useSignupEmailPassword from '../../custom-hooks/useSignupEmailPassowrd';
import UserIcon from '../../common/icons/UserIcon';
import EmailIcon from '../../common/icons/EmailIcon';
import LockIcon from '../../common/icons/LockIcon';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';

import accountTypes from '../../const-values/accountTypes';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import TermsModal from './TermsModal';
import LoadingModal from '../../common/loading-modal/LoadingModal';
// import useFirebaseDeviceToken from '../../custom-hooks/useFirebaseDeviceToken';

const EmployeeSignupForm = () => {
  // console.log('get countries : ', getCountries());
  // console.log('get countries props : ', getCountryCallingCode('EG'));

  const { i18n, t } = useTranslation();
  const [passwrodVisible, setPasswordVisible] = React.useState(false);
  useContext(ForgetPasswordContext);
  const generalLabelStr = (v) => t(`signup_form.${v}.label`);
  const generalrequiredErrStr = (v) => t(`signup_form.${v}.errors.required`);
  const generalTypeErrorStr = (v) => t(`signup_form.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`signup_form.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    name: Yup.string().required(generalrequiredErrStr('name')),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(10, generalMinErrorStr('phone', 10)),
    email: Yup.string()
      .required(generalrequiredErrStr('email'))
      .email(generalTypeErrorStr('email')),
    password: Yup.string().required(generalrequiredErrStr('password')),
    password_confirmation: Yup.string()
      .required(generalrequiredErrStr('password_confirmation'))
      .oneOf(
        [Yup.ref('password')],
        generalTypeErrorStr('password_confirmation')
      ),
    terms: Yup.boolean()
      .required('يجب الموافقة على الشروط والاحكام اولا')
      .oneOf([true], 'يجب الموافقة على الشروط الاحكام لاستكمال التسجيبل')
  });
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
      remember: true
    }
  });
  const [termsModalOpened, setTermsModalOpened] = useState(false);
  const { isLoadingSignup, signMeUpWithEmailPassword } =
    useSignupEmailPassword();
  const onSubmit = (data) => {
    // signMeUpWithEmailPassword({ ...data, accountType: 3 });
    const formatedIntlPhone = formatPhoneNumberIntl(data.phone); // '+966 50 123 4444'
    const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    const countryCode = phoneStrArr[0];
    let phone = '';
    if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    else if (phoneStrArr?.length > 2) {
      let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
      phone = array.join('');
    }

    signMeUpWithEmailPassword({
      ...data,
      phone,
      countryCode,
      accountType: accountTypes()?.employee
    });
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="signup-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-header">
          <p className="bold-font main-title">{t('signup_form.newAcc')}</p>
          <div>
            <p className="sub-title">{t('signup_form.subTitle')}</p>
          </div>
        </div>

        <div className="form-body">
          <AntdTextField
            name="name"
            type="text"
            placeholder={generalLabelStr('name')}
            label={generalLabelStr('name')}
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<UserIcon />}
            control={control}
          />
          <AntdTextField
            name="email"
            type="text"
            placeholder={generalLabelStr('email')}
            label={generalLabelStr('email')}
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EmailIcon />}
            control={control}
          />
          <div
            className={`country-code-phone-wrap ${i18n.dir()}`}
            style={{
              marginBottom: 28
            }}
          >
            <p
              style={{
                paddingBottom: 8
              }}
            >
              {generalLabelStr('phone')}
            </p>
            <Controller
              name="phone"
              control={control}
              // render={({ field: { onChange, onBlur, value, name, ref } }) => {
              render={({ field }) => {
                return (
                  <PhoneInput
                    {...field}
                    placeholder={generalLabelStr('phone')}
                    // value={phoneValue}
                    // onChange={setPhoneValue}
                    defaultCountry="SA"
                    className={`custom-phone-input ${i18n.language}`}
                  />
                );
              }}
            />
            <p className="error-p">{errors?.phone?.message}</p>
          </div>

          <div className="login-password-field-wrap">
            <AntdTextField
              name="password"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder={generalLabelStr('password')}
              label={generalLabelStr('password')}
              errorMsg={errors?.password?.message}
              prefix={<LockIcon />}
              validateStatus={errors?.password ? 'error' : ''}
              control={control}
            />

            {watch('password') && (
              <div
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOpenedIcon /> : <EyeClosedIcon />}
              </div>
            )}
          </div>
          <div className="login-password-field-wrap">
            <AntdTextField
              name="password_confirmation"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder={generalLabelStr('password_confirmation')}
              label={generalLabelStr('password_confirmation')}
              errorMsg={errors?.password_confirmation?.message}
              prefix={<LockIcon />}
              validateStatus={errors?.password_confirmation ? 'error' : ''}
              control={control}
            />

            {watch('password_confirmation') && (
              <div
                className="eye-icon-btn"
                style={{
                  left: i18n.dir() === 'rtl' ? '14px' : 'auto',
                  right: i18n.dir() === 'ltr' ? '14px' : 'auto'
                }}
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOpenedIcon /> : <EyeClosedIcon />}
              </div>
            )}
          </div>

          <div className="terms-checkbox-wrap">
            <AntdCheckbox
              name="terms"
              control={control}
              label={t('signup_form.accept')}
              errorMsg={errors?.remember?.message}
              validateStatus={errors?.remember ? 'error' : ''}
            />

            <button
              className="terms-btn"
              onClick={(e) => {
                setTermsModalOpened(true);
                e.preventDefault();
              }}
              to={generalRouterLinks?.refundTermsRoute}
            >
              {t('signup_form.terms')}
            </button>
          </div>
          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isLoadingSignup}
          >
            {isLoadingSignup
              ? t('signup_form.submit_btn.loading')
              : t('signup_form.submit_btn.label')}
          </Button>
        </div>
        <div className="already-have-account">
          <span>{t('signup_form.haveAccount')} </span>{' '}
          <RouterLink to={routerLinks?.signinPage}>
            {t('signup_form.signin')}
          </RouterLink>
        </div>
      </Form>

      {termsModalOpened && (
        <TermsModal
          modalOpened={termsModalOpened}
          setModalOpened={setTermsModalOpened}
        />
      )}

      {isLoadingSignup && <LoadingModal />}
    </>
  );
};

export default EmployeeSignupForm;
