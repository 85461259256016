import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoadingSingleProductDetails: false,
  setIsLoadingSingleProductDetails: (v) => {},
  fetchSingleStoreDetailsCount: 0,
  setFetchSingleProductDetailsCount: (v) => {},
  fetchedSingleProductDetails: null,
  setFetchedSingleProductDetails: (v) => {},
  isLoading: false,
  setIsLoading: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  //
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const SingleProductContext = createContext(INITIAL_VALUES);

export const SingleProductProvider = ({ children }) => {
  const [isLoadingSingleProductDetails, setIsLoadingSingleProductDetails] =
    useState(INITIAL_VALUES.isLoading);
  const [fetchSingleStoreDetailsCount, setFetchSingleProductDetailsCount] =
    useState(INITIAL_VALUES.fetchSingleStoreDetailsCount);
  const [fetchedSingleProductDetails, setFetchedSingleProductDetails] =
    useState(INITIAL_VALUES.fetchedSingleProductDetails);
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );

  return (
    <SingleProductContext.Provider
      value={{
        isLoadingSingleProductDetails,
        setIsLoadingSingleProductDetails,
        fetchSingleStoreDetailsCount,
        setFetchSingleProductDetailsCount,
        fetchedSingleProductDetails,
        setFetchedSingleProductDetails,
        isLoading,
        setIsLoading,
        isSubmittingForm,
        setIsSubmittingForm
      }}
    >
      {children}
    </SingleProductContext.Provider>
  );
};

export default SingleProductContext;
