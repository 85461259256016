import React, { useContext } from 'react';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from './useCustomApiRequest';
import checkRes from '../utils/checkRes';
import errorNotification from '../utils/errorNotification';
import { useTranslation } from 'react-i18next';
import readNotificationsApi from '../apis/auth/readNotificationsApi';

const useReadNotification = () => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const [isReadingNotification, setIsReadingNotification] =
    React.useState(false);
  const readNotification = ({ values, successCb }) => {
    setIsReadingNotification(true);
    const formData = new FormData();
    formData.append('id', values?.id);
    customApiRequest(
      readNotificationsApi(formData, user?.token, i18n.language),
      (res) => {
        setIsReadingNotification(false);
        if (checkRes(res)) {
          if (typeof successCb === 'function') successCb();

          //
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || t('errorMessage')
          });
        }
      },

      (error) => {
        setIsReadingNotification(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || t('errorMessage')
        });
      }
    );
  };

  return { readNotification, isReadingNotification };
};

export default useReadNotification;
