/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import getAllLeftedCartApi from '../../../apis/stores-apis/getAllLeftedCartApi';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import StoreLeftedCartContext from '../../../contexts/store-lefted-cart-context/StoreLeftedCartContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import LeftedCartOfferFormModal from './LeftedCartOfferFormModal';
import LeftedCartTable from './LeftedCartTable';
import './StoreLeftedCartPage.scss';

const StoreLeftedCartPage = () => {
  const { user } = useContext(UserContext);
  const params = useParams();
  const {
    isLoading,
    setIsLoading,
    filter,
    fetchCount,
    allFetchedData,
    setAllFetchedData,
    formModalOpened
  } = useContext(StoreLeftedCartContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);

  const customApiRequest = useCustomApiRequest();
  //
  // select request user or merchent

  //

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    customApiRequest(
      getAllLeftedCartApi(
        {
          ...filter,
          ...values,
          store_id: params?.store_id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedData(data);
          } else {
            setAllFetchedData(null);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount, filter, search]);

  const renderPageContent = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            padding: '132px 0',
            margin: '32px auto'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (allFetchedData?.length === 0)
      return (
        <div className="custom-cotainer">
          <div
            style={{
              display: 'grid',
              padding: '52px 22px'
            }}
          >
            <Empty description={false}>
              {i18n.language === 'ar'
                ? 'لا توجد خدمات معلقة من العميل'
                : 'No lefted services from the client'}
            </Empty>
          </div>
        </div>
      );
    else if (allFetchedData?.length > 0)
      return (
        <div className="table-title-wrap">
          {/* <div className="table-title">
            <h2>{sharedTrans('leftedCart')}</h2>
          </div> */}

          <LeftedCartTable />
        </div>
      );

    return null;
  };

  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  return (
    <div className="store-lefted-cart-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('leftedCart'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-lefted-cart-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">
              {sharedTrans('leftedCartReport')}
            </div>
          </div>
        </div>

        <div className="page-body">{renderPageContent()}</div>
      </div>

      {formModalOpened && <LeftedCartOfferFormModal />}
    </div>
  );
};

export default StoreLeftedCartPage;
