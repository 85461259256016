import React from 'react';

const TextIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        fill={color || '#c4c4c4'}
        d="M5 8h2V6h3.252L7.68 18H5v2h8v-2h-2.252L13.32 6H17v2h2V4H5z"
      ></path>
    </svg>
  );
};

export default TextIcon;
