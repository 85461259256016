/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useContext } from 'react';
import Geocode from 'react-geocode';
import { useTranslation } from 'react-i18next';
import getGeneralStoresApi from '../../apis/general/getGeneralStoresApi';
import getHomeSlidesApi from '../../apis/general/getHomeSlidesApi';
import getHomeDataApi from '../../apis/general/homeApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useGeoLocation from '../../custom-hooks/useGeoLocation';
import checkRes from '../../utils/checkRes';
import FilterCatsContext from '../filter-cats/FilterCatsContext';
import UserContext from '../user-context/UserProvider';

const INITIAL_VALUES = {
  allFetchedFilterCats: [],
  location: null,
  appCity: null,
  setAppCity: (v) => {},

  // appbar search stores
  isLoadingStores: false,
  setIsLoadingStores: (v) => {},
  allFetchedStores: [],
  setAllFetchedStores: (v) => {},
  fetchStoresCount: 0,
  setFetchStoresCount: (v) => {},

  // home data
  isLoadingHomeData: false,
  setIsLoadingHomeData: (v) => {},
  allFetchedHomeData: [],
  setAllFetchedHomeData: (v) => {},
  fetchHomeCount: 0,
  setFetchHomeCount: (v) => {},
  //
  isLoadingHomeSlider: false,
  setIsLoadingHomeSlider: (v) => {},
  fetchedHomeSlides: [],
  setFetchedHomeSlides: (v) => {}
};

const SharedAppContext = createContext(INITIAL_VALUES);

export const SharedAppProvider = ({ children }) => {
  const customApiRequest = useCustomApiRequest();
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const [appCity, setAppCity] = useState(INITIAL_VALUES?.appCity);
  const [isLoadingStores, setIsLoadingStores] = useState(
    INITIAL_VALUES?.isLoadingStores
  );
  const [allFetchedStores, setAllFetchedStores] = useState(
    INITIAL_VALUES?.allFetchedStores
  );
  const [fetchStoresCount, setFetchStoresCount] = useState(
    INITIAL_VALUES?.fetchStoresCount
  );

  const [isLoadingHomeData, setIsLoadingHomeData] = useState(
    INITIAL_VALUES.isLoadingHomeData
  );
  const [allFetchedHomeData, setAllFetchedHomeData] = useState(
    INITIAL_VALUES.allFetchedHomeData
  );
  const [fetchHomeCount, setFetchHomeCount] = useState(
    INITIAL_VALUES.fetchHomeCount
  );
  const [isLoadingHomeSlider, setIsLoadingHomeSlider] = useState(
    INITIAL_VALUES.isLoadingHomeSlider
  );
  const [fetchedHomeSlides, setFetchedHomeSlides] = useState(
    INITIAL_VALUES.fetchedHomeSlides
  );

  const location = useGeoLocation();
  const { fetchedData: allFetchedFilterCats } = useContext(FilterCatsContext);

  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage(i18n.language);
    Geocode.fromLatLng(
      location?.coordinates?.lat,
      location?.coordinates?.lng
    ).then(
      (res) => {
        if (
          res?.results?.length > 0 &&
          res.results[0]?.address_components?.length > 0 &&
          res?.results[0]?.address_components[4]
        ) {
          // console.log('results : ', res);
          setAppCity(res.results[0].address_components[4].short_name);
        }
      },
      (error) => {
        // console.log('err : ', error);
      }
    );
  }, [location?.loaded, location?.coordinates, i18n.language]);

  useEffect(() => {
    let isMounted = true;
    if (location?.coordinates?.lat && location?.coordinates?.lng) {
      setIsLoadingStores(true);
      customApiRequest(
        getGeneralStoresApi(
          // {
          //   lat: location?.coordinates?.lat,
          //   lng: location?.coordinates?.lng
          // },
          {},
          user?.token,
          i18n.language
        ),
        (res) => {
          if (isMounted) {
            setIsLoadingStores(false);

            if (checkRes(res) && res?.data?.data?.length > 0) {
              setAllFetchedStores(res.data.data);
              setFetchStoresCount((prev) => prev + 1);
            } else {
              setAllFetchedStores([]);
            }
          }
        },
        (error) => {
          setIsLoadingStores(false);
        }
      );
    }

    return () => (isMounted = false);
  }, [location?.coordinates?.lat, location?.coordinates?.lng]);

  useEffect(() => {
    let isMounted = true;
    setIsLoadingHomeData(true);
    customApiRequest(
      getHomeDataApi(user?.token, i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoadingHomeData(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setAllFetchedHomeData(data);
          }
        }
      },
      (error) => {
        setIsLoadingHomeData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchHomeCount]);

  useEffect(() => {
    let isMounted = true;
    setIsLoadingHomeSlider(true);
    customApiRequest(
      getHomeSlidesApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoadingHomeSlider(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setFetchedHomeSlides(
              data.map((d) => ({
                ...d,
                mainTitle: d?.title,
                subTitle: d?.desc,
                to: d?.btnLink
              }))
            );
          }
        }
      },
      (error) => {
        setIsLoadingHomeSlider(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language]);

  return (
    <SharedAppContext.Provider
      value={{
        appCity,
        setAppCity,
        location,
        allFetchedFilterCats,
        //
        isLoadingStores,
        setIsLoadingStores,
        allFetchedStores,
        setAllFetchedStores,
        fetchStoresCount,
        setFetchStoresCount,
        // home
        isLoadingHomeData,
        setIsLoadingHomeData,
        allFetchedHomeData,
        setAllFetchedHomeData,
        fetchHomeCount,
        setFetchHomeCount,
        //
        isLoadingHomeSlider,
        setIsLoadingHomeSlider,
        fetchedHomeSlides,
        setFetchedHomeSlides
      }}
    >
      {children}
    </SharedAppContext.Provider>
  );
};

export default SharedAppContext;
