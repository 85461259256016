import React from 'react';

const OrdersIcon = ({ color }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1295_26920)">
        <path
          d="M14.0455 3.85008L12.2891 1.47708C12.0731 1.17511 11.7883 0.928965 11.4582 0.759045C11.1281 0.589124 10.7622 0.500323 10.3909 0.500001H4.61011C4.24065 0.499654 3.87638 0.587048 3.54729 0.75499C3.2182 0.922931 2.9337 1.16662 2.71719 1.466L0.929276 3.85592C0.638172 4.2664 0.487836 4.76013 0.50077 5.26319C0.513704 5.76625 0.689212 6.2516 1.00103 6.64658L6.12969 13.8379C6.29313 14.0449 6.50145 14.212 6.73892 14.3267C6.97639 14.4414 7.23681 14.5007 7.50053 14.5C7.76877 14.4995 8.03331 14.4374 8.2738 14.3186C8.51428 14.1997 8.7243 14.0273 8.88769 13.8146L13.9755 6.70317C14.3013 6.30241 14.4849 5.80489 14.4976 5.28858C14.5102 4.77226 14.3513 4.26634 14.0455 3.85008ZM11.3459 2.16308L13.1064 4.54192C13.1157 4.55475 13.118 4.56992 13.1274 4.58333H10.2737L9.44069 1.66667H10.3909C10.5782 1.66725 10.7625 1.71261 10.9287 1.79897C11.0948 1.88533 11.2378 2.01017 11.3459 2.16308ZM7.50053 11.6522L5.92553 5.75H9.07553L7.50053 11.6522ZM5.94069 4.58333L6.77369 1.66667H8.22736L9.06036 4.58333H5.94069ZM3.66044 2.15492C3.76868 2.00406 3.91124 1.88111 4.07637 1.79622C4.24149 1.71132 4.42444 1.66692 4.61011 1.66667H5.56036L4.72736 4.58333H1.85269C1.86086 4.5705 1.86319 4.55475 1.87253 4.5425L3.66044 2.15492ZM1.92853 5.93958C1.88641 5.88009 1.85026 5.81659 1.82061 5.75H4.71919L6.46569 12.3008L1.92853 5.93958ZM8.53361 12.3067L10.2836 5.75H13.1921C13.1545 5.83707 13.107 5.91949 13.0504 5.99558L8.53361 12.3067Z"
          fill={color ? color : '#6D6D6D'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1295_26920">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OrdersIcon;
