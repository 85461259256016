export const ingredientsOptionsValues = {
  natural: '1',
  artificial: '2',
  mixed: '3'
};
const ingredientsOptions = (t) => {
  return [
    {
      title: t('natural'),
      value: ingredientsOptionsValues.natural,
      id: '1'
    },
    {
      title: t('artificial'),
      value: ingredientsOptionsValues.artificial,
      id: '2'
    },
    {
      title: t('natural_artificial'),
      value: ingredientsOptionsValues.mixed,
      id: '3'
    }
  ];
};

export default ingredientsOptions;
