/* eslint-disable react-hooks/exhaustive-deps */
import './ChatGroups.styles.scss';

import React, { useContext, useEffect } from 'react';
import { Avatar } from 'antd';
import ChatContext from '../../contexts/chat-context/ChatContext';
import UserContext from '../../contexts/user-context/UserProvider';

const ChatGroups = () => {
  const { myChatGroups, selectedGroupId, setSelectedGroupId } =
    useContext(ChatContext);
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (myChatGroups.length) setSelectedGroupId(myChatGroups[0].group_id);
  }, [myChatGroups.length]);
  // fetch all groups

  const renderChatMembers = (group) => {
    if (group.is_group === '1') {
      return (
        <>
          <div className="user-img">
            <Avatar />
          </div>

          <div className="user-name">
            <div className="names">
              <div className="member">{group.group_names}</div>;
            </div>
            <div className="last-msg">{group.lastMessage}</div>
          </div>
        </>
      );
    }
    const sender = group.chatMembers.find(
      (member) => member.id !== user.user_id
    );
    return (
      <>
        <div className="user-img">
          <Avatar src={sender.image} />
        </div>

        <div className="user-name">
          <div className="names">
            <div className="member">{sender.name}</div>
          </div>
          <div className="last-msg">{group.lastMessage}</div>
        </div>
      </>
    );
  };

  const renderChatUsers = () => {
    // check if there are chat users then display them
    if (myChatGroups.length) {
      return (
        <div className="users">
          {myChatGroups.map((group) => (
            <div
              className={`user ${
                group.group_id === selectedGroupId ? 'active-chat' : ''
              }`}
              key={group.group_id}
              // onClick={() => setSelectedGroupId(group.group_id)}
              onClick={() => {
                setSelectedGroupId(group.group_id);
              }}
            >
              {renderChatMembers(group)}
            </div>
          ))}
        </div>
      );
    }
    // return renderContentLoaders();
    return null;
  };

  // const renderContentLoaders = () => {
  // 	let arr = [1, 2, 3, 4];
  // 	return (
  // 		<div className="groups-Skeleton">
  // 			{arr.map(item => (
  // 				<div key={item} className="skeleton-row">
  // 					<ContentLoader
  // 						width="60"
  // 						height="60"
  // 						speed={1}
  // 						rtl={true}
  // 						backgroundColor="#ddd"
  // 						foregroundColor="#e8e8e8"
  // 					>
  // 						<circle cx="50%" cy="50%" r="50%" />
  // 					</ContentLoader>
  // 					<ContentLoader
  // 						width="90"
  // 						height="34"
  // 						speed={1}
  // 						rtl={true}
  // 						backgroundColor="#ddd"
  // 						foregroundColor="#e8e8e8"
  // 					>
  // 						<rect x="0" y="0" width="100%" height="100%" rx="4" ry="4" />
  // 					</ContentLoader>
  // 				</div>
  // 			))}
  // 		</div>
  // 	);
  // };

  return <div className="users-wrapper">{renderChatUsers()}</div>;
};

export default ChatGroups;
