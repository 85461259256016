/* eslint-disable eqeqeq */
import moment from 'moment';
import discountCodeTypes from '../const-values/discountCodeTypes';
import {
  hasDiscountValues,
  hasOfferValues,
  offerTypes
} from '../const-values/leftedCartOptions';

// get product discount price
export const getDiscountPrice = ({
  pPrice,
  hasDiscount,
  discountType,
  discountValue
}) => {
  let discountPrice = 0;
  if (hasDiscount === hasDiscountValues.yes) {
    if (discountType == discountCodeTypes?.fixedValue)
      discountPrice = parseFloat(discountValue);
    else if (discountType == discountCodeTypes?.percentageValue)
      discountPrice = (parseFloat(pPrice) * parseFloat(discountValue)) / 100;
  }
  return discountPrice;
};
// get product offer price
export const getOfferPrice = ({ pPrice, hasOffer, offerType, offerValue }) => {
  let offerPrice = 0;
  if (hasOffer == hasOfferValues?.yes) {
    if (offerType == offerTypes?.static) offerPrice = parseFloat(offerValue);
    else if (offerType == offerTypes?.percentage)
      offerPrice = (parseFloat(pPrice) * parseFloat(offerValue)) / 100;
  }
  return offerPrice;
};

// product price after discount
export const getProductPriceAfterDiscount = ({
  pPrice,
  hasDiscount,
  discountType,
  discountValue,
  codeEndDate
}) => {
  if (
    hasDiscount === hasDiscountValues?.yes &&
    pPrice &&
    codeEndDate &&
    (moment(codeEndDate).isAfter() ||
      moment(codeEndDate).date() === moment().date())
  ) {
    return (
      parseFloat(pPrice) -
      getDiscountPrice({
        pPrice,
        hasDiscount,
        discountType,
        discountValue
      })
    );
  }
  return parseFloat(pPrice);
};

// product price and offer
export const getProductPriceAfterOffer = ({
  pPrice,
  hasOffer,
  offerType,
  offerValue
}) => {
  if (hasOffer == hasOfferValues?.yes && pPrice) {
    return (
      parseFloat(pPrice) -
      getOfferPrice({
        pPrice,
        hasOffer,
        offerType,
        offerValue
      })
    );
  }
  return parseFloat(pPrice);
};

// product price after discount and offer
export const getProductPriceAfterDiscountAndOffer = ({
  pPrice,
  hasDiscount,
  discountType,
  discountValue,
  hasOffer,
  offerType,
  offerValue,
  offerEndDate,
  codeEndDate
}) => {
  if (pPrice) {
    // if has offer and discount
    if (
      hasOffer == hasOfferValues?.yes &&
      offerType &&
      offerValue &&
      hasDiscount === hasDiscountValues?.yes &&
      discountType &&
      discountValue &&
      offerEndDate &&
      (moment(offerEndDate).isAfter() ||
        moment(offerEndDate).date() === moment().date()) &&
      codeEndDate &&
      (moment(codeEndDate).isAfter() ||
        moment(codeEndDate).date() === moment().date())
    ) {
      const discountPrice = getDiscountPrice({
        pPrice,
        hasDiscount,
        discountType,
        discountValue
      });
      const offerPrice = getOfferPrice({
        pPrice: pPrice - discountPrice,
        hasOffer,
        offerType,
        offerValue
      });

      const p = parseFloat(pPrice) - discountPrice - offerPrice;
      return p;
    }

    // else has discount but no offer
    else if (
      (hasOffer === hasOfferValues?.no || moment(offerEndDate).isBefore()) &&
      hasDiscount &&
      discountType &&
      discountValue &&
      codeEndDate &&
      (moment(codeEndDate).isAfter() ||
        moment(codeEndDate).date() === moment().date())
    ) {
      return (
        parseFloat(pPrice) -
        getDiscountPrice({ pPrice, hasDiscount, discountType, discountValue })
      );
    }

    // else if has offer but no discount
    else if (
      hasOffer == hasOfferValues?.yes &&
      offerType &&
      offerValue &&
      (hasDiscount === hasDiscountValues?.no ||
        moment(codeEndDate).isBefore()) &&
      offerEndDate &&
      (moment(offerEndDate).isAfter() ||
        moment(offerEndDate).date() === moment().date())
    )
      return (
        parseFloat(pPrice) -
        getOfferPrice({
          pPrice,
          hasOffer,
          offerType,
          offerValue
        })
      );

    return parseFloat(pPrice);
  }

  return parseFloat(pPrice);
};
