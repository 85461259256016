/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../../components/app/routerLinks';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './UserAffiliateStatisticsPage.scss';
import useUserStatistics from '../../../custom-hooks/useUserStatistics';
import EmptyCompoent from '../../../common/empty-compoent/EmptyComponent';
import emptyImg from '../../../assets/imgs/empty/empty.png';
import { Pagination } from 'antd';
import UserStatisticsCard from './UserStatisticsCard';
import { notification } from 'antd';

const UserAffiliateStatisticsPage = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const sharedTrans = (key) => t(`userDashboard.${key}`);

  const { isLoadingData, allFetchedTableData, tablePagination } =
    useUserStatistics();

  const [copied, setCopied] = useState(false);
  const [copyCount, setCopyCount] = useState(false);
  useEffect(() => {
    if (copied && copyCount) {
      notification.success({
        message: t('copied'),
        duration: 1.5,
        onClick: () => {
          // console.log('Notification Clicked!');
        }
      });
    }
  }, [copied, copyCount]);

  const renderLinks = () => {
    return allFetchedTableData.map((obj) => {
      return (
        <UserStatisticsCard
          setCopied={setCopied}
          setCopyCount={setCopyCount}
          key={obj?.id}
          card={obj}
        />
      );
    });
  };

  const renderPageContent = () => {
    if (isLoadingData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedTableData?.length === 0) {
      return <EmptyCompoent img={emptyImg} />;
    } else if (allFetchedTableData?.length > 0) {
      return (
        <div className="page-content-wrapper">
          <div className="custom-container">
            <div className="links-wrapper">{renderLinks()}</div>

            {tablePagination?.total > 0 && (
              <Pagination
                locale={{
                  jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                  // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                  page: ''
                }}
                style={{
                  marginTop: 32
                }}
                showSizeChanger={false}
                defaultCurrent={1}
                current={tablePagination.current_page}
                pageSize={tablePagination.per_page}
                total={tablePagination.total}
                // itemRender={itemRender}
                onChange={(page, pageSize) => {
                  navigate(`${userRouterLinks?.statistics}?page=${page}`);
                }}
                hideOnSinglePage={true}
              />
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="user-aff-stats-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: userRouterLinks?.userDashboard
          },
          {
            title: t('userAffiliatePage.title'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{t('userAffiliatePage.title')}</div>
        </div>
        <div className="page-body">{renderPageContent()}</div>
      </div>
    </div>
  );
};

export default UserAffiliateStatisticsPage;
