export const urlTypes = {
  imageUrl: 1,
  videoUrl: 2
};
export const checkUrlExtenstion = (url) => {
  const images = ['jpg', 'jpeg', 'jfif', 'gif', 'png', 'tiff', 'bmp'];
  const videos = [
    'mp4',
    '3gp',
    'ogg',
    'mkv',
    'wmv',
    'avi',
    'avchd',
    'flv',
    'webm',
    'html5'
  ];
  if (url && typeof url === 'string') {
    const urlObj = new URL(url);
    const urlExt = urlObj.pathname.split('.')[1]; // 'jpg' // 'mp4'
    if (images.includes(urlExt.toLowerCase())) return urlTypes.imageUrl;
    else if (videos.includes(urlExt.toLowerCase())) return urlTypes.videoUrl;
    return '';
  }
};
