import React from 'react';

const StatisticsIcon = ({ color }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1295_7016)">
        <path
          d="M13.4167 13.3333H2.91667C2.45254 13.3333 2.00742 13.149 1.67923 12.8208C1.35104 12.4926 1.16667 12.0475 1.16667 11.5833V1.08333C1.16667 0.928624 1.10521 0.780251 0.995812 0.670854C0.886416 0.561458 0.738043 0.5 0.583333 0.5C0.428624 0.5 0.280251 0.561458 0.170854 0.670854C0.0614582 0.780251 0 0.928624 0 1.08333L0 11.5833C0.00092625 12.3566 0.308514 13.0979 0.855295 13.6447C1.40208 14.1915 2.1434 14.4991 2.91667 14.5H13.4167C13.5714 14.5 13.7197 14.4385 13.8291 14.3291C13.9385 14.2197 14 14.0714 14 13.9167C14 13.762 13.9385 13.6136 13.8291 13.5042C13.7197 13.3948 13.5714 13.3333 13.4167 13.3333Z"
          fill={color ? color : '#afb7d1'}
        />
        <path
          d="M3.49935 12.1665C3.65406 12.1665 3.80243 12.105 3.91183 11.9957C4.02122 11.8863 4.08268 11.7379 4.08268 11.5832V7.49984C4.08268 7.34513 4.02122 7.19675 3.91183 7.08736C3.80243 6.97796 3.65406 6.9165 3.49935 6.9165C3.34464 6.9165 3.19627 6.97796 3.08687 7.08736C2.97747 7.19675 2.91602 7.34513 2.91602 7.49984V11.5832C2.91602 11.7379 2.97747 11.8863 3.08687 11.9957C3.19627 12.105 3.34464 12.1665 3.49935 12.1665Z"
          fill={color ? color : '#afb7d1'}
        />
        <path
          d="M5.83398 6.33333V11.5833C5.83398 11.738 5.89544 11.8864 6.00484 11.9958C6.11424 12.1052 6.26261 12.1667 6.41732 12.1667C6.57203 12.1667 6.72041 12.1052 6.8298 11.9958C6.9392 11.8864 7.00066 11.738 7.00066 11.5833V6.33333C7.00066 6.17862 6.9392 6.03025 6.8298 5.92085C6.72041 5.81146 6.57203 5.75 6.41732 5.75C6.26261 5.75 6.11424 5.81146 6.00484 5.92085C5.89544 6.03025 5.83398 6.17862 5.83398 6.33333Z"
          fill={color ? color : '#afb7d1'}
        />
        <path
          d="M8.75 8.08333V11.5833C8.75 11.738 8.81146 11.8864 8.92086 11.9958C9.03025 12.1052 9.17863 12.1667 9.33334 12.1667C9.48805 12.1667 9.63642 12.1052 9.74582 11.9958C9.85521 11.8864 9.91667 11.738 9.91667 11.5833V8.08333C9.91667 7.92862 9.85521 7.78025 9.74582 7.67085C9.63642 7.56146 9.48805 7.5 9.33334 7.5C9.17863 7.5 9.03025 7.56146 8.92086 7.67085C8.81146 7.78025 8.75 7.92862 8.75 8.08333Z"
          fill={color ? color : '#afb7d1'}
        />
        <path
          d="M11.666 5.74984V11.5832C11.666 11.7379 11.7275 11.8863 11.8369 11.9956C11.9463 12.105 12.0946 12.1665 12.2494 12.1665C12.4041 12.1665 12.5524 12.105 12.6618 11.9956C12.7712 11.8863 12.8327 11.7379 12.8327 11.5832V5.74984C12.8327 5.59513 12.7712 5.44675 12.6618 5.33736C12.5524 5.22796 12.4041 5.1665 12.2494 5.1665C12.0946 5.1665 11.9463 5.22796 11.8369 5.33736C11.7275 5.44675 11.666 5.59513 11.666 5.74984Z"
          fill={color ? color : '#afb7d1'}
        />
        <path
          d="M3.49922 5.75016C3.65392 5.75012 3.80227 5.68864 3.91164 5.57924L6.00347 3.48741C6.11466 3.38149 6.26233 3.32241 6.41589 3.32241C6.56945 3.32241 6.71712 3.38149 6.82831 3.48741L8.09531 4.75441C8.42348 5.08248 8.86852 5.26678 9.33256 5.26678C9.79659 5.26678 10.2416 5.08248 10.5698 4.75441L13.8283 1.49591C13.9346 1.38589 13.9934 1.23854 13.992 1.08559C13.9907 0.932641 13.9294 0.786333 13.8212 0.678179C13.713 0.570024 13.5667 0.508675 13.4138 0.507346C13.2608 0.506017 13.1135 0.564814 13.0035 0.671073L9.74497 3.92899C9.63558 4.03835 9.48724 4.09978 9.33256 4.09978C9.17788 4.09978 9.02953 4.03835 8.92014 3.92899L7.65314 2.66257C7.32497 2.3345 6.87993 2.1502 6.41589 2.1502C5.95185 2.1502 5.50681 2.3345 5.17864 2.66257L3.08681 4.75441C3.00525 4.83599 2.94972 4.93992 2.92722 5.05305C2.90472 5.16619 2.91627 5.28346 2.96041 5.39004C3.00455 5.49661 3.0793 5.58771 3.17521 5.65181C3.27111 5.71591 3.38387 5.75013 3.49922 5.75016Z"
          fill={color ? color : '#afb7d1'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1295_7016">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StatisticsIcon;
