/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import getGeneralSingleProductApi from '../../apis/general/getGeneralSingleProductApi';
import emptyImg from '../../assets/imgs/empty/not-found.png';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import EmptyCompoent from '../../common/empty-compoent/EmptyComponent';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import accountTypes from '../../const-values/accountTypes';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import GeneralProductData from './GeneralProductData';
import './GeneralSingleProductPage.scss';
import GeneralSingleProductRelatedProducts from './GeneralSingleProductRelatedProducts';

const GeneralSignleProductPage = () => {
  const { i18n, t } = useTranslation();
  const params = useParams();
  const { pathname } = useLocation();
  const [isLoadingSingleProduct, setIsLoadingSingleProduct] = useState(false);
  const [fetchSingleProductCount, setFetchSingleProductCount] = useState(0);
  const [fetchedSingleProduct, setFetchedSingleProduct] = useState(null);
  const [fetchedRelatedProducts, setFetchedRelatedProducts] = useState([]);
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    setIsLoadingSingleProduct(true);
    customApiRequest(
      getGeneralSingleProductApi(
        user?.token,
        {
          product_id: params?.productId
        },
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingSingleProduct(false);
          if (checkRes(res) && res.data?.data) {
            const productData = res.data.data.singleProduct;
            const relatedProducts = res.data.data.relatedProducts;
            setFetchedSingleProduct(productData);
            setFetchedRelatedProducts(relatedProducts);
          }
        }
      },
      (error) => {
        setIsLoadingSingleProduct(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchSingleProductCount, pathname, i18n.language]);

  if (isLoadingSingleProduct) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 332
        }}
      >
        <LoadingOutlined style={{ fontSize: 20 }} />
      </div>
    );
  } else if (!fetchedSingleProduct)
    return (
      <div className="shared-empty-wrapper">
        <img className="empty-img" src={emptyImg} alt="empty" />
        <p></p>
      </div>
    );
  else if (
    (!user || user?.accountType === accountTypes()?.user) &&
    fetchedSingleProduct
  )
    return (
      <>
        <Helmet>
          <title>{fetchedSingleProduct?.name}</title>
        </Helmet>

        <div className="general-single-product-page">
          <div className="breadcrumb-title">
            <CustomBreadcrumb
              arr={[
                {
                  title: t('main_app_bar_links.home'),
                  isLink: true,
                  to: routerLinks?.homePage
                },
                {
                  title: t('productsPage.title'),
                  isLink: true,
                  to: generalRouterLinks?.generalProductsRoute
                }
              ]}
            />
          </div>

          <div className="custom-container">
            <GeneralProductData
              isLoadingSingleProduct={isLoadingSingleProduct}
              setIsLoadingSingleProduct={setIsLoadingSingleProduct}
              fetchSingleProductCount={fetchSingleProductCount}
              setFetchSingleProductCount={setFetchSingleProductCount}
              fetchedSingleProduct={fetchedSingleProduct}
            />
            {fetchedSingleProduct?.description && (
              <div className="product-desc-wrap">
                <p className="desc-label">{t('singleProductPage.desc')}</p>
                <div className="desc-content">
                  {fetchedSingleProduct?.description}
                </div>
              </div>
            )}
          </div>

          {fetchedRelatedProducts?.length > 0 && (
            <GeneralSingleProductRelatedProducts
              slides={fetchedRelatedProducts}
              isLoading={isLoadingSingleProduct}
              setFetchCount={setFetchSingleProductCount}
            />
          )}
        </div>
      </>
    );

  return (
    <div className="general-single-product-page">
      <div className="custom-container">
        <div
          style={{
            textAlign: 'center',
            marginTop: 32
          }}
        >
          <EmptyCompoent img={emptyImg} />

          <h1
            style={{
              marginBottom: 12
            }}
          >
            {t('noAccess')}
          </h1>
          <h2>{t('loginAsUser')}</h2>
        </div>
      </div>
    </div>
  );
};

export default GeneralSignleProductPage;
