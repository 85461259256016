// 1=>active ,
// 2=>un active / hide
// 3=> in review
// 4=>rejected

const productStatus = {
  active: 1,
  hidden: 2,
  underReview: 3,
  rejected: 4
};

export default productStatus;
