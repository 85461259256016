/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import emptyImg from '../../../../assets/imgs/empty/empty.png';
import EmptyCompoent from '../../../../common/empty-compoent/EmptyComponent';
import SharedAntdTable from '../../../../common/shared-antd-table/SharedAntdTable';
import useUserTransactions from '../../../../custom-hooks/useUserTransactions';
import './styles.scss';
import TableColumns from './TableColumns';

const UserWalletTransactions = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const sharedTrans = (key) => t(`userDashboard.${key}`);
  const { isLoadingData, allFetchedTableData } = useUserTransactions();

  const renderPageContent = () => {
    if (isLoadingData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedTableData?.length === 0) {
      return <EmptyCompoent img={emptyImg} />;
    } else if (allFetchedTableData?.length > 0) {
      return (
        <div className="user-wallet-transactions-table-wrapper">
          <SharedAntdTable
            scroll={900}
            className={`user-messages-table ${i18n.dir()}`}
            hasPagination={true}
            tableDataSource={
              allFetchedTableData && allFetchedTableData?.length > 0
                ? allFetchedTableData.map((obj) => ({
                    ...obj,
                    key: obj.id
                  }))
                : []
            }
            tableColumns={TableColumns}
            allFetchedTableData={allFetchedTableData}
            //
            //

            isLoadingTableData={isLoadingData}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="custom-container">
      <div className="page-body">{renderPageContent()}</div>
    </div>
  );
};

export default UserWalletTransactions;
