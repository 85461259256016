/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';
import queryString from 'query-string';
import UserContext from '../contexts/user-context/UserProvider';
import productStatisticsApi from '../apis/stores-apis/productStatisticsApi';

const useProductStatistics = ({ fetchCount, setIsLoading, setFetchedData }) => {
  const { i18n } = useTranslation();
  const params = useParams();
  const customApiRequest = useCustomApiRequest();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { user } = useContext(UserContext);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    customApiRequest(
      productStatisticsApi(
        {
          product_id: params?.product_id,
          ...values
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedData(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount, search]);
};

export default useProductStatistics;
