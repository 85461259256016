const generalRouterLinks = {
  generalProductsRoute: '/products',
  generalSingleProductRoute: (productId) => {
    if (productId) return `/products/${productId}`;
    return '/products/:productId';
  },
  newProductsRoute: '/new-products',
  popularProductsRoute: '/popular-products',
  generalAffiliateProductRoute: (productId, agentKey) => {
    if (productId && agentKey) return `/products/${productId}/${agentKey}`;
    return '/products/:productId/:agentKey';
  },
  blogsRoute: '/blogs',
  contact_us: '/contact-us',
  singleBlogRoute: (blogId) => {
    if (blogId) return `/blogs/${blogId}`;
    return '/blogs/:blogId';
  },
  refundTermsRoute: '/refund-terms',
  merchantRefundTermsRoute: '/merchant-refund-terms',
  aboutRoute: '/about',
  privacyPolicyRoute: '/privacy',
  termsRoute: '/terms'
};

export default generalRouterLinks;
