/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../contexts/user-context/UserProvider';
import StoreOrdersContext from '../../../contexts/store-orders-context/StoreOrdersContext';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../utils/errorNotification';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import { orderStatusValues } from '../../../const-values/orderStatus';
import acceptOrRejectOrderApi from '../../../apis/stores-apis/acceptOrRejectOrderApi';
import './AcceptServiceForm.scss';

const AcceptServiceForm = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    selectedOrder,
    setSelectedOrder,
    setConfirmFormOpened,
    setIsAccepting,
    setFetchOrdersCount
  } = useContext(StoreOrdersContext);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        cart_id: Yup.string().required(
          t('ordersPage.invoiceNumber.errors.required')
        )
      })
    ),
    mode: 'all',
    defaultValues: {
      cart_id: ''
    }
  });

  const customApiRequest = useCustomApiRequest();

  const onSubmit = (data) => {
    if (data?.cart_id == selectedOrder?.id) {
      const formData = new FormData();
      formData.append('cart_id', data?.cart_id);
      formData.append('status', orderStatusValues?.completed);
      setIsAccepting(true);
      // setIsLoadSingleProduct(true);
      customApiRequest(
        acceptOrRejectOrderApi(formData, user?.token, i18n.language),
        (res) => {
          setIsAccepting(false);
          // setIsLoadSingleProduct(false);
          if (checkRes(res)) {
            successNotification({
              title: t('successTitle'),
              message: res?.data?.message || 'تمت الموافقة على الطلب'
            });
            setFetchOrdersCount((prev) => prev + 1);
            setConfirmFormOpened(false);
            setSelectedOrder(null);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsAccepting(false);
          // setIsLoadSingleProduct(false);
          errorNotification({
            title: t('errorTitle'),
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      errorNotification({
        title: t('errorTitle'),
        message: 'خطأ فى رقم الفاتورة'
      });
    }
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="accept-service-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <AntdTextField
            name="cart_id"
            type="text"
            style={{
              marginBottom: 0
            }}
            className="accept-input"
            placeholder={t('ordersPage.invoiceNumber.label')}
            // label={t('ordersPage.invoiceNumber.label')}
            // errorMsg={errors?.cart_id?.message}
            validateStatus={errors?.cart_id ? 'error' : ''}
            // prefix={<img src={receiptIcon} alt="receipt icon" />}
            control={control}
          />
        </div>

        <Button
          className={`submit-btn ${i18n.dir()}`}
          htmlType="submit"
          type="primary"
          // loading={isSubmittingForm}
        >
          {t('ordersPage.submit')}
        </Button>
      </Form>
    </>
  );
};

export default AcceptServiceForm;
