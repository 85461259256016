/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../contexts/user-context/UserProvider';
import './MerchentWalletForm.scss';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import storeWithdrawApi from '../../../apis/stores-apis/storeWithdarwApi';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import LoadingModal from '../../../common/loading-modal/LoadingModal';

const MerchentWalletForm = ({
  setModalOpened,
  selectedStore,
  setFetchCount,
  page = false
}) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        price: Yup.string()
          .required(t('walletPage.amount'))
          .test('price', t('walletPage.onlyNumbers'), (v, context) => {
            let result = true;
            if (v && !v.match(/^(\d+)?(\.\d+)?$/)) result = false;
            return result;
          })
      })
    ),
    mode: 'all',
    defaultValues: {
      price: ''
    }
  });

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const customApiRequest = useCustomApiRequest();

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('store_id', selectedStore?.id || '');
    formData.append('price', data.price);
    setIsSubmittingForm(true);
    // setIsLoadSingleProduct(true);
    customApiRequest(
      storeWithdrawApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        // setIsLoadSingleProduct(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: res?.data?.message || t('successMessage')
          });
          setFetchCount((prev) => prev + 1);
          setModalOpened(false);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || t('errorMessage')
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        // setIsLoadSingleProduct(false);
        errorNotification({
          title: t('errorTitle'),
          message: t('errorMessage')
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="user-wallet-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <AntdTextField
            className="amount-field"
            name="price"
            type="text"
            placeholder={t('walletPage.amount')}
            // label={t('walletPage.amount')}
            errorMsg={errors?.price?.message}
            validateStatus={errors?.price ? 'error' : ''}
            prefix={t('currency.sar')}
            control={control}
          />
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          loading={isSubmittingForm}
        >
          {t('walletPage.continue')}
        </Button>
      </Form>

      {isSubmittingForm && <LoadingModal />}
    </>
  );
};

export default MerchentWalletForm;
