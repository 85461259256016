/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/user-context/UserProvider';
import handleClickNotification from '../../utils/handleClickNotification';
import useReadNotification from '../../custom-hooks/useReadNotification';
// import successNotification from '../../utils/successNotification';
// import { store } from 'react-notifications-component';

// const duration = 10 * 60;
const duration = 5;
const ForegroundNotifications = ({
  fcmNotificationPayload,
  fcmNotificationFetchCount,
  setFetchCount,
  setFcmNotificationFetchCount,
  setFcmNotificationPayload
}) => {
  const navigate = useNavigate();
  const { readNotification } = useReadNotification();
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (fcmNotificationFetchCount > 0 && fcmNotificationPayload) {
      notification.destroy();
      notification.success({
        // message: fcmNotificationPayload?.data?.title,
        message: ' ',
        description: fcmNotificationPayload?.data?.body,
        duration,
        onClick: () => {
          handleClickNotification({
            obj: fcmNotificationPayload,
            user,
            navigate,
            setFetchCount: setFetchCount,
            readNotification
          });
        },
        placement: 'bottomLeft',
        className: 'fcm-foreground-wrapper'
      });
      // successNotification({
      //   id: 'fcm-notification',
      //   title: fcmNotificationPayload?.notification?.title,
      //   message: fcmNotificationPayload?.notification?.body,
      //   duration: 1000,
      //   onClick: () => {
      //     console.log('Notification Clicked!');
      //   }
      // });
    }

    setTimeout(() => {
      // store.removeNotification('fcm-notification');

      setFcmNotificationFetchCount(0);
      setFcmNotificationPayload(null);
    }, duration * 1000);
  }, [fcmNotificationFetchCount, fcmNotificationPayload]);
  return null;
};

export default ForegroundNotifications;
