import timeOfUseImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/time-of-use.png';
import coinsImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/coin-img.png';
import usersImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/users-img.png';
import { useTranslation } from 'react-i18next';

const AgentStatsContentWrapper = ({ obj }) => {
  const { t } = useTranslation();
  const sharedT = (key, options) =>
    t(`affiliateAgentStatisticsModal.${key}`, options);
  const StatisticsBox = ({ img, title, value, className }) => {
    return (
      <div className={`statistics-box ${className ? className : ''}`}>
        <img src={img} alt={title} />
        <p className="value-p">{value}</p>
        <p className="title-p">{title}</p>
      </div>
    );
  };

  const renderBoxs = () => {
    return (
      <div className="boxs-wrap">
        <StatisticsBox
          img={timeOfUseImg}
          value={obj?.useCount || 0}
          title={sharedT('timeOfUse')}
          className="s-box time-of-use"
        />
        <StatisticsBox
          img={coinsImg}
          value={`${obj?.myTotalValue || 0} ${t('currency.sar')}`}
          title={sharedT('totalSales')}
          className="s-box total-sales"
        />
        <StatisticsBox
          img={usersImg}
          value={obj?.newUsers || 0}
          title={sharedT('newUsers')}
          className="s-box new-users"
        />
      </div>
    );
  };

  return <div className="agent-stats-content-wrapper">{renderBoxs()}</div>;
};

export default AgentStatsContentWrapper;
