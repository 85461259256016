import CustomImage from '../../common/custom-image/CustomImage';
import trimWords from '../../utils/trimWords';
import { EyeOutlined } from '@ant-design/icons';
import { Link as RouterLink } from 'react-router-dom';
import './BlogCard.scss';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import { useTranslation } from 'react-i18next';

const BlogCard = ({ card, className }) => {
  const { t } = useTranslation();
  return (
    <div className={`blog-card ${className || ''}`}>
      <RouterLink to={generalRouterLinks?.singleBlogRoute(card?.id)}>
        <CustomImage src={card?.image} className="blog-img" />
      </RouterLink>
      <div className="blog-data">
        <div className="blog-cat">{card?.cat?.name}</div>
        <RouterLink
          to={generalRouterLinks?.singleBlogRoute(card?.id)}
          className="blog-title"
        >
          {card?.title}
        </RouterLink>

        {card?.content && (
          <div className="card-desc">{trimWords(card.content, 12)}</div>
        )}

        <div className="more-count">
          <RouterLink
            className="more"
            to={generalRouterLinks?.singleBlogRoute(card?.id)}
          >
            {t('blogsPage.readMore')}
          </RouterLink>
          <div className="count">
            <EyeOutlined /> {card?.viewCount}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
