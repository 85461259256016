/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import {
  useLocation,
  Navigate,
  Route,
  Routes as Switch
} from 'react-router-dom';
import accountTypes from '../../const-values/accountTypes';
import { ForgetPasswordProvider } from '../../contexts/forget-password-context/ForgetPasswordContext';
import { GeneralProductsProvider } from '../../contexts/general-products-context/GeneralProductsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import ActivateAccountPage from '../../pages/activate-account-page/ActivateAccountPage';
import ForgetPasswordPage from '../../pages/forget-password-page/ForgetPasswordPage';
import HomePage from '../../pages/home-page/HomePage';
import MerchentDashboardPage from '../../pages/merchent-pages/merchent-dashboard-page/MerchentDashboardPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import SigninPage from '../../pages/signin-page/SigninPage';
import EmployeeSignupPage from '../../pages/signup-page/EmployeeSignupPage';
import MerchentSignupPage from '../../pages/signup-page/MerchentSignupPage';
import UserSignupPage from '../../pages/signup-page/UserSignupPage';
import UserChatCenterPage from '../../pages/user-profile-menu-pages/user-chat-center-page/UserChatCenterPage';
import generalRoute from '../../utils/generalRoute';
import protectMe from '../../utils/protectMe';
import GeneralRoutes from './general-routes/GeneralRoutes';
import merchentRouterLinks from './merchent-routes/merchentRouterLinks';
import MerchentRoutes from './merchent-routes/MerchentRoutes';
import routerLinks from './routerLinks';
import UserRoutes from './user-routes/UserRoutes';
const Routes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const location = useLocation();
  const renderSigninRoute = () => {
    if (!loggedIn) {
      return <SigninPage />;
    } else if (loggedIn && user && !user?.activated) {
      return <Navigate to={routerLinks?.activateAccountRoute} />;
    } else {
      if (user?.accountType == accountTypes()?.user)
        return <Navigate to={routerLinks?.homePage} />;
      return <Navigate to={merchentRouterLinks?.merchentDashboard} />;
    }
  };
  const renderSignupRoute = (type) => {
    if (!loggedIn) {
      if (type == accountTypes()?.user) return <UserSignupPage />;
      else if (type == accountTypes()?.merchent) return <MerchentSignupPage />;
      else if (type == accountTypes()?.employee) return <EmployeeSignupPage />;
      return null;
    } else if (loggedIn && user && !user?.activated) {
      return <Navigate to={routerLinks?.activateAccountRoute} />;
    } else {
      if (
        user?.accountType == accountTypes()?.user ||
        user?.accountType == accountTypes()?.employee
      )
        return <Navigate to={routerLinks?.homePage} />;
      return <Navigate to={merchentRouterLinks?.merchentDashboard} />;
    }
  };

  return (
    <Switch location={location} key={location?.pathname}>
      <Route
        exact
        path={routerLinks?.userSignupRoute}
        element={renderSignupRoute(accountTypes()?.user)}
      />
      <Route
        exact
        path={routerLinks?.merchentSignupRoute}
        element={renderSignupRoute(accountTypes()?.merchent)}
      />
      <Route
        exact
        path={routerLinks?.employeeSignupRoute}
        element={renderSignupRoute(accountTypes()?.employee)}
      />
      <Route
        exact
        path={routerLinks?.signinPage}
        element={renderSigninRoute()}
      />
      <Route
        exact
        path={routerLinks?.forgetPasswordRoute}
        element={
          !loggedIn ? (
            <ForgetPasswordProvider>
              <ForgetPasswordPage />
            </ForgetPasswordProvider>
          ) : (
            <Navigate to={routerLinks?.signinPage} />
          )
        }
      />
      <Route
        exact
        path={routerLinks?.activateAccountRoute}
        element={
          loggedIn && user && !user?.activated ? (
            <ActivateAccountPage />
          ) : (
            <Navigate to={routerLinks?.signinPage} />
          )
        }
      />
      {/* {MainAppBarRoutes()} */}
      {user?.activated &&
      (user?.accountType == accountTypes()?.merchent ||
        user?.accountType == accountTypes()?.employee)
        ? protectMe(
            routerLinks?.merchentDashboard,
            <MerchentDashboardPage />,
            1,
            routerLinks?.signinPage,
            loggedIn,
            user,
            [accountTypes()?.merchent, accountTypes()?.employee]
          )
        : generalRoute(
            routerLinks?.homePage,
            <GeneralProductsProvider>
              <HomePage />
            </GeneralProductsProvider>,
            3,
            routerLinks?.signinPage,
            loggedIn,
            user
          )}

      {protectMe(
        routerLinks?.userChatCenter,
        <UserChatCenterPage />,
        'notifications_route_id',
        routerLinks?.signinPage,
        loggedIn,
        user,
        [
          accountTypes()?.user,
          accountTypes()?.employee,
          accountTypes()?.merchent
        ]
      )}
      {GeneralRoutes()}
      {/* {(user?.accountType == accountTypes()?.user ||
        user?.accountType == accountTypes()?.employee) &&
        UserRoutes()} */}
      {/* {user?.accountType == accountTypes()?.merchent && MerchentRoutes()} */}

      {UserRoutes()}
      {MerchentRoutes()?.length > 0 && MerchentRoutes()}

      <Route path="*" element={<NotFoundPage />} />
    </Switch>
  );
};

export default Routes;
