import React, { useContext } from 'react';
import { Modal } from 'antd';
import ProductsContext from '../../../contexts/products-context/ProductsContext';

const RejectedProductModal = () => {
  const {
    selectedTableRow,
    setSelectedTableRow,
    rejectedModalOpened,
    setRejectedModalOpened
  } = useContext(ProductsContext);

  return (
    <Modal
      transitionName=""
      className="slides-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">سبب رفض المنتج</div>}
      open={rejectedModalOpened}
      onOk={() => {
        setRejectedModalOpened(false);
        setSelectedTableRow(null);
      }}
      onCancel={() => {
        setRejectedModalOpened(false);
        setSelectedTableRow(null);
      }}
      footer={false}
    >
      {selectedTableRow?.refusedReason}
    </Modal>
  );
};

export default RejectedProductModal;
