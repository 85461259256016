import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useController } from 'react-hook-form';

const CustomAntdTimePicker = ({
  control,
  name,
  label,
  placeholder,
  validateStatus,
  defaultValue,
  errorMsg,
  className,
  formClassName,
  disablePast,
  handleDisableDate,
  disableFuture
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });

  const handleDisabledDate = (curr) => {
    if (handleDisableDate) {
      return handleDisableDate(curr);
    } else {
      if (disablePast) {
        // Can not select days before today and today
        // return curr && curr < dayjs().endOf('day');
        return curr && curr < dayjs().startOf('minute');
      } else if (disableFuture) {
        return curr && curr > dayjs().endOf('minute');
      }
      return false;
    }
  };

  return (
    <div className="custom-picker-wrap">
      <TimePicker
        disabledDate={handleDisabledDate}
        {...field}
        className={className}
        format="h:mm a"
        // format="YYYY-MM-DD"
        // 'HH:mm'
        placeholder={placeholder}
        size="large"
        defaultValue={defaultValue}
        variant="borderless"
        getPopupContainer={() => document.querySelector(`.${formClassName}`)}
        // value={defaultValue ? defaultValue : field.value}
      />

      {errorMsg && <p className="error-p">{errorMsg}</p>}
    </div>
  );
};

export default CustomAntdTimePicker;
