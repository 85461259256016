/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import emptyCartImg from '../../assets/imgs/icons/empty-cart.svg';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import EmptyCompoent from '../../common/empty-compoent/EmptyComponent';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import routerLinks from '../../components/app/routerLinks';
import CartContext from '../../contexts/cart-context/CartContext';
import CustomStepperWrapContext from '../../contexts/custom-stepper-wrap-context/CustomStepperWrapContext';
import CartStepperWrap from './CartStepperWrap';
import './UserCartPage.scss';

const UserCartPage = () => {
  const { t, i18n } = useTranslation();
  const { isLoadingCart, isRemovingProduct, cartProducts } =
    useContext(CartContext);
  const { step } = useContext(CustomStepperWrapContext);

  if (isLoadingCart) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: 332
        }}
      >
        <LoadingOutlined style={{ fontSize: 20 }} />
      </div>
    );
  } else if (
    !isLoadingCart &&
    (cartProducts?.length === 0 || !cartProducts) &&
    step !== 2
  ) {
    return (
      <EmptyCompoent
        img={emptyCartImg}
        p={
          i18n.language === 'ar'
            ? 'سلة الشراء فارغة!!'
            : 'Your cart is empty !!'
        }
      />
    );
  } else if (cartProducts?.length >= 0)
    return (
      <div className="user-cart-page">
        <div className="breadcrumb-title">
          <CustomBreadcrumb
            arr={[
              {
                title: t('main_app_bar_links.home'),
                isLink: true,
                to: routerLinks?.homePage
              },
              {
                title: t('userCartPage.pageTitle'),
                isLink: false
              }
            ]}
          />
          <div className="custom-container">
            <h2>{t('userCartPage.pageTitle')}</h2>
          </div>
        </div>

        <div className="custom-container">
          <Steps
            className="cart-steps-indicator"
            // size="small"
            current={step}
            items={[
              {
                title: t('userCartPage.stepOne')
              },
              {
                title: t('userCartPage.stepTwo')
              },
              {
                title: t('userCartPage.stepThree')
              }
            ]}
          />
          <CartStepperWrap />
        </div>

        {isRemovingProduct && <LoadingModal />}
      </div>
    );
  return null;
};

export default UserCartPage;
