import { Link as RouterLink } from 'react-router-dom';
import CustomImage from '../../common/custom-image/CustomImage';
import routerLinks from '../../components/app/routerLinks';
import './SaloonCard.scss';

const SaloonCard = ({ obj }) => {
  return (
    <RouterLink
      className="saloon-card"
      to={
        obj?.type === 1
          ? routerLinks.singleSaloonRoute(obj?.id)
          : routerLinks?.singleStoreRoute(obj?.id)
      }
      // to={routerLinks?.singleStoreRoute(obj?.id)}
    >
      <CustomImage className="saloon-img" src={obj?.image} />
      <p>{obj?.name}</p>
    </RouterLink>
  );
};

export default SaloonCard;
