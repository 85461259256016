const baseLabelTransString = (t, key) => t(`serviceStatus.${key}`);

export const paymentStatusValues = {
  paid: 1,
  unpaid: 2
};

export const paymentStatusArr = (t) => {
  return [
    {
      id: 1,
      name: t('paid'),
      value: paymentStatusValues.paid,
      color: 'green'
    },
    {
      id: 2,
      name: t('unpaid'),
      value: paymentStatusValues.unpaid,
      color: 'red'
    }
  ];
};

export const serviceOrderStatusValues = {
  new: 1,
  pending: 2,
  completed: 3,
  rejected: 4
};

export const serviceOrderStatusArr = (t) => {
  return [
    {
      name: baseLabelTransString(t, 'new'),
      value: 1,
      color: 'blue',
      id: 1
    },
    {
      name: baseLabelTransString(t, 'pending'),
      value: 2,
      color: 'orange',
      id: 2
    },
    {
      name: baseLabelTransString(t, 'completed'),
      value: 3,
      color: 'green',
      id: 3
    },
    {
      name: baseLabelTransString(t, 'rejected'),
      value: 4,
      color: 'red',
      id: 4
    }
  ];
};
