/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import getAllOtherServicesApi from '../apis/other-services-apis/getAllOtherServicesApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useOtherServices = () => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [fetchServicesCount, setFetchServicesCount] = useState(0);
  const [allFetchedServices, setAllFetchedServices] = useState([]);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    setIsLoadingServices(true);
    customApiRequest(
      getAllOtherServicesApi(null, user?.token, i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoadingServices(false);
          if (checkRes(res) && res?.data?.data) {
            setAllFetchedServices(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoadingServices(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchServicesCount]);

  return {
    isLoadingServices,
    setIsLoadingServices,
    //
    fetchServicesCount,
    setFetchServicesCount,
    //
    allFetchedServices,
    setAllFetchedServices
  };
};

export default useOtherServices;
