/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import getAllOtherServicesApi from '../apis/other-services-apis/getAllOtherServicesApi';
import getServiceOrdersApi from '../apis/other-services-apis/getServiceOrdersApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useOtherServiceOrders = () => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [allFetchedData, setAllFetchedData] = useState([]);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    customApiRequest(
      getServiceOrdersApi(null, user?.token, i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setAllFetchedData(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount]);

  return {
    isLoading,
    setIsLoading,
    //
    fetchCount,
    setFetchCount,
    //
    allFetchedData,
    setAllFetchedData
  };
};

export default useOtherServiceOrders;
