import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import calendarImg from '../../assets/imgs/icons/calendar.png';
import { LoadingOutlined } from '@ant-design/icons';
import useSingleBlog from '../../custom-hooks/useSingleBlog';
import { Empty } from 'antd';
import BlogsSideCats from './BlogsSideCats';
import './SingleBlogPage.scss';
import CustomImage from '../../common/custom-image/CustomImage';
import { Link as RouterLink } from 'react-router-dom';

const SingleBlogPage = () => {
  const { t } = useTranslation();
  const { isLoading, fetchedBlog, fetchedRelatedBlogs } = useSingleBlog();

  if (isLoading) {
    return (
      <div
        className="custom-container"
        style={{
          marginTop: 92,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    );
  } else if (!fetchedBlog) {
    return <Empty description={false}>Blog Not Found!!!</Empty>;
  } else if (fetchedBlog)
    return (
      <div className="single-blog-page">
        <div className="breadcrumb-title">
          <CustomBreadcrumb
            arr={[
              {
                title: t('main_app_bar_links.home'),
                isLink: true,
                to: routerLinks?.homePage
              },
              {
                title: t('main_app_bar_links.blogs'),
                isLink: true,
                to: generalRouterLinks?.blogsRoute
              },
              {
                title: fetchedBlog.title,
                isLink: false
              }
            ]}
          />
          <div className="custom-container">
            <h2 className="page-title">{fetchedBlog.title}</h2>
            {fetchedBlog?.date && (
              <div className="date-wrap">
                <img src={calendarImg} alt="date" /> {fetchedBlog.date}
              </div>
            )}
          </div>
        </div>

        <div className="custom-container">
          <div className="main-page-wrap">
            <div className="blog-details">
              <CustomImage src={fetchedBlog.image} className="blog-img" />

              <div className="blog-desc">{fetchedBlog.content}</div>
            </div>

            <div className="filter-related-wrap">
              <BlogsSideCats />

              {fetchedRelatedBlogs?.length > 0 && (
                <div className="related-wrap">
                  <h3>{t('blogsPage.relatedBlogs')}</h3>

                  <div className="related-blogs-ul">
                    {fetchedRelatedBlogs.map((obj) => (
                      <RouterLink
                        className="blog-wrap"
                        to={generalRouterLinks?.singleBlogRoute(obj?.id)}
                      >
                        <div className="blog-media">
                          <img src={obj?.image} alt="" />
                        </div>
                        <div className="blog-title__date">
                          <div className="cat-wrap">{obj?.cat?.name}</div>
                          <div className="title">{obj?.title}</div>
                          <div className="blog-date">
                            <i className="icon-calendar"></i>
                            <span> {obj?.date} </span>
                          </div>
                        </div>
                      </RouterLink>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );

  return null;
};

export default SingleBlogPage;
