import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import UserWalletForm from './UserWalletForm';
import filledWalletImg from '../../../assets/imgs/icons/wallet-full.png';
import './UserWalletModal.scss';

const UserWalletModal = ({ modalOpened, setModalOpened }) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="user-wallet-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '842px' }}
      title=""
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <img className="wallet-img" src={filledWalletImg} alt="wallet img" />
      <h2>{t('walletPage.addMoney')}</h2>
      <UserWalletForm setModalOpened={setModalOpened} />
    </Modal>
  );
};

export default UserWalletModal;
