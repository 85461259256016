const baseLabelTransString = (t, key) => t(`product_form.${key}.label`);

export const refundOptionsValues = {
  notRefundable: '1',
  oneDayRefundable: '2',
  threeDaysRefundable: '3',
  sevenDaysRefundable: '4',
  refundAnyTime: '5'
};
const refundOptions = (t) => {
  return [
    {
      title: baseLabelTransString(t, 'notRefundalbe'),
      value: '1',
      id: '1'
    },
    {
      title: baseLabelTransString(t, '1DayRefundable'),
      value: '2',
      id: '2'
    },
    {
      title: baseLabelTransString(t, '3DaysRefundable'),
      value: '3',
      id: '3'
    },
    {
      title: baseLabelTransString(t, '7DaysRefundable'),
      value: '4',
      id: '4'
    },
    {
      title: baseLabelTransString(t, 'refundAnyTime'),
      value: '5',
      id: '5'
    }
  ];
};

export default refundOptions;
