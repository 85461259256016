/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import queryString from 'query-string';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import checkRes from '../../utils/checkRes';
import { LoadingOutlined } from '@ant-design/icons';
import { Pagination } from 'antd';
import SaloonStoreCard from './SaloonStoreCard';
import SingleSaloonContext from '../../contexts/single-saloon-context/SingleSaloonContext';
import singleSaloonApi from '../../apis/general/singleSaloonApi';
import CustomImage from '../../common/custom-image/CustomImage';
// import PinIcon from '../../common/icons/PinIcon';
import emptyImg from '../../assets/imgs/empty/empty.png';
import './SingleSaloonPage.scss';

const SingleSaloonPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const params = useParams();
  const values = queryString.parse(search);
  const {
    isLoadingData,
    setIsLoadingData,
    fetchedSaloonData,
    fetchedSaloonStores,
    setFetchedSaloonData,
    setFetchedSaloonStores,
    tableFilter,
    setTablePagination,
    setFetchDataCount,
    fetchDataCount,
    tablePagination
  } = useContext(SingleSaloonContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingData(true);
    customApiRequest(
      singleSaloonApi(
        {
          ...tableFilter,
          ...values,
          saloonId: params?.saloonId
        },
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingData(false);
          if (checkRes(res)) {
            if (res?.data?.data?.saloonData)
              setFetchedSaloonData(res?.data?.data.saloonData);
            if (res?.data?.data?.stores?.data?.length >= 0)
              setFetchedSaloonStores(res.data.data.stores.data);
            if (res?.data?.data?.stores?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchDataCount, i18n.language]);
  const renderSaloonData = () => {
    return (
      <div className="saloon-data">
        <div className="img-name-active">
          <CustomImage className="saloon-img" src={fetchedSaloonData?.image} />

          <div className="saloon-name">
            {fetchedSaloonData?.saloon?.saloonName}
          </div>
        </div>

        <div className="saloon-desc">
          <p className="label-p">{t('saloonDetailsPage.descLabel')}</p>
          <div className="desc-text">
            {fetchedSaloonData?.saloon?.saloonDescription}
          </div>
        </div>

        {/* <div className="contact-info">
          <p className="label-p">{t('saloonDetailsPage.contactLabel')}</p>

          <div className="location-wrap">
            <div className="country-city">
              <PinIcon />
              <span>{fetchedSaloonData?.saloon?.country}</span>/
              <span>{fetchedSaloonData?.saloon?.city}</span>
            </div>
            <div className="addresss-wrap">
              <PinIcon />
              <span>{fetchedSaloonData?.saloon?.address}</span>
            </div>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSaloonsList = () => {
    if (isLoadingData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center',
            minHeight: 332
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 24, width: 24, height: 24 }}
            spin
          />
        </div>
      );
    } else if (!fetchedSaloonData) {
      return (
        <div className="shared-empty-wrapper">
          <img className="empty-img" src={emptyImg} alt="empty" />
          <p>الصالون غير متاح</p>
        </div>
      );
    } else if (fetchedSaloonData) {
      return (
        <div className="list-pagination-wrap">
          {renderSaloonData()}
          {fetchedSaloonStores?.length > 0 && (
            <>
              <h3>{t('saloonDetailsPage.storesTitle')}</h3>
              <div className="saloons-wrap">
                {fetchedSaloonStores.map((obj) => (
                  <SaloonStoreCard key={obj?.id} obj={obj} />
                ))}
              </div>

              {tablePagination?.total > 0 && (
                <Pagination
                  locale={{
                    jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                    // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                    page: ''
                  }}
                  showSizeChanger={false}
                  className="saloons-pagination"
                  defaultCurrent={1}
                  current={tablePagination.current_page}
                  pageSize={tablePagination.per_page}
                  otal={tablePagination.total}
                  // itemRender={itemRender}
                  onChange={(page, pageSize) => {
                    setFetchDataCount((prev) => prev + 1);
                    navigate(`${fetchDataCount?.topSaloonsRoute}?page=${page}`);
                  }}
                  hideOnSinglePage={true}
                />
              )}
            </>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="single-saloon-page">
      <div className="custom-container">{renderSaloonsList()}</div>
    </div>
  );
};

export default SingleSaloonPage;
