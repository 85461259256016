/* eslint-disable eqeqeq */

const UserMessagesTableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t,
  props
) => {
  const sharedT = (key) => t(`messagesPage.${key}`);
  return [
    {
      title: '#',
      dataIndex: 'id',
      render: (_, record) => record.id
    },
    {
      title: sharedT('description'),
      dataIndex: 'description',
      render: (_, record) => {
        return record?.description || '----';
      }
    }
  ];
};

export default UserMessagesTableColumns;
