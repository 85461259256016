/* eslint-disable react-hooks/exhaustive-deps */
import timeOfUseImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/time-of-use.png';
import coinsImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/coin-img.png';
import usersImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/users-img.png';
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  PDFViewer,
  Font
} from '@react-pdf/renderer';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import getSingleAgentApi from '../../../apis/stores-apis/getSingleAgentApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { LoadingOutlined } from '@ant-design/icons';
import './AffiliateStatisticsSharePdfPage.scss';

// ../assets/fonts/Poppins-Regular.woff2) format('woff2'
// import fontSrc from '../../../assets/fonts/Cairo-Regular.ttf';
import Cairo from '../../../assets/fonts/Cairo-Regular.ttf';

Font.register({
  // family: 'PoppinsRegular',
  // src: fontSrc

  family: 'Cairo',
  fonts: [
    {
      src: Cairo
    }
  ]
});

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: '22px',
    backgroundColor: '#fff',
    fontFamily: 'Cairo',
    direction: 'rtl',
    display: 'flex',
    alignItems: 'center',
    height: '400px'
  },
  statisticsBox: {
    backgroundColor: '#fff',
    border: '1px solid #000',
    borderRadius: '8px',
    padding: '28px 18px',
    textAlign: 'cener',
    width: '31%',
    fontSize: '14px',
    marginRight: '2%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxTitle: {},
  boxValue: {
    fontSize: '20px',
    fontWeight: 'bold'
  }
});

const StatisticsBox = ({ img, title, value, style }) => {
  return (
    <View
      style={{
        ...styles.statisticsBox,
        ...style
      }}
    >
      <Image
        style={{
          width: 42
        }}
        src={img}
        alt={title}
      />
      <Text style={styles.boxValue}>{value}</Text>
      <Text>{title}</Text>
    </View>
  );
};

const AffiliateStatisticsSharePdfPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedAgent, setFetchedAgent] = useState(null);
  const params = useParams();
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const sharedT = (key, options) =>
    t(`affiliateAgentStatisticsModal.${key}`, options);
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    customApiRequest(
      getSingleAgentApi(
        {
          id: params?.agent_id
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setFetchedAgent(data);
          } else {
            setFetchedAgent(null);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, []);

  const MyDocument = () => (
    <Document language={i18n.language}>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            justifyContent: 'center',
            height: '300px',
            alignItems: 'center'
          }}
        >
          <StatisticsBox
            img={usersImg}
            value={fetchedAgent?.newUsers || 0}
            title={sharedT('newUsers')}
            className="time-of-use"
            style={{
              color: '#9d4edd',
              borderColor: '#9d4edd'
            }}
          />
          <StatisticsBox
            img={coinsImg}
            value={fetchedAgent?.myTotalValue || 0}
            title={sharedT('totalSales')}
            className="time-of-use"
            style={{
              color: '#25d366',
              borderColor: '#25d366'
            }}
          />
          <StatisticsBox
            img={timeOfUseImg}
            value={fetchedAgent?.useCount || 0}
            title={sharedT('timeOfUse')}
            style={{
              color: '#fb2e86',
              borderColor: '#fb2e86'
            }}
          />
        </View>
      </Page>
    </Document>
  );

  if (isLoading) {
    return (
      <div
        className="custom-container"
        style={{
          paddingTop: 92,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    );
  } else if (fetchedAgent)
    return (
      <div className="pdf-share-page">
        <div className="custom-container">
          <PDFViewer
            style={{
              width: '80vw',
              maxWidth: '1024px',
              height: '82vh',
              border: 0,
              outline: 0,
              borderRadius: 18
            }}
          >
            <MyDocument />
          </PDFViewer>
        </div>
      </div>
    );

  return null;
};

export default AffiliateStatisticsSharePdfPage;
