import "./MessagesWrapper.styles.scss";
import React from "react";
import ChatGroups from "./ChatGroups";
import Messages from "./Messages";

const MessagesWrapper = () => {
	return (
		<div className="messages-wrapper">
			<Messages />
			<ChatGroups />
		</div>
	);
};

export default MessagesWrapper;
