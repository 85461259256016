/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { Button, Drawer, Dropdown, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  useLocation,
  Link as RouterLink,
  Link
} from 'react-router-dom';
import LanguageButton from '../../common/language-button/LanguageButton';
import Logo from '../../common/logo/Logo';
import mainAppBarLinks from './mainAppBarLinks';
import './MainAppBarMd.scss';
import UserContext from '../../contexts/user-context/UserProvider';
import routerLinks from '../app/routerLinks';
import useSignout from '../../custom-hooks/useSignout';
import CustomImage from '../../common/custom-image/CustomImage';
import accountTypes from '../../const-values/accountTypes';
import generalRouterLinks from '../app/general-routes/gerneralRouterLinks';
import userRouterLinks from '../app/user-routes/uesRouterLinks';
import FixedBottomHeaderMd from './FixedBottomHeaderMd';
import FilterCatsContext from '../../contexts/filter-cats/FilterCatsContext';

const MainAppBarMd = ({ className, exceeds0 }) => {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { user, loggedIn } = useContext(UserContext);
  const { fetchedData: allFetchedFilterCats } = useContext(FilterCatsContext);

  const { signMeOut } = useSignout();
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const renderCatFilterIdLabel = (obj) => {
    if (obj?.filterCat?.cats?.length > 0) {
      return <span>{obj?.filterCat?.name}</span>;
    }
    return (
      <Link
        onClick={(e) => {
          e.stopPropagation();
          setVisible(false);
        }}
        to={`${generalRouterLinks?.generalProductsRoute}?catFilter_id=${
          obj?.filterCat?.id || ''
        }`}
      >
        <span>{obj?.filterCat?.name}</span>
      </Link>
    );
  };

  function getItem(obj, key, icon, children, type) {
    return {
      className:
        obj?.filterCat?.cats?.length > 0
          ? 'main-label with-drop-down'
          : 'main-label without-drop-down',
      key,
      icon,
      children,
      label: renderCatFilterIdLabel(obj),
      type
    };
  }

  const renderLabelLink = (obj) => {
    return (
      <Link
        onClick={(e) => {
          e.stopPropagation();
          setVisible(false);
        }}
        to={`${generalRouterLinks?.generalProductsRoute}?cat_id=${
          obj?.cat?.id || ''
        }&catFilter_id=${obj?.filterCat?.id || ''}`}
      >
        {obj?.cat?.name}
      </Link>
    );
  };
  function getItemWithLink(obj, key, icon, children, type) {
    return {
      label: renderLabelLink(obj),
      key,
      icon,
      children: null
    };
  }

  const [items, setItems] = useState([]);
  useEffect(() => {
    if (allFetchedFilterCats?.length > 0) {
      setItems(
        allFetchedFilterCats.map((filterCat) => {
          return getItem(
            { filterCat },
            filterCat?.id,
            null,
            filterCat?.cats?.length > 0
              ? filterCat.cats.map((cat) => {
                  return getItemWithLink(
                    {
                      filterCat,
                      cat
                    },
                    cat?.id,
                    null
                  );
                })
              : null
          );
        })
      );
    }
  }, [allFetchedFilterCats]);

  const DrawerTitle = () => {
    if (user?.name) {
      return (
        <RouterLink
          onClick={() => setVisible(false)}
          to={userRouterLinks?.userDashboard}
          className="username-img"
        >
          <CustomImage className="user-img" src={user?.image} />
          <div className="username">{user?.name}</div>
        </RouterLink>
      );
    }
    return i18n.language === 'ar' ? 'سوق الكنترول' : 'ECU Souq';
  };

  const renderMenuSubMenu = () => {
    return (
      <Menu
        style={{
          border: 0
        }}
        // defaultSelectedKeys={['1']}
        // defaultOpenKeys={['sub1']}
        mode="inline"
        items={items}
      />
    );
  };

  const renderNavLinks = () => {
    return (
      <ul>
        {mainAppBarLinks(i18n, t, user).map(({ id, name, link, sub }) => (
          <li key={id}>
            <NavLink
              className={({ isActive }) =>
                isActive ? 'active-link main-nav-link' : 'main-nav-link'
              }
              to={link}
            >
              {sub?.length > 0 ? (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 1,
                        label: '---'
                      }
                    ]
                  }}
                >
                  <div className="main-nav-link-div">
                    <span>{name}</span>
                    <div className="active-img-wrap"></div>
                  </div>
                </Dropdown>
              ) : (
                <div className="main-nav-link-div">
                  <span>{name}</span>
                  <div className="active-img-wrap"></div>
                </div>
              )}
            </NavLink>
          </li>
        ))}
        {/*  */}
        {(user?.type == accountTypes()?.user || !user) &&
          renderMenuSubMenu(allFetchedFilterCats)}

        <li key="blogs_key">
          <NavLink
            className={({ isActive }) =>
              isActive ? 'active-link main-nav-link' : 'main-nav-link'
            }
            to={generalRouterLinks?.blogsRoute}
          >
            <div className="main-nav-link-div">
              <span>{t('main_app_bar_links.blogs')}</span>
              <div className="active-img-wrap"></div>
            </div>
          </NavLink>
        </li>
      </ul>
    );
  };

  return (
    <>
      <div className={className}>
        <div className="custom-container">
          <div className="nav-content-wrapper">
            <div className="nav-lang-wrapper">
              <Button
                style={{
                  color: '#000'
                }}
                onClick={showDrawer}
                edge="start"
                // className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuOutlined />
              </Button>
              <LanguageButton />
            </div>

            <Logo
              colored={exceeds0 || pathname !== '/'}
              className="main-app-bar-logo"
            />
          </div>
        </div>
        <Drawer
          title={<DrawerTitle />}
          placement={i18n.dir() === 'ltr' ? 'left' : 'right'}
          onClose={onClose}
          open={visible}
          className="header-md-drawer"

          // anchor="right"
        >
          <div className="logo-links">
            {/* <Logo className="main-app-bar-logo" /> */}
            {renderNavLinks()}
          </div>

          {!loggedIn ? (
            <RouterLink
              className="appbar-signin-link"
              to={routerLinks?.signinPage}
              onClick={() => setVisible(false)}
            >
              {t('signinSignup.signin')}
            </RouterLink>
          ) : (
            <div className="signout-messages-wrap">
              <button
                onClick={() => {
                  signMeOut();
                  setVisible(false);
                }}
              >
                {i18n.language === 'ar' && 'تسجيل الخروج'}
                {i18n.language === 'en' && 'Signout'}
              </button>
              {/* <div className="messages-wrap">
              <MainAppMessages isAppbarMd />
            </div> */}
            </div>
          )}
        </Drawer>
      </div>
      <FixedBottomHeaderMd />
    </>
  );
};

export default MainAppBarMd;
