import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { sortArr } from '../../const-values/sortOptions';
import './SortBy.scss';

const { Option } = Select;

const SortBy = ({ handleChangeSort, defaultValue }) => {
  const { t } = useTranslation();
  const handleChange = (v) => {
    if (handleChangeSort && typeof handleChangeSort === 'function')
      handleChangeSort(v);
  };

  return (
    <Select
      className="sort-by-select"
      defaultValue={defaultValue || ''}
      style={{ width: 162 }}
      size="large"
      onChange={handleChange}
      suffixIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="5"
          viewBox="0 0 10 5"
          fill="none"
        >
          <path
            d="M1 0.5L5 4.5L9 0.5"
            stroke="#6D6D6D"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
    >
      <Option value="">{t('sort.empty')}</Option>
      {sortArr(t)?.length > 0 &&
        sortArr(t).map((op, index) => (
          <Option key={index} value={op.value}>
            {op.name}
          </Option>
        ))}
    </Select>
  );
};

export default SortBy;
