import React from 'react';

const StatisticsIcon = ({ color }) => {
  return (
    <svg
      width="59"
      height="59"
      viewBox="0 0 59 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.6905 32.1305C49.7564 32.1305 48.9697 31.4175 48.8468 30.4834C48.2568 24.9275 45.2576 19.888 40.6359 16.6921C39.8001 16.1021 39.6034 14.9713 40.1689 14.1355C40.7589 13.2996 41.9143 13.103 42.7255 13.6684C48.2322 17.4788 51.7722 23.4771 52.4851 30.09C52.5834 31.098 51.8705 32.0075 50.838 32.1305C50.8134 32.1305 50.7397 32.1305 50.6905 32.1305Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M8.57911 32.2533C8.50536 32.2533 8.45619 32.2533 8.38244 32.2533C7.37452 32.1304 6.63702 31.2208 6.73536 30.2129C7.39911 23.5999 10.9145 17.6262 16.3474 13.7666C17.1833 13.1766 18.3387 13.3733 18.9287 14.2091C19.5187 15.0449 19.322 16.2004 18.4862 16.7904C13.9137 20.0108 10.9637 25.0504 10.3983 30.6062C10.3245 31.5404 9.51327 32.2533 8.57911 32.2533Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M29.6477 55.5829C26.0094 55.5829 22.5432 54.7471 19.2982 53.1246C18.3886 52.6575 18.0198 51.5512 18.4869 50.6417C18.954 49.7321 20.0602 49.3633 20.9698 49.8304C26.2798 52.51 32.6715 52.5592 38.0307 49.9779C38.9402 49.5354 40.0465 49.9287 40.489 50.8383C40.9315 51.7479 40.5382 52.8542 39.6286 53.2967C36.4819 54.8208 33.1386 55.5829 29.6477 55.5829Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M29.6476 20.7483C24.8539 20.7483 20.9697 16.8641 20.9697 12.0704C20.9697 7.27662 24.8539 3.39246 29.6476 3.39246C34.4414 3.39246 38.3256 7.27662 38.3256 12.0704C38.3256 16.8641 34.4168 20.7483 29.6476 20.7483ZM29.6476 7.10454C26.8943 7.10454 24.6572 9.34162 24.6572 12.095C24.6572 14.8483 26.8943 17.0854 29.6476 17.0854C32.401 17.0854 34.6381 14.8483 34.6381 12.095C34.6381 9.34162 32.3764 7.10454 29.6476 7.10454Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M11.8742 50.8137C7.08046 50.8137 3.19629 46.9295 3.19629 42.1358C3.19629 37.3666 7.08046 33.4579 11.8742 33.4579C16.668 33.4579 20.5521 37.3421 20.5521 42.1358C20.5521 46.905 16.668 50.8137 11.8742 50.8137ZM11.8742 37.1454C9.12087 37.1454 6.88379 39.3825 6.88379 42.1358C6.88379 44.8891 9.12087 47.1262 11.8742 47.1262C14.6275 47.1262 16.8646 44.8891 16.8646 42.1358C16.8646 39.3825 14.6275 37.1454 11.8742 37.1454Z"
        fill={color ? color : '#6D6D6D'}
      />
      <path
        d="M47.1262 50.8137C42.3324 50.8137 38.4482 46.9295 38.4482 42.1358C38.4482 37.3666 42.3324 33.4579 47.1262 33.4579C51.9199 33.4579 55.8041 37.3421 55.8041 42.1358C55.7795 46.905 51.8953 50.8137 47.1262 50.8137ZM47.1262 37.1454C44.3728 37.1454 42.1357 39.3825 42.1357 42.1358C42.1357 44.8891 44.3728 47.1262 47.1262 47.1262C49.8795 47.1262 52.1166 44.8891 52.1166 42.1358C52.092 39.3825 49.8795 37.1454 47.1262 37.1454Z"
        fill={color ? color : '#6D6D6D'}
      />
    </svg>
  );
};

export default StatisticsIcon;
