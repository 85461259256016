const renderTime = (time, i18n) => {
  // const str = time.slice(time.length - 2)
  let arr = time?.trim().split('');
  if (arr?.length > 0) {
    let s = arr.splice(arr.length - 2, 2).join(''); // pm
    let timeInt = arr.join('')?.trim(); // 09:45
    if (i18n?.language === 'en' && (s === 'pm' || s === 'am')) return time;
    else if (i18n?.language === 'ar') {
      if (s === 'pm') return `${timeInt} م`;
      return `${timeInt} ص`;
    }
  }
  return null;
};

export default renderTime;
