export const guaranteeOptionsValues = {
  threeHour: '1',
  no_guarantee: '2'
};
const guaranteeOptions = (t) => {
  return [
    {
      title: t('three_hours_guarantee'),
      value: guaranteeOptionsValues.threeHour,
      id: '1'
    },
    {
      title: t('no_guarantee'),
      value: guaranteeOptionsValues.no_guarantee,
      id: '2'
    }
  ];
};

export default guaranteeOptions;
