import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import successImg from '../../assets/imgs/icons/success.png';
import './ChooseCartOrShoppingModal.scss';
import CartIcon from '../../common/icons/CartIcon';

const ChooseCartOrShoppingModal = ({ modalOpened, setModalOpened }) => {
  const { i18n } = useTranslation();

  return (
    <Modal
      className="shared-custom-modal choose-cart-modal"
      width="96%"
      style={{ maxWidth: '642px' }}
      // title={t('signup_form.terms')}
      title={
        i18n.language === 'ar'
          ? 'تمت إضافة الخدمة الى السلة بنجاح'
          : 'Added to cart successfully!!!'
      }
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <img src={successImg} alt="success" />
      {/* <p className="success-text">
        {i18n.language === 'ar'
          ? 'تمت إضافة الخدمة الى السلة بنجاح'
          : 'Added to cart successfully!!!'}
      </p> */}

      <div className="modal-btns-wrap">
        <RouterLink
          className="modal-btn cart-btn"
          to={userRouterLinks?.userCartRoute}
        >
          <CartIcon color="#fff" />
          {i18n.language === 'ar' ? 'الذهاب الى عربة التسوق' : 'Shopping cart'}
        </RouterLink>
        <button
          onClick={() => {
            setModalOpened(false);
          }}
          type="button"
          className="modal-btn continue-btn"
        >
          {i18n.language === 'ar' ? 'أكمل التسوق' : 'Continue Shopping'}
        </button>
      </div>
    </Modal>
  );
};

export default ChooseCartOrShoppingModal;
