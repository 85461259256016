import React from 'react';

const ProductsIcon = ({ color }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1317_26698)">
        <path
          d="M5.74935 8.66667H9.24934C9.40405 8.66667 9.55242 8.60521 9.66182 8.49582C9.77121 8.38642 9.83267 8.23805 9.83267 8.08334C9.83267 7.92863 9.77121 7.78025 9.66182 7.67086C9.55242 7.56146 9.40405 7.5 9.24934 7.5H5.74935C5.59464 7.5 5.44627 7.56146 5.33687 7.67086C5.22747 7.78025 5.16602 7.92863 5.16602 8.08334C5.16602 8.23805 5.22747 8.38642 5.33687 8.49582C5.44627 8.60521 5.59464 8.66667 5.74935 8.66667Z"
          fill={color ? color : '#6D6D6D'}
        />
        <path
          d="M11.5833 0.5H3.41667C2.6434 0.500926 1.90208 0.808514 1.35529 1.35529C0.808514 1.90208 0.500926 2.6434 0.5 3.41667L0.5 4C0.50017 4.24602 0.552209 4.48923 0.652722 4.71378C0.753235 4.93832 0.899964 5.13916 1.08333 5.30317V11.5833C1.08426 12.3566 1.39185 13.0979 1.93863 13.6447C2.48541 14.1915 3.22674 14.4991 4 14.5H11C11.7733 14.4991 12.5146 14.1915 13.0614 13.6447C13.6082 13.0979 13.9157 12.3566 13.9167 11.5833V5.30317C14.1 5.13916 14.2468 4.93832 14.3473 4.71378C14.4478 4.48923 14.4998 4.24602 14.5 4V3.41667C14.4991 2.6434 14.1915 1.90208 13.6447 1.35529C13.0979 0.808514 12.3566 0.500926 11.5833 0.5V0.5ZM1.66667 3.41667C1.66667 2.95254 1.85104 2.50742 2.17923 2.17923C2.50742 1.85104 2.95254 1.66667 3.41667 1.66667H11.5833C12.0475 1.66667 12.4926 1.85104 12.8208 2.17923C13.149 2.50742 13.3333 2.95254 13.3333 3.41667V4C13.3333 4.15471 13.2719 4.30308 13.1625 4.41248C13.0531 4.52187 12.9047 4.58333 12.75 4.58333H2.25C2.09529 4.58333 1.94692 4.52187 1.83752 4.41248C1.72812 4.30308 1.66667 4.15471 1.66667 4V3.41667ZM12.75 11.5833C12.75 12.0475 12.5656 12.4926 12.2374 12.8208C11.9092 13.149 11.4641 13.3333 11 13.3333H4C3.53587 13.3333 3.09075 13.149 2.76256 12.8208C2.43437 12.4926 2.25 12.0475 2.25 11.5833V5.75H12.75V11.5833Z"
          fill={color ? color : '#6D6D6D'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1317_26698">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProductsIcon;
