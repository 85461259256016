import axios from 'axios';

export const checkPasswordCodeApi = async (values, token, langIsoCode) => {
  const url = '/Auth_private/check_active_code';
  try {
    const res = await axios.post(url, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        lang: langIsoCode || 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const resendCodeApi = async (values, token, langIsoCode) => {
  const url = '/Auth_private/resend_code';
  try {
    const res = await axios.post(url, values, {
      headers: {
        Authorization: `Bearer ${token}`,
        lang: langIsoCode || 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};
