import React from 'react';

const RemoveUserIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.5007 31.3441C21.7077 31.3441 15.3652 25.0016 15.3652 17.2087C15.3652 9.41574 21.7077 3.07324 29.5007 3.07324C37.2936 3.07324 43.6361 9.41574 43.6361 17.2087C43.6361 25.0016 37.2936 31.3441 29.5007 31.3441ZM29.5007 6.76074C23.7482 6.76074 19.0527 11.4562 19.0527 17.2087C19.0527 22.9612 23.7482 27.6566 29.5007 27.6566C35.2532 27.6566 39.9486 22.9612 39.9486 17.2087C39.9486 11.4562 35.2532 6.76074 29.5007 6.76074Z"
          fill={color ? color : '#ED0006'}
        />
        <path
          d="M8.38281 55.9271C7.3749 55.9271 6.53906 55.0913 6.53906 54.0833C6.53906 43.5863 16.8395 35.0312 29.4999 35.0312C31.9828 35.0312 34.4165 35.3509 36.7765 36.0146C37.7599 36.2851 38.3253 37.2929 38.0549 38.2763C37.7845 39.2596 36.7765 39.8251 35.7932 39.5547C33.7774 38.9892 31.6632 38.7188 29.4999 38.7188C18.8799 38.7188 10.2266 45.6021 10.2266 54.0833C10.2266 55.0913 9.39073 55.9271 8.38281 55.9271Z"
          fill={color ? color : '#ED0006'}
        />
        <path
          d="M44.2494 55.9274C41.3485 55.9274 38.5952 54.8458 36.4564 52.9037C35.596 52.1662 34.8339 51.2566 34.2439 50.2487C33.1622 48.4787 32.5723 46.3891 32.5723 44.2503C32.5723 41.1774 33.7523 38.3012 35.8664 36.1132C38.0789 33.827 41.0535 32.5732 44.2494 32.5732C47.5927 32.5732 50.7639 33.9991 52.9273 36.4575C54.8448 38.5962 55.9264 41.3495 55.9264 44.2503C55.9264 45.1845 55.8035 46.1187 55.5577 47.0037C55.3119 48.1099 54.8447 49.2654 54.2056 50.2733C52.1652 53.7641 48.3302 55.9274 44.2494 55.9274ZM44.2494 36.2607C42.0614 36.2607 40.0456 37.1211 38.5215 38.6699C37.071 40.1694 36.2598 42.1362 36.2598 44.2503C36.2598 45.7007 36.6531 47.1265 37.4152 48.3557C37.8085 49.044 38.3247 49.6586 38.9147 50.1748C40.3897 51.5269 42.2827 52.2645 44.2494 52.2645C47.0273 52.2645 49.6577 50.7895 51.0835 48.4049C51.5014 47.7166 51.821 46.93 51.9931 46.1679C52.1652 45.5287 52.2389 44.9141 52.2389 44.2749C52.2389 42.3083 51.5014 40.4154 50.1739 38.9404C48.6989 37.2196 46.5356 36.2607 44.2494 36.2607Z"
          fill={color ? color : '#ED0006'}
        />
        <path
          d="M41.5954 48.6751C41.1283 48.6751 40.6613 48.5031 40.2925 48.1344C39.5796 47.4214 39.5796 46.2414 40.2925 45.5285L45.4795 40.3413C46.1925 39.6284 47.3725 39.6284 48.0854 40.3413C48.7983 41.0542 48.7983 42.2342 48.0854 42.9472L42.8983 48.1344C42.5296 48.5031 42.0625 48.6751 41.5954 48.6751Z"
          fill={color ? color : '#ED0006'}
        />
        <path
          d="M46.8567 48.7239C46.3896 48.7239 45.9225 48.5518 45.5538 48.183L40.3667 42.996C39.6538 42.2831 39.6538 41.1031 40.3667 40.3902C41.0796 39.6772 42.2596 39.6772 42.9725 40.3902L48.1596 45.5772C48.8725 46.2901 48.8725 47.4701 48.1596 48.183C47.7908 48.5518 47.3238 48.7239 46.8567 48.7239Z"
          fill={color ? color : '#ED0006'}
        />
      </svg>
    </span>
  );
};

export default RemoveUserIcon;
