/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Empty, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

const MerchentStoreRejectModal = (props) => {
  const { t } = useTranslation();
  const { store, setSelectedTableRow, modalOpened, setModalOpened } = props;

  const sharedT = (key) => t(`rejectedModal.${key}`);

  return (
    <Modal
      transitionName=""
      className="slides-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">{sharedT('title')}</div>}
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedTableRow && setSelectedTableRow(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedTableRow && setSelectedTableRow(null);
      }}
      footer={false}
    >
      <div>
        {store?.refusedReason || (
          <Empty description={false}>{sharedT('noReason')}</Empty>
        )}
      </div>
    </Modal>
  );
};

export default MerchentStoreRejectModal;
