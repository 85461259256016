/* eslint-disable eqeqeq */
import { CloudDownloadOutlined } from '@ant-design/icons';
import CustomImage from '../../../common/custom-image/CustomImage';
import { Tag } from 'antd';
import paymentTypes from '../../../const-values/paymentTypes';
import {
  paymentStatusArr,
  serviceOrderStatusArr
} from '../../../const-values/serviceOrderStatus';

const tableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t
) => {
  // const handleViewDelegateDetails = (record) => {
  //   const found = allFetchedTableData.find((obj) => obj.id === record.id);
  //   if (found) {
  //     setSelectedTableRow({ ...found });
  //     setDetailsModalOpened(true);
  //   }
  // };

  const renderItemOfList = (arr, v) => {
    const found = arr?.length > 0 ? arr.find((item) => item.value == v) : null;
    if (found) {
      return (
        <Tag color={found?.color || 'magenta'}>{found?.name || '---'}</Tag>
      );
    }
    return '---';
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        {record.video ? (
          <a
            href={record.video}
            target="_blank"
            download
            referrerPolicy="no-referrer"
            className="edit-btn"
            rel="noreferrer"
          >
            <CloudDownloadOutlined />
            {t('download')}
          </a>
        ) : (
          '----'
        )}
      </div>
    );
  };

  return [
    // {
    //   title: t('serviceName'),
    //   dataIndex: 'serviceName',
    //   width: 142,
    //   render: (_, record) => {
    //     return (
    //       <div className="service-name-photo-wrap">
    //         <CustomImage src={record?.service?.logo} />
    //         <div className="name-phone">
    //           <p>{record?.service?.name}</p>
    //         </div>
    //       </div>
    //     );
    //   }
    // },
    {
      title: '##',
      dataIndex: 'ID',
      width: 92,
      render: (_, record) => {
        return record?.id || '----';
      }
    },
    {
      title: t('order'),
      dataIndex: 'order',
      width: 362,
      render: (_, record) => {
        return record?.servicePrice?.name || '----';
      }
    },
    {
      title: t('price'),
      dataIndex: 'price',
      width: 122,
      render: (_, record) => {
        return record?.price ? (
          <span>
            {record.price} {t('currency.sar')}
          </span>
        ) : (
          '----'
        );
      }
    },
    {
      title: t('paymentType'),
      dataIndex: 'paymentType',
      width: 162,
      render: (_, record) => {
        return renderItemOfList(paymentTypes(t), record?.paymentType) || '----';
      }
    },
    {
      title: t('paymentStatus'),
      dataIndex: 'paymentStatus',
      width: 162,
      render: (_, record) => {
        return (
          renderItemOfList(paymentStatusArr(t), record?.paymentStatus) || '----'
        );
      }
    },
    {
      title: t('status'),
      dataIndex: 'status',
      width: 102,
      render: (_, record) => {
        return (
          renderItemOfList(serviceOrderStatusArr(t), record?.status) || '----'
        );
      }
    },
    {
      title: t('download'),
      dataIndex: 'download',
      width: 112,
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
