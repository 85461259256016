/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Modal } from 'antd';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import closeUserAccountApi from '../../../apis/auth/closeUserAccountApi';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import routerLinks from '../../../components/app/routerLinks';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import './CloseUserAccountModal.scss';

const CloseUserAccountModal = (props) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const { modalOpened, setModalOpened } = props;
  const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        reason: Yup.string().required(
          t('closeAccountForm.reason.errors.required')
        )
      })
    ),
    mode: 'all',
    defaultValues: {
      reason: ''
    }
  });
  const navigate = useNavigate();

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    const formData = new FormData();
    formData.append('reason', data?.reason);
    customApiRequest(
      closeUserAccountApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        if (checkRes(res)) {
          setModalOpened(false);
          successNotification({
            title: t('successTitle'),
            message: 'تم غلق الحساب بنجاح'
          });
          navigate(routerLinks.accountSettings);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();

  return (
    <Modal
      transitionName=""
      className="close-account-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={
        <div className="modal-title">
          {i18n.language === 'ar' ? 'غلق الحساب' : 'Close My Bussiness'}
        </div>
      }
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <h2>{i18n.language === 'ar' ? 'غلق الحساب' : 'Close account'}</h2>

      <Form
        className="close-account-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-area-label-wrap">
            <p className="label-p">{t('closeAccountForm.reason.label')}</p>
            <div className="text-field-wrap">
              <AntdTextarea
                className="form-text-area white-bg"
                name="reason"
                rows={4}
                placeholder={t('closeAccountForm.reason.label')}
                errorMsg={errors?.reason?.message}
                validateStatus={errors?.reason ? 'error' : ''}
                control={control}
              />
            </div>
          </div>

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmittingForm}
          >
            {i18n.language === 'ar' ? 'حفظ' : 'Save'}
          </Button>
        </div>
      </Form>

      {isSubmittingForm && <LoadingModal />}
    </Modal>
  );
};

export default CloseUserAccountModal;
