const newStoreDefaultValues = {
  name_ar: '',
  name_en: '',
  phone: '',
  country_id: '',
  city_id: '',
  area_ar: '',
  area_en: '',
  street_ar: '',
  street_en: '',
  buildingNumber: '',
  lat: '',
  lng: '',
  description_ar: '',
  description_en: '',
  paymentType: '',
  logo: null,
  cover: null,
  storeFiles: null,
  bankName: '',
  shortNum: '',
  longNum: '',
  bankCountry_id: '',
  bankCity_id: ''
};

export default newStoreDefaultValues;
