import { useState, createContext } from 'react';
import { orderStatusValues } from '../../const-values/orderStatus';

const INITIAL_VALUES = {
  isLoadingOrders: false,
  setIsLoadingOrders: (v) => {},
  isRating: false,
  setIsRating: (v) => {},
  fetchOrdersCount: 0,
  setFetchOrdersCount: (v) => {},
  allFetchedOrders: null,
  setAllFetchedOrders: (v) => {},
  isCancelling: false,
  setIsCancelling: (v) => {},
  receiptModalOpened: false,
  setReceiptModalOpened: (v) => {},
  rateModalOpened: false,
  setRateModalOpened: (v) => {},
  addressMapModalOpened: false,
  setAddressMapModalOpened: (v) => {},
  selectedOrder: null,
  setSelectedOrder: (v) => {},
  //
  //
  ordersPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setOrdersPagination: (v) => {},
  // filter
  ordersFilter: {
    status: orderStatusValues?.all,
    page: 1
  },
  setOrdersFilter: (v) => {}
};

const UserOrdersContext = createContext(INITIAL_VALUES);

export const UserOrdersProvider = ({ children }) => {
  const [isLoadingOrders, setIsLoadingOrders] = useState(
    INITIAL_VALUES.isLoadingOrders
  );
  const [isRating, setIsRating] = useState(INITIAL_VALUES.isRating);
  const [isCancelling, setIsCancelling] = useState(INITIAL_VALUES.isCancelling);
  const [receiptModalOpened, setReceiptModalOpened] = useState(
    INITIAL_VALUES?.receiptModalOpened
  );
  const [rateModalOpened, setRateModalOpened] = useState(
    INITIAL_VALUES?.rateModalOpened
  );
  const [addressMapModalOpened, setAddressMapModalOpened] = useState(
    INITIAL_VALUES?.addressMapModalOpened
  );
  const [fetchOrdersCount, setFetchOrdersCount] = useState(
    INITIAL_VALUES.fetchOrdersCount
  );
  const [selectedOrder, setSelectedOrder] = useState(
    INITIAL_VALUES.selectedOrder
  );
  const [allFetchedOrders, setAllFetchedOrders] = useState(
    INITIAL_VALUES.allFetchedOrders
  );

  //
  const [ordersFilter, setOrdersFilter] = useState(
    INITIAL_VALUES?.ordersFilter
  );
  const [ordersPagination, setOrdersPagination] = useState(
    INITIAL_VALUES?.ordersPagination
  );

  return (
    <UserOrdersContext.Provider
      value={{
        isLoadingOrders,
        setIsLoadingOrders,
        isRating,
        setIsRating,
        isCancelling,
        setIsCancelling,
        receiptModalOpened,
        setReceiptModalOpened,
        rateModalOpened,
        setRateModalOpened,
        addressMapModalOpened,
        setAddressMapModalOpened,
        selectedOrder,
        setSelectedOrder,
        fetchOrdersCount,
        setFetchOrdersCount,
        allFetchedOrders,
        setAllFetchedOrders,
        ordersFilter,
        setOrdersFilter,
        ordersPagination,
        setOrdersPagination
      }}
    >
      {children}
    </UserOrdersContext.Provider>
  );
};

export default UserOrdersContext;
