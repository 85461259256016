/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import getStoresApi from '../apis/stores-apis/getAllStoresApi';
import UserContext from '../contexts/user-context/UserProvider';
import storeStatus from '../const-values/storeStatus';

const useStores = () => {
  const { i18n } = useTranslation();
  const [isLoadingData, setIsLoadingTableData] = useState(false);
  const [fetchTableDataCount, setFetchTableDataCount] = useState(0);
  const [allFetchedTableData, setAllFetchedTableData] = useState(0);
  const [tablePagination, setTablePagination] = useState({
    current_page: 1,
    per_page: 0,
    total: 0
  });
  const [tableFilter, setTableFilter] = useState({
    page: 1,
    status: storeStatus?.active
  });
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const { search } = useLocation();
  const values = queryString.parse(search);

  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      getStoresApi(
        {
          ...tableFilter,
          ...values // { page: ...}
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedTableData(data);
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          } else {
            setAllFetchedTableData([]);
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, i18n.language]);

  return {
    isLoadingData,
    setIsLoadingTableData,
    //
    tableFilter,
    setTableFilter,
    //
    fetchTableDataCount,
    setFetchTableDataCount,
    //
    allFetchedTableData,
    setAllFetchedTableData,
    //
    tablePagination,
    setTablePagination
  };
};

export default useStores;
