/* eslint-disable eqeqeq */
import CopyToClipboard from 'react-copy-to-clipboard';
import CustomImage from '../../../common/custom-image/CustomImage';
import CopyIcon from '../../../common/icons/PercentageIcon copy';
import generalRouterLinks from '../../../components/app/general-routes/gerneralRouterLinks';
import timeOfUseImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/time-of-use.png';
import coinsImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/coin-img.png';
import usersImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/users-img.png';
import feesImg from '../../../assets/imgs/icons/affiliate-statisctis-icons/fees-icon.png';
import { useTranslation } from 'react-i18next';
import './UserStatisticsCard.scss';
import { Link } from 'react-router-dom';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import {
  affiliatFeesTypes,
  agentTypes,
  firstOrEach
} from '../../../const-values/affiliateOptions';

const StatisticsBox = ({ img, title, value, className }) => {
  return (
    <div className={`statistics-box ${className ? className : ''}`}>
      <img src={img} alt={title} />
      <p className="value-p">{value}</p>
      <p className="title-p">{title}</p>
    </div>
  );
};

const UserStatisticsCard = ({ card, setCopied, setCopyCount }) => {
  const { t } = useTranslation();
  const sharedT = (key, options) =>
    t(`affiliateAgentStatisticsModal.${key}`, options);
  const affiliatePageTrans = (key) => t(`userAffiliatePage.${key}`);
  return (
    <div className="user-statistics-card">
      <div className="card-details-fees-wrapper">
        <div className="product-wrapper">
          <CustomImage src={card?.product?.logo} className="p-img-wrapper" />
          <div className="p-data">
            <Link
              to={userRouterLinks?.singleStoreRoute(card?.product?.store_id)}
              className="store-name"
            >
              {card?.product?.store}
            </Link>
            <div className="p-name bold-font">{card?.product?.name}</div>

            <div className="to-copy-text-box">
              <div className="box-label">
                {affiliatePageTrans('affiliateLink')}
              </div>
              <div className="box-content">
                <span>
                  {`${
                    process.env.REACT_APP_WEBSITE_URL
                  }${generalRouterLinks?.generalAffiliateProductRoute(
                    card?.product?.id || '',
                    card?.agentKey || ''
                  )}`}
                </span>
                <CopyToClipboard
                  text={`${
                    process.env.REACT_APP_WEBSITE_URL
                  }${generalRouterLinks?.generalAffiliateProductRoute(
                    card?.product?.id || '',
                    card?.agentKey || ''
                  )}`}
                  onCopy={() => setCopied(true)}
                >
                  <button
                    className="affiliate-action-btn copy"
                    onClick={() => setCopyCount((prev) => prev + 1)}
                  >
                    <CopyIcon />
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>

        <div className="v-divider"></div>

        <div className="fees-wrapper">
          <div className="img-data">
            <img className="fees-img" src={feesImg} alt="fees" />
            <div className="value-label-wrap">
              <div className="fees-label">
                {affiliatePageTrans('affiliateFees')}
              </div>

              <div className="fees-amount-label">
                <div className="fees-amount">
                  {card?.discountValue}
                  {card?.discountType == affiliatFeesTypes?.percentage
                    ? ' %'
                    : t('currency.sar')}
                </div>
                <div className="fees-label">
                  {card?.firstOrEach == firstOrEach?.first
                    ? affiliatePageTrans('firstOrder')
                    : affiliatePageTrans('eachOrder')}
                </div>
              </div>
            </div>
          </div>
          <div className="status-wrapper">
            <span className="status-label">
              {affiliatePageTrans('linkStatus.title')} :{' '}
            </span>
            {card?.status == agentTypes?.active ? (
              <span className="status-value active">
                {affiliatePageTrans('linkStatus.online')}
              </span>
            ) : (
              <span className="status-value not-active">
                {affiliatePageTrans('linkStatus.offline')}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="card-statistics">
        <div className="boxs-wrap">
          <StatisticsBox
            img={timeOfUseImg}
            value={card?.useCount || 0}
            title={sharedT('timeOfUse')}
            className="s-box time-of-use"
          />
          <StatisticsBox
            img={coinsImg}
            value={`${card?.myTotalValue || 0} ${t('currency.sar')}`}
            title={sharedT('totalSales')}
            className="s-box total-sales"
          />
          <StatisticsBox
            img={usersImg}
            value={card?.newUsers || 0}
            title={sharedT('newUsers')}
            className="s-box new-users"
          />
        </div>
      </div>
    </div>
  );
};

export default UserStatisticsCard;
