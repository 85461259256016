/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Pagination } from 'antd';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import popularProductsApi from '../../apis/products-apis/popularProductsApi';
import ArchHeading from '../../common/arch-heading/ArchHeading';
import generalRouterLinks from '../../components/app/general-routes/gerneralRouterLinks';
import productTypes from '../../const-values/productTypes';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import ProductCard from '../merchent-pages/products-page/ProductCard';
import './styles.scss';

const PopularProductsPage = () => {
  const { t, i18n } = useTranslation();
  const sharedTrans = (key) => t(`productsPage.${key}`);
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [products, setProducts] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 0,
    total: 0
  });
  const { search } = useLocation();
  const values = queryString.parse(search);

  const customApiRequest = useCustomApiRequest();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    customApiRequest(
      popularProductsApi(
        { ...values },
        user?.token ?? '',
        // { ...tableFilter, ...values },
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res.data?.data?.data) {
            const data = res.data.data.data;
            setProducts(data);
            if (res.data.data?.pagination) {
              setPagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount, user, search]);

  const renderProducts = () => {
    if (isLoading) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (products?.length === 0)
      return <Empty description={false}>No products Found!!!</Empty>;
    else if (products?.length > 0) {
      return (
        <div className="products-wrap">
          {products.map((obj) => (
            <ProductCard
              setFetchParentCount={setFetchCount}
              key={obj?.id}
              card={obj}
              type={productTypes?.userProduct}
            />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="popular-products-page">
      <div className="custom-container">
        <div className="page-content">
          <div className="title-wrap">
            <ArchHeading title={sharedTrans('popular_products')} />
          </div>
          {renderProducts()}

          {pagination?.total > 0 && (
            <Pagination
              locale={{
                jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                page: ''
              }}
              showSizeChanger={false}
              style={{
                marginTop: 22
              }}
              defaultCurrent={1}
              current={pagination.current_page}
              pageSize={pagination.per_page}
              total={pagination.total}
              // itemRender={itemRender}
              onChange={(page, pageSize) => {
                navigate(
                  `${generalRouterLinks.popularProductsRoute}?page=${page}`
                );
              }}
              hideOnSinglePage={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PopularProductsPage;
