/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import StoreOrdersContext from '../../../contexts/store-orders-context/StoreOrdersContext';
import ReportClientForm from './ReportClientForm';

const ReportClientModal = () => {
  const { t } = useTranslation();
  const {
    reportClientModalOpened,
    setReportClientModalOpened,
    setSelectedOrder
  } = useContext(StoreOrdersContext);

  return (
    <Modal
      transitionName=""
      className="slides-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={
        <div className="modal-title">{t('ordersPage.complaint.label')}</div>
      }
      open={reportClientModalOpened}
      onOk={() => {
        setSelectedOrder(null);
        setReportClientModalOpened(false);
      }}
      onCancel={() => {
        setSelectedOrder(null);
        setReportClientModalOpened(false);
      }}
      footer={false}
    >
      <ReportClientForm />
    </Modal>
  );
};

export default ReportClientModal;
