import axios from 'axios';

const getGeneralStoresApi = async (filterParams, token, langIsoCode) => {
  try {
    const url = `/Saloon/getStores?lat=${filterParams?.lat || ''}&lng=${
      filterParams?.lng || ''
    }&searchText=${filterParams?.searchText || ''}`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getGeneralStoresApi;
