import { Checkbox, Form } from 'antd';
import React from 'react';
import { useController } from 'react-hook-form';

const AntdCheckboxsGroup = ({
  control,
  name,
  label,
  onChange,
  validateStatus,
  errorMsg,
  options,
  defaultValue,
  className,
  disabled,
  style,
  children
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });

  // console.log('default value : ', defaultValue);
  return (
    <Form.Item
      style={style}
      name={name}
      label={label}
      help={errorMsg}
      validateStatus={validateStatus}
      className={className}
    >
      <Checkbox.Group
        options={options}
        disabled={disabled ? disabled : false}
        {...field}
        size="large"
        onChange={(e) => {
          field.onChange(e);
          onChange && onChange(e);
        }}
        // defaultValue={defaultValue ? defaultValue : ''}
      />
      {children}
    </Form.Item>
  );
};

export default AntdCheckboxsGroup;
