import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import videoBg from '../../../assets/imgs/video-bg.jpg';
import './TutorialPage.scss';
import { useTranslation } from 'react-i18next';
import Logo from '../../../common/logo/Logo';
import ArchHeading from '../../../common/arch-heading/ArchHeading';
import { useContext, useState } from 'react';
import VideoModal from './VideoModal';
import GeneralSettingsContext from '../../../contexts/general-settings-context/GeneralSettingsContext';

const TutorialPage = () => {
  const { i18n, t } = useTranslation();
  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  const { fetchedGeneralSettings } = useContext(GeneralSettingsContext);
  const [videoModalOpened, setVideoModalOpened] = useState(false);
  return (
    <div className="tutorial-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('video'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        {/* <div className="title-create-store-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">{sharedTrans('video')}</div>
          </div>
        </div> */}
        <Logo className="tut-page-logo" />
        <div className="page-content">
          <ArchHeading title={sharedTrans('video')} />

          <p>
            {i18n.language === 'ar'
              ? 'الفيديو التعليمي أدناه يوضح طريقة التعامل مع منصة نعوم بالكامل خطوة بخطوة حتى الوصول إلى الهدف المنشود وهو زيادة المبيعات وزيادة الارباح مما يساعد في استمرارية العمل.'
              : 'The educational video below, Explains the way to deal with the NAOMY platform, completely step by step, until reaching the .desired goal of increasing sales, increasing profits, which helps in the continuity of the business'}
          </p>

          <button
            onClick={() => {
              setVideoModalOpened(true);
            }}
            className="video-placeholder-wrap"
          >
            <div className="icon-wrap">
              {/* <img className="icon-img" src={playVideoIcon} alt="icon" /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="#9d4edd"
                  d="M16.394 12L10 7.737v8.526L16.394 12zm2.982.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z"
                />
              </svg>
            </div>
            <img className="bg-img" src={videoBg} alt="img" />
          </button>
        </div>
      </div>

      {fetchedGeneralSettings?.video && (
        <VideoModal
          src={fetchedGeneralSettings.video}
          modalOpened={videoModalOpened}
          setModalOpened={setVideoModalOpened}
        />
      )}
    </div>
  );
};

export default TutorialPage;
