/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MailOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { forgetPasswordEnterEmailApi } from '../../apis/auth/forgetPassApis';
import checkRes from '../../utils/checkRes';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import ForgetPasswordContext from '../../contexts/forget-password-context/ForgetPasswordContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';

const ForgetPasswordFormEnterEmail = () => {
  const { t } = useTranslation();
  const sharedT = (key) => t(`forgetPasswordPage.${key}`);

  const schema = Yup.object().shape({
    email: Yup.string()
      .required(sharedT('email.errors.required'))
      .email(sharedT('email.errors.typeError'))
  });

  const {
    setForgetPasswordFormEnterEmailAppended,
    setForgetPasswordFormEnterCodeAppended,
    setUser
  } = useContext(ForgetPasswordContext);
  const {
    control,
    handleSubmit,
    reset,
    // watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      email: ''
    }
  });

  React.useEffect(() => {
    return () => {
      reset({ email: '' });
    };
  }, []);
  const [isLoading, setIsLoading] = React.useState(false);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsLoading(true);
    customApiRequest(
      forgetPasswordEnterEmailApi(data),
      (res) => {
        setIsLoading(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: t('forgetPasswordPage.codeSent')
          });
          setForgetPasswordFormEnterEmailAppended(false);
          setForgetPasswordFormEnterCodeAppended(true);
          setUser(res?.data?.data);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        setIsLoading(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {/* <p className="label-p">البريد الاكترونى</p> */}
        <AntdTextField
          // className="form-text-field"
          label={sharedT('email.label')}
          name="email"
          type="text"
          placeholder={sharedT('email.label')}
          errorMsg={errors?.email?.message}
          validateStatus={errors?.email ? 'error' : ''}
          control={control}
          prefix={<MailOutlined />}
        />

        {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isLoading}
        >
          {sharedT('send')}
        </Button>
      </div>
    </Form>
  );
};

export default ForgetPasswordFormEnterEmail;
