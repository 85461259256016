/* eslint-disable react-hooks/exhaustive-deps */
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import getWalletOrdersApi from '../../../apis/merchent-wallet-apis/getWalletOrdersApi';
import SharedAntdTable from '../../../common/shared-antd-table/SharedAntdTable';
import routerLinks from '../../../components/app/routerLinks';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import tableColumns from './tableColumns';
import queryString from 'query-string';
import { LoadingOutlined } from '@ant-design/icons';

const MerchantTransactionsTable = () => {
  const { i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [isLoadingDate, setIsLoadingData] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [dataPagination, setDataPagination] = useState({
    current_page: 1,
    per_page: 0,
    total: 0
  });
  const [status, setStatus] = useState('');
  const [storeId, setStoreId] = useState('');

  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingData(true);
    customApiRequest(
      getWalletOrdersApi(
        {
          status,
          storeId,
          ...values
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingData(false);
          if (checkRes(res) && res.data?.data?.data?.length > 0) {
            const data = res.data.data.data;
            setFetchedData(data);

            const pagination = res?.data?.data?.pagination;
            setDataPagination(pagination);
          }
        }
      },
      (error) => {
        setIsLoadingData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, search]);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (fetchedData?.length >= 0) {
      const mappedData = mapDataSource(fetchedData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchedData, fetchedData?.length]);

  const renderTable = () => {
    if (isLoadingDate) {
      return (
        <div
          style={{
            width: 'fit-content',
            margin: 'auto',
            padding: '72px 0'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (!fetchedData || fetchedData?.length === 0) {
      return (
        <h2
          style={{
            textAlign: 'center',
            backgroundColor: '#fffbe6',
            border: '1px solid #ffe58f',
            width: 'fit-content',
            padding: '14px 32px',
            borderRadius: 12,
            margin: 'auto'
          }}
        >
          {i18n.language === 'ar'
            ? 'لا توجد تحويلات متاحه!!'
            : 'No transactions yet!!'}
        </h2>
      );
    } else if (fetchedData?.length > 0) {
      return (
        <SharedAntdTable
          className="transactions-table"
          bordered
          // scroll={1200}
          hasPagination={true}
          tableDataSource={tableDataSource}
          tableColumns={tableColumns}
          allFetchedTableData={fetchedData}
          // setFormModalOpened={setFormModalOpened}
          // setDetailsModalOpened={setDetailsModalOpened}
          // setFetchTableDataCount={setFetchTableDataCount}
          isLoadingTableData={isLoadingDate}
          setIsLoadingTableData={setIsLoadingData}
          // setSelectedTableRow={setSelectedTableRow}
          tablePagination={dataPagination}
          user={user}
          baseTablePageUrl={routerLinks?.merchentWallet}
        />
      );
    }
    return null;
  };
  return (
    <section className="transactions-section">
      <h2>{t('walletPage.transactionsHistory')}</h2>
      {renderTable()}
    </section>
  );
};

export default MerchantTransactionsTable;
