/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'antd';
import errorNotification from '../../../utils/errorNotification';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import setStoreTerms from '../../../apis/stores-apis/setStoreTermsApi';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import UserContext from '../../../contexts/user-context/UserProvider';

const MerchentStoreTermsAndConditionsModal = (props) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    store,
    setSelectedTableRow,
    modalOpened,
    setModalOpened,
    setFetchCount
  } = props;
  const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);
  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        terms: Yup.string().required(t('singleStoreDetailsPage.terms'))
      })
    ),
    mode: 'all',
    defaultValues: {
      terms: ''
    }
  });
  // handle initial values
  useEffect(() => {
    if (store) {
      store?.terms && setValue('terms', store.terms);
    }
  }, [store]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    const formData = new FormData();
    formData.append('store_id', store?.id);
    formData.append('terms', data?.terms);
    customApiRequest(
      setStoreTerms(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        if (checkRes(res)) {
          setModalOpened(false);
          setFetchCount((prevState) => prevState + 1);
          successNotification({
            title: t('successTitle'),
            message: 'تم التعديل بنجاح'
          });
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();

  return (
    <Modal
      transitionName=""
      className="slides-details-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={
        <div className="modal-title">{t('singleStoreDetailsPage.terms')}</div>
      }
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedTableRow && setSelectedTableRow(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedTableRow && setSelectedTableRow(null);
      }}
      footer={false}
    >
      <Form
        className="create-delegate-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-area-label-wrap">
            <p className="label-p">{t('singleStoreDetailsPage.terms')}</p>
            <div className="text-field-wrap">
              <AntdTextarea
                className="form-text-area white-bg"
                name="terms"
                rows={4}
                placeholder={t('singleStoreDetailsPage.terms')}
                errorMsg={errors?.terms?.message}
                validateStatus={errors?.terms ? 'error' : ''}
                control={control}
              />
            </div>
          </div>

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmittingForm}
          >
            {i18n.language === 'ar' ? 'حفظ' : 'Save'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default MerchentStoreTermsAndConditionsModal;
