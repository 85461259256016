const pdf = ['application/pdf'];
const wordFiles = ['application/doc', 'application/docx'];
const images = ['image/jpg', 'image/jpeg', 'image/png'];

const SUPPORTED_FORMATS = {
  pdf: pdf,
  images: images,
  pdfImgs: [...pdf, ...images],
  wordPdfImgs: [...wordFiles, ...pdf, ...images]
};

export default SUPPORTED_FORMATS;
