import React from 'react';

const NewUserIcon = ({ color }) => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5892 11.6872C8.68336 11.6872 6.31836 9.32218 6.31836 6.41634C6.31836 3.51051 8.68336 1.14551 11.5892 1.14551C14.495 1.14551 16.86 3.51051 16.86 6.41634C16.86 9.32218 14.495 11.6872 11.5892 11.6872ZM11.5892 2.52051C9.44419 2.52051 7.69336 4.27134 7.69336 6.41634C7.69336 8.56134 9.44419 10.3122 11.5892 10.3122C13.7342 10.3122 15.485 8.56134 15.485 6.41634C15.485 4.27134 13.7342 2.52051 11.5892 2.52051Z"
        fill="white"
      />
      <path
        d="M3.71484 20.8542C3.33901 20.8542 3.02734 20.5425 3.02734 20.1667C3.02734 16.2525 6.86817 13.0625 11.589 13.0625C12.5148 13.0625 13.4223 13.1817 14.3023 13.4292C14.669 13.53 14.8799 13.9058 14.779 14.2725C14.6782 14.6392 14.3023 14.85 13.9357 14.7492C13.184 14.5384 12.3957 14.4375 11.589 14.4375C7.62901 14.4375 4.40234 17.0042 4.40234 20.1667C4.40234 20.5425 4.09068 20.8542 3.71484 20.8542Z"
        fill="white"
      />
      <path
        d="M17.0905 20.8538C16.0088 20.8538 14.9821 20.4505 14.1846 19.7264C13.8638 19.4514 13.5797 19.1122 13.3597 18.7363C12.9563 18.0763 12.7363 17.2972 12.7363 16.4997C12.7363 15.3538 13.1763 14.2813 13.9647 13.4655C14.7897 12.613 15.8988 12.1455 17.0905 12.1455C18.3372 12.1455 19.5197 12.6772 20.3263 13.5939C21.0413 14.3914 21.4447 15.418 21.4447 16.4997C21.4447 16.848 21.3988 17.1963 21.3072 17.5263C21.2155 17.9388 21.0413 18.3697 20.803 18.7455C20.0422 20.0472 18.6122 20.8538 17.0905 20.8538ZM17.0905 13.5205C16.2747 13.5205 15.523 13.8413 14.9547 14.4188C14.4138 14.978 14.1113 15.7113 14.1113 16.4997C14.1113 17.0405 14.258 17.5722 14.5422 18.0305C14.6888 18.2872 14.8813 18.5163 15.1013 18.7088C15.6513 19.213 16.3572 19.488 17.0905 19.488C18.1263 19.488 19.1072 18.938 19.6388 18.0488C19.7947 17.7922 19.9138 17.4989 19.978 17.2147C20.0422 16.9764 20.0697 16.7472 20.0697 16.5089C20.0697 15.7755 19.7947 15.0697 19.2997 14.5197C18.7497 13.878 17.943 13.5205 17.0905 13.5205Z"
        fill="white"
      />
      <path
        d="M18.4654 17.1689H15.7246C15.3488 17.1689 15.0371 16.8573 15.0371 16.4814C15.0371 16.1056 15.3488 15.7939 15.7246 15.7939H18.4654C18.8413 15.7939 19.1529 16.1056 19.1529 16.4814C19.1529 16.8573 18.8413 17.1689 18.4654 17.1689Z"
        fill="white"
      />
      <path
        d="M17.0898 18.5719C16.714 18.5719 16.4023 18.2602 16.4023 17.8844V15.1436C16.4023 14.7677 16.714 14.4561 17.0898 14.4561C17.4657 14.4561 17.7773 14.7677 17.7773 15.1436V17.8844C17.7773 18.2694 17.4657 18.5719 17.0898 18.5719Z"
        fill="white"
      />
    </svg>
  );
};

export default NewUserIcon;
