import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user-context/UserProvider';
import {
  orderStatusArr,
  orderStatusValues
} from '../../const-values/orderStatus';
import './RenderOrderStatus.scss';

/* eslint-disable eqeqeq */
const RenderOrderStatus = ({ status }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const getFoundKey = (foundObj) => {
    if (foundObj) {
      for (let key in orderStatusValues) {
        if (orderStatusValues[key] == foundObj?.status) return key;
      }
    }
  };

  const renderOrderStatus = () => {
    if (orderStatusArr(user, t)?.length > 0) {
      const found = orderStatusArr(user, t).find(
        (item) => item?.status == status
      );
      if (found)
        return (
          <div className={`order-status ${getFoundKey(found)}`}>
            {found?.name}
          </div>
        );
      return null;
    }
  };
  return renderOrderStatus();
};

export default RenderOrderStatus;
