const getProductNetPrice = (price, quantity) => {
  return parseFloat(price) * parseFloat(quantity);
};

const getProductPriceWithOptionPrice = (
  productPrice,
  optionPrice,
  quantity
) => {
  if (optionPrice && parseFloat(optionPrice) > 0)
    return getProductNetPrice(productPrice, quantity) + parseFloat(optionPrice);
  return getProductNetPrice(productPrice, quantity);
};

///////////////////////////////////////
// get total without tax (subtotal)
export const getAllProductsTotalWithoutTax = (arr) => {
  let total = 0;
  for (let p of arr) {
    total += getProductPriceWithOptionPrice(
      p?.productPrice,
      p?.optionPrice,
      p?.quantity
    );
  }
  return total;
};

// get total with tax
export const getAllProductsTotalAfterTax = (subTotal, tax) => {
  let total = 0;
  if (tax && Number(tax) > 0) {
    total = parseFloat(subTotal) * (1 + Number(tax) / 100);
  } else {
    total = subTotal;
  }
  return Number(total);
};
