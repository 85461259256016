/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getFilterCatsApi from '../apis/general/getFilterCats';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useFilterCats = ({ setIsLoading, fetchCount, setFetchedData }) => {
  const { i18n } = useTranslation();
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    customApiRequest(
      getFilterCatsApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedData(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount]);
};

export default useFilterCats;
