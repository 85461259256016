/* eslint-disable eqeqeq */
import { useTranslation } from 'react-i18next';
import verifyIcon from '../../assets/imgs/icons/store-card/verify.svg';
import pindingIcon from '../../assets/imgs/icons/store-card/pinding-icon.svg';
import refusedIcon from '../../assets/imgs/icons/store-card/refused-icon.svg';
import hiddenIcon from '../../assets/imgs/icons/store-card/hidden.svg';
import productStatus from '../../const-values/productStatus';

const RenderProductStatus = ({
  obj,
  setSelectedTableRow,
  setRejectedModalOpened
}) => {
  const { t } = useTranslation();
  const sharedStoreT = (key) => t(`storeStatus.${key}`);
  const sharedProductT = (key) => t(`productStatus.${key}`);

  const renderStatus = () => {
    if (obj?.status == productStatus?.active) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 4
          }}
        >
          <div
            className="status"
            style={{
              color: '#25D366'
            }}
          >
            <img src={verifyIcon} alt="verify" />
            {sharedStoreT('active')}
          </div>
          <div
            className="status"
            style={{
              color: '#25D366'
            }}
          >
            <img src={verifyIcon} alt="verify" />
            {sharedProductT('active')}
          </div>
        </div>
      );
    } else if (obj?.status == productStatus?.hidden)
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '4px'
          }}
        >
          <div
            className="status"
            style={{
              color: '#25D366'
            }}
          >
            <img src={verifyIcon} alt="verify" />
            {sharedStoreT('active')}
          </div>
          <div
            className="status"
            style={{
              color: '#F9A000'
            }}
          >
            <img src={hiddenIcon} alt="hidden" />
            {sharedProductT('hidden')}
          </div>
        </div>
      );
    else if (obj?.status == productStatus?.underReview)
      return (
        <div
          className="status"
          style={{
            color: '#F9A000'
          }}
        >
          <img src={pindingIcon} alt="pinding" />
          {sharedProductT('underReview')}
        </div>
      );
    else if (obj?.status == productStatus?.rejected)
      return (
        <div
          style={{
            display: 'flex',
            gap: 4,
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div
            className="status"
            style={{
              color: '#ED0006'
            }}
          >
            <img src={refusedIcon} alt="rejected" />
            {sharedProductT('rejected')}
          </div>

          <div
            style={{
              color: '#ED0006',
              cursor: 'pointer',
              textDecoration: 'underline'
            }}
            onClick={() => {
              setSelectedTableRow(obj);
              setRejectedModalOpened(true);
            }}
          >
            {sharedProductT('rejectionReason')}
          </div>
        </div>
      );
    return '';
  };

  return renderStatus();
};

export default RenderProductStatus;
