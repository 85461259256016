/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import * as Yup from 'yup';
import getUserInfo from '../../../apis/auth/myInfoApi';
import updateProfileApi from '../../../apis/auth/updateProfileApi';
import bottomBorderImg from '../../../assets/imgs/icons/bottom-border.svg';
import cloudImg from '../../../assets/imgs/icons/fi-rr-cloud-upload.png';
import leftBorderImg from '../../../assets/imgs/icons/left-border.svg';
import topBorderImg from '../../../assets/imgs/icons/top-border.svg';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import FileInput from '../../../common/file-input/FileInput';
import EmailIcon from '../../../common/icons/EmailIcon';
import UserIcon from '../../../common/icons/UserIcon';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import './MerchentSettingsForm.scss';
// import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import LoadingModal from '../../../common/loading-modal/LoadingModal';

const MerchentSettingsForm = () => {
  const { i18n, t } = useTranslation();
  const generalLabelStr = (v) => t(`user_settings_form.${v}.label`);
  const generalrequiredErrStr = (v) =>
    t(`user_settings_form.${v}.errors.required`);
  const generalTypeErrorStr = (v) =>
    t(`user_settings_form.${v}.errors.type_error`);
  const generalMinErrorStr = (v, min) =>
    t(`user_settings_form.${v}.errors.min`, {
      min
    });
  const schema = Yup.object().shape({
    name: Yup.string().required(generalrequiredErrStr('name')),
    phone: Yup.string()
      .required(generalrequiredErrStr('phone'))
      // .matches(/^[0-9]+$/, generalTypeErrorStr('phone'))
      .min(10, generalMinErrorStr('phone', 10)),
    email: Yup.string()
      .required(generalrequiredErrStr('email'))
      .email(generalTypeErrorStr('email'))
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    unregister,
    // setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      saloonName: '',
      phone: '',
      email: '',
      password: '',
      username: '',
      dateOfBirth: '',
      image: null
    }
  });

  const { user, setCurrentUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getUserInfo(user?.token),
        (res) => {
          if (checkRes(res)) {
            const data = res.data.data;
            setCurrentUser({
              ...data,
              token: user?.token,
              accountType: user.accountType,
              activated: user.activated
            });
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  const [cancelCount, setCancelCount] = useState(0);
  // handle Initial values
  useEffect(() => {
    if (user) {
      if (user?.saloonName) {
        setValue('name', user.saloonName);
      }
      if (user?.name) {
        setValue('name', user.name);
      }
      if (user?.phone && user?.countryCode) {
        setValue('phone', `${user.countryCode}${user.phone}`);
      } else {
        setValue('phone', user?.phone || '');
      }
      if (user?.email) {
        setValue('email', user.email);
      }
      setValue(
        'dateOfBirth',
        user?.dateOfBirth ? dayjs(user.dateOfBirth, 'YYYY-MM-DD') : null
      );
      setValue('username', user.username || '');
      setValue(
        'saloonDescription_ar',
        user?.saloon?.saloonDescription_ar || ''
      );
      setValue(
        'saloonDescription_en',
        user?.saloon?.saloonDescription_en || ''
      );
    }
  }, [user, cancelCount]);

  // handle cancel
  const handleCancel = () => {
    setCancelCount((prev) => prev + 1);
  };

  const onSubmit = (data) => {
    // if (!watch('image') && !systemImgUrl) {
    //   setError('image', {
    //     type: 'required',
    //     message: 'اختار صورة البروفايل'
    //   });
    // }

    const formData = new FormData();

    if (data.name) formData.append('name', data.name);
    const formatedIntlPhone = formatPhoneNumberIntl(data.phone); // '+966 50 123 4444'
    const phoneStrArr = formatedIntlPhone.split(' '); // ['+966', '50', '123', '4444']
    const countryCode = phoneStrArr[0];
    let phone = '';
    if (phoneStrArr?.length === 2) phone = phoneStrArr[1];
    else if (phoneStrArr?.length > 2) {
      let array = phoneStrArr.filter((item, index) => index > 0); // ['50', '123', '4444']
      phone = array.join('');
    }
    if (data.phone) formData.append('phone', phone);
    if (data.phone) formData.append('countryCode', countryCode);
    if (data.email) formData.append('email', data.email);
    if (data.username) formData.append('username', data.username);
    if (data.dateOfBirth)
      formData.append(
        'dateOfBirth',
        dayjs(data.dateOfBirth).format('YYYY-MM-DD')
      );
    if (data.image) formData.append('image', data.image[0]);

    setIsLoading(true);

    customApiRequest(
      updateProfileApi(user?.token, formData),
      (res) => {
        setIsLoading(false);
        if (checkRes(res)) {
          setFetchCount((prev) => prev + 1);
          successNotification({
            title: t('successTitle'),
            message: 'تم تعديل البيانات بنجاح'
          });
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message ? res.data.message : 'حاول فى وقت لاحق'
          });
        }
      },
      (error) => {
        setIsLoading(false);
        errorNotification({
          title: t('errorTitle'),
          message: 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form merchent-settings-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-fields-wrap">
          <AntdTextField
            name="email"
            type="text"
            placeholder={generalLabelStr('email')}
            label={generalLabelStr('email')}
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            prefix={<EmailIcon />}
            control={control}
          />
          <AntdTextField
            name="name"
            type="text"
            placeholder={generalLabelStr('name')}
            label={generalLabelStr('name')}
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            prefix={<UserIcon />}
            control={control}
          />
          <label className="custom-date-input birthday-date-label">
            <span className="label-span">{generalLabelStr('birthday')}</span>
            <CustomAntdDatePicker
              placeholder={generalLabelStr('birthday')}
              disableFuture
              control={control}
              name={`dateOfBirth`}
              className="birthday-date-picker"
              formClassName="merchent-settings-form"
              errorMsg={errors?.dateOfBirth && errors.dateOfBirth.message}
            />
          </label>

          <div
            className={`country-code-phone-wrap ${i18n.dir()}`}
            style={{
              marginBottom: 28
            }}
          >
            <p
              style={{
                paddingBottom: 8
              }}
            >
              {generalLabelStr('phone')}
            </p>
            <Controller
              name="phone"
              control={control}
              // render={({ field: { onChange, onBlur, value, name, ref } }) => {
              render={({ field }) => {
                return (
                  <PhoneInput
                    {...field}
                    placeholder="Enter phone number"
                    // value={phoneValue}
                    // onChange={setPhoneValue}
                    defaultCountry="SA"
                    className={`custom-phone-input ${i18n.language}`}
                  />
                );
              }}
            />
            <p className="error-p">{errors?.phone?.message}</p>
          </div>

          <AntdTextField
            className={`username-field ${i18n.dir()}`}
            name="username"
            type="text"
            placeholder={generalLabelStr('username')}
            label={generalLabelStr('username')}
            errorMsg={errors?.username?.message}
            validateStatus={errors?.username ? 'error' : ''}
            control={control}
            prefix="naomy.net/"
          />
        </div>
        <div className="user-settings-img-wrap">
          <FileInput
            name="image"
            label={null}
            // label="صورة المستخدم"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            // dropzoneText="اسحب صورة المستخدم وضعها هنا ..."
            className="user-settings-images-dropzone"
            dropzoneUrls={user?.image ? [{ url: user?.image }] : []}
            canDelete={false}
          />
          <div className="cloud-img-wrap">
            <img className="cloud-img" src={cloudImg} alt="upload" />
          </div>
          <div className="borders-wrap">
            <img className="left-border" src={leftBorderImg} alt="border" />
            <img className="top-border" src={topBorderImg} alt="border" />
            <img className="bottom-border" src={bottomBorderImg} alt="border" />
          </div>

          {errors?.image?.message && !watch('image') && (
            <p className="error-p">{errors.image.message}</p>
          )}
        </div>

        <div className="save-cancel-btns">
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isLoading}
          >
            {t('user_settings_form.submit_btn.label')}
          </Button>
          <button onClick={handleCancel} type="button" className="cancel-btn">
            {t('user_settings_form.cancelBtn')}
          </button>
        </div>
      </div>

      {isLoading && <LoadingModal />}
    </Form>
  );
};

export default MerchentSettingsForm;
