import React from 'react';

const PlusIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1222_5818)">
          <path
            d="M29.5 0C23.6655 0 17.9619 1.73014 13.1107 4.97165C8.25944 8.21315 4.47835 12.8204 2.24557 18.2108C0.0127819 23.6013 -0.571416 29.5327 0.566847 35.2552C1.70511 40.9776 4.51471 46.234 8.64036 50.3597C12.766 54.4853 18.0224 57.2949 23.7449 58.4332C29.4673 59.5714 35.3988 58.9872 40.7892 56.7544C46.1796 54.5217 50.7869 50.7406 54.0284 45.8893C57.2699 41.0381 59 35.3346 59 29.5C58.9916 21.6787 55.8808 14.1802 50.3503 8.64969C44.8198 3.1192 37.3213 0.00845933 29.5 0V0ZM29.5 54.0833C24.6379 54.0833 19.885 52.6415 15.8422 49.9403C11.7995 47.239 8.64863 43.3997 6.78797 38.9076C4.92732 34.4156 4.44049 29.4727 5.38904 24.704C6.3376 19.9353 8.67893 15.555 12.117 12.117C15.555 8.67892 19.9353 6.33758 24.704 5.38903C29.4727 4.44048 34.4156 4.92731 38.9076 6.78796C43.3997 8.64862 47.2391 11.7995 49.9403 15.8422C52.6416 19.8849 54.0833 24.6379 54.0833 29.5C54.0762 36.0177 51.4839 42.2664 46.8751 46.8751C42.2664 51.4839 36.0177 54.0762 29.5 54.0833ZM41.7917 29.5C41.7917 30.152 41.5327 30.7773 41.0717 31.2383C40.6106 31.6993 39.9853 31.9583 39.3333 31.9583H31.9583V39.3333C31.9583 39.9853 31.6993 40.6106 31.2383 41.0716C30.7773 41.5327 30.152 41.7917 29.5 41.7917C28.848 41.7917 28.2227 41.5327 27.7617 41.0716C27.3007 40.6106 27.0417 39.9853 27.0417 39.3333V31.9583H19.6667C19.0147 31.9583 18.3894 31.6993 17.9284 31.2383C17.4673 30.7773 17.2083 30.152 17.2083 29.5C17.2083 28.848 17.4673 28.2227 17.9284 27.7617C18.3894 27.3007 19.0147 27.0417 19.6667 27.0417H27.0417V19.6667C27.0417 19.0147 27.3007 18.3894 27.7617 17.9284C28.2227 17.4673 28.848 17.2083 29.5 17.2083C30.152 17.2083 30.7773 17.4673 31.2383 17.9284C31.6993 18.3894 31.9583 19.0147 31.9583 19.6667V27.0417H39.3333C39.9853 27.0417 40.6106 27.3007 41.0717 27.7617C41.5327 28.2227 41.7917 28.848 41.7917 29.5Z"
            fill={color ? color : '#6D6D6D'}
          />
        </g>
        <defs>
          <clipPath id="clip0_1222_5818">
            <rect width="59" height="59" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default PlusIcon;
