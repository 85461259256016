/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import './TermsPage.scss';
import GeneralSettingsContext from '../../contexts/general-settings-context/GeneralSettingsContext';
import ArchHeading from '../../common/arch-heading/ArchHeading';
import Logo from '../../common/logo/Logo';
import PageArrowRightIcon from '../../common/icons/PageArrowRightIcon';
import PageArrowLeftIcon from '../../common/icons/PageArrowLeftIcon';

const TermsPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const sharedTrans = (key) => t(`termsPage.${key}`);

  const { fetchedGeneralSettings, isLoadingGeneralSettings } = useContext(
    GeneralSettingsContext
  );
  const renderTerms = () => {
    if (isLoadingGeneralSettings) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center',
            minHeight: 332
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 24, width: 24, height: 24 }}
            spin
          />
        </div>
      );
    } else if (!fetchedGeneralSettings?.refund) {
      return <Empty description={false}>Not Found Terms!!!</Empty>;
    } else if (fetchedGeneralSettings?.terms) {
      return <p className="refund-p">{fetchedGeneralSettings.terms}</p>;
    }
    return null;
  };

  return (
    <div className="terms-page">
      <div className="custom-container">
        <Logo className="app-logo" />

        <div className="page-content">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <PageArrowRightIcon />
              ) : (
                <PageArrowLeftIcon />
              )}
            </button>
            <ArchHeading title={sharedTrans('title')} />
          </div>
          {renderTerms()}
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
