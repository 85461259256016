/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
// import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import checkRes from '../../utils/checkRes';
import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Pagination } from 'antd';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import routerLinks from '../../components/app/routerLinks';
import CustomBreadcrumb from '../../common/bread-crumb/Breadcrumb';
import getAllUserFavApi from '../../apis/user-fav-apis/getAllUserFavApi';
import UserFavouritesContext from '../../contexts/user-favourites-context/UserFavouritesContext';
import ProductCard from '../merchent-pages/products-page/ProductCard';
import productTypes from '../../const-values/productTypes';
import UserContext from '../../contexts/user-context/UserProvider';
import ViewAs from '../../components/view-as/ViewAs';
import { viewAsArr } from '../../const-values/viewAsOptions';
import SortBy from '../../components/sort-by/SortBy';
import queryString from 'query-string';
import './UserFavouritesPage.scss';

const UserFavouritesPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    isLoadingTableData,
    setIsLoadingTableData,
    allFetchedTableData,
    setAllFetchedTableData,
    // tableFilter,
    setTablePagination,
    setFetchTableDataCount,
    fetchTableDataCount,
    tablePagination,
    selectedViewAsObj,
    setSelectedViewAsObj
  } = useContext(UserFavouritesContext);

  const handleChangeSort = (v) => {
    navigate(
      `${userRouterLinks?.myFavRoute}?sort=${v || ''}&page=${values?.page || 1}`
    );
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      getAllUserFavApi(
        {
          sort: values?.sort || ''
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedTableData(data);
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, search, i18n.language]);

  const renderProductsList = () => {
    if (isLoadingTableData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center',
            minHeight: 332
          }}
        >
          <LoadingOutlined
            style={{ fontSize: 24, width: 24, height: 24 }}
            spin
          />
        </div>
      );
    } else if (allFetchedTableData?.length === 0) {
      return <Empty description={false}>{t('userFavPage.notFound')}!!!</Empty>;
    } else if (allFetchedTableData?.length > 0) {
      return (
        <div className="list-pagination-wrap">
          <div className="view-as-sort-wrapper">
            <ViewAs
              defaultValue={viewAsArr(t)[0]}
              cb={(obj) => setSelectedViewAsObj(obj)}
            />

            <div className="sort-filter-wrapper">
              <div className="title">{t('sort.sortBy')}</div>
              <SortBy
                defaultValue={parseInt(values?.sort) || ''}
                handleChangeSort={handleChangeSort}
              />
            </div>
          </div>

          <div className={`products-wrap ${selectedViewAsObj?.name || ''}`}>
            {allFetchedTableData.map((obj) => (
              <ProductCard
                className={selectedViewAsObj?.name || ''}
                card={obj}
                type={productTypes?.userProduct}
                key={obj?.id}
                setFetchParentCount={setFetchTableDataCount}
              />
            ))}
          </div>
          {tablePagination?.total > 0 && (
            <Pagination
              locale={{
                jump_to: i18n?.language === 'ar' ? 'إذهب إلى' : 'Go to',
                // page: i18n?.language === 'ar' ? 'الصفحة' : 'Page'
                page: ''
              }}
              showSizeChanger={false}
              className="user-fav-pagination"
              defaultCurrent={1}
              current={tablePagination.current_page}
              pageSize={tablePagination.per_page}
              total={tablePagination.total}
              // itemRender={itemRender}
              onChange={(page, pageSize) => {
                setFetchTableDataCount((prev) => prev + 1);
                navigate(`${userRouterLinks?.topSaloonsRoute}?page=${page}`);
              }}
              hideOnSinglePage={true}
            />
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="user-fav-page">
      <div className="breadcrumb-title">
        <CustomBreadcrumb
          arr={[
            {
              title: t('main_app_bar_links.home'),
              isLink: true,
              to: routerLinks?.homePage
            },
            {
              title: t('userFavPage.pageTitle'),
              isLink: false
            }
          ]}
        />
        <div className="custom-container">
          <div className="title-counter-wrapper">
            <h2>{t('userFavPage.pageTitle')}</h2>

            <p className="counter-p bold-font">
              ( {allFetchedTableData?.length}
              {i18n.language === 'ar' ? ' خدمة' : ' Service'} )
            </p>
          </div>
        </div>
      </div>

      <div className="custom-container">{renderProductsList()}</div>
    </div>
  );
};

export default UserFavouritesPage;
