import axios from 'axios';

const changePasswordApi = async (values, token, langIsoCode) => {
  try {
    const signinRes = await axios.post(
      '/Auth_private/change_password',
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          lang: langIsoCode
        }
      }
    );
    return signinRes;
  } catch (error) {
    throw error;
  }
};

export default changePasswordApi;
