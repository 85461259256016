import axios from 'axios';

const getSingleProductApi = async (paramsFilter, token, langIsoCode) => {
  try {
    const url = `/Product/single?product_id=${paramsFilter?.product_id || ''}`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        lang: langIsoCode
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getSingleProductApi;
