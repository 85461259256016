/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import appPages from '../../../const-values/appPages';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import UserContext from '../../../contexts/user-context/UserProvider';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import useSelectedPage from '../../../custom-hooks/useSelectedPage';
import { Steps } from 'antd';
import { useParams } from 'react-router-dom';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import './NewStorePage.scss';
import NewStoreStepperForm from './NewStoreStepperForm';
import CreateStoreStepThree from './CreateStoreStepThree';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import SingleStoreContext from '../../../contexts/single-store-context/SingleStoreContext';
import getSingleStoreApi from '../../../apis/stores-apis/getSingleStoreApi';
import { LoadingOutlined } from '@ant-design/icons';
import storeStatus from '../../../const-values/storeStatus';
import EmptyCompoent from '../../../common/empty-compoent/EmptyComponent';
import emptyImg from '../../../assets/imgs/empty/empty.png';

const NewStorePage = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { merchentStores } = appPages(i18n, t, user);
  useSelectedPage({
    currentPage: merchentStores
  });

  const params = useParams();
  const {
    fetchSingleStoreDetailsCount,
    fetchedSingleStoreDetails,
    setFetchedSingleStoreDetails,
    isLoadingSingleStoreDetails,
    setIsLoadingSingleStoreDetails,
    isSubmittingForm
  } = useContext(SingleStoreContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (params?.store_id) {
      const fetchData = () => {
        setIsLoadingSingleStoreDetails(true);
        customApiRequest(
          getSingleStoreApi(
            {
              store_id: params?.store_id
            },
            user?.token,
            i18n.language
          ),
          (res) => {
            if (isMounted) {
              setIsLoadingSingleStoreDetails(false);
              if (checkRes(res)) {
                const data = res.data.data;
                setFetchedSingleStoreDetails(data?.store);
              }
            }
          },
          (error) => {
            setIsLoadingSingleStoreDetails(false);
          }
        );
      };
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [fetchSingleStoreDetailsCount]);

  const [currentStep, setCurrentStep] = React.useState(0);
  const labelTransString = (key) => t(`merchentDashboard.${key}`);

  if (isLoadingSingleStoreDetails) {
    return (
      <div
        className="custom-container"
        style={{
          paddingTop: 92,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    );
  } else if (
    fetchedSingleStoreDetails?.status == storeStatus?.active ||
    fetchedSingleStoreDetails?.status == storeStatus?.notCompleted ||
    !fetchedSingleStoreDetails
  )
    return (
      <div className="merchent-new-store-page shared-dashboard-page shared-custom-page">
        <CustomBreadcrumb
          arr={[
            {
              title: labelTransString('home'),
              isLink: true,
              to: routerLinks?.merchentDashboard
            },
            {
              title: labelTransString('dashboard'),
              isLink: true,
              to: merchentRouterLinks?.merchentDashboard
            },
            {
              title: labelTransString('stores'),
              isLink: true,
              to: merchentRouterLinks?.merchentStores
            },
            {
              title: labelTransString('newStore'),
              isLink: false
            }
          ]}
        />

        <div className="custom-container">
          <div className="title-create-store-btn-wrap">
            <div className="title-arrow-wrap">
              <button
                onClick={() => {
                  navigate(-1);
                }}
              >
                {i18n.dir() === 'rtl' ? (
                  <img src={HrArrowRtl} alt="back" />
                ) : (
                  <img src={HrArrowLtr} alt="back" />
                )}
              </button>
              <div className="main-page-title">
                {labelTransString('newStore')}
              </div>
            </div>
          </div>

          <Steps
            className="create-store-steps-indicator"
            // size="small"
            current={currentStep}
            items={[
              {
                title: t('newStorePage.steps.first')
              },
              {
                title: t('newStorePage.steps.second')
              },
              {
                title: t('newStorePage.steps.third')
              }
            ]}
          />

          {currentStep === 2 ? (
            <CreateStoreStepThree />
          ) : (
            <NewStoreStepperForm step={currentStep} setStep={setCurrentStep} />
          )}

          {(isLoadingSingleStoreDetails || isSubmittingForm) && (
            <LoadingModal />
          )}
        </div>
      </div>
    );
  return <EmptyCompoent img={emptyImg} />;
};

export default NewStorePage;
