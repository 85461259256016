import axios from 'axios';

const allSaloonsApi = async (filterValues, langIsoCode) => {
  try {
    const url = `/Saloon/all?city_id=${filterValues?.city_id || ''}&page=${
      filterValues?.page || 1
    }`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        lang: langIsoCode
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default allSaloonsApi;
