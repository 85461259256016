import React from 'react';

const NewProductIcon = ({ color }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1222_26694)">
        <path
          d="M7 0.5C5.61553 0.5 4.26216 0.910543 3.11101 1.67971C1.95987 2.44888 1.06266 3.54213 0.532846 4.82122C0.003033 6.1003 -0.13559 7.50777 0.134506 8.86563C0.404603 10.2235 1.07129 11.4708 2.05026 12.4497C3.02922 13.4287 4.2765 14.0954 5.63437 14.3655C6.99224 14.6356 8.3997 14.497 9.67879 13.9672C10.9579 13.4373 12.0511 12.5401 12.8203 11.389C13.5895 10.2378 14 8.88447 14 7.5C13.998 5.6441 13.2599 3.86479 11.9475 2.55247C10.6352 1.24015 8.8559 0.502007 7 0.5V0.5ZM7 13.3333C5.84628 13.3333 4.71846 12.9912 3.75918 12.3502C2.79989 11.7093 2.05222 10.7982 1.61071 9.73232C1.16919 8.66642 1.05368 7.49353 1.27876 6.36197C1.50384 5.23042 2.05941 4.19102 2.87521 3.37521C3.69102 2.5594 4.73042 2.00383 5.86198 1.77875C6.99353 1.55367 8.16642 1.66919 9.23232 2.1107C10.2982 2.55221 11.2093 3.29989 11.8502 4.25917C12.4912 5.21846 12.8333 6.34628 12.8333 7.5C12.8316 9.04658 12.2165 10.5293 11.1229 11.6229C10.0293 12.7165 8.54658 13.3316 7 13.3333ZM9.91667 7.5C9.91667 7.65471 9.85521 7.80308 9.74582 7.91248C9.63642 8.02188 9.48805 8.08333 9.33334 8.08333H7.58334V9.83333C7.58334 9.98804 7.52188 10.1364 7.41248 10.2458C7.30309 10.3552 7.15471 10.4167 7 10.4167C6.84529 10.4167 6.69692 10.3552 6.58752 10.2458C6.47813 10.1364 6.41667 9.98804 6.41667 9.83333V8.08333H4.66667C4.51196 8.08333 4.36359 8.02188 4.25419 7.91248C4.14479 7.80308 4.08334 7.65471 4.08334 7.5C4.08334 7.34529 4.14479 7.19692 4.25419 7.08752C4.36359 6.97813 4.51196 6.91667 4.66667 6.91667H6.41667V5.16667C6.41667 5.01196 6.47813 4.86358 6.58752 4.75419C6.69692 4.64479 6.84529 4.58333 7 4.58333C7.15471 4.58333 7.30309 4.64479 7.41248 4.75419C7.52188 4.86358 7.58334 5.01196 7.58334 5.16667V6.91667H9.33334C9.48805 6.91667 9.63642 6.97813 9.74582 7.08752C9.85521 7.19692 9.91667 7.34529 9.91667 7.5Z"
          fill={color ? color : '#afb7d1'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1222_26694">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewProductIcon;
