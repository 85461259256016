import { useEffect } from 'react';
import sleep from './sleep';
const renderSharedProductsBtns = (sliderClassName) => {
  const slider = document.querySelector(`.${sliderClassName}`);
  if (
    slider.querySelector('button.slick-prev') &&
    slider.querySelector('button.slick-next')
  ) {
    slider.querySelector('button.slick-prev').innerHTML = `
        <div class="btn-content">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.439 9.47487L5.025 13.0609C5.30639 13.3421 5.68799 13.5001 6.08585 13.5C6.48371 13.4999 6.86524 13.3418 7.1465 13.0604C7.42776 12.779 7.58572 12.3974 7.58563 11.9995C7.58553 11.6017 7.42739 11.2201 7.146 10.9389L4.707 8.49987L13.5 8.49987C13.8978 8.49987 14.2794 8.34184 14.5607 8.06053C14.842 7.77923 15 7.3977 15 6.99987C15 6.60205 14.842 6.22052 14.5607 5.93921C14.2794 5.65791 13.8978 5.49987 13.5 5.49987L4.707 5.49987L7.146 3.06087C7.42739 2.77961 7.58553 2.39809 7.58563 2.00023C7.58572 1.60237 7.42776 1.22077 7.1465 0.939375C6.86524 0.65798 6.48371 0.49984 6.08585 0.499746C5.68799 0.499653 5.30639 0.657611 5.025 0.938873L1.439 4.52487C0.783767 5.18191 0.415813 6.07196 0.415813 6.99987C0.415813 7.92779 0.783767 8.81784 1.439 9.47487Z" fill="#43116F"/>
          </svg>
        </div>
        `;
    slider.querySelector('button.slick-next').innerHTML = `
        <div class="btn-content">
          <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.561 4.52513L9.975 0.939127C9.69361 0.657865 9.31201 0.499906 8.91415 0.5C8.51629 0.500094 8.13476 0.658232 7.8535 0.939627C7.57224 1.22102 7.41428 1.60262 7.41437 2.00048C7.41447 2.39834 7.57261 2.77986 7.854 3.06113L10.293 5.50013H1.5C1.10218 5.50013 0.720644 5.65816 0.43934 5.93947C0.158035 6.22077 0 6.6023 0 7.00013C0 7.39795 0.158035 7.77948 0.43934 8.06079C0.720644 8.34209 1.10218 8.50013 1.5 8.50013H10.293L7.854 10.9391C7.57261 11.2204 7.41447 11.6019 7.41437 11.9998C7.41428 12.3976 7.57224 12.7792 7.8535 13.0606C8.13476 13.342 8.51629 13.5002 8.91415 13.5003C9.31201 13.5003 9.69361 13.3424 9.975 13.0611L13.561 9.47513C14.2162 8.81809 14.5842 7.92804 14.5842 7.00013C14.5842 6.07221 14.2162 5.18216 13.561 4.52513Z" fill="#43116F"/>
          </svg>
        </div>
        `;
  }
};

const SlickSliderBtns = (sliderClassName, isLoading) => {
  return useEffect(() => {
    const slidesFunc = async () => {
      await sleep(100);
      if (document.querySelector(`.${sliderClassName}`)) {
        if (
          document.querySelector(`.${sliderClassName}`) &&
          sliderClassName === 'main-app-slick-slider'
        ) {
          const slider = document.querySelector(`.${sliderClassName}`);
          // console.log(slider);
          if (
            slider.querySelector('button.slick-prev') &&
            slider.querySelector('button.slick-next')
          ) {
            slider.querySelector('button.slick-prev').innerHTML = `
            <div class="btn-content">
              <svg width="31" height="54" viewBox="0 0 31 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.7829 29.7806L23.7154 51.9098C24.0756 52.2769 24.5042 52.5683 24.9764 52.7671C25.4486 52.966 25.9551 53.0684 26.4667 53.0684C26.9782 53.0684 27.4847 52.966 27.9569 52.7671C28.4291 52.5683 28.8577 52.2769 29.2179 51.9098C29.9396 51.176 30.3447 50.1833 30.3447 49.1486C30.3447 48.1138 29.9396 47.1212 29.2179 46.3873L10.0367 26.804L29.2179 7.41648C29.9396 6.68265 30.3447 5.68996 30.3447 4.65523C30.3447 3.62051 29.9396 2.62782 29.2179 1.89398C28.859 1.52391 28.431 1.22949 27.9588 1.02779C27.4865 0.826096 26.9793 0.721138 26.4667 0.718987C25.954 0.721138 25.4468 0.826096 24.9745 1.02779C24.5023 1.22949 24.0743 1.52391 23.7154 1.89398L1.7829 24.0231C1.38957 24.3899 1.07566 24.835 0.860962 25.3305C0.646257 25.826 0.535414 26.361 0.535414 26.9019C0.535414 27.4428 0.646257 27.9778 0.860962 28.4733C1.07566 28.9687 1.38957 29.4139 1.7829 29.7806Z" fill="white"/>
              </svg>
            </div>
            `;
            slider.querySelector('button.slick-next').innerHTML = `
            <div class="btn-content">
              <svg width="31" height="54" viewBox="0 0 31 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.2171 24.2194L7.28459 2.09018C6.92436 1.72308 6.49578 1.4317 6.02358 1.23286C5.55138 1.03402 5.04489 0.931641 4.53334 0.931641C4.0218 0.931641 3.51531 1.03402 3.04311 1.23286C2.5709 1.4317 2.14233 1.72308 1.78209 2.09018C1.06037 2.82402 0.655273 3.81671 0.655273 4.85143C0.655273 5.88616 1.06037 6.87885 1.78209 7.61269L20.9633 27.196L1.78209 46.5835C1.06037 47.3174 0.655273 48.31 0.655273 49.3448C0.655273 50.3795 1.06037 51.3722 1.78209 52.106C2.14098 52.4761 2.56895 52.7705 3.04122 52.9722C3.51348 53.1739 4.02065 53.2789 4.53334 53.281C5.04604 53.2789 5.55321 53.1739 6.02547 52.9722C6.49774 52.7705 6.92571 52.4761 7.28459 52.106L29.2171 29.9769C29.6104 29.6101 29.9243 29.165 30.139 28.6695C30.3537 28.174 30.4646 27.639 30.4646 27.0981C30.4646 26.5572 30.3537 26.0222 30.139 25.5267C29.9243 25.0313 29.6104 24.5861 29.2171 24.2194V24.2194Z" fill="white"/>
              </svg>
            </div>
            `;
          }
        } else if (
          document.querySelector(`.${sliderClassName}`) &&
          sliderClassName === 'trendy-products-slider'
        ) {
          renderSharedProductsBtns('trendy-products-slider');
        } else if (
          document.querySelector(`.${sliderClassName}`) &&
          sliderClassName === 'new-products-slider'
        ) {
          renderSharedProductsBtns('new-products-slider');
        } else {
          const slider = document.querySelector(`.${sliderClassName}`);
          // console.log(slider);
          if (
            slider.querySelector('button.slick-prev') &&
            slider.querySelector('button.slick-next')
          ) {
            slider.querySelector('button.slick-prev').innerHTML = `
            <div class="btn-content">
              <svg xmlns="http://www.w3.org/2000/svg" width="9.752" height="9.752" viewBox="0 0 9.752 9.752">
                <path id="Path_578" data-name="Path 578" d="M8.876,4l.859.859-3.4,3.407h7.418V9.485H6.334l3.4,3.407-.859.859L4,8.876Z" transform="translate(-4 -4)"/>
              </svg>
            </div>
            `;
            slider.querySelector('button.slick-next').innerHTML = `
            <div class="btn-content">
              <svg xmlns="http://www.w3.org/2000/svg" width="9.752" height="9.752" viewBox="0 0 9.752 9.752">
                <path id="Path_578" data-name="Path 578" d="M8.876,4l-.859.859,3.4,3.407H4V9.485h7.418l-3.4,3.407.859.859,4.876-4.876Z" transform="translate(-4 -4)"/>
              </svg>
            </div>
            `;
          }
        }
      }
    };
    slidesFunc();
  }, [sliderClassName, isLoading]);
};

export default SlickSliderBtns;
