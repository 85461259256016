/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Modal } from 'antd';
import StoreLeftedCartContext from '../../../contexts/store-lefted-cart-context/StoreLeftedCartContext';
import { useTranslation } from 'react-i18next';
import LeftedCartOfferForm from './LeftedCartOfferForm';
import './LeftedCartOfferFormModal.scss';

const LeftedCartOfferFormModal = (props) => {
  const { formModalOpened, setFormModalOpened } = useContext(
    StoreLeftedCartContext
  );
  const { t } = useTranslation();
  const sharedTrans = (key) => t(`leftedCartPage.${key}`);
  return (
    <Modal
      transitionName=""
      className="lefted-cart-offer-modal shared-custom-modal"
      wrapClassName="lefted-modal-wrapper"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">{sharedTrans('sendOffer')}</div>}
      open={formModalOpened}
      onOk={() => {
        setFormModalOpened(false);
      }}
      onCancel={() => {
        setFormModalOpened(false);
      }}
      footer={false}
    >
      <LeftedCartOfferForm />
    </Modal>
  );
};

export default LeftedCartOfferFormModal;
