const baseLabelTransString = (t, key) => t(`discount_offer_filter.${key}`);

const discountOfferFilterArr = (t) => [
  {
    id: 10,
    name: baseLabelTransString(t, 'tenOrMore'),
    value: 10
  },
  {
    id: 15,
    name: baseLabelTransString(t, 'fifteenOrMore'),
    value: 15
  },
  {
    id: 20,
    name: baseLabelTransString(t, 'twentyOrMore'),
    value: 20
  },
  {
    id: 30,
    name: baseLabelTransString(t, 'thirtyOrMore'),
    value: 30
  },
  {
    id: 50,
    name: baseLabelTransString(t, 'fiftyOrMore'),
    value: 50
  },
  {
    id: 80,
    name: baseLabelTransString(t, 'eightyOrMore'),
    value: 80
  }
];

export default discountOfferFilterArr;
