/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import closexIcon from '../../assets/imgs/close-x.svg';
import emptyImg from '../../assets/imgs/empty/empty.png';
import './StoreViewTermsModal.scss';

const StoreViewTermsModal = (props) => {
  const { t, i18n } = useTranslation();
  const { store, modalOpened, setModalOpened } = props;

  return (
    <Modal
      transitionName=""
      className={`store-view-terms-modal shared-custom-modal ${i18n.dir()}`}
      width="90%"
      style={{ maxWidth: '694px' }}
      title=""
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <div className="h3-btn">
        <h3
          style={{
            color: '#290A44',
            fontSize: 24,
            textAlign: 'center',
            marginBottom: 22
          }}
        >
          {store?.name}
        </h3>
        <button
          onClick={() => {
            setModalOpened(false);
          }}
          className="close-btn"
        >
          <img src={closexIcon} alt="close" />
        </button>
      </div>
      <h3
        style={{
          color: '#290A44',
          fontSize: 20,
          marginBottom: 18
        }}
      >
        {t('singleStoreDetailsPage.terms')}
      </h3>
      <p
        style={{
          color: '#aaa',
          lineHeight: '1.8'
        }}
      >
        {store?.terms ? (
          store.terms
        ) : (
          <div className="shared-empty-wrapper">
            <img className="empty-img" src={emptyImg} alt="empty" />
            <p>غير متاحة</p>
          </div>
        )}
      </p>
    </Modal>
  );
};

export default StoreViewTermsModal;
