/* eslint-disable react-hooks/exhaustive-deps */
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../../components/app/routerLinks';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import { useTranslation } from 'react-i18next';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import NewAddressForm from './NewAddressForm';
import { useContext, useEffect, useState } from 'react';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import UserContext from '../../../contexts/user-context/UserProvider';
import { useParams, useNavigate } from 'react-router-dom';
import getSingleAddressApi from '../../../apis/my-addresses-apis/getSingleAddressApi';
import checkRes from '../../../utils/checkRes';

const NewAddressPage = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const params = useParams();
  const { user } = useContext(UserContext);
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [fetchAddressCount, setFetchAddressCount] = useState(0);
  const [fetchedAddress, setFetchedAddress] = useState(null);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;

    if (params?.addressId) {
      setIsLoadingAddress(true);
      customApiRequest(
        getSingleAddressApi(
          {
            addressId: params?.addressId
          },
          user?.token,
          i18n.language
        ),
        (res) => {
          if (isMounted) {
            setIsLoadingAddress(false);
            if (checkRes(res) && res?.data?.data) {
              setFetchedAddress(res.data.data);
            }
          }
        },
        (error) => {
          if (isMounted) setIsLoadingAddress(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchAddressCount]);

  const sharedTrans = (key) => t(`userDashboard.${key}`);
  return (
    <div className="user-new-address-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: userRouterLinks?.userDashboard
          },
          {
            title: sharedTrans('address'),
            isLink: true,
            to: userRouterLinks?.myAddresses
          },
          {
            title: params?.addressId
              ? t('addressesPage.editAddress')
              : t('addressesPage.newAddress'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{t('addressesPage.title')}</div>
        </div>
        <div className="page-body">
          <NewAddressForm
            isLoadingAddress={isLoadingAddress}
            fetchedAddress={fetchedAddress}
            setFetchedAddress={setFetchedAddress}
          />
        </div>
      </div>
    </div>
  );
};

export default NewAddressPage;
