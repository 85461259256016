/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import './DiscountCodeForm.scss';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import applyDiscountCode from '../../apis/cart-apis/applyDiscountCodeApi';
import UserContext from '../../contexts/user-context/UserProvider';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import CartContext from '../../contexts/cart-context/CartContext';
import removeDiscountCodeApi from '../../apis/cart-apis/removeDiscountCodeApi';
import successNotification from '../../utils/successNotification';
// import useFirebaseDeviceToken from '../../custom-hooks/useFirebaseDeviceToken';

const discountTypes = {
  value: 1,
  percentage: 2
};
const DiscountCodeForm = () => {
  const {
    setDiscountCode,
    setDiscountType,
    setDiscountValue,
    setDiscountStoreId,
    discountStoreId,
    discountCode,
    discountCodeId,
    subTotal,
    setDiscountCodeId
  } = useContext(CartContext);
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const generalLabelStr = (v) => t(`discountCodeForm.${v}.label`);
  const generalrequiredErrStr = (v) =>
    t(`discountCodeForm.${v}.errors.required`);

  const schema = Yup.object().shape({
    code: Yup.string().required(generalrequiredErrStr('code'))
  });
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      code: ''
    }
  });
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setDiscountCode(watch('code'));
  }, [watch('code')]);

  // handle initial values
  useEffect(() => {
    if (discountCode && !watch('code')) setValue('code', discountCode);
  }, [discountCode]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsLoading(true);
    if (!discountStoreId) {
      const formData = new FormData();
      formData.append('code', data.code);
      customApiRequest(
        applyDiscountCode(formData, user?.token, i18n.language),
        (res) => {
          setIsLoading(false);
          if (checkRes(res)) {
            setDiscountType(res?.data?.data?.discount_type);
            if (res?.data?.data?.discount_type == discountTypes.value)
              setDiscountValue(res?.data?.data?.discount_value);
            else if (res?.data?.data?.discount_type == discountTypes.percentage)
              setDiscountValue(
                (parseFloat(res?.data?.data?.discount_value) *
                  parseFloat(subTotal)) /
                  100
              );
            setDiscountStoreId(res?.data?.data?.store_id);
            setDiscountCodeId(res?.data?.data?.code_id);
            successNotification({
              title: t('successTitle'),
              message: res?.data?.message || 'تم التفعيل بنجاح'
            });
          } else {
            errorNotification({
              title: 'Error',
              message: res?.data?.message || 'Code is not available'
            });
          }
        },
        (error) => {
          setIsLoading(false);
          errorNotification({
            title: 'Error',
            message: error?.response?.data?.message || 'Code is not available'
          });
        }
      );
    } else {
      customApiRequest(
        removeDiscountCodeApi(null, user?.token, i18n.language),
        (res) => {
          setIsLoading(false);
          if (checkRes(res)) {
            setValue('code', '');
            setDiscountCode('');
            setDiscountType('');
            setDiscountValue('');
            setDiscountStoreId('');
            setDiscountCodeId('');
            successNotification({
              title: t('successTitle'),
              message: res?.data?.message || 'تم الغاء الكود بنجاح'
            });
          } else {
            errorNotification({
              title: 'Error',
              message: res?.data?.message || 'Something went wrong'
            });
          }
        },
        (error) => {
          setIsLoading(false);
          errorNotification({
            title: 'Error',
            message: error?.response?.data?.message || 'Something went wrong'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="discount-code-form  custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <AntdTextField
            disabled={discountStoreId}
            className="discount-text-field"
            name="code"
            type="text"
            placeholder={generalLabelStr('code')}
            // label={generalLabelStr('code')}
            errorMsg={errors?.code?.message}
            validateStatus={errors?.code ? 'error' : ''}
            control={control}
          />

          {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
          <Button
            className="apply-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isLoading}
          >
            {isSubmitting ? (
              <LoadingOutlined />
            ) : discountStoreId ? (
              t('discountCodeForm.unactive_btn.label')
            ) : (
              t('discountCodeForm.submit_btn.label')
            )}
          </Button>
        </div>

        {isLoading && <LoadingModal />}
      </Form>
    </>
  );
};

export default DiscountCodeForm;
