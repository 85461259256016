/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';
import getSingleProductApi from '../apis/products-apis/getSingleProudctApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useSingleProduct = () => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [fetchedData, setFetchedData] = useState(null);
  const customApiRequest = useCustomApiRequest();
  const params = useParams();
  const { user } = useContext(UserContext);
  useEffect(() => {
    let isMounted = true;
    if (params?.product_id) {
      const fetchData = () => {
        setIsLoading(true);
        customApiRequest(
          getSingleProductApi(
            {
              product_id: params?.product_id
            },
            user?.token,
            i18n.language
          ),
          (res) => {
            if (isMounted) {
              if (checkRes(res)) {
                setIsLoading(false);
                const data = res.data.data;
                setFetchedData(data);
              }
            }
          },
          (error) => {
            setIsLoading(false);
          }
        );
      };
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, i18n.language, pathname]);

  return {
    isLoading,
    setIsLoading,
    //
    fetchCount,
    setFetchCount,
    //
    fetchedData,
    setFetchedData
  };
};

export default useSingleProduct;
