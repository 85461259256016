import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  selectedPage: {
    id: '',
    name: '',
    link: '',
    icon: null,
    altName: ''
  },
  setSelectedPage: (v) => {}
};

const SelectedPageContext = createContext(INITIAL_VALUES);

export const SelectedPageProvider = ({ children }) => {
  const [selectedPage, setSelectedPage] = useState(INITIAL_VALUES.selectedPage);
  return (
    <SelectedPageContext.Provider
      value={{
        selectedPage,
        setSelectedPage
      }}
    >
      {children}
    </SelectedPageContext.Provider>
  );
};

export default SelectedPageContext;
