import React from 'react';

const LeftedCartIcon = ({ color }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1295_7025)">
        <path
          d="M13.7493 2.87825C13.5852 2.68134 13.3797 2.52297 13.1476 2.41439C12.9154 2.30581 12.6621 2.24969 12.4058 2.25H2.9745L2.95 2.04525C2.89986 1.61966 2.69531 1.22726 2.37512 0.942445C2.05493 0.65763 1.64137 0.500199 1.21283 0.5L1.08333 0.5C0.928624 0.5 0.780251 0.561458 0.670854 0.670854C0.561458 0.780251 0.5 0.928624 0.5 1.08333C0.5 1.23804 0.561458 1.38642 0.670854 1.49581C0.780251 1.60521 0.928624 1.66667 1.08333 1.66667H1.21283C1.35571 1.66669 1.49361 1.71914 1.60038 1.81408C1.70715 1.90903 1.77537 2.03985 1.79208 2.18175L2.59475 9.00675C2.67808 9.71654 3.01911 10.371 3.55311 10.846C4.08711 11.321 4.77692 11.5833 5.49158 11.5833H11.5833C11.738 11.5833 11.8864 11.5219 11.9958 11.4125C12.1052 11.3031 12.1667 11.1547 12.1667 11C12.1667 10.8453 12.1052 10.6969 11.9958 10.5875C11.8864 10.4781 11.738 10.4167 11.5833 10.4167H5.49158C5.13053 10.4157 4.77863 10.303 4.48413 10.0941C4.18962 9.88525 3.96693 9.5904 3.84658 9.25H10.7999C11.4838 9.25004 12.1459 9.0098 12.6706 8.57126C13.1953 8.13271 13.5492 7.52374 13.6705 6.85075L14.1284 4.31092C14.1741 4.05892 14.1639 3.79996 14.0983 3.55237C14.0328 3.30479 13.9136 3.07465 13.7493 2.87825ZM12.9833 4.10383L12.5248 6.64367C12.452 7.04792 12.2393 7.41366 11.9239 7.67687C11.6086 7.94007 11.2107 8.08396 10.7999 8.08333H3.66108L3.11217 3.41667H12.4058C12.4915 3.41616 12.5763 3.43453 12.6541 3.47048C12.7318 3.50644 12.8008 3.55909 12.8559 3.62469C12.911 3.69029 12.951 3.76723 12.9731 3.85005C12.9951 3.93286 12.9986 4.01951 12.9833 4.10383Z"
          fill="#6D6D6D"
        />
        <path
          d="M4.58268 14.5003C5.22701 14.5003 5.74934 13.978 5.74934 13.3337C5.74934 12.6893 5.22701 12.167 4.58268 12.167C3.93835 12.167 3.41602 12.6893 3.41602 13.3337C3.41602 13.978 3.93835 14.5003 4.58268 14.5003Z"
          fill={color ? color : '#afb7d1'}
        />
        <path
          d="M10.4167 14.5003C11.061 14.5003 11.5833 13.978 11.5833 13.3337C11.5833 12.6893 11.061 12.167 10.4167 12.167C9.77233 12.167 9.25 12.6893 9.25 13.3337C9.25 13.978 9.77233 14.5003 10.4167 14.5003Z"
          fill={color ? color : '#afb7d1'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1295_7025">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeftedCartIcon;
