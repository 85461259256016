import React from 'react';

const GridViewIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33329 2.66699H3.99996C3.26358 2.66699 2.66663 3.26395 2.66663 4.00033V9.33366C2.66663 10.07 3.26358 10.667 3.99996 10.667H9.33329C10.0697 10.667 10.6666 10.07 10.6666 9.33366V4.00033C10.6666 3.26395 10.0697 2.66699 9.33329 2.66699Z"
        fill={color || '#9D4EDD'}
      />
      <path
        d="M20 2.66699H14.6667C13.9303 2.66699 13.3334 3.26395 13.3334 4.00033V9.33366C13.3334 10.07 13.9303 10.667 14.6667 10.667H20C20.7364 10.667 21.3334 10.07 21.3334 9.33366V4.00033C21.3334 3.26395 20.7364 2.66699 20 2.66699Z"
        fill={color || '#9D4EDD'}
      />
      <path
        d="M9.33329 13.333H3.99996C3.26358 13.333 2.66663 13.93 2.66663 14.6663V19.9997C2.66663 20.7361 3.26358 21.333 3.99996 21.333H9.33329C10.0697 21.333 10.6666 20.7361 10.6666 19.9997V14.6663C10.6666 13.93 10.0697 13.333 9.33329 13.333Z"
        fill={color || '#9D4EDD'}
      />
      <path
        d="M20 13.333H14.6667C13.9303 13.333 13.3334 13.93 13.3334 14.6663V19.9997C13.3334 20.7361 13.9303 21.333 14.6667 21.333H20C20.7364 21.333 21.3334 20.7361 21.3334 19.9997V14.6663C21.3334 13.93 20.7364 13.333 20 13.333Z"
        fill={color || '#9D4EDD'}
      />
    </svg>
  );
};

export default GridViewIcon;
