/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import generalSingleStoreApi from '../apis/general/generalSingleStoreApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useSingleStore = (storeId) => {
  const { i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const customApiRequest = useCustomApiRequest();
  const { user } = useContext(UserContext);
  useEffect(() => {
    let isMounted = true;
    if (storeId) {
      setIsLoading(true);
      customApiRequest(
        generalSingleStoreApi(
          {
            storeId
          },
          user?.token,
          i18n.language
        ),
        (res) => {
          if (isMounted) {
            setIsLoading(false);
            if (checkRes(res) && res.data?.data) {
              const data = res.data.data;
              setFetchedData(data);
            } else {
              setFetchedData(null);
            }
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [i18n.language, storeId]);

  return {
    isLoading,
    setIsLoading,
    //
    fetchedData,
    setFetchedData
  };
};

export default useSingleStore;
