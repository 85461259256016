/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import successImg from '../../../assets/imgs/icons/success.png';
import { Link as RouterLink } from 'react-router-dom';
import './CreateStoreStepThree.scss';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import { useTranslation } from 'react-i18next';

const CreateStoreStepThree = () => {
  const { t } = useTranslation();
  const sharedTrans = (key) => t(`newStorePage.success.${key}`);
  return (
    <div className="stepper-step create-store-step-three">
      <img src={successImg} alt="success" />
      <p className="main-text">{sharedTrans('success')}</p>
      <div className="extra-text">
        <p>{sharedTrans('waiting')}</p>
        <p>{sharedTrans('24Hours')}</p>
      </div>

      <RouterLink
        className="stores-link"
        to={merchentRouterLinks?.merchentStores}
      >
        {sharedTrans('myStores')}
      </RouterLink>
    </div>
  );
};

export default CreateStoreStepThree;
