import axios from 'axios';

const getGeneralSingleProductApi = async (token, filterValues, langIsoCode) => {
  try {
    const url = `/publicProducts/single?product_id=${
      filterValues?.product_id || ''
    }`;
    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
        lang: langIsoCode
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getGeneralSingleProductApi;
