/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import contactUsApi from '../../apis/general/contactUsApi';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import ArchHeading from '../../common/arch-heading/ArchHeading';
import EmailIcon from '../../common/icons/EmailIcon';
import PageArrowLeftIcon from '../../common/icons/PageArrowLeftIcon';
import PageArrowRightIcon from '../../common/icons/PageArrowRightIcon';
import UserIcon from '../../common/icons/UserIcon';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import Logo from '../../common/logo/Logo';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';

import './ContactUsPage.scss';

const ContactUsPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const sharedTrans = (key) => t(`contact_us.${key}`);

  const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);

  const schema = Yup.object().shape({
    name: Yup.string().required(t('contact_us.name.errors.required')),
    message: Yup.string().required(t('contact_us.message.errors.required')),
    email: Yup.string()
      .required(t('contact_us.email.errors.required'))
      .email(t('contact_us.email.errors.type_error'))
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      email: '',
      message: ''
    }
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    const formData = new FormData();
    formData.append('name', data?.name);
    formData.append('email', data?.email);
    formData.append('message', data?.message);
    customApiRequest(
      contactUsApi(formData, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: res?.data?.message ?? ''
          });
          reset({
            name: '',
            email: '',
            message: ''
          });
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message
        });
      }
    );
  };

  const [form] = Form.useForm();

  return (
    <div className="contact-us-page">
      <div className="custom-container">
        <Logo className="app-logo" />

        <div className="page-content">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <PageArrowRightIcon />
              ) : (
                <PageArrowLeftIcon />
              )}
            </button>
            <ArchHeading title={sharedTrans('contact_us')} />
          </div>
          <Form
            className="contact-us-form custom-shared-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
          >
            <div className="form-body">
              <div className="email-field-wrap">
                <p className="touch-p">{t('contact_us.name.label')}</p>
                <AntdTextField
                  name="name"
                  type="text"
                  placeholder={t('contact_us.name.label')}
                  errorMsg={errors?.name?.message}
                  validateStatus={errors?.name ? 'error' : ''}
                  prefix={<UserIcon />}
                  control={control}
                />
              </div>
              <div className="email-field-wrap">
                <p className="touch-p">{t('contact_us.email.label')}</p>
                <AntdTextField
                  name="email"
                  type="text"
                  placeholder={t('contact_us.email.label')}
                  errorMsg={errors?.email?.message}
                  validateStatus={errors?.email ? 'error' : ''}
                  prefix={<EmailIcon />}
                  control={control}
                />
              </div>
              <div className="text-area-label-wrap">
                <p className="label-p">{t('contact_us.message.label')}</p>

                <div className="text-field-wrap">
                  <AntdTextarea
                    className="form-text-area white-bg"
                    name="message"
                    rows={4}
                    placeholder={t('contact_us.message.label')}
                    errorMsg={errors?.message?.message}
                    validateStatus={errors?.message ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>

              <Button
                className="submit-btn"
                htmlType="submit"
                type="primary"
                // icon={<LoginOutlined />}
                loading={isSubmittingForm}
              >
                {i18n.language === 'ar' ? 'ارسل' : 'Send'}
              </Button>
            </div>
          </Form>

          {isSubmittingForm && <LoadingModal />}
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
