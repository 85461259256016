import React from 'react';

const WalletIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        width="59"
        height="59"
        viewBox="0 0 59 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1222_5767)">
          <path
            d="M13.5215 41.792C15.558 41.792 17.209 40.141 17.209 38.1045C17.209 36.0679 15.558 34.417 13.5215 34.417C11.4849 34.417 9.83398 36.0679 9.83398 38.1045C9.83398 40.141 11.4849 41.792 13.5215 41.792Z"
            fill={color ? color : '#6D6D6D'}
          />
          <path
            d="M46.7083 7.375L12.2917 7.375C9.03291 7.3789 5.90875 8.67517 3.60446 10.9795C1.30017 13.2837 0.00390348 16.4079 0 19.6667L0 39.3333C0.00390348 42.5921 1.30017 45.7163 3.60446 48.0205C5.90875 50.3248 9.03291 51.6211 12.2917 51.625L46.7083 51.625C49.9671 51.6211 53.0913 50.3248 55.3955 48.0205C57.6998 45.7163 58.9961 42.5921 59 39.3333L59 19.6667C58.9961 16.4079 57.6998 13.2837 55.3955 10.9795C53.0913 8.67517 49.9671 7.3789 46.7083 7.375ZM12.2917 12.2917L46.7083 12.2917C48.6643 12.2917 50.5402 13.0687 51.9232 14.4518C53.3063 15.8348 54.0833 17.7107 54.0833 19.6667L4.91667 19.6667C4.91667 17.7107 5.69367 15.8348 7.07675 14.4518C8.45984 13.0687 10.3357 12.2917 12.2917 12.2917ZM46.7083 46.7083L12.2917 46.7083C10.3357 46.7083 8.45984 45.9313 7.07675 44.5482C5.69367 43.1652 4.91667 41.2893 4.91667 39.3333L4.91667 24.5833L54.0833 24.5833L54.0833 39.3333C54.0833 41.2893 53.3063 43.1652 51.9232 44.5482C50.5402 45.9313 48.6643 46.7083 46.7083 46.7083Z"
            fill={color ? color : '#6D6D6D'}
          />
        </g>
        <defs>
          <clipPath id="clip0_1222_5767">
            <rect width="59" height="59" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default WalletIcon;
