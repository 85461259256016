import React, { createContext, useState } from 'react';

const ChatContext = createContext({
  myChatGroups: [],
  setMyChatGroups: () => {},
  // ///////////////
  selectedGroupId: '',
  setSelectedGroupId: () => {},
  groupMessages: [],
  setGroupMessages: () => {}
});

export const ChatProvider = ({ children }) => {
  const [myChatGroups, setMyChatGroups] = useState([]);
  const [groupMessages, setGroupMessages] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');

  return (
    <ChatContext.Provider
      value={{
        myChatGroups,
        setMyChatGroups,
        groupMessages,
        setGroupMessages,
        selectedGroupId,
        setSelectedGroupId
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContext;
