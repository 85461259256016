import { useState, createContext } from 'react';
import useFilterCats from '../../custom-hooks/useFilterCats';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchedData: [],
  setFetchedData: (v) => {}
  //
};

const FilterCatsContext = createContext(INITIAL_VALUES);

export const FilterCatsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [fetchedData, setFetchedData] = useState(INITIAL_VALUES.fetchedData);

  useFilterCats({
    setIsLoading,
    setFetchCount,
    setFetchedData
  });

  return (
    <FilterCatsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        fetchedData,
        setFetchedData
      }}
    >
      {children}
    </FilterCatsContext.Provider>
  );
};

export default FilterCatsContext;
