/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import useCountries from '../../custom-hooks/useCountries';
import { Link as RouterLink } from 'react-router-dom';
import Logo from '../../common/logo/Logo';
import routerLinks from '../../components/app/routerLinks';
import './SignupPage.scss';
import EmployeeSignupForm from './EmployeeSignupForm';

const EmployeeSignupPage = () => {
  const { allFetchedCountries } = useCountries();

  const { t } = useTranslation();

  return (
    <div className="shared-custom-page signup-page">
      <div className="custom-container">
        <section className="signup-form-section">
          <Logo className="logo" />

          <div className="btns-types-wrap">
            <RouterLink to={routerLinks?.userSignupRoute}>
              {t('signupPage.user')}
            </RouterLink>
            <RouterLink to={routerLinks?.merchentSignupRoute}>
              {t('signupPage.merchent')}
            </RouterLink>
            <RouterLink
              className="selected"
              to={routerLinks?.employeeSignupRoute}
            >
              {t('signupPage.employee')}
            </RouterLink>
          </div>
          <EmployeeSignupForm countries={allFetchedCountries} />
        </section>
      </div>
    </div>
  );
};

export default EmployeeSignupPage;
