/* eslint-disable eqeqeq */
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import NewAddressIcon from '../../common/icons/NewAddressIcon';
import RadioButtonEmpty from '../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../common/icons/RadioButtonFilled';
import userRouterLinks from '../../components/app/user-routes/uesRouterLinks';
import CartContext from '../../contexts/cart-context/CartContext';
import useAddresses from '../../custom-hooks/useAddresses';
import './SelectAddressWrap.scss';

const SelectAddressWrap = () => {
  const { i18n, t } = useTranslation();
  const { setSelectedAddress, selectedAddress } = useContext(CartContext);

  const { allFetchedAddresses } = useAddresses();

  useEffect(() => {
    const foundDefaultAddress =
      allFetchedAddresses && allFetchedAddresses?.length > 0
        ? allFetchedAddresses.find((obj) => obj.is_default == 1)
        : null;
    if (foundDefaultAddress) setSelectedAddress({ ...foundDefaultAddress });
    else setSelectedAddress(null);
  }, [JSON.stringify(allFetchedAddresses)]);

  return (
    <div className="select-address-wrap">
      <div className="header-wrapper">
        <h3>{t('checkoutForm.yourAddresses')}</h3>

        <RouterLink
          to={userRouterLinks?.createNewAddressRoute}
          className="add-address"
        >
          <NewAddressIcon />
          <p>{t('addressesPage.newAddress')}</p>
        </RouterLink>
      </div>
      <div className="radios-wrap">
        <p className="radios-group-title">{t('checkoutForm.selectAddress')}</p>
        <div className="labels-wrap need-label">
          {allFetchedAddresses?.length > 0 &&
            allFetchedAddresses.map((obj) => (
              <label
                key={obj?.id}
                className={`radio-input-label ${i18n.dir()} ${
                  String(selectedAddress?.id) == String(obj.id)
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  name="address"
                  type="radio"
                  value={obj?.id}
                  onChange={(e) => {
                    setSelectedAddress(obj);
                  }}
                />
                {selectedAddress?.id == obj?.id ? (
                  <RadioButtonFilled />
                ) : (
                  <RadioButtonEmpty />
                )}
                {obj?.country && <span>{obj.country}</span>}
                {obj?.city && <span> / {obj.city}</span>}
                {obj?.area && <span> / {obj.area} </span>}
                {obj?.streetName && <span> / {obj.streetName}</span>}
              </label>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SelectAddressWrap;
