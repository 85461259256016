/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PdfIcon from '../../../common/icons/PdfIcon';
import CopyIcon from '../../../common/icons/PercentageIcon copy';
import generalRouterLinks from '../../../components/app/general-routes/gerneralRouterLinks';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import { affiliatFeesTypes } from '../../../const-values/affiliateOptions';
import AffiliateContext from '../../../contexts/affiliate-context/AffiliateContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import './AffiliateShareLinkModal.scss';

const AffiliateShareLinkModal = () => {
  const params = useParams();
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    shareLinkModalOpened,
    setShareLinkModalOpened,
    setSelectedTableRow,
    setCopyCount,
    setCopied,
    selectedTableRow
  } = useContext(AffiliateContext);

  const sharedT = (key, options) => t(`affiliatePage.${key}`, options);

  const handleCancel = () => {
    setShareLinkModalOpened(false);
    setSelectedTableRow && setSelectedTableRow(null);
  };

  const renderDiscountValue = () => {
    const discountType = selectedTableRow.discountType;
    const discountValue = selectedTableRow.discountValue;
    if (discountType == affiliatFeesTypes?.static) {
      return `${discountValue} ${t('currency.sar')}`;
    } else if (discountType == affiliatFeesTypes?.percentage)
      return `${discountValue} %`;
    return '';
  };

  const toBeCopied = () => {
    const hello = `${sharedT('hello')} , ${user?.name}`;
    const congrats = `${sharedT('congrats', {
      discountValue: renderDiscountValue()
    })} ${sharedT('congratsMsg', {
      discountValue: renderDiscountValue()
    })}

    ${sharedT('affiliateLink')}
     ${
       process.env.REACT_APP_WEBSITE_URL
     }${generalRouterLinks?.generalAffiliateProductRoute(
       selectedTableRow?.product?.id || '',
       selectedTableRow?.agentKey || ''
     )}

    ${sharedT('affStatLink')}
    ${
      process.env.REACT_APP_WEBSITE_URL
    }${generalRouterLinks?.generalSingleProductRoute(
      selectedTableRow?.product?.id || ''
    )}
    `;
    return `${hello}
${congrats}
    `;
  };

  return (
    <Modal
      transitionName=""
      className="affiliate-share-link-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      title={<div className="modal-title">{sharedT('shareLink')}</div>}
      open={shareLinkModalOpened}
      onOk={() => {
        setShareLinkModalOpened(false);
        setSelectedTableRow && setSelectedTableRow(null);
      }}
      onCancel={() => {
        handleCancel();
      }}
      footer={false}
    >
      <div className="modal-content">
        <p className="modal-title">{sharedT('shareLinkMessag')}</p>

        <div className="congrats-text">
          <p>
            {sharedT('hello')} , {user?.name}
          </p>
          <p>
            <strong>{sharedT('congrats')}</strong>
            {sharedT('congratsMsg', {
              discountValue: renderDiscountValue()
            })}
          </p>
        </div>

        <div className="to-copy-text-box">
          <div className="box-label">{sharedT('affiliateLink')}</div>
          <div className="box-content">
            <span>
              {`${
                process.env.REACT_APP_WEBSITE_URL
              }${generalRouterLinks?.generalAffiliateProductRoute(
                selectedTableRow?.product?.id || '',
                selectedTableRow?.agentKey || ''
              )}`}
            </span>
            <CopyToClipboard
              text={`${
                process.env.REACT_APP_WEBSITE_URL
              }${generalRouterLinks?.generalAffiliateProductRoute(
                selectedTableRow?.product?.id || '',
                selectedTableRow?.agentKey || ''
              )}`}
              onCopy={() => setCopied(true)}
            >
              <button
                className="affiliate-action-btn copy"
                onClick={() => setCopyCount((prev) => prev + 1)}
              >
                <CopyIcon />
              </button>
            </CopyToClipboard>
          </div>
        </div>

        <div className="to-copy-text-box">
          <div className="box-label">{sharedT('affStatLink')}</div>
          <div className="box-content">
            <a
              className="affiliate-action-btn pdf-link"
              href={`${
                process.env.REACT_APP_WEBSITE_URL
              }${merchentRouterLinks?.storeProductStatisticsAffilliatePdfRoute(
                params?.store_id,
                params?.product_id,
                selectedTableRow?.id
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              {i18n.language === 'ar'
                ? 'تحميل احصائيات مسوق الخدمة'
                : 'Download affiliate statistics'}
              <PdfIcon color="#9d4edd" />
            </a>
          </div>
        </div>

        <div className="modal-btns">
          <CopyToClipboard text={toBeCopied()} onCopy={() => setCopied(true)}>
            <Button
              className="submit-btn"
              onClick={() => setCopyCount((prev) => prev + 1)}
              type="primary"
              // icon={<LoginOutlined />}
            >
              {sharedT('copyMsg')}
            </Button>
          </CopyToClipboard>

          <Button onClick={handleCancel} className="cancel-btn">
            {sharedT('close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AffiliateShareLinkModal;
