/* eslint-disable eqeqeq */
import { CheckOutlined } from '@ant-design/icons';
import { Descriptions, Rate, Tag } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import userCancelOrderApi from '../../../apis/cart-apis/userCancelOrderApi';
import mapImg from '../../../assets/imgs/icons/map-svg.svg';
import receiptIcon from '../../../assets/imgs/icons/receipt.png';
import starImg from '../../../assets/imgs/icons/star-icon.svg';
import CustomImage from '../../../common/custom-image/CustomImage';
import MobileIcon from '../../../common/icons/MobileIcon';
import PinIcon from '../../../common/icons/PinIcon';
import ShieldIcon from '../../../common/icons/ShieldIcon';
import UserIcon from '../../../common/icons/UserIcon';
import RenderOrderStatus from '../../../common/render-order-status/RenderOrderStatus';
import paymentTypes, {
  paymentStatusValues,
  paymentTypesValues
} from '../../../const-values/paymentTypes';
import productSettingsOptions from '../../../const-values/productSettingsOptions';
import refundOptions from '../../../const-values/refundOptions';
import UserContext from '../../../contexts/user-context/UserProvider';
import UserOrdersContext from '../../../contexts/user-orders-context/UserOrdersContext';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import './UserOrderCard.scss';
// import chatImg from '../../../assets/imgs/icons/chat-img.svg';
// import productStatus from '../../../const-values/productStatus';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import getTabbyCheckoutUrlApi from '../../../apis/cart-apis/getTabbyCheckoutUrlApi';
import getTamaraCheckoutUrlApi from '../../../apis/cart-apis/getTamaraCheckoutUrlApi';
import { hasOfferValues } from '../../../const-values/leftedCartOptions';
import { orderStatusValues } from '../../../const-values/orderStatus';
import renderTime from '../../../utils/renderTime';
import successNotification from '../../../utils/successNotification';

// import routerLinks from '../../../components/app/routerLinks';
// import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';

const UserOrderCard = ({ card }) => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(UserContext);

  const {
    setFetchOrdersCount,
    setIsCancelling,
    setReceiptModalOpened,
    setSelectedOrder,
    setAddressMapModalOpened,
    setRateModalOpened
  } = useContext(UserOrdersContext);

  const renderRefundOption = (p) => {
    if (refundOptions(t)?.length > 0) {
      const foundRefundOption = refundOptions(t).find(
        (item) => item.id == p?.refundData?.refundOption
      );
      if (foundRefundOption)
        return (
          <p className="refund-title">
            <ShieldIcon color="#64022C" />
            {foundRefundOption.title}{' '}
            {p?.refundData?.expireDate && (
              <>
                {t('ordersPage.end')}{' '}
                {dayjs(p.refundData.expireDate).format('MMM, D, YYYY hh:mm a')}
              </>
            )}
          </p>
        );
      return '';
    }
  };
  const getOptionPrice = (obj) => {
    if (productSettingsOptions(t)?.length > 0) {
      const foundOption = productSettingsOptions(t).find(
        (item) => item?.value == obj?.optionType
      );
      return foundOption;
    }
    return null;
  };
  const renderProductSettingsOptions = (obj) => {
    if (productSettingsOptions(t)?.length > 0) {
      const foundOption = getOptionPrice(obj);
      if (foundOption)
        return (
          <span className="option-p">
            <CheckOutlined /> {foundOption.name}
          </span>
        );
      return '';
    }
  };

  const renderMerchentInfo = () => {
    return (
      <div className="merchent-info-wrap">
        <div className="merchent">
          <p className="title">{t('ordersPage.merchentInfo')}</p>
          <div className="data">
            <div className="name">
              <div className="label">
                <UserIcon color="#6D6D6D" />
                {t('ordersPage.saloonName')} :
              </div>
              <div className="value">{card?.store?.name}</div>
            </div>
            <div className="phone">
              <div className="label">
                <MobileIcon color="#6D6D6D" />
                {t('ordersPage.phone')} :
              </div>
              <div className="value">{card?.store?.phone}</div>
            </div>

            <div
              onClick={() => {
                setSelectedOrder(card);
                setAddressMapModalOpened(true);
              }}
              className="address"
            >
              <div className="label">
                <PinIcon color="#6D6D6D" />
                {t('ordersPage.address')} :
              </div>
              <div className="value">
                <p>
                  {card?.store?.country && (
                    <span>{card?.store.country} | </span>
                  )}
                  {card?.store?.city && <span>{card?.store.city} | </span>}
                  {card?.store?.area && <span>{card?.store.area} | </span>}
                  {card?.store?.streetName && (
                    <span>{card?.store.streetName} | </span>
                  )}
                  {card?.store?.buildingNumber && (
                    <span>{card?.store.buildingNumber} | </span>
                  )}
                </p>

                <span className="map-span">
                  {t('ordersPage.map')} <img src={mapImg} alt="map" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="order-tax-total-price-wrapper">
          <Descriptions column={1} title="" bordered>
            <Descriptions.Item
              label={i18n.language === 'ar' ? 'إجمالى الرسوم' : 'Tax Price'}
            >
              <div className="img-name-wrap">
                <p>
                  {card?.taxPrice || 0} {t('currency.sar')}
                </p>
              </div>
            </Descriptions.Item>
            <Descriptions.Item
              label={i18n.language === 'ar' ? 'إجمالى الفاتورة' : 'Total Price'}
            >
              <div className="img-name-wrap">
                <p>
                  {card?.totalPrice || 0} {t('currency.sar')}
                </p>
              </div>
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    );
  };

  const handleCancelOrder = (id) => {
    setIsCancelling(true);
    const formData = new FormData();
    formData.append('order_id', card?.id);
    formData.append('cart_id', id);
    customApiRequest(
      userCancelOrderApi(formData, user?.token, i18n.language),
      (res) => {
        setIsCancelling(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message:
              i18n.language === 'ar'
                ? 'تم الالغاء بنجاح'
                : 'Canceled successfully'
          });
          setFetchOrdersCount((prev) => prev + 1);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || t('errorMessage')
          });
        }
      },
      (error) => {
        setIsCancelling(false);
        errorNotification({
          title: 'Error!',
          message: error?.response?.data?.message || 'Try again later'
        });
      }
    );
  };

  const renderPricePerService = (orderP) => {
    // check if has code
    if (
      orderP?.hasOffer == hasOfferValues.yes &&
      orderP?.offerPrice &&
      orderP?.offerEndDate &&
      (dayjs(orderP.offerEndDate).isAfter() ||
        dayjs(orderP.offerEndDate).date() === dayjs().date())
    ) {
      return (
        <div className="old-new-price-wrapper">
          <span className="old-price striked">
            {orderP?.servicePrice}
            <span className="line"></span>
          </span>
          <span className="new-price">
            {Number(orderP.servicePrice) - Number(orderP.offerPrice)}{' '}
            {t('currency.sar')}
          </span>
        </div>
      );
    }
    return (
      <p>
        <span className="no-discount-price">
          {orderP?.servicePrice} {t('currency.sar')}
        </span>
      </p>
    );
  };

  const renderOrderProducts = () => {
    return card.products.map((p) => {
      return (
        <div key={p?.id} className="product-wrap">
          <div className="img-order-status-wrapper">
            <CustomImage
              className="p-logo"
              src={
                p?.product?.productImages?.length > 0
                  ? p.product.productImages[0]?.image
                  : p?.product?.logo
              }
            />
            <div className="status-wrap">
              <RenderOrderStatus status={p?.isOrder} />
            </div>
          </div>
          <div className="p-data">
            <div className="main-data">
              <div className="p-name">{p?.product?.name}</div>
              <div className="cat-filter-cat">
                {p?.product?.cat} - {p?.product?.catFilter}
              </div>
              <div className="item-price">
                <div className="label">{t('ordersPage.itemPrice')} :</div>
                {renderPricePerService(p)}
                {/* <div className="value">
                  <span>{p?.servicePrice}</span>
                  <span>{t('currency.sar')}</span>

                </div> */}
              </div>
              <div className="option-price">
                <span className="label">{renderProductSettingsOptions(p)}</span>
                {p?.optionPrice && Number(p?.optionPrice) > 0 ? (
                  <span className="value">
                    <span>{p?.optionPrice || 0}</span>
                    <span>{t('currency.sar')}</span>
                  </span>
                ) : null}
              </div>
              <div className="refund-wrap">{renderRefundOption(p)}</div>

              <div className="address-wrap">
                {card?.address?.country &&
                card?.address?.city &&
                card?.address?.area ? (
                  <p>
                    <PinIcon color="#6D6D6D" />
                    {t('ordersPage.myAddress')} : {card.address.country} |{' '}
                    {card.address?.city} | {card.address.area} |{' '}
                    {card?.address?.streetName}
                  </p>
                ) : (
                  <p>
                    {card?.address?.address && (
                      <>
                        <PinIcon color="#6D6D6D" /> {t('ordersPage.myAddress')}{' '}
                        {card.address.address}
                      </>
                    )}
                  </p>
                )}
              </div>
            </div>

            <div className="side-data">
              <div className="side-data-list">
                <div className="quantity-data">
                  <div className="label">{t('ordersPage.quantity')}</div>
                  <div className="value">{p?.quantity}</div>
                </div>
                <div className="total-data">
                  <div className="label">{t('ordersPage.total')}</div>
                  <div className="value">
                    {p?.totalPrice} <span>{t('currency.sar')}</span>
                  </div>
                </div>
                <div className="delivery-wrapper">
                  <p className="label">{t('ordersPage.delivery')}</p>
                  <div className="value">
                    {p?.optionPrice || 0}
                    <span>{t('currency.sar')}</span>
                  </div>
                </div>
                <div className="confirm-wrapper">
                  <p className="label">{t('ordersPage.confirmNumber')}</p>
                  <div className="value">{p?.id || 0}</div>
                </div>
                <div className="inform-date-wrapper">
                  <p className="label">{t('ordersPage.informDate')}</p>
                  <div className="date value">
                    {p?.date}
                    {p?.time && `- ${renderTime(p.time, i18n)}`}
                  </div>
                </div>
              </div>
              {(p?.isOrder === orderStatusValues?.new ||
                p?.isOrder === orderStatusValues?.accepted) && (
                <button
                  onClick={() => {
                    handleCancelOrder(p?.id);
                  }}
                  className="cancel-order-btn"
                >
                  {t('ordersPage.cancel')}
                </button>
              )}
              {/* {p?.isOrder === orderStatusValues?.new && (
                <button
                  onClick={() => {
                    handleCancelOrder(p?.id);
                  }}
                  className="cancel-order-btn"
                >
                  {t('ordersPage.cancel')}
                </button>
              )} */}
              {p?.isOrder === orderStatusValues?.canceledByUser &&
                p.cancelDate && (
                  <p className="cancel-date">
                    {t('ordersPage.cancelDate')} :{' '}
                    <span>( {p.cancelDate} )</span>
                  </p>
                )}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderPaymentType = () => {
    const foundType = paymentTypes(t).find(
      (obj) => obj.value == card?.paymentType
    );
    if (foundType)
      return (
        <div className="img-label-wrap">
          <img src={foundType.img} alt="payment" />
          {/* <span>{foundType.name}</span> */}
        </div>
      );
  };

  const customApiRequest = useCustomApiRequest();

  const [isLoadingTabby, setIsLoadingPayment] = useState(false);
  let isMounted = true;

  const handleTammaraCheckout = async () => {
    customApiRequest(
      getTamaraCheckoutUrlApi(
        {
          id: card?.superOrderId
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingPayment(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            if (data?.checkout_url) window.location.replace(data.checkout_url);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'Try again later'
            });
          }
        }
      },
      (error) => {
        setIsLoadingPayment(false);
      }
    );
  };

  const handleTabbyCheckout = async () => {
    customApiRequest(
      getTabbyCheckoutUrlApi(
        {
          id: card?.superOrderId
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingPayment(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            if (data?.checkoutUrl) window.location.replace(data.checkoutUrl);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'Try again later'
            });
          }
        }
      },
      (error) => {
        setIsLoadingPayment(false);
      }
    );
  };

  const renderRepayBtn = () => {
    if (card?.paymentType == paymentTypesValues.onlinePayment)
      return (
        <a
          // target="_blank"
          href={`${
            process.env.REACT_APP_BASE_URL
          }/Payment/preparePayment?order_id=${card?.superOrderId || ''}`}
          className="repay-link"
        >
          {t('repay')}
        </a>
      );
    else if (card?.paymentType == paymentTypesValues.tamara)
      return (
        <button
          // target="_blank"
          onClick={handleTammaraCheckout}
          className="my-orders-link"
          disabled={isLoadingTabby}
        >
          {isLoadingTabby && <LoadingOutlined />} {t('repay')}
        </button>
      );
    else if (card?.paymentType == paymentTypesValues.tabby)
      return (
        <button
          // target="_blank"
          onClick={handleTabbyCheckout}
          className="my-orders-link"
          disabled={isLoadingTabby}
        >
          {isLoadingTabby && <LoadingOutlined />} {t('repay')}
        </button>
      );
  };

  return (
    <div className="user-order-card">
      <div className="card-header">
        <div className="nums-method-wrap">
          <div className="num">
            <div className="label">{t('ordersPage.orderNumber')}</div>
            <div className="value"># {card?.id}</div>
          </div>
          {!!(card?.paymentStatus === paymentStatusValues.not_paid) &&
            renderRepayBtn()}
        </div>

        <div className="dates-wrap">
          <div className="place-date-wrapper">
            <p className="label">{t('ordersPage.placedDate')}</p>
            <div className="date value">
              {dayjs(card?.created_at).format('MMM, D, YYYY')}
            </div>
          </div>
        </div>

        <div className="method">
          {/* <div className="label">{t('ordersPage.paymentType')}</div> */}
          <Tag
            color={
              card?.paymentStatus === paymentStatusValues.paid
                ? 'green-inverse'
                : 'red-inverse'
            }
          >
            {card?.paymentStatus === paymentStatusValues.paid
              ? t('paid')
              : t('not_paid')}
          </Tag>
          {renderPaymentType()}
        </div>

        {/* <div className="status-wrap">
          <RenderOrderStatus status={card?.status} />
        </div> */}
      </div>

      {card?.products?.length > 0 && (
        <div className="order-products">{renderOrderProducts()}</div>
      )}

      <div className="card-footer">
        <div className="btns-wrap">
          <button
            onClick={() => {
              setReceiptModalOpened(true);
              setSelectedOrder(card);
            }}
            className="receipt-btn"
          >
            <img src={receiptIcon} alt="receipt" />
            {t('ordersPage.orderReceipt')}
          </button>

          {/* <RouterLink
            to={userRouterLinks?.userChatCenterRoute}
            className="chat-btn"
          >
            <img src={chatImg} alt="chat" />
            {t('ordersPage.chat')}
          </RouterLink> */}
        </div>

        <div className="rate-wrap">
          {(!card?.rate || card?.rate == 0) &&
          !card?.isRate &&
          card?.canRate === 1 ? (
            <button
              onClick={() => {
                setRateModalOpened(true);
                setSelectedOrder(card);
              }}
              className="rate-order-btn"
            >
              {t('orderRateModal.rateService')}
              <img src={starImg} alt="rate" />
            </button>
          ) : (
            <div className="rate-stars-date-wrapper">
              <Rate value={card?.rate} disabled />
              {card?.rateDate && (
                <p className="rate-date">
                  {t('ordersPage.rateDate')} : <span>( {card.rateDate} )</span>
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      {renderMerchentInfo()}
    </div>
  );
};

export default UserOrderCard;
