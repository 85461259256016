/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import SelectedPageContext from '../contexts/selected-page-context/SelectedPageContext';

const useSelectedPage = ({ altName, currentPage }) => {
  const { setSelectedPage } = useContext(SelectedPageContext);
  const location = useLocation();
  useEffect(() => {
    let isMounted = true;
    if (currentPage && isMounted) {
      if (currentPage?.link == location?.pathname) {
        setSelectedPage({
          altName,
          ...currentPage
        });
      }
    }

    return () => {
      isMounted = false;
      setSelectedPage(null);
    };
  }, []);
};

export default useSelectedPage;
