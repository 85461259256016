/* eslint-disable eqeqeq */
import { Switch } from 'antd';
import { useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toggleAgentApi from '../../../apis/stores-apis/toggleAgentApi';
import copyImg from '../../../assets/imgs/icons/affiliate-icons/copy.svg';
import shareImg from '../../../assets/imgs/icons/affiliate-icons/share.svg';
import statisticsImg from '../../../assets/imgs/icons/affiliate-icons/status-up.svg';
import trashImg from '../../../assets/imgs/icons/affiliate-icons/trash.svg';
import EditIcon from '../../../common/icons/EditIcon';
import generalRouterLinks from '../../../components/app/general-routes/gerneralRouterLinks';
import { agentTypes } from '../../../const-values/affiliateOptions';
import UserContext from '../../../contexts/user-context/UserProvider';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';

const AffiliatePageTableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t,
  props
) => {
  const { user } = useContext(UserContext);
  // const handleViewDelegateDetails = (record) => {
  //   const found = allFetchedTableData.find((obj) => obj.id === record.id);
  //   if (found) {
  //     setSelectedTableRow({ ...found });
  //     setDetailsModalOpened(true);
  //   }
  // };
  const sharedT = (key) => t(`affiliatePage.${key}`);
  const handleEdit = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };

  const handleShareLinkClick = (record) => {
    if (props?.setShareLinkModalOpened) {
      setSelectedTableRow(record);
      props.setShareLinkModalOpened(true);
    }
  };

  const handleStatisticsClick = (record) => {
    if (props?.setStatisticsModalOpened) {
      setSelectedTableRow(record);
      props.setStatisticsModalOpened(true);
    }
  };

  const handleDelete = (record) => {
    if (props?.setDeleteModalOpened) {
      setSelectedTableRow(record);
      props.setDeleteModalOpened(true);
    }
  };

  const handleToggleAgent = (agent) => {
    if (agent) {
      const setIsTogglingAgent = props.setIsTogglingAgent;
      setIsTogglingAgent(true);
      const formData = new FormData();
      formData.append('agent_id', agent?.id);
      customApiRequest(
        toggleAgentApi(formData, user?.token, i18n.language),
        (res) => {
          setIsTogglingAgent(false);
          if (checkRes(res)) {
            successNotification({
              title: t('successTitle'),
              message: res?.data?.message || 'تمت التعديل بنجاح'
            });
            setFetchTableDataCount((prev) => prev + 1);
            setFormModalOpened(false);
          } else {
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsTogglingAgent(false);
          errorNotification({
            title: t('errorTitle'),
            message: 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <button
          className="affiliate-action-btn edit"
          onClick={() => handleEdit(record)}
        >
          <EditIcon color="#196c10" />
          <span>{sharedT('edit')}</span>
        </button>
        <CopyToClipboard
          text={`${
            process.env.REACT_APP_WEBSITE_URL
          }${generalRouterLinks?.generalAffiliateProductRoute(
            record?.product?.id || '',
            record?.agentKey || ''
          )}`}
          onCopy={() => props?.setCopied(true)}
        >
          <button
            className="affiliate-action-btn copy"
            onClick={() => props?.setCopyCount((prev) => prev + 1)}
          >
            <img src={copyImg} alt="copy" />
            <span>{sharedT('copy')}</span>
          </button>
        </CopyToClipboard>
        {/* <button className="affiliate-action-btn copy">
          <img src={copyImg} alt="copy" />
          <span>{sharedT('copy')}</span>
        </button> */}
        <button
          className="affiliate-action-btn share"
          onClick={() => handleShareLinkClick(record)}
        >
          <img src={shareImg} alt="share" />
          <span>{sharedT('shareLink')}</span>
        </button>
        <button
          className="affiliate-action-btn statistics"
          onClick={() => handleStatisticsClick(record)}
        >
          <img src={statisticsImg} alt="statistics" />
          <span>{sharedT('statistics')}</span>
        </button>
        <button
          className="affiliate-action-btn delete"
          onClick={() => handleDelete(record)}
        >
          <img src={trashImg} alt="delete" />
          <span>{sharedT('delete')}</span>
        </button>

        <Switch
          defaultChecked={record?.status == agentTypes?.active}
          onChange={(v) => {
            handleToggleAgent(record);
          }}
          className={`affiliate-toggle ${
            record?.status == agentTypes?.notActive ? 'not-active' : ''
          }`}
        />
      </div>
    );
  };

  return [
    {
      title: sharedT('name'),
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name || '----';
      }
    },

    {
      title: sharedT('action'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default AffiliatePageTableColumns;
