/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import SharedAntdTable from '../../../common/shared-antd-table/SharedAntdTable';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import AffiliateContext from '../../../contexts/affiliate-context/AffiliateContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import AffiliatePageTableColumns from './AffiliatePageTableColumns';
import { useParams } from 'react-router-dom';
import AffiliateDeleteModal from './AffiliateDeleteModal';
import { useTranslation } from 'react-i18next';
import AffiliateShareLinkModal from './AffiliateShareLinkModal';
import AffiliateStatisticsModal from './AffiliateStatisticsModal';
import { notification } from 'antd';
import LoadingModal from '../../../common/loading-modal/LoadingModal';

const AffiliatePageTable = () => {
  const { i18n, t } = useTranslation();
  const params = useParams();
  const { user } = useContext(UserContext);
  const {
    allFetchedTableData,
    tablePagination,
    fetchTableDataCount,
    setFetchTableDataCount,
    setIsLoadingTableData,
    isLoadingTableData,
    setSelectedTableRow,
    setFormModalOpened,
    isTogglingAgent,
    setIsTogglingAgent,
    copied,
    setCopied,
    copyCount,
    setCopyCount,
    //
    deleteAffiliateModalOpened,
    setDeleteAffiliateModalOpened,
    //
    statisticsModalOpened,
    setStatisticsModalOpened,
    //
    shareLinkModalOpened,
    setShareLinkModalOpened
  } = useContext(AffiliateContext);

  useEffect(() => {
    if (copied && copyCount) {
      notification.success({
        message: t('copied'),
        duration: 1.5,
        onClick: () => {
          // console.log('Notification Clicked!');
        }
      });
    }
  }, [copied, copyCount]);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedTableData?.length >= 0) {
      const mappedData = mapDataSource(allFetchedTableData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchTableDataCount, allFetchedTableData]);
  return (
    <div className={`affiliate-table-wrap`}>
      <SharedAntdTable
        scroll={1200}
        showHeader={false}
        className={`affiliate-table ${i18n.dir()}`}
        hasPagination={true}
        tableDataSource={tableDataSource}
        tableColumns={AffiliatePageTableColumns}
        allFetchedTableData={allFetchedTableData}
        setFormModalOpened={setFormModalOpened}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        deleteModalOpened={deleteAffiliateModalOpened}
        setDeleteModalOpened={setDeleteAffiliateModalOpened}
        statisticsModalOpened={statisticsModalOpened}
        setStatisticsModalOpened={setStatisticsModalOpened}
        shareLinkModalOpened={shareLinkModalOpened}
        setShareLinkModalOpened={setShareLinkModalOpened}
        //

        setFetchTableDataCount={setFetchTableDataCount}
        isLoadingTableData={isLoadingTableData}
        setIsLoadingTableData={setIsLoadingTableData}
        setSelectedTableRow={setSelectedTableRow}
        isTogglingAgent={isTogglingAgent}
        setIsTogglingAgent={setIsTogglingAgent}
        copied={copied}
        setCopied={setCopied}
        copyCount={copyCount}
        setCopyCount={setCopyCount}
        tablePagination={tablePagination}
        user={user}
        baseTablePageUrl={merchentRouterLinks?.storeProductAffilliate(
          params?.store_id,
          params?.product_id
        )}
      />

      {shareLinkModalOpened && <AffiliateShareLinkModal />}
      {statisticsModalOpened && <AffiliateStatisticsModal />}
      {deleteAffiliateModalOpened && <AffiliateDeleteModal />}
      {isTogglingAgent && <LoadingModal />}
    </div>
  );
};

export default AffiliatePageTable;
