/* eslint-disable eqeqeq */
import { Popconfirm, Tooltip } from 'antd';
import deleteEmployeeApi from '../../../apis/employess-apis/deleteEmployeeApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteUserImg from '../../../assets/imgs/icons/delete-user.png';
import { allPermissionsArr } from '../../../const-values/permissions';
import CustomImage from '../../../common/custom-image/CustomImage';
import EditIcon from '../../../common/icons/EditIcon';

const tableColumns = (
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  i18n,
  t
) => {
  // const handleViewDelegateDetails = (record) => {
  //   const found = allFetchedTableData.find((obj) => obj.id === record.id);
  //   if (found) {
  //     setSelectedTableRow({ ...found });
  //     setDetailsModalOpened(true);
  //   }
  // };
  const sharedT = (key) => t(`employeesTable.${key}`);

  const handleEditUser = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoadingTableData(true);
      const formData = new FormData();
      formData.append('employee_id', id);
      const res = await deleteEmployeeApi(formData, token, i18n.language);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoadingTableData(false);
        setFetchTableDataCount((prevState) => prevState + 1);
        successNotification({
          title: t('successTitle'),
          message: 'تم حذف العميل بنجاح'
        });
      } else {
        setIsLoadingTableData(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoadingTableData(false);
      errorNotification({
        title: t('errorTitle'),
        message: 'حاول فى وقت لاحق'
      });
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditUser(record)}>
          <EditIcon />
          {t('employeesTable.edit')}
        </div>
        <Popconfirm
          title={t('employeesTable.sure')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('employeesTable.yes')}
          cancelText={t('employeesTable.no')}
        >
          <Tooltip title={t('employeesTable.delete')}>
            <div className="delete-btn">
              <img src={deleteUserImg} alt="delete" />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  const renderSingleRole = (role) => {
    const foundPermission = allPermissionsArr(t).find(
      (item) => item?.value == role
    );
    if (foundPermission)
      return (
        <div key={foundPermission?.id} className="role-tag">
          {foundPermission?.name}
        </div>
      );
  };

  const renderRoles = (record) => {
    return (
      <div className="roles-list">
        {record?.employeeRolesArray?.length > 0 &&
          record.employeeRolesArray.map((perm) => {
            return renderSingleRole(perm);
          })}
        {record?.storeRoles?.length > 0 &&
          record.storeRoles.map((perm) => {
            return renderSingleRole(perm);
          })}
      </div>
    );
  };

  return [
    {
      title: sharedT('empName'),
      dataIndex: 'empName',
      render: (_, record) => {
        return (
          <div className="emp-name-photo-wrap">
            <CustomImage src={record?.image} />
            <div className="name-phone">
              <p>{record?.name}</p>
              <p>{record?.phone}</p>
            </div>
          </div>
        );
      }
    },
    {
      title: sharedT('storeName'),
      dataIndex: 'storeName',
      render: (_, record) => {
        return record?.employeeStore?.name || '----';
      }
    },
    {
      title: sharedT('date'),
      dataIndex: 'date',
      render: (_, record) => {
        return record.employeeCreatedAt || '---';
      }
    },
    {
      title: sharedT('roles'),
      dataIndex: 'roles',
      width: 432,
      render: (_, record) => {
        return renderRoles(record);
      }
    },
    {
      title: sharedT('action'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default tableColumns;
