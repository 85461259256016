import { useState, createContext } from 'react';
import { EditorState } from 'draft-js';
import storeStatus from '../../const-values/storeStatus';

const INITIAL_VALUES = {
  isLoadingTableData: false,
  setIsLoadingTableData: (v) => {},
  formModalOpened: false,
  setFormModalOpened: (v) => {},
  detailsModalOpened: false,
  setDetailsModalOpened: (v) => {},
  fetchTableDataCount: 0,
  setFetchTableDataCount: (v) => {},
  allFetchedTableData: null,
  setAllFetchedTableData: (v) => {},
  //
  termsAndConditionsModalOpened: false,
  setTermsAndConditionsModalOpened: (v) => {},
  deactivateStoreModalOpened: false,
  setDeactivateStoreModalOpened: (v) => {},
  reactivateStoreModalOpened: false,
  setReactivateStoreModalOpened: (v) => {},
  rejectModalOpened: false,
  setRejectModalOpened: (v) => {},
  deleteStoreModalOpened: false,
  setDeleteStoreModalOpened: (v) => {},
  withdrawModalOpened: false,
  setWithdrawModalOpened: (v) => {},
  //
  questionEditorStateAr: EditorState.createEmpty(),
  setQuestionEditorStateAr: (v) => {},
  questionEditorStateEn: EditorState.createEmpty(),
  setQuestionEditorStateEn: (v) => {},
  answerEditorStateAr: EditorState.createEmpty(),
  setAnswerEditorStateAr: (v) => {},
  answerEditorStateEn: EditorState.createEmpty(),
  setAnswerEditorStateEn: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  selectedTableRow: null,
  setSelectedTableRow: (v) => {},
  // filter
  tableFilter: {
    page: 1,
    status: storeStatus?.active
  },
  setTableFilter: (v) => {}
};

const StoresContext = createContext(INITIAL_VALUES);

export const StoresProvider = ({ children }) => {
  const [isLoadingTableData, setIsLoadingTableData] = useState(
    INITIAL_VALUES.isLoading
  );
  const [fetchTableDataCount, setFetchTableDataCount] = useState(
    INITIAL_VALUES.fetchTableDataCount
  );
  const [allFetchedTableData, setAllFetchedTableData] = useState(
    INITIAL_VALUES.allFetchedTableData
  );
  const [selectedTableRow, setSelectedTableRow] = useState(
    INITIAL_VALUES.selectedTableRow
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES.tablePagination
  );
  const [formModalOpened, setFormModalOpened] = useState(
    INITIAL_VALUES.modalOpened
  );
  const [detailsModalOpened, setDetailsModalOpened] = useState(
    INITIAL_VALUES.detailsModalOpened
  );
  const [withdrawModalOpened, setWithdrawModalOpened] = useState(
    INITIAL_VALUES.withdrawModalOpened
  );

  //
  const [termsAndConditionsModalOpened, setTermsAndConditionsModalOpened] =
    useState(INITIAL_VALUES.termsAndConditionsModalOpened);
  const [deactivateStoreModalOpened, setDeactivateStoreModalOpened] = useState(
    INITIAL_VALUES.deactivateStoreModalOpened
  );
  const [reactivateStoreModalOpened, setReactivateStoreModalOpened] = useState(
    INITIAL_VALUES.reactivateStoreModalOpened
  );
  const [rejectModalOpened, setRejectModalOpened] = useState(
    INITIAL_VALUES.rejectModalOpened
  );
  const [deleteStoreModalOpened, setDeleteStoreModalOpened] = useState(
    INITIAL_VALUES.deleteStoreModalOpened
  );
  //
  const [questionEditorStateAr, setQuestionEditorStateAr] = useState(
    INITIAL_VALUES.questionEditorStateAr
  );
  const [questionEditorStateEn, setQuestionEditorStateEn] = useState(
    INITIAL_VALUES.questionEditorStateEn
  );
  const [answerEditorStateAr, setAnswerEditorStateAr] = useState(
    INITIAL_VALUES.answerEditorStateAr
  );
  const [answerEditorStateEn, setAnswerEditorStateEn] = useState(
    INITIAL_VALUES.questionEditorStateEn
  );
  //
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES.tableFilter);

  return (
    <StoresContext.Provider
      value={{
        isLoadingTableData,
        setIsLoadingTableData,
        formModalOpened,
        setFormModalOpened,
        detailsModalOpened,
        setDetailsModalOpened,
        fetchTableDataCount,
        setFetchTableDataCount,
        allFetchedTableData,
        setAllFetchedTableData,
        selectedTableRow,
        setSelectedTableRow,
        //
        termsAndConditionsModalOpened,
        setTermsAndConditionsModalOpened,
        rejectModalOpened,
        setRejectModalOpened,
        deactivateStoreModalOpened,
        setDeactivateStoreModalOpened,
        reactivateStoreModalOpened,
        setReactivateStoreModalOpened,
        deleteStoreModalOpened,
        setDeleteStoreModalOpened,
        withdrawModalOpened,
        setWithdrawModalOpened,
        //
        questionEditorStateAr,
        setQuestionEditorStateAr,
        questionEditorStateEn,
        setQuestionEditorStateEn,
        answerEditorStateAr,
        setAnswerEditorStateAr,
        answerEditorStateEn,
        setAnswerEditorStateEn,
        //
        tablePagination,
        setTablePagination,
        tableFilter,
        setTableFilter
      }}
    >
      {children}
    </StoresContext.Provider>
  );
};

export default StoresContext;
