import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoadingData: false,
  setIsLoadingData: (v) => {},
  fetchDataCount: 0,
  setFetchDataCount: (v) => {},
  fetchedData: null,
  setFetchedData: (v) => {},
  //
  // filter
  tableFilter: {
    page: 1,
    date: ''
  },
  setTableFilter: (v) => {}
};

const ProductStatisticsContext = createContext(INITIAL_VALUES);

export const ProductStatisticsProvider = ({ children }) => {
  const [isLoadingData, setIsLoadingData] = useState(INITIAL_VALUES.isLoading);
  const [fetchDataCount, setFetchDataCount] = useState(
    INITIAL_VALUES.fetchDataCount
  );
  const [fetchedData, setFetchedData] = useState(INITIAL_VALUES.fetchedData);
  //
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES.tableFilter);

  return (
    <ProductStatisticsContext.Provider
      value={{
        isLoadingData,
        setIsLoadingData,
        fetchDataCount,
        setFetchDataCount,
        fetchedData,
        setFetchedData,
        //
        //
        //
        tableFilter,
        setTableFilter
      }}
    >
      {children}
    </ProductStatisticsContext.Provider>
  );
};

export default ProductStatisticsContext;
