import axios from 'axios';

const getAllUserFavApi = async (filterObj, token, langIsoCode) => {
  try {
    const res = await axios.get(
      `/Favourite/myFavourite?sort=${filterObj?.sort || ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          lang: langIsoCode
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllUserFavApi;
