import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../../components/app/routerLinks';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserSettingsForm from './UserSettingsForm';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';

const UserSettingsPage = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const sharedTrans = (key) => t(`userDashboard.${key}`);
  return (
    <div className="user-settings-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: userRouterLinks?.userDashboard
          },
          {
            title: sharedTrans('settings'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{t('user_settings_form.title')}</div>
        </div>
        <div className="page-body">
          <UserSettingsForm />
        </div>
      </div>
    </div>
  );
};

export default UserSettingsPage;
