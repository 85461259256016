/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import emptyImg from '../../../assets/imgs/empty/empty.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import EmptyCompoent from '../../../common/empty-compoent/EmptyComponent';
import routerLinks from '../../../components/app/routerLinks';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import useMessages from '../../../custom-hooks/useMessages';
import useUserCoupons from '../../../custom-hooks/useUserCoupons';
import UserCouponCard from './UserCouponCard';
import './UserCouponsPage.scss';

const UserCouponsPage = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const sharedTrans = (key) => t(`userDashboard.${key}`);
  const { isLoadingData, allFetchedTableData } = useUserCoupons();
  const [copied, setCopied] = useState(false);
  const [copyCount, setCopyCount] = useState(false);
  useEffect(() => {
    if (copied && copyCount) {
      notification.success({
        message: t('copied'),
        duration: 1.5,
        onClick: () => {
          // console.log('Notification Clicked!');
        }
      });
    }
  }, [copied, copyCount]);

  useMessages();
  const renderPageContent = () => {
    if (isLoadingData) {
      return (
        <div
          className="custom-container"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (allFetchedTableData?.length === 0) {
      return <EmptyCompoent img={emptyImg} />;
    } else if (allFetchedTableData?.length > 0) {
      return (
        <div className="page-content-wrapper">
          <div className="custom-container">
            <div className="list-wrapper">
              {allFetchedTableData.map((card) => (
                <UserCouponCard
                  key={card.id}
                  card={card}
                  setCopied={setCopied}
                  setCopyCount={setCopyCount}
                />
              ))}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="user-coupons-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: userRouterLinks?.userDashboard
          },
          {
            title: t('couponsPage.title'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{t('couponsPage.title')}</div>
        </div>
        <div className="page-body">{renderPageContent()}</div>
      </div>
    </div>
  );
};

export default UserCouponsPage;
