import { useParams, Link as RouterLink } from 'react-router-dom';
import CustomImage from '../../common/custom-image/CustomImage';
import PinIcon from '../../common/icons/PinIcon';
import RenderStoreStatus from '../../common/render-store-status/RenderStoreStatus';
import routerLinks from '../../components/app/routerLinks';
import './SaloonStoreCard.scss';

const SaloonStoreCard = ({ obj }) => {
  const params = useParams();
  return (
    <RouterLink
      className="general-store-card"
      to={routerLinks?.singleStoreRoute(obj?.id)}
    >
      <div className="cover-img-wrap">
        <CustomImage className="store-cover" src={obj?.cover} />
      </div>
      <CustomImage className="store-img" src={obj?.logo} />

      <div className="tt-wrap">
        <p className="store-name">{obj?.name}</p>
        <p className="store-location">
          <PinIcon />
          {obj?.country}/{obj?.city}
        </p>
        <RenderStoreStatus obj={obj} />
        <div className="desc">{obj?.description}</div>
      </div>
    </RouterLink>
  );
};

export default SaloonStoreCard;
