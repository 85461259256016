import { useTranslation } from 'react-i18next';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import routerLinks from '../../../components/app/routerLinks';
import userRouterLinks from '../../../components/app/user-routes/uesRouterLinks';
import { useNavigate } from 'react-router-dom';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import useAddresses from '../../../custom-hooks/useAddresses';
import { LoadingOutlined } from '@ant-design/icons';
import { Link as RouterLink } from 'react-router-dom';
import NewAddressIcon from '../../../common/icons/NewAddressIcon';
import MyAddressCard from './MyAddressCard';
import './UserAddressesPage.scss';

const UserAddressesPage = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { isLoadingAddresses, allFetchedAddresses, setFetchAddressesCount } =
    useAddresses();

  const renderNewAddressCard = () => {
    return (
      <RouterLink
        to={userRouterLinks?.createNewAddressRoute}
        className="my-address-card new-card"
      >
        <NewAddressIcon />
        <p>{t('addressesPage.newAddress')}</p>
      </RouterLink>
    );
  };

  const renderPageContent = () => {
    if (isLoadingAddresses) {
      return (
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            padding: '132px 0',
            margin: '32px auto'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (allFetchedAddresses?.length === 0) return renderNewAddressCard();
    else if (allFetchedAddresses?.length > 0)
      return (
        <ul className="my-addresses-ul">
          {allFetchedAddresses.map((card) => (
            <MyAddressCard
              setFetchAddressesCount={setFetchAddressesCount}
              key={card?.id}
              card={card}
            />
          ))}
          {renderNewAddressCard()}
        </ul>
      );

    return null;
  };

  const sharedTrans = (key) => t(`userDashboard.${key}`);
  return (
    <div className="user-addresses-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: userRouterLinks?.userDashboard
          },
          {
            title: sharedTrans('address'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-arrow-wrap">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            {i18n.dir() === 'rtl' ? (
              <img src={HrArrowRtl} alt="back" />
            ) : (
              <img src={HrArrowLtr} alt="back" />
            )}
          </button>
          <div className="main-page-title">{t('addressesPage.title')}</div>
        </div>
        <div className="page-body">{renderPageContent()}</div>
      </div>
    </div>
  );
};

export default UserAddressesPage;
