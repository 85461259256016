import React from 'react';

const DashboardIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2328_34845)">
        <path
          d="M17 0.0478516H20C21.0609 0.0478516 22.0783 0.469279 22.8284 1.21942C23.5786 1.96957 24 2.98699 24 4.04785V7.04785C24 8.10872 23.5786 9.12613 22.8284 9.87628C22.0783 10.6264 21.0609 11.0479 20 11.0479H17C15.9391 11.0479 14.9217 10.6264 14.1716 9.87628C13.4214 9.12613 13 8.10872 13 7.04785V4.04785C13 2.98699 13.4214 1.96957 14.1716 1.21942C14.9217 0.469279 15.9391 0.0478516 17 0.0478516V0.0478516ZM15 7.04785C15 7.57828 15.2107 8.08699 15.5858 8.46206C15.9609 8.83714 16.4696 9.04785 17 9.04785H20C20.5304 9.04785 21.0391 8.83714 21.4142 8.46206C21.7893 8.08699 22 7.57828 22 7.04785V4.04785C22 3.51742 21.7893 3.00871 21.4142 2.63364C21.0391 2.25857 20.5304 2.04785 20 2.04785H17C16.4696 2.04785 15.9609 2.25857 15.5858 2.63364C15.2107 3.00871 15 3.51742 15 4.04785V7.04785Z"
          fill={color || '#6D6D6D'}
        />
        <path
          d="M4.00001 0.0478516H7.00001C8.06087 0.0478516 9.07828 0.469279 9.82843 1.21943C10.5786 1.96957 11 2.98699 11 4.04785V7.04786C11 8.10872 10.5786 9.12614 9.82843 9.87629C9.07828 10.6264 8.06087 11.0479 7.00001 11.0479H4.00001C2.93915 11.0479 1.92173 10.6264 1.17159 9.87629C0.421443 9.12614 1.52588e-05 8.10872 1.52588e-05 7.04786V4.04785C1.52588e-05 2.98699 0.421443 1.96957 1.17159 1.21943C1.92173 0.469279 2.93915 0.0478516 4.00001 0.0478516V0.0478516ZM2.00001 7.04786C2.00001 7.57829 2.21073 8.087 2.5858 8.46207C2.96087 8.83714 3.46958 9.04786 4.00001 9.04786H7.00001C7.53044 9.04786 8.03914 8.83714 8.41422 8.46207C8.78929 8.087 9 7.57829 9 7.04786V4.04785C9 3.51742 8.78929 3.00871 8.41422 2.63364C8.03914 2.25857 7.53044 2.04785 7.00001 2.04785H4.00001C3.46958 2.04785 2.96087 2.25857 2.5858 2.63364C2.21073 3.00871 2.00001 3.51742 2.00001 4.04785V7.04786Z"
          fill={color || '#6D6D6D'}
        />
        <path
          d="M17 13.0479H20C21.0609 13.0479 22.0783 13.4693 22.8284 14.2194C23.5786 14.9696 24 15.987 24 17.0479V20.0479C24 21.1087 23.5786 22.1261 22.8284 22.8763C22.0783 23.6264 21.0609 24.0479 20 24.0479H17C15.9391 24.0479 14.9217 23.6264 14.1716 22.8763C13.4214 22.1261 13 21.1087 13 20.0479V17.0479C13 15.987 13.4214 14.9696 14.1716 14.2194C14.9217 13.4693 15.9391 13.0479 17 13.0479ZM15 20.0479C15 20.5783 15.2107 21.087 15.5858 21.4621C15.9609 21.8371 16.4696 22.0479 17 22.0479H20C20.5304 22.0479 21.0391 21.8371 21.4142 21.4621C21.7893 21.087 22 20.5783 22 20.0479V17.0479C22 16.5174 21.7893 16.0087 21.4142 15.6336C21.0391 15.2586 20.5304 15.0479 20 15.0479H17C16.4696 15.0479 15.9609 15.2586 15.5858 15.6336C15.2107 16.0087 15 16.5174 15 17.0479V20.0479Z"
          fill={color || '#6D6D6D'}
        />
        <path
          d="M4.00001 13.0479H7.00001C8.06087 13.0479 9.07828 13.4693 9.82843 14.2194C10.5786 14.9696 11 15.987 11 17.0479V20.0479C11 21.1087 10.5786 22.1261 9.82843 22.8763C9.07828 23.6264 8.06087 24.0479 7.00001 24.0479H4.00001C2.93915 24.0479 1.92173 23.6264 1.17159 22.8763C0.421443 22.1261 1.52588e-05 21.1087 1.52588e-05 20.0479V17.0479C1.52588e-05 15.987 0.421443 14.9696 1.17159 14.2194C1.92173 13.4693 2.93915 13.0479 4.00001 13.0479ZM2.00001 20.0479C2.00001 20.5783 2.21073 21.087 2.5858 21.4621C2.96087 21.8371 3.46958 22.0479 4.00001 22.0479H7.00001C7.53044 22.0479 8.03914 21.8371 8.41422 21.4621C8.78929 21.087 9 20.5783 9 20.0479V17.0479C9 16.5174 8.78929 16.0087 8.41422 15.6336C8.03914 15.2586 7.53044 15.0479 7.00001 15.0479H4.00001C3.46958 15.0479 2.96087 15.2586 2.5858 15.6336C2.21073 16.0087 2.00001 16.5174 2.00001 17.0479V20.0479Z"
          fill={color || '#6D6D6D'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2328_34845">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 1 24 0.0478516)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DashboardIcon;
