import React from 'react';

const CameraOtherIcon = ({ color }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4981_5213)">
        <path
          d="M57.25 22.3895C55.5614 21.5338 53.5332 21.7122 52.02 22.8495L47.4375 26.252C46.7878 19.8686 41.4164 15.0101 35 15.002H34.7876L24.4826 4.69949C23.0802 3.28726 21.1704 2.49578 19.1801 2.50199L2.49996 2.50199C1.11926 2.50199 0 3.62136 0 5.00207C0 6.38277 1.11926 7.50203 2.49996 7.50203L19.18 7.50203C19.8427 7.50379 20.4778 7.76699 20.9475 8.23457L27.715 15.002L12.5 15.002C5.59992 15.0104 0.00832031 20.602 0 27.5021L0 47.5021C0.00832031 54.4021 5.59992 59.9938 12.5 60.002L35 60.002C41.4165 59.9939 46.7878 55.1355 47.4375 48.752L52.0125 52.1621C54.2216 53.8189 57.3557 53.3712 59.0125 51.1621C59.6616 50.2967 60.0124 49.244 60.0124 48.1621L60.0124 26.8595C60.019 24.9647 58.9477 23.2312 57.25 22.3895ZM42.5 47.502C42.5 51.6441 39.1421 55.002 35 55.002L12.5 55.002C8.35793 55.002 5.00004 51.6441 5.00004 47.502L5.00004 27.5021C5.00004 23.36 8.35793 20.0021 12.5 20.0021L35 20.0021C39.1421 20.0021 42.5 23.36 42.5 27.5021L42.5 47.502ZM55 48.1445L47.5 42.5546V32.4496L55 26.8596L55 48.1445Z"
          fill={color || '#6d6d6d'}
        />
      </g>
      <defs>
        <clipPath id="clip0_4981_5213">
          <rect width="60" height="60" fill={color || '#6d6d6d'} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CameraOtherIcon;
