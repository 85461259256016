import React from 'react';

const LockIcon = ({ color, width, height, ...props }) => {
  return (
    <span {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1026_4383)">
          <path
            d="M14.25 6.318V5.25C14.25 3.85761 13.6969 2.52226 12.7123 1.53769C11.7277 0.553124 10.3924 0 9 0C7.60761 0 6.27226 0.553124 5.28769 1.53769C4.30312 2.52226 3.75 3.85761 3.75 5.25V6.318C3.08202 6.60953 2.51347 7.08939 2.11387 7.69891C1.71428 8.30842 1.50096 9.02118 1.5 9.75V14.25C1.50119 15.2442 1.89666 16.1973 2.59966 16.9003C3.30267 17.6033 4.2558 17.9988 5.25 18H12.75C13.7442 17.9988 14.6973 17.6033 15.4003 16.9003C16.1033 16.1973 16.4988 15.2442 16.5 14.25V9.75C16.499 9.02118 16.2857 8.30842 15.8861 7.69891C15.4865 7.08939 14.918 6.60953 14.25 6.318ZM5.25 5.25C5.25 4.25544 5.64509 3.30161 6.34835 2.59835C7.05161 1.89509 8.00544 1.5 9 1.5C9.99456 1.5 10.9484 1.89509 11.6517 2.59835C12.3549 3.30161 12.75 4.25544 12.75 5.25V6H5.25V5.25ZM15 14.25C15 14.8467 14.7629 15.419 14.341 15.841C13.919 16.2629 13.3467 16.5 12.75 16.5H5.25C4.65326 16.5 4.08097 16.2629 3.65901 15.841C3.23705 15.419 3 14.8467 3 14.25V9.75C3 9.15326 3.23705 8.58097 3.65901 8.15901C4.08097 7.73705 4.65326 7.5 5.25 7.5H12.75C13.3467 7.5 13.919 7.73705 14.341 8.15901C14.7629 8.58097 15 9.15326 15 9.75V14.25Z"
            fill={color ? color : '#C4C4C4'}
          />
          <path
            d="M9 10.5C8.80109 10.5 8.61032 10.579 8.46967 10.7197C8.32902 10.8603 8.25 11.0511 8.25 11.25V12.75C8.25 12.9489 8.32902 13.1397 8.46967 13.2803C8.61032 13.421 8.80109 13.5 9 13.5C9.19892 13.5 9.38968 13.421 9.53034 13.2803C9.67099 13.1397 9.75001 12.9489 9.75001 12.75V11.25C9.75001 11.0511 9.67099 10.8603 9.53034 10.7197C9.38968 10.579 9.19892 10.5 9 10.5Z"
            fill={color ? color : '#C4C4C4'}
          />
        </g>
        <defs>
          <clipPath id="clip0_1026_4383">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  );
};

export default LockIcon;
