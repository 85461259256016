/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getPriceFilterApi from '../apis/general/getPriceFilterApi';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const usePriceFilter = () => {
  const { i18n } = useTranslation();
  const [isLoadingPriceFilter, setIsLoadingPriceFilter] = useState(false);
  const [fetchPriceFilterCount, setFetchPriceFilterCount] = useState(0);
  const [allFetchedPriceFilter, setAllFetchedPriceFilter] = useState([]);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    setIsLoadingPriceFilter(true);
    customApiRequest(
      getPriceFilterApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoadingPriceFilter(false);
          if (checkRes(res) && res?.data?.data) {
            setAllFetchedPriceFilter(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoadingPriceFilter(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchPriceFilterCount]);

  return {
    isLoadingPriceFilter,
    setIsLoadingPriceFilter,
    //
    fetchPriceFilterCount,
    setFetchPriceFilterCount,
    //
    allFetchedPriceFilter,
    setAllFetchedPriceFilter
  };
};

export default usePriceFilter;
