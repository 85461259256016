/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import UserContext from '../../../contexts/user-context/UserProvider';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import BankIcon from '../../../common/icons/BankIcon';
import './UserBankAccountForm.scss';
import IbanIcon from '../../../common/icons/IbanIcon';
import cameraImg from '../../../assets/imgs/icons/camera-icon.png';
import UserIcon from '../../../common/icons/UserIcon';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import useCountries from '../../../custom-hooks/useCountries';
import FileInput from '../../../common/file-input/FileInput';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import getBankDataApi from '../../../apis/user-bank-apis/getBankDataApi';
import checkRes from '../../../utils/checkRes';
import deleteBankAccountImageApi from '../../../apis/user-bank-apis/deleteBankAccountImageApi';
import postBankDataApi from '../../../apis/user-bank-apis/postBankDataApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import LoadingModal from '../../../common/loading-modal/LoadingModal';

const UserBankAccountForm = () => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const sharedLabelTrans = (key) => t(`bankAccountForm.${key}.label`);
  const sharedRequiredErrorsTrans = (key) =>
    t(`bankAccountForm.${key}.errors.required`);
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    register,
    unregister,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(sharedRequiredErrorsTrans('bankName')),
        city_id: Yup.string().required(sharedRequiredErrorsTrans('city')),
        iban: Yup.string().required(sharedRequiredErrorsTrans('iban'))
      })
    ),
    mode: 'all',
    defaultValues: {
      name: '',
      bankName: '',
      accountNumber: '',
      country_id: '',
      city_id: '',
      iban: '',
      image: null
    }
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [fetchedBankData, setFetchedBankData] = useState(null);
  const { allFetchedCountries: countries } = useCountries();
  const handleCityOptions = (countryId) => {
    if (countryId && countries?.length > 0) {
      const foundCountry = countries.find((c) => c.id == countryId);
      if (foundCountry && foundCountry.cities?.length > 0) {
        return foundCountry.cities.map((city) => ({
          title: city?.name,
          value: city?.id
        }));
      }
    }
    return [];
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    customApiRequest(
      getBankDataApi(user?.token, i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res.data?.data) {
            const data = res.data.data;
            setFetchedBankData(data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount]);

  const handleDeleteImgReq = () => {
    customApiRequest(
      deleteBankAccountImageApi(null, user?.token, i18n.language),
      (res) => {
        setIsLoading(false);
        if (checkRes(res)) {
          setFetchCount((prev) => prev + 1);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  // handle initial values
  useEffect(() => {
    if (fetchedBankData) {
      setValue('name', fetchedBankData?.name || '');
      setValue('bankName', fetchedBankData?.bankName || '');
      setValue('accountNumber', fetchedBankData?.accountNumber || '');

      if (countries?.length > 0 && fetchedBankData.country_id) {
        setValue('country_id', String(fetchedBankData.country_id));
      }
      setValue('city_id', String(fetchedBankData?.city_id) || '');
      setValue('iban', fetchedBankData?.iban || '');
    }
  }, [fetchedBankData, countries?.length]);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name', data.name || '');
    formData.append('bankName', data.bankName || '');
    formData.append('accountNumber', data.accountNumber || '');
    formData.append('country_id', data.country_id || '');
    formData.append('city_id', data.city_id || '');
    formData.append('iban', data.iban || '');
    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }
    setIsSubmittingForm(true);
    // setIsLoadSingleProduct(true);
    customApiRequest(
      postBankDataApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        // setIsLoadSingleProduct(false);
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message:
              i18n?.language === 'ar'
                ? 'تم تعديل البيانات بنجاح'
                : 'Operation done successfully'
          });
          setFetchCount((prev) => prev + 1);
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || t('errorMessage')
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        // setIsLoadSingleProduct(false);
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || t('errorMessage')
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <>
      <Form
        className="user-bank-account-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="fields-wrapper">
            <AntdTextField
              name="name"
              type="text"
              placeholder={sharedLabelTrans('name')}
              label={sharedLabelTrans('name')}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              prefix={<UserIcon />}
              control={control}
            />
            <AntdTextField
              name="bankName"
              type="text"
              placeholder={sharedLabelTrans('bankName')}
              label={sharedLabelTrans('bankName')}
              errorMsg={errors?.bankName?.message}
              validateStatus={errors?.bankName ? 'error' : ''}
              prefix={<BankIcon />}
              control={control}
            />
            <div className="select-label-wrap">
              <p className="label-p">{sharedLabelTrans('country')}</p>
              <div className="custom-select-wrap without-icon">
                <AntdSelectOption
                  name={`country_id`}
                  errorMsg={errors?.country_id && errors.country_id.message}
                  validateStatus={errors?.country_id ? 'error' : ''}
                  control={control}
                  setValue={setValue}
                  placeholder={sharedLabelTrans('country')}
                  prefix={<UserIcon />}
                  options={
                    countries?.length > 0 &&
                    countries.map((obj) => {
                      return {
                        title: obj?.name,
                        value: obj?.id
                      };
                    })
                  }
                  showSearch={true}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  formClassName="user-bank-account-form"
                />
              </div>
            </div>

            <div className="select-label-wrap">
              <p className="label-p">{sharedLabelTrans('city')}</p>
              <div className="custom-select-wrap without-icon">
                <AntdSelectOption
                  name={`city_id`}
                  errorMsg={errors?.city_id && errors.city_id.message}
                  validateStatus={errors?.city_id ? 'error' : ''}
                  control={control}
                  setValue={setValue}
                  placeholder={sharedLabelTrans('city')}
                  options={handleCityOptions(watch('country_id'))}
                  showSearch={true}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  formClassName="user-bank-account-form"
                />
              </div>
            </div>
            <AntdTextField
              name="accountNumber"
              type="text"
              placeholder={sharedLabelTrans('accountNumber')}
              label={sharedLabelTrans('accountNumber')}
              errorMsg={errors?.accountNumber?.message}
              validateStatus={errors?.accountNumber ? 'error' : ''}
              prefix={<IbanIcon />}
              control={control}
            />
            <AntdTextField
              name="iban"
              type="text"
              placeholder={sharedLabelTrans('iban')}
              label={sharedLabelTrans('iban')}
              errorMsg={errors?.iban?.message}
              validateStatus={errors?.iban ? 'error' : ''}
              prefix={<IbanIcon />}
              control={control}
            />
          </div>
          <FileInput
            name="image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={sharedLabelTrans('image')}
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            icon={
              <img
                style={{
                  width: 52
                }}
                src={cameraImg}
                alt="upload"
              />
            }
            className="product-images-dropzone"
            dropzoneUrls={
              fetchedBankData?.image ? [{ url: fetchedBankData.image }] : []
            }
            canDelete={true}
            handleDeleteImgReq={handleDeleteImgReq}
            showError={false}
            errorMsg={errors?.image?.message}
          />
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // loading={isSubmittingForm}
        >
          {t('walletPage.continue')}
        </Button>
      </Form>
      {(isLoading || isSubmittingForm) && <LoadingModal />}
    </>
  );
};

export default UserBankAccountForm;
