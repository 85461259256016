import axios from 'axios';

const getAllUserSaloonsApi = async (filter, token, langIsoCode) => {
  try {
    const url = `/Saloon/userCitySaloons?page=${filter?.page || 1}`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        lang: langIsoCode
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllUserSaloonsApi;
