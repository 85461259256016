/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getBlogCatsApi from '../apis/blogs-apis/getBlogCatsApi';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useBlogCats = () => {
  const { i18n } = useTranslation();
  const [isLoadingCats, setIsLoadingCats] = useState(false);
  const [fetchCarsCount, setFetchCarsCount] = useState(0);
  const [allFetchedCats, setAllFetchedCats] = useState([]);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    setIsLoadingCats(true);
    customApiRequest(
      getBlogCatsApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoadingCats(false);
          if (checkRes(res) && res?.data?.data) {
            setAllFetchedCats(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoadingCats(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCarsCount]);

  return {
    isLoadingCats,
    setIsLoadingCats,
    //
    fetchCarsCount,
    setFetchCarsCount,
    //
    allFetchedCats,
    setAllFetchedCats
  };
};

export default useBlogCats;
