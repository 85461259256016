/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Button } from 'antd';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import deleteSingleProductApi from '../../../apis/products-apis/deleteSingleProductApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import WarningImg from '../../../assets/imgs/icons/warning.png';
import './SingleProductDeleteModal.scss';

const SingleProductDeleteModal = (props) => {
  const { i18n, t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    product,
    setSelectedCard,
    modalOpened,
    setModalOpened,
    setFetchCount,
    isSubmittingForm,
    setIsSubmittingForm
  } = props;

  const customApiRequest = useCustomApiRequest();

  const handleDeleteProduct = () => {
    setIsSubmittingForm(true);
    const formData = new FormData();
    formData.append('product_id', product?.id);
    customApiRequest(
      deleteSingleProductApi(formData, user?.token, i18n.language),
      (res) => {
        setIsSubmittingForm(false);
        setSelectedCard(null);
        if (checkRes(res)) {
          setFetchCount((prev) => prev + 1);
        } else {
          errorNotification({
            title: 'Error',
            message: res?.data.message || 'Try again later'
          });
        }
      },
      (error) => {
        setSelectedCard(null);
        errorNotification({
          title: 'Error',
          message: error?.response?.data.message || 'Try again later'
        });
      }
    );
  };

  const sharedT = (key) => t(`deleteModal.${key}`);

  const handleCancel = () => {
    setModalOpened(false);
    setSelectedCard && setSelectedCard(null);
  };

  return (
    <Modal
      wrapClassName="shared-delete-modal-wrap"
      transitionName=""
      className="product-delete-modal shared-custom-modal shared-delete-modal"
      width="90%"
      style={{ maxWidth: '694px' }}
      // title={<div className="modal-title"></div>}
      title={null}
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedCard && setSelectedCard(null);
      }}
      onCancel={() => {
        handleCancel();
      }}
      footer={false}
    >
      <div className="modal-content">
        <img className="warning-img" src={WarningImg} alt="warning" />
        <p>{sharedT('title')}</p>
        <div className="modal-btns">
          <Button
            className="submit-btn"
            onClick={handleDeleteProduct}
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmittingForm}
          >
            {sharedT('yes')}
          </Button>
          <Button onClick={handleCancel} className="cancel-btn">
            {sharedT('no')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SingleProductDeleteModal;
