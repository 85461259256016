/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedAntdTable from '../../../../../common/shared-antd-table/SharedAntdTable';
import StoreOrdersContext from '../../../../../contexts/store-orders-context/StoreOrdersContext';
import UserContext from '../../../../../contexts/user-context/UserProvider';
import tableColumns from './tableColumns';

const OrderReportsTable = ({ complaints, isLoadingDate, setIsLoadingData }) => {
  const { i18n } = useTranslation();
  const { user } = useContext(UserContext);
  const { reportRepliesModal, setReportRepliesModal } =
    useContext(StoreOrdersContext);

  console.log({ reportRepliesModal });

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (complaints?.length >= 0) {
      const mappedData = mapDataSource(complaints);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [complaints, complaints?.length]);

  const renderTable = () => {
    if (isLoadingDate) {
      return (
        <div
          style={{
            width: 'fit-content',
            margin: 'auto',
            padding: '72px 0'
          }}
        >
          <LoadingOutlined />
        </div>
      );
    } else if (!complaints || complaints?.length === 0) {
      return (
        <Empty
          style={{
            margin: '42px 0'
          }}
          description={false}
        >
          {i18n.language === 'ar'
            ? 'لا توجد شكاوي خاصة بالطلب!!'
            : 'No Available order complaints!!'}
        </Empty>
      );
    } else if (complaints?.length > 0) {
      return (
        <SharedAntdTable
          className="transactions-table"
          bordered
          // scroll={1200}
          scroll={852}
          hasPagination={true}
          tableDataSource={tableDataSource}
          tableColumns={tableColumns}
          allFetchedTableData={complaints}
          // setFormModalOpened={setFormModalOpened}
          // setDetailsModalOpened={setDetailsModalOpened}
          // setFetchTableDataCount={setFetchTableDataCount}
          isLoadingTableData={isLoadingDate}
          setIsLoadingTableData={setIsLoadingData}
          // setSelectedTableRow={setSelectedTableRow}
          user={user}
          setReportRepliesModal={setReportRepliesModal}
        />
      );
    }
    return null;
  };
  return <section className="transactions-section">{renderTable()}</section>;
};

export default OrderReportsTable;
