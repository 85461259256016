/* eslint-disable eqeqeq */
import React, { useState, useContext } from 'react';
import ActivationCodeForm from '../../common/activation-code-form/ActivationCodeForm';
import useDigitInput from 'react-digit-input';
import checkRes from '../../utils/checkRes';
import UesrContext from '../../contexts/user-context/UserProvider';
import './ActivateUserPage.scss';
import routerLinks from '../../components/app/routerLinks';
import { useNavigate } from 'react-router-dom';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import {
  checkPasswordCodeApi,
  resendCodeApi
} from '../../apis/auth/checkActiveCodeApi';
import { useTranslation } from 'react-i18next';
import Logo from '../../common/logo/Logo';
import accountTypes from '../../const-values/accountTypes';
import merchentRouterLinks from '../../components/app/merchent-routes/merchentRouterLinks';

const btnTypes = {
  confirmCode: 1,
  resendCode: 2
};

const ActivateAccountPage = () => {
  const { i18n, t } = useTranslation();
  const { user, setCurrentUser } = useContext(UesrContext);
  const customApiRequest = useCustomApiRequest();

  const navigate = useNavigate();
  const [submitCodeCount, setSubmitCodeCount] = React.useState(0);
  const [value, onChange] = React.useState('');
  const [err, setErr] = useState({});
  const digits = useDigitInput({
    acceptedCharacters: /^[0-9]$/,
    length: 4,
    value,
    onChange
  });

  const [isLoadingState, setIsLoadingState] = React.useState({
    type: '',
    isLoading: false
  });

  const submitForm = () => {
    if (value?.trim().length === 4) {
      setErr({});
      setIsLoadingState({
        type: btnTypes.confirmCode,
        isLoading: true
      });

      const formData = new FormData();
      formData.append('code', value);

      customApiRequest(
        checkPasswordCodeApi(formData, user?.token, i18n.language),
        (res) => {
          setIsLoadingState({
            type: btnTypes.confirmCode,
            isLoading: false
          });
          if (checkRes(res)) {
            successNotification({
              title: t('successTitle'),
              message: res?.data?.message || 'تم تفعيل الحساب بنجاح'
            });
            setCurrentUser({
              ...user,
              accountType: user?.accountType || null,
              activated: true,
              firstTime: true
            });
            sessionStorage.removeItem('currentUser');
            //
            if (user?.accountType == accountTypes()?.user)
              navigate(routerLinks?.homePage);
            else navigate(merchentRouterLinks?.merchentDashboard);
          } else {
            setSubmitCodeCount((prev) => prev + 1);
            errorNotification({
              title: t('errorTitle'),
              message: res?.data?.message || 'الكود غير صحيح'
            });
          }
        },
        (error) => {
          setIsLoadingState({
            type: btnTypes.confirmCode,
            isLoading: false
          });
          setSubmitCodeCount((prev) => prev + 1);
          errorNotification({
            title: t('errorTitle'),
            message: error?.response?.data?.message || 'الكود غير صحيح'
          });
        }
      );
    } else {
      setErr({
        message: 'الكود غير صحيح'
      });
    }
  };

  const resendCode = () => {
    setIsLoadingState({
      type: btnTypes.resendCode,
      isLoading: true
    });
    // check == 1 <= check activation code after registeration
    // check == 2 <= check activation code after resetting Password
    const formData = new FormData();
    formData.append('check', 1);

    customApiRequest(
      resendCodeApi(formData, user?.token, i18n.language),
      (res) => {
        setIsLoadingState({
          type: btnTypes.resendCode,
          isLoading: false
        });
        if (checkRes(res)) {
          successNotification({
            title: t('successTitle'),
            message: 'تم ارسال رمز التحقق إلى البريد الإلكتروني'
          });
        } else {
          errorNotification({
            title: t('errorTitle'),
            message: res?.data?.message || 'حاول فى وقت لاحق..'
          });
        }
      },
      (error) => {
        setIsLoadingState({
          type: btnTypes.resendCode,
          isLoading: false
        });
        errorNotification({
          title: t('errorTitle'),
          message: error?.response?.data?.message || 'حاول فى وقت لاحق..'
        });
      }
    );
  };

  return (
    <div className="activate-user-page ">
      <div className="mfa-container">
        <Logo className="logo-wrap" />
        <div className="title-form-wrap">
          <div className="modal-title">{t('activateCodeForm.ver')}</div>
          <div className="form-title">{t('activateCodeForm.check')}</div>
          <ActivationCodeForm
            value={value}
            digits={digits}
            err={err}
            setErr={setErr}
            submitCodeCount={submitCodeCount}
            onChange={onChange}
            isLoadingState={isLoadingState}
            submitForm={submitForm}
            resendCode={resendCode}
            btnTypes={btnTypes}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivateAccountPage;
