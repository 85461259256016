/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { LoadingOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import StoreStatisticsContext from '../../../contexts/store-statistics-context/StoreStatisticsContext';
import useStoreStatistics from '../../../custom-hooks/useStoreStatistics';
import MerchentStoreStatisticsChart from './MerchentStoreStatisticsChart';
import './MerchentStoreStatisticsPage.scss';
import StatisticsProductCard from './StatisticsProductCard';

const MerchentStoreStatisticsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    isLoadingData,
    fetchedData,
    setIsLoadingData,
    setFetchedData,
    fetchDataCount,
    setTableFilter,
    tableFilter
  } = useContext(StoreStatisticsContext);
  useStoreStatistics({
    fetchCount: fetchDataCount,
    setFetchedData,
    setIsLoading: setIsLoadingData,
    dataFitler: tableFilter
  });
  const { i18n, t } = useTranslation();

  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  const onChange = (date, dateString) => {
    // setFetchDataCount((prev) => prev + 1);
    setTableFilter((prev) => ({
      ...prev,
      date: dateString
    }));
    navigate(
      `${merchentRouterLinks?.storeStatistics(params?.store_id)}?date=${
        dateString || ''
      }`
    );
  };

  useEffect(() => {
    if (!values?.date)
      navigate(
        `${merchentRouterLinks?.storeStatistics(params?.store_id)}?date=${dayjs(
          new Date()
        ).format('YYYY-MM')}`
      );
  }, []);

  const renderProductsWrapper = () => {
    if (isLoadingData) {
      return (
        <div
          className="custom-container products-wrapper"
          style={{
            marginTop: 92,
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      );
    } else if (fetchedData) {
      return (
        <div className="products-wrapper">
          <div className="ps-wrapper most-sales-products">
            <div className="title-date-wrapper">
              <p className="wrapper-title">{t('storeStatistics.lessSales')}</p>
              <DatePicker
                defaultValue={
                  values?.date
                    ? dayjs(values?.date, 'YYYY-MM')
                    : dayjs(new Date(), 'YYYY-MM')
                }
                onChange={onChange}
                picker="month"
              />
            </div>

            {renderProductList(fetchedData?.lessSeller)}
          </div>
          <div className="ps-wrapper less-sales-products">
            <div className="title-date-wrapper">
              <p className="wrapper-title">{t('storeStatistics.mostSales')}</p>
              <DatePicker
                defaultValue={
                  values?.date ? dayjs(values?.date, 'YYYY-MM') : ''
                }
                onChange={onChange}
                picker="month"
              />
            </div>
            {renderProductList(fetchedData?.mostSeller)}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderProductList = (arr) => {
    return (
      arr?.length > 0 &&
      arr.map((item) => {
        return <StatisticsProductCard key={item?.id} card={{ ...item }} />;
      })
    );
  };

  return (
    <div className="merchent-store-statistics-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('stores'),
            isLink: true,
            to: merchentRouterLinks?.merchentStores
          },
          {
            title: sharedTrans('statistics'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-create-store-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">{sharedTrans('statistics')}</div>
          </div>
        </div>

        <div className="chart-products-wrapper">
          <div className="chart-wrapper">
            <MerchentStoreStatisticsChart />
          </div>
          {renderProductsWrapper()}
        </div>
      </div>
    </div>
  );
};

export default MerchentStoreStatisticsPage;
