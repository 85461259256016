import { useTranslation } from 'react-i18next';
import ArchHeading from '../../common/arch-heading/ArchHeading';
import productStatus from '../../const-values/productStatus';
import SlickSliderBtns from '../../utils/SlickSliderBtns';
import SharedProductsSlider from '../home-page/home-slides/SharedProductsSlider';

const GeneralSingleProductRelatedProducts = ({
  slides,
  isLoading,
  setFetchCount
}) => {
  SlickSliderBtns('related-products-slider', isLoading);
  const { t } = useTranslation();

  return (
    <section className="related-products-section">
      <div className="custom-container">
        <ArchHeading title={t('singleProductPage.relatedProduct')} />

        <SharedProductsSlider
          slides={
            slides?.length > 0
              ? slides.filter(
                  (slide) => slide?.status === productStatus?.active
                )
              : []
          }
          className="related-products-slider"
          setFetchCount={setFetchCount}
        />
      </div>
    </section>
  );
};

export default GeneralSingleProductRelatedProducts;
