import MessagesWrapper from '../../../components/messages/MessagesWrapper';

const UserChatCenterPage = () => {
  return (
    <div className="user-chat-center-page">
      <h1>User chat center</h1>
      <MessagesWrapper />
    </div>
  );
};

export default UserChatCenterPage;
