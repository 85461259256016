import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
dayjs.extend(customParseFormat);

const CustomAntdDatePicker = ({
  name,
  placeholder,
  control,
  // defaultValue,
  errorMsg,
  className,
  disablePast,
  disableFuture,
  label,
  format,
  onChange,
  disabled = false,
  handleDisableDate,
  open = undefined
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });

  const { t: mainT } = useTranslation();
  const { i18n } = useTranslation();
  const handleDisabledDate = (curr) => {
    if (handleDisableDate) {
      return handleDisableDate(curr);
    } else {
      if (disablePast) {
        // Can not select days before today and today
        // return curr && curr < dayjs().endOf('day');
        return curr && curr < dayjs().startOf('day');
      } else if (disableFuture) {
        return curr && curr > dayjs().endOf('day');
      }
      return false;
    }
  };

  // const onChange: DatePickerProps['onChange'] = (date, dateString) => {
  //   console.log(date, dateString);
  //   // setValue(name, dateString);
  // };

  return (
    <div className="custom-date-picker-wrap">
      {label ? <p className="picker-label">{label}</p> : null}
      <DatePicker
        open={open}
        disabled={disabled}
        disabledDate={handleDisabledDate}
        {...field}
        className={className}
        format={
          format ? format : i18n.language === 'ar' ? 'DD-MM-YYYY' : 'YYYY-MM-DD'
        }
        onChange={(date, dateString) => {
          field.onChange(date);
          onChange && onChange(date, dateString);
        }}
        // onChange={onChange}
        // format={format || 'YYYY-MM-DD'}
        // picker={picker || null}
        placeholder={placeholder || mainT('select_date')}
        size="large"
        // defaultValue={defaultValue}
        variant="borderless"
        // value={defaultValue ? defaultValue : field.value}
      />

      {errorMsg && errorMsg && typeof errorMsg === 'string' && (
        <p className="error-p">{errorMsg}</p>
      )}
    </div>
  );
};

export default CustomAntdDatePicker;
