/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import queryString from 'query-string';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import './MerchentEmployeesPage.scss';
import { useTranslation } from 'react-i18next';
import NewUserIcon from '../../../common/icons/NewUserIcon';
import { useContext, useEffect } from 'react';
import EmployeesContext from '../../../contexts/employees-context/EmployeesContext';
import NewEmployeeModal from './NewEmployeeModal';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import getAllEmployeesApi from '../../../apis/employess-apis/getAllEmployeesApi';
import checkRes from '../../../utils/checkRes';
import UserContext from '../../../contexts/user-context/UserProvider';
import EmployeesTable from './EmployeesTable';

const MerchentEmployeesPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { i18n, t } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    setFormModalOpened,
    tableFilter,
    formModalOpened,
    setIsLoadingTableData,
    fetchTableDataCount,
    setAllFetchedTableData,
    setTablePagination
  } = useContext(EmployeesContext);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    setIsLoadingTableData(true);
    customApiRequest(
      getAllEmployeesApi(
        {
          ...tableFilter,
          ...values
        },
        user?.token,
        i18n.language
      ),
      (res) => {
        if (isMounted) {
          setIsLoadingTableData(false);
          if (checkRes(res) && res.data?.data?.data) {
            const data = res.data.data.data;
            setAllFetchedTableData(data);
            if (res.data.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        }
      },
      (error) => {
        setIsLoadingTableData(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchTableDataCount, search]);

  const sharedTrans = (key) => t(`merchentDashboard.${key}`);
  return (
    <div className="merchent-employees-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: sharedTrans('employees'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-create-employee-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">{t('employees.employees')}</div>
          </div>
          <button
            className="new-employee-btn"
            onClick={() => {
              setFormModalOpened(true);
            }}
          >
            <NewUserIcon />
            {t('employees.newEmployee')}
          </button>
        </div>

        <EmployeesTable />
      </div>

      {formModalOpened && <NewEmployeeModal />}
    </div>
  );
};

export default MerchentEmployeesPage;
