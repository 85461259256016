import { useNavigate } from 'react-router-dom';
import CustomBreadcrumb from '../../../common/bread-crumb/Breadcrumb';
import merchentRouterLinks from '../../../components/app/merchent-routes/merchentRouterLinks';
import routerLinks from '../../../components/app/routerLinks';
import HrArrowRtl from '../../../assets/imgs/icons/horizontal-arrow-rtl.png';
import HrArrowLtr from '../../../assets/imgs/icons/horizontal-arrow-ltr.png';
import './MerchentOtherOrdersPage.scss';
import { useTranslation } from 'react-i18next';
import useOtherServiceOrders from '../../../custom-hooks/useOtherServiceOrders';
import SharedAntdTable from '../../../common/shared-antd-table/SharedAntdTable';
import { useEffect, useState } from 'react';
import tableColumns from './tableColumns';

const MerchentOtherOrdersPage = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { isLoading, allFetchedData } = useOtherServiceOrders();

  const sharedTrans = (key) => t(`merchentDashboard.${key}`);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedData?.length >= 0) {
      const mappedData = mapDataSource(allFetchedData);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [allFetchedData]);

  return (
    <div className="merchent-other-orders-page shared-dashboard-page shared-custom-page">
      <CustomBreadcrumb
        arr={[
          {
            title: sharedTrans('home'),
            isLink: true,
            to: routerLinks?.homePage
          },
          {
            title: sharedTrans('dashboard'),
            isLink: true,
            to: merchentRouterLinks?.merchentDashboard
          },
          {
            title: t('videoOrImgOrders'),
            isLink: false
          }
        ]}
      />

      <div className="custom-container">
        <div className="title-create-store-btn-wrap">
          <div className="title-arrow-wrap">
            <button
              onClick={() => {
                navigate(-1);
              }}
            >
              {i18n.dir() === 'rtl' ? (
                <img src={HrArrowRtl} alt="back" />
              ) : (
                <img src={HrArrowLtr} alt="back" />
              )}
            </button>
            <div className="main-page-title">{t('videoOrImgOrders')}</div>
          </div>
        </div>

        <SharedAntdTable
          className="other-orders-table"
          hasPagination={false}
          tableDataSource={tableDataSource}
          tableColumns={tableColumns}
          allFetchedTableData={allFetchedData}
          // setDetailsModalOpened={setDetailsModalOpened}
          isLoadingTableData={isLoading}
        />
      </div>
    </div>
  );
};

export default MerchentOtherOrdersPage;
