/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getCatsApi from '../../apis/general/getCats';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchedData: [],
  setFetchedData: (v) => {}
  //
};

const CatsContext = createContext(INITIAL_VALUES);

export const CatsProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const customApiRequest = useCustomApiRequest();
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [fetchedData, setFetchedData] = useState(INITIAL_VALUES.fetchedData);

  useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    customApiRequest(
      getCatsApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedData(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount]);

  return (
    <CatsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        fetchedData,
        setFetchedData
      }}
    >
      {children}
    </CatsContext.Provider>
  );
};

export default CatsContext;
